import { Injectable } from '@angular/core';
import { ExportService } from './export.service';

@Injectable({
  providedIn: 'root'
})
export class TxtService extends ExportService {

  public async generateTable<T>(
    data: T[], {
      title = "title",
      aliasColumns = {},
      dateColumns = {},
      showColumns = []
    }, header=true) {

    try {
      let table = this.convertJSONToTxtTable(data, { aliasColumns, dateColumns, showColumns }, header);
      let blob = new Blob([table], { type: "text/csv" });
      let dataURL = await this.saveFile(blob);
      return dataURL;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  protected mapStringValues(value: string): string {
    return value
      .replace(/\s+/g, " ")
      .replace(/\"/g, "'");
  }

  public convertJSONToTxtTable<T>(
    data: Array<T>,
    {
      aliasColumns = {},
      dateColumns = {},
      showColumns = []
    }, header
  ): string {

    return this
      .convertJSONToTable(data, { aliasColumns, dateColumns, showColumns }, header)
      .map(row => row.join(";"))
      .join("\n");
  }
}
