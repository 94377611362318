import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SaldoClienteCtaCte } from '../interfaces/saldo-cliente-cta-cte';

@Injectable({
  providedIn: 'root'
})
export class GoogleSheetService {

  constructor(private http: HttpClient, private ngZone: NgZone) { }

  public async findSaldosCtasCtes(): Promise<any> {
    await this.signIn();
    let params = {
      spreadsheetId: '1QNDBjHNI02jQmTAdtEPukEyH9_Qz-8pIufRDpoLD52o',
      range: 'Cta.Cte.SIGE 2022',
    };
    return gapi.client.sheets.spreadsheets.values.get(params);
  }
  public async findConsultaSaldosDeFondo(): Promise<any> {
    await this.signIn();
    let params = {
      spreadsheetId: '1QNDBjHNI02jQmTAdtEPukEyH9_Qz-8pIufRDpoLD52o',
      range: 'Sdos.SIGE 2022',
    };
    return gapi.client.sheets.spreadsheets.values.get(params);
  }


  public async signIn() {
    if (!gapi.auth2.getAuthInstance().isSignedIn.get())
      await gapi.auth2.getAuthInstance().signIn();
  }

  public signOut() {
    gapi.auth2.getAuthInstance().signOut();
  }

  public async loadGoogleApiScript() {
    let script = document.createElement('script');
    script.src = "https://apis.google.com/js/api.js";
    (script.onload as any) = "this.onload=function(){};this.handleClientLoad()";
    (script as any).onreadystatechange = "if (this.readyState === 'complete') this.onload()";
    document.body.append(script);
  }

  public updateTablaSaldosCtaCte(saldos: Array<SaldoClienteCtaCte>): Observable<any> {
    return this.http.patch<any>(`${environment.apiEndpoint}/saldosCtaCte/actualizarTabla`, saldos);
  }

  public uploadSendMail(saldos: Array<SaldoClienteCtaCte>, downloadMail, nombreGrupoEconomico = null): Observable<Array<string>> {
    const params = new URLSearchParams([])
    params.append('download', downloadMail)
    if (nombreGrupoEconomico) params.append('grupoEconomico', nombreGrupoEconomico)
    return this.http.patch<Array<string>>(`${environment.apiEndpoint}/saldosCtaCte/mail?${params.toString()}`, saldos);
  }
}