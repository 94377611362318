import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HABILITADO } from '../enums/habilitado';
import { AltaObligacion } from '../interfaces/alta-obligacion';
import { IdDescripcionCod } from '../interfaces/id-descripcion-cod';
import { Page } from '../interfaces/page';
import { PlantillaVencimientos } from '../interfaces/plantillas-vencimientos';
import { Obligacion } from '../interfaces/obligacion';

@Injectable({
  providedIn: 'root'
})
export class AbmObligacionesService {

  recurso: string = '/definir';

  constructor(private http: HttpClient) { }

  public findById(id: number): Observable<AltaObligacion> {
    return this.http.get<AltaObligacion>(environment.apiEndpoint + "/obligaciones/findById/" + id);
  }
  public findAreas(): Observable<Page<IdDescripcionCod>> {
    let endpoint = '/taopcion/area';
    return this.http.get<Page<IdDescripcionCod>>(environment.apiEndpoint + endpoint);
  }
  public findTiposVencimiento(): Observable<any> {
    let endpoint = '/taopcion/tipovencimiento';
    return this.http.get(environment.apiEndpoint + endpoint);
  }
  public findOrganismos(): Observable<any> {
    let endpoint = '/organismos';
    return this.http.get(environment.apiEndpoint + endpoint);
  }

  /**
   * 
   * @param {object} param0 
   * @param {string} param0.area código area de la obligación
   * @param {number} param0.organismo id organismo
   * @param {string} param0.tipoVencimiento código tipo de vencimiento
   * @param {number} param0.tipoObligacion id tipo de obligación
   * 
   */
  public findObligaciones({
    area = "",
    organismo = 0,
    tipoVencimiento = "",
    tipoObligacion = 0,
    habilitado = HABILITADO.SI
  } = {}): Observable<Page<AltaObligacion>> {

    let params = new URLSearchParams([["habilitado", habilitado]]);

    if (area)
      params.append("area", area);
    if (organismo)
      params.append("organismo", organismo.toString());
    if (tipoVencimiento)
      params.append("tipo_vencimiento", tipoVencimiento);
    if (tipoObligacion)
      params.append("tipo_obligacion", tipoObligacion.toString());

    return this.http.get<Page<AltaObligacion>>(`${environment.apiEndpoint}/obligaciones/dto?${params.toString()}`);
  }
  public findObligacionesAsignadas({
    obligacion = 0,
    asignados = true,

  } = {}): Observable<Page<AltaObligacion>> {
    let params = new URLSearchParams([["pageSize", "10000"],["asignados", asignados.toString()]]);
    return this.http.get<Page<AltaObligacion>>(`${environment.apiEndpoint}/obligaciones/${obligacion}/clientes?${params.toString()}`);
  }
  public findObligacionesAnuales(): Observable<Page<Obligacion>> {
    return this.http.get<Page<Obligacion>>(`${environment.apiEndpoint}/obligaciones/anual`)
  }

  public createObligacion(obligacion: AltaObligacion) {
    let endpoint = '/obligaciones/add';
    return this.http.post(environment.apiEndpoint + endpoint, obligacion);
  }

  public editObligacion(obligacion: AltaObligacion) {
    let endpoint = '/obligaciones/edit';
    return this.http.put(environment.apiEndpoint + endpoint, obligacion);
  }

  public habilitarMesVtoClienteSocio(idObligacion: number): Observable<boolean> {
    let habilitar = [33, 40].indexOf(idObligacion) !== -1;
    return of(habilitar);
  }

  public habilitarMesVtoClienteNoSocio(idObligacion: number): Observable<boolean> {
    let habilitar = [33, 40].indexOf(idObligacion) !== -1;
    return of(habilitar);
  }

  public getVencimientos(idObligacion: number, anio: number): Observable<PlantillaVencimientos[]> {
    return this.http.get<PlantillaVencimientos[]>(`${environment.apiEndpoint}/obligaciones/${idObligacion}/vencimientos/${anio}`);
  }
  public getVencimientosByCliente(idObligacion: number, anio: number, clienteId: number): Observable<PlantillaVencimientos[]> {
    return this.http.get<PlantillaVencimientos[]>(`${environment.apiEndpoint}/obligaciones/${idObligacion}/vencimientos/${anio}/cliente/${clienteId}`);
  }

  public updateVencimientos(plantillaVencimientos: Array<PlantillaVencimientos>): Observable<void> {
    return this.http.put<void>(`${environment.apiEndpoint}/obligaciones/vencimientos`, plantillaVencimientos);
  }
}
