import { Injectable } from '@angular/core';
import { convertMillimetersToTwip, Document as DocxDocument, ImageRun, Packer, PageOrientation, Paragraph, Table, TableCell, TableRow, WidthType } from 'docx';
import { ExportService } from './export.service';

const PageSize = {
  A4: {
    width: 210,
    height: 297
  }
}
@Injectable({
  providedIn: 'root'
})
export class MswordService extends ExportService {

  public generateTable<T>(data: Array<T>,
    {
      title = "title",
      pageSize = "A4",
      pageOrientation = "landscape",
      aliasColumns = {},
      dateColumns = {},
      showColumns = []
    } = {},
    imagenGrafico?: any): void {
    const orientation = pageOrientation == PageOrientation.LANDSCAPE ? PageOrientation.LANDSCAPE : PageOrientation.PORTRAIT;
    const defaultMargin = convertMillimetersToTwip(10);
    const page = pageSize in PageSize ? PageSize[pageSize] : PageSize.A4;
    const maxWidth = convertMillimetersToTwip(orientation === PageOrientation.LANDSCAPE ? page.height : page.width) - defaultMargin * 2;

    let children = [
      new Paragraph(title),
      this.convertJSONToDocxTable(data, { aliasColumns, dateColumns, showColumns, maxWidth }),
    ];
    if (imagenGrafico) {
      let image = new Image();
      image.src = imagenGrafico;
      children.push(
        new Paragraph("\nGráfico de informe"),
        new Paragraph({
          children: [
            new ImageRun({
              data: imagenGrafico,
              transformation: {
                width: orientation === PageOrientation.LANDSCAPE ? 800 : 700,
                height: orientation === PageOrientation.LANDSCAPE ? 330 : 288
              },
            })
          ],
        }),
      )
    }
    let doc = new DocxDocument({
      sections: [{
        properties: {
          page: {
            margin: {
              top: defaultMargin,
              right: defaultMargin,
              bottom: defaultMargin,
              left: defaultMargin
            },
            size: {
              orientation,
              width: convertMillimetersToTwip(page.width),
              height: convertMillimetersToTwip(page.height)
            }
          }
        },
        children
      }]
    });

    Packer.toBlob(doc).then((blob) => {
      this.saveFile(blob);
    });
  }

  public convertJSONToDocxTable<T>(
    data: Array<T>,
    {
      aliasColumns = {},
      dateColumns = {},
      showColumns = [],
      maxWidth
    }
  ): Table {

    const columnWidth = Math.round(maxWidth / showColumns.length);

    let mappedData = super.convertJSONToTable(data, { aliasColumns, dateColumns, showColumns });

    return new Table({
      columnWidths: showColumns.map(() => columnWidth),
      width: { size: maxWidth, type: WidthType.AUTO },
      rows: [
        new TableRow({
          tableHeader: true,
          children: mappedData[0].map(d =>
            new TableCell({ children: [new Paragraph(d)] })
          )
        })
      ].concat(
        mappedData
          .slice(1)
          .map(data =>
            new TableRow({
              children: data.map(d =>
                new TableCell({ children: [new Paragraph(d)] })
              )
            })
          )
      )
    });
  }
  
}
