import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { DocumentoBase } from "../../../interfaces/documento-base";
import { DocumentacionService } from "../../../services/documentacion.service";
import { Documento } from "../../../interfaces/documento";
import { CategoriaDocumento, CategoriaDocumentoIdEnum } from '../../../interfaces/categoria-documento';
import { ObservacionesService } from "src/app/services/observaciones.service";
import { PlantillasObservaciones } from "src/app/interfaces/plantillas-observaciones";
import { Observacion } from "src/app/interfaces/observacion";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { forkJoin, Observable, of } from "rxjs";
import { tap } from "rxjs/operators";
import { switchMap } from "rxjs/operators";
import { Subscriber } from "rxjs";
import { ERROR_MESSAGES } from 'src/app/enums/error-messages';
const swal = require("sweetalert");

@Component({
  selector: "app-archivos-finales",
  templateUrl: "./archivos-finales.component.html",
  styleUrls: ["./archivos-finales.component.scss"]
})
export class ArchivosFinalesComponent implements OnInit {

  // DOCUMENTACION
  @ViewChild("modalCumplir") modalCumplir: ElementRef;
  @ViewChild("modalObservaciones") modalObservaciones: ElementRef;

  @Input() categoriaId: string;
  @Input() plantillasObservaciones: PlantillasObservaciones;

  @Output() agregar = new EventEmitter<any>();
  @Output() observacionAgregada = new EventEmitter();
  @Output() eliminar = new EventEmitter<any>();
  @Output() sindocumentacion = new EventEmitter<DocumentoBase>();
  @Output() beforeAddDocumentacion = new EventEmitter<{
    padreId: number,
    categoria: CategoriaDocumento,
    documento: DocumentoBase
  }>();

  public form: FormGroup;
  public caso: string = null;
  public observaciones: Array<Observacion>;

  public requiereObservacion = false;
  private observacionesAgregadaSubscriber: Subscriber<any> = null;
  private observacionesAgregadaObservable: Observable<any> = new Observable(observer => {
    this.observacionesAgregadaSubscriber = observer;
  });

  public nuevo: boolean = true;
  @Input() set padreId(value: number) {
    this.init(value);
    this.initObservaciones(value);
  }
  @Input() set categorias(value: Array<CategoriaDocumento>) {
    this._categorias = value;
    this.init(this._padreId);
    this.initObservaciones(this._padreId);
  }

  @Input() set soloLectura(value: boolean) {
    this._soloLectura = value;
  }

  @Input() set update(value: number) {
    this.init(this._padreId);
    this.initObservaciones(this._padreId);
  }

  @Input() set swalGuardar(value: boolean) {
    this._swalGuardar = value;
  }

  @Input() recurso: string;
  @Input() requireConfirmation: boolean = false;
  @Input() confirmation: Observable<[boolean, string]> = null;
  @Input() eliminable: boolean = true;
  @Input() swapPadreCategoria: boolean = false;
  @Input() isEjercicioRegular = false;

  public _soloLectura: boolean;
  public _padreId: number;
  public _categorias: Array<CategoriaDocumento>;
  public _swalGuardar: boolean = true;

  public loading: boolean = false;
  public documentaciones = {};
  public cantidadDocumentaciones: any = [];
  public categoriaSeleccionada: CategoriaDocumento;
  public documentoSeleccionado: Documento = null;

  constructor(formBuilder: FormBuilder, private documentacionService: DocumentacionService, public router: Router, private observacionesService: ObservacionesService) {
    this.form = formBuilder.group({
      mensaje: [null, Validators.required],
      plantillaSeleccionada: [null, false]
    });
  }

  ngOnInit(): void {
  }

  public init(padreId: any) {
    this.reset();
    if (this.recurso && padreId && this._categorias) {
      const sumaSaldosIndex = this._categorias.findIndex(categoria => categoria.nombre === 'Sumas y Saldos')
      if (sumaSaldosIndex != -1) {
        this._categorias[sumaSaldosIndex].id = 'SUMAS_Y_SALDOS'
        const sumaSaldos = JSON.parse(JSON.stringify(this._categorias[sumaSaldosIndex]))
        this._categorias.splice(0, 0, sumaSaldos)
        this._categorias.splice(sumaSaldosIndex + 1, 1)
      }
      this.plantillasObservaciones.mostrar = false;
      this.loading = true;
      this._padreId = padreId;
      this.categoriaSeleccionada = null;
      forkJoin(
        this._categorias.map(c => this.swapPadreCategoria ? this.documentacionService.listar(this.recurso, c.id, padreId) : this.documentacionService.listar(this.recurso, padreId, c.id))
      ).subscribe(documentaciones => {
        documentaciones.forEach((documentacion, i) => {
          if (this.swapPadreCategoria) {
            this.documentaciones[this._categorias[i].id] = documentacion;
            this.removerDocumentosVacios(this._categorias[i].id);
            this.cantidadDocumentaciones[this._categorias[i].id] = documentacion.documentos.length;
          } else {
            this.documentaciones[documentacion.categoria.id] = documentacion;
            this.removerDocumentosVacios(documentacion.categoria.id);
            this.cantidadDocumentaciones[documentacion.categoria.id] = documentacion.documentos.length;
          }
          this.loading = false;
        });
      });
    }
  }

  public nuevoDocumento(categoria: CategoriaDocumento) {
    if (this.existeDocumentoVacio(categoria.id))
      return;

    if (!this.documentaciones[categoria.id])
      this.documentaciones[categoria.id] = { categoria, documentos: [], selected: false };

    this.loading = false;
    this.nuevo = false;

    let documentos = this.documentaciones[categoria.id].documentos as Documento[];

    documentos.forEach((doc) => doc.seleccionado = false);

    documentos.reverse().push({
      id: null,
      nombre: "",
      formato: "",
      url_archivo: "",
      carga_outsourcing: false,
      eliminable: true,
      modificable: true,
      fecha_carga: "",
      observacion: "",
      seleccionado: true,
      subcategoria: null,
      nivel: null
    });

    documentos.reverse();
  }

  public agregarDocumento(categoria: CategoriaDocumento, documento: Documento) {
    this.requiereObservacion = false;
    this.documentoSeleccionado = documento;
    this.plantillasObservaciones.mostrar = false;

    (this.confirmation || of([false, ""])).pipe(
      tap(([requiereObservacion]) => {
        if (this.plantillasObservaciones.mostrar = this.requiereObservacion = requiereObservacion)
          swal({
            title: "¡Lo siento!",
            text: "Debe agregar observación.",
            icon: "warning",
            buttons: false,
            timer: 2000
          });
      }),
      switchMap(() => this.requiereObservacion ? this.observacionesAgregadaObservable : of(null))
    ).subscribe(() => {

      if (this.requiereObservacion && (!this.form.controls.mensaje.value || !/\w+/.test(this.form.controls.mensaje.value))) {
        return;
      }

      this.loading = true;
      documento.observacion = this.form.controls.mensaje.value;
      (this.swapPadreCategoria ? this.documentacionService.agregarDocumento(this.recurso, categoria.id, this._padreId, documento) : this.documentacionService.agregarDocumento(this.recurso, this._padreId, categoria.id, documento)).subscribe(async (response: Documento) => {

        this.nuevo = true;

        let documentos = this.documentaciones[categoria.id].documentos as Documento[];

        Object.assign(documentos.find(d => !d.id), response, { seleccionado: true });

        // if (this.documentaciones[categoria.id].documentos.length === 1) {
        //   if (this.documentaciones[categoria.id].documentos[0].nombre === "") {
        //     this.documentaciones[categoria.id].documentos[0] = response;
        //   } else {
        //     this.documentaciones[categoria.id].documentos.push(response);
        //   }
        // } else {
        //   this.documentaciones[categoria.id].documentos.push(response);
        // }
        await this.vincularVencimientoEECC(categoria);
        this.cantidadDocumentaciones[categoria.id]++;
        this.documentaciones[categoria.id].documentos = this.documentaciones[categoria.id].documentos.filter(documento => documento.nombre !== "");
        this.loading = false;
        this.agregar.emit({
          categoria,
          documento: response
        });
        this.documentoSeleccionado=response
      },
        error => {
          this.loading = false;
          swal("Lo sentimos!", error.error?.message, "error");
        }
      );
    },
      error => {
        this.loading = false;
        swal("Lo sentimos!", error.error?.message, "error");
      }
    );

    this.beforeAddDocumentacion.emit({ padreId: this._padreId, categoria, documento });
  }

  public async vincularVencimientoEECC(categoria: CategoriaDocumento) {
    return new Promise((resolve) => {
      this.isEjercicioRegular && categoria.id === CategoriaDocumentoIdEnum.EECC_EXCEL ?
        this.documentacionService.setVencimientoEECC(this._padreId).subscribe(() => { resolve(true) }/* , error => swal("Lo sentimos!", error.error?.message, "error") */) : resolve(true);
    })
  }
  private revertirVinculacionEECC(categoria: CategoriaDocumento) {
    this.isEjercicioRegular && categoria.id === CategoriaDocumentoIdEnum.EECC_EXCEL ?
      this.documentacionService.reverseEECC(this._padreId).subscribe(() => { }) : null
  }
  public eliminarDocumento(categoria: CategoriaDocumento, documento: Documento) {
    if (this.documentaciones[categoria.id]) {
      swal({
        title: "¿Estás seguro?",
        text: "El registro se eliminará de forma permanente en el sistema.",
        icon: "warning",
        buttons: {
          cancel: {
            text: "No, cancelar!",
            value: null,
            visible: true,
            className: "",
            closeModal: true
          },
          confirm: {
            text: "Si, continuar!",
            value: true,
            visible: true,
            className: "bg-danger",
            closeModal: false
          }
        }
      }).then((isConfirm) => {
        if (isConfirm) {
          /* Confirmación  */
          (this.swapPadreCategoria ?
            this.documentacionService.eliminarAuditoria(this.recurso, categoria.id, this._padreId, documento) :
            this.documentacionService.eliminarAuditoria(this.recurso, this._padreId, this.documentaciones[categoria.id].categoria.id, documento))
            .subscribe((response: DocumentoBase) => {
              this.revertirVinculacionEECC(categoria);
              this.eliminar.emit({
                categoria,
                documento: response
              });
              swal({ title: "¡Operación exitosa!", text: "La operación fue realizada exitosamente.", icon: "success", buttons: false, timer: 1000 });
              for (let i = 0; i < this.documentaciones[categoria.id].documentos.length; i++) {
                if (this.documentaciones[categoria.id].documentos[i].nombre === response.nombre) {
                  this.documentaciones[categoria.id].documentos.splice(i, 1);
                  break;
                }
              }
              this.cantidadDocumentaciones[categoria.id]--;
              if (!this.existeDocumentacion(categoria.id)) {
                this.sindocumentacion.emit();
              }
            }, error => { swal("¡Lo sentimos!", error?.error?.message || ERROR_MESSAGES.DEFAULT, "error") }
            );
          /* Confirmación  */
        } else {
          swal("¡Cancelado!", "La acción se canceló.", "error");
        }
      });
    }
  }

  public agregarObservacion(categoria: CategoriaDocumento, documento: Documento) {

    if (!this.form.valid)
      return;

    if (this.requiereObservacion) {
      this.observacionesAgregadaSubscriber.next();
      return;
    }

    documento.observacion = this.form.controls.mensaje.value;
    forkJoin((this.swapPadreCategoria ? [
      this.documentacionService.agregarObservacion(
        this.recurso,
        categoria.id,
        this.documentaciones[categoria.id].categoria.id, documento),
      this.observacionesService.agregar(
        this.recurso,
        categoria.id,
        {
          mensaje: `ARCHIVO: ${documento.nombre} OBSERVACIÓN: ${this.form.controls.mensaje.value}`,
          id: 0,
          fechahora: "",
          usuario: JSON.parse(localStorage.getItem("sige_personal"))
        }
      )
    ] :
      [
        this.documentacionService.agregarObservacion(
          this.recurso,
          this._padreId,
          this.documentaciones[categoria.id].categoria.id, documento),
        this.observacionesService.agregar(
          this.recurso,
          this._padreId,
          {
            mensaje: `ARCHIVO: ${documento.nombre} OBSERVACIÓN: ${this.form.controls.mensaje.value}`,
            id: 0,
            fechahora: "",
            usuario: JSON.parse(localStorage.getItem("sige_personal"))
          }
        )
      ]
    )).subscribe(([observacion, observacionGeneral]) => {

      this.observaciones.push(observacion);
      this.observaciones.push(observacionGeneral);

      this.plantillasObservaciones.mostrar = false;

      this.observacionAgregada.emit(observacion);
      swal({
        title: "Guardado",
        text: "La observación se guardó exitosamanete.",
        icon: "success",
        buttons: false,
        timer: 800
      });
    },
      error => {
        swal("Error.", error.error.message, "error");
      }
    );
  }

  private existeDocumentoVacio(categoriaId: string): boolean {
    return this.documentaciones[categoriaId].documentos.some(d => this.documentoVacio(d));
  }

  private documentoVacio(documento: Documento) {
    return !/\w/.test(documento.nombre);
  }

  private removerDocumentosVacios(categoriaId: string): void {
    if (this.documentaciones[categoriaId])
      this.documentaciones[categoriaId].documentos = this.documentaciones[categoriaId].documentos.filter(d => !this.documentoVacio(d));
  }

  private existeDocumentacion(categoriaId: string): boolean {
    return this.documentaciones[categoriaId]?.documentos.length > 0 && !this.existeDocumentoVacio(categoriaId);
  }

  // OBSERVACIONES
  public seleccionarCategoria(categoria: CategoriaDocumento) {
    this.plantillasObservaciones.mostrar = false;
    this.categoriaSeleccionada = categoria;
    if (this.documentaciones[categoria.id]?.documentos.length > 0) {
      this.documentoSeleccionado = this.documentaciones[categoria?.id]?.documentos[this.documentaciones[categoria?.id]?.documentos.length - 1];
      this.form.controls.mensaje.setValue(this.documentoSeleccionado.observacion);
    } else {
      this.documentoSeleccionado = null;
      this.form.controls.mensaje.setValue("");
    }
  }

  public seleccionarDocumento(documento: Documento) {
    if (documento.id || this.documentoSeleccionado?.id) {
      this.plantillasObservaciones.mostrar = false;
      this.form.controls.mensaje.setValue(documento.observacion);
    }
    this.documentoSeleccionado = documento;
  }

  public initObservaciones(padreId: number) {
    this._padreId = null;
    this.observaciones = [];
    if (this.recurso && padreId) {
      this._padreId = padreId;
      (this.swapPadreCategoria && this._categorias ?
        forkJoin(this._categorias.map(c => this.observacionesService.listar(this.recurso, c.id))) :
        !isNaN(padreId) ?
          this.observacionesService.listar(this.recurso, padreId) : of([]))
        .subscribe((response: Array<Observacion>) => {
          if (this.swapPadreCategoria) {
            response.forEach(r => {
              this.observaciones = this.observaciones.concat(r);
            })
          } else
            this.observaciones = response;
        });
    }
  }

  public onSelectPlantillaObservaciones($event): void {
    this.form.controls.mensaje.setValue($event.currentTarget.value);
  }

  public reset(): void {
    this.requiereObservacion = false;
  }

}
