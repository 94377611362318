import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Documento } from 'src/app/classes/documento';
import { ERROR_MESSAGES } from 'src/app/enums/error-messages';
import { Accionista, Socio } from 'src/app/interfaces/cliente-datos-societarios';
import { IdNombreCodigo } from 'src/app/interfaces/id-nombre-codigo';
import { Page } from 'src/app/interfaces/page';
import { FechaPipe } from 'src/app/pipes/fecha.pipe';
import { AuthService } from 'src/app/services/auth.service';
import { DatosSocietariosService } from 'src/app/services/clientes/datos-societarios.service';
import modalUtils from 'src/app/utils/modal';
import { DdjjService } from '../../../services/ddjj.service';
import { CargaDocumentosComponent } from '../carga-documentos/carga-documentos.component';
import { BeneficiarioDdjj } from 'src/app/interfaces/beneficiario-ddjj';
const swal = require('sweetalert');

@Component({
  selector: 'app-tenencia-de-acciones',
  templateUrl: './tenencia-de-acciones.component.html',
  styleUrls: ['./tenencia-de-acciones.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TenenciaDeAccionesComponent implements OnInit {

  @ViewChild('modal') modal: ModalDirective;
  @ViewChild('modalDDJJ') modalDDJJ: ModalDirective;
  @ViewChild('carga') cargaDocumentos: CargaDocumentosComponent;

  @Input() set idCliente(value) {
    if(value){
      this.id = value;
      this.init();
    }
  }

  @Input() bandera: boolean;

  form: FormGroup;
  formModal: FormGroup;
  public listadoSiNo: Array<any> = [{ id: true, text: "SI" }, { id: false, text: "NO" }];
  public listadoTipoVariacion: Array<any> = [{ id: '01', text: "Constitución" }, { id: '02', text: "Aumento de Capital" }, { id: '03', text: "Disminución de Capital" }, { id: '04', text: "Transferencia" }];
  public listadoClaseAccion: Array<any> = [{ id: '01', text: "ORD. NOMINATIVA NO ENDOSABLE" }, { id: '02', text: "ORD. AL PORTADOR" }, { id: '03', text: "PREFERIDAS" }, { id: '04', text: "ESCRITURALES" }, { id: '05', text: "CUOTAS SOCIALES" }];
  public accionistas: Array<Accionista> = [];
  public listadoAccionistas: Array<any> = [];
  public accionista: Accionista;
  public variaciones: Array<Accionista> = [];
  public variacionesMostradas: Array<Accionista> = [];
  public variacion: Accionista;

  public orden: number = 1;
  public ordenV: number = 1;
  public filtrax: string;
  public filtraxV: string;
  private defaultErrorMessage = "Ocurrió un error al aplicar los cambios.";

  public totalAccionistas: Accionista;
  public totalVariaciones: Accionista;

  public declaraciones = []
  public documentos: Array<Documento> = []
  public declaracionSeleccionada: BeneficiarioDdjj = null
  public fechaPipe = new FechaPipe()
  public isAlta = true

  filter: string = "";
  bsConfig: Partial<BsDatepickerConfig>;
  loading: boolean;
  loadingVariacion: boolean;
  public id: number;

  public usuario: IdNombreCodigo;


  constructor(private formBuilder: FormBuilder,
    private localeService: BsLocaleService,
    private datosSocietariosService: DatosSocietariosService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private ddjjService: DdjjService) {
    this.loading = true;
    this.loadingVariacion = true;
    this.form = formBuilder.group({
      'tipoVariacion': [null, Validators.required],
      'fecha': [null],
      'claseAccion': [null, Validators.required],
      'socioTransfiere': [{ value: null, disabled: true }, Validators.required],
      'cantidadAcciones': [null],
      'valorNominalAccion': [null, Validators.required],
      'valorNominalTotal': [null],
      'votosPorAccion': [null, Validators.required],
      'totalVotos': [null]
    });
    this.formModal = formBuilder.group({
      'nombre': [null, Validators.required],
      'fecha': [null, Validators.required]
    });
    this.bsConfig = Object.assign({},
      {
        containerClass: 'theme-angle',
        dateInputFormat: 'DD/MM/YYYY'
      });
    this.localeService.use('es');

  }

  public setAuthorization() {
    this.usuario = JSON.parse(localStorage.getItem("sige_personal"));
  }

  ngOnInit(): void {
    if(!this.id && this.bandera){
      this.id = parseInt(this.activatedRoute.snapshot.paramMap.get('id')) || null;
      this.init();
    }
  }

  public init (){
    this.setAuthorization();
    forkJoin([
      this.datosSocietariosService.findAccionistas(this.id),
      this.datosSocietariosService.findVariaciones(this.id),
      this.datosSocietariosService.findSociosAccionesTotal(this.id)
    ]).pipe(finalize(() => {
      this.loading = false;
      this.loadingVariacion = false;
    })).subscribe(([accionistas, variaciones, total]) => {

      if (variaciones.content.length) {
        this.variaciones = variaciones.content;
      }
      if (accionistas.length) {
        this.calculateAcciones(accionistas);
        this.orden = this.orden * -1;
        this.ordenarNumerico(accionistas, 'totalAcciones');
        this.accionista = this.accionistas[0] 
        this.see(this.accionista/*  || this.accionistas[0] */);
      }
    }, error => {
      swal("¡Lo sentimos!", error.error.message, "error");
    });
  }
  public findAccionistas() {
    this.datosSocietariosService.findAccionistas(this.id).subscribe((accionistas: Array<Accionista>) => {
      if (accionistas.length) {
        this.calculateAcciones(accionistas);
      }
    })
  }
  public getVariaciones() {
    this.loadingVariacion = true;
    this.datosSocietariosService.findVariaciones(this.id).subscribe((variaciones: Page<Accionista>) => {
      if (variaciones.content.length) {
        this.variaciones = variaciones.content;
      }
      this.findVariaciones(this.accionista);
      this.loadingVariacion = false;
    })
  }
  public calculateAcciones(accionistas: Array<Accionista>) {
    this.totalAccionistas = this.defaultObject()
    Object.assign(this.totalAccionistas, accionistas.pop());
    this.accionistas = accionistas;
    this.listadoAccionistas = this.accionistas.map(o => { return { text: (o.socio as Socio).razonSocial, id: (o.socio as Socio).id } });
    this.accionistas.forEach(accionista => {
      this.totalAccionistas.capitalSocial += accionista.capitalSocial = accionista.cantidadAcciones * accionista?.valorNominalAccion;
      this.totalAccionistas.aumentoCapital += accionista.aumentoCapital = this.getAumentoDeCapital(accionista);
      this.totalAccionistas.aumentoCantAcciones += accionista.aumentoCantAcciones = accionista.aumentoCapital/accionista.valorNominalAccion;
      this.totalAccionistas.totalAcciones += accionista.totalAcciones = accionista.cantidadAcciones * accionista?.valorNominalAccion;
    });
    this.accionistas.forEach(accionista => {
      if (accionista?.cantidadAcciones) {
        this.totalAccionistas.porcentajeParticipacion += accionista.porcentajeParticipacion = accionista.capitalSocial / this.totalAccionistas.capitalSocial;
      } else accionista.porcentajeParticipacion = 0;
    });
    this.ordenarNumerico(accionistas, 'totalAcciones');
  }
  public findVariaciones(accionista: Accionista) {
    this.variacionesMostradas = this.variaciones.filter(v => v?.socio?.id === accionista?.socio?.id);
    this.totalVariaciones = this.defaultObject();
    this.variacionesMostradas.forEach(variacion => {
      this.totalVariaciones.capitalSocial += variacion.capitalSocial = variacion.cantidadAcciones * variacion?.valorNominalAccion;
    });
    this.ordenar(this.variacionesMostradas, 'fecha');
  }

  public formatDate(date: string) {
    if (date && moment(date).isValid())
      return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
    else return null;
  }
  public unFormatDate(date: string) {
    if (date && moment(date).isValid())
      return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
    else return null;
  }
  public getClaseAccion(codigo: string): string {
    let texto = this.listadoClaseAccion.find(c => c.id === codigo).text;
    return texto.charAt(0).toUpperCase() + texto.slice(1).toLowerCase();
  }
  public getTipoVariacion(codigo: string): string {
    return this.listadoTipoVariacion.find(c => c.id === codigo).text;
  }
  public getAumentoDeCapital(accionista: Accionista): number {
    let aumento: number = 0;
    this.variaciones.forEach(v => {
      if (accionista?.socio?.id === v?.socio?.id && (v?.tipoVariacion === "02" || v?.tipoVariacion === "03"))
        aumento += v.cantidadAcciones * v.valorNominalAccion * (v?.tipoVariacion === "03" ? -1 : 1);
    });
    return aumento;
  }
  public getTotal(arreglo: Array<any>, key: string): number {
    let total: number = 0;
    arreglo.forEach(a => {
      total += a[key];
    });
    return total;
  }

  public submitForm($event, value: any): void {
    $event.preventDefault();
    for (let c in this.form.controls) {
      this.form.controls[c].markAsTouched();
    }
    if (this.form.valid) {
      value = Object.assign({}, value);
      value.dc = null;
      value.fecha = this.unFormatDate(value.fecha);
      value.socio = this.accionista?.socio;
      value.socioTransfiere = this.accionistas.find(a => a.socio.id === value.socioTransfiere)?.socio || this.defaultSocio();
      Object.assign(this.variacion, value);
      this.datosSocietariosService.createVariacion(this.variacion, this.id).subscribe((variacion: Accionista) => {
        this.variacion = variacion;
        this.setForm(variacion);
        this.init();
        this.modal.hide();
        swal({
          title: 'Guardado',
          text: 'El registro se guardó exitosamente.',
          icon: 'success',
          buttons: false,
          timer: 1600
        });
      }, (error) => {
        this.variacion = this.defaultObject();
        swal('¡Lo sentimos!', error.error?.message || this.defaultErrorMessage, 'error');
      });
    }
  }

  public valueRequired(value: string, form = 'form') {
    return this[form].controls[value].hasError('required') && (this[form].controls[value].dirty || this[form].controls[value].touched);
  }

  public create() {
    this.variacion = this.defaultObject();
    this.form.reset();
    this.setForm(this.variacion);
  }

  public see(accionista: Accionista) {
    this.accionista = accionista;
    this.findVariaciones(accionista);
  }

  edit(variacion: Accionista) {
    this.variacion = variacion;
    this.setForm(this.variacion);
  }

  private async setForm(variacion: Accionista) {
    variacion = Object.assign({}, variacion);
    variacion.fecha = this.formatDate(variacion?.fecha);
    this.form.patchValue(variacion);
    this.form.controls['socioTransfiere'].setValue(variacion?.socioTransfiere.id || null);
  }

  private defaultObject(): Accionista {
    return {
      id: null,
      tipoVariacion: null,
      fecha: null,
      claseAccion: null,
      cantidadAcciones: null,
      valorNominalAccion: null,
      valorNominalTotal: null,
      votosPorAccion: null,
      totalVotos: 0,
      socioTransfiere: this.defaultSocio(),
      socio: this.defaultSocio(),
      capitalSocial: 0,
      aumentoCapital: 0,
      aumentoCantAcciones: 0,
      totalAcciones: 0,
      porcentajeParticipacion: 0
    };
  }
  private defaultSocio(): Socio {
    return {
      id: null,
      sinCuit: null,
      razonSocial: null,
      cuit: null,
      mail: null,
      telefono: null,
      fechaNac: null,
      nacionalidad: null,
      tipoDocumento: null,
      ndocumento: null,
      socioFundador: null,
      tipoPersona: null,
      cliente: null,
      datosGrales: null,
      documento: null,
    }
  }

  public ordenar(arreglo: Array<any>, campo: string) {
    let orden: number;
    if (arreglo === this.accionistas) {
      this.filtrax = campo;
      orden = this.orden;
    } else if (arreglo === this.variacionesMostradas) {
      this.filtraxV = campo;
      orden = this.ordenV;
    }

    arreglo.sort((a, b) => {
      const codigoA = (a[campo] || "").toString().toLowerCase();
      const codigoB = (b[campo] || "").toString().toLowerCase();
      if (codigoA < codigoB) {
        return -1 * (orden);
      }
      if (codigoA > codigoB) {
        return 1 * (orden);
      }
      return 0;
    });
  }
  public ordenarSubcampo(arreglo: Array<any>, campo: string, subcampo: string) {
    let orden: number;
    if (arreglo === this.accionistas) {
      this.filtrax = campo;
      orden = this.orden;
    } else if (arreglo === this.variaciones) {
      this.filtraxV = campo;
      orden = this.ordenV;
    }

    arreglo.sort((a, b) => {
      const codigoA = a[campo][subcampo]?.toString().toLowerCase();
      const codigoB = b[campo][subcampo]?.toString().toLowerCase();
      if (codigoA < codigoB) {
        return -1 * (orden);
      }
      if (codigoA > codigoB) {
        return 1 * (orden);
      }
      return 0;
    });
  }
  public ordenarNumerico(arreglo: Array<any>, campo: string) {
    let orden: number;
    if (arreglo === this.accionistas) {
      this.filtrax = campo;
      orden = this.orden;
    } else if (arreglo === this.variacionesMostradas) {
      this.filtraxV = campo;
      orden = this.ordenV;
    }
    arreglo.sort((a, b) => {
      const codigoA = a[campo];
      const codigoB = b[campo];
      if (codigoA < codigoB) {
        return -1 * (orden);
      }
      if (codigoA > codigoB) {
        return 1 * (orden);
      }
      return 0;
    });
    arreglo.forEach((a, i) => {
      let deleted: any[];
      if (this.isDisminucion(a)) {
        deleted = arreglo.splice(i, 1);
        if (orden === 1)
          arreglo.splice(0, 0, deleted[0]);
        else arreglo.push(deleted[0]);
      }


    })
  }
  public eliminarExitoso() {
    this.findAccionistas();
    this.getVariaciones();
    swal({
      title: 'Eliminado',
      text: 'La variación se eliminó exitosamente.',
      icon: 'success',
      buttons: false,
      timer: 1600
    });
  }
  public deleteVariacion(variacion: Accionista) {
    swal({
      title: 'Eliminar variación',
      text: '¿Está seguro que quiere eliminar esta variación?',
      icon: 'warning',
      buttons: {
        cancel: { text: 'Cancelar', value: null, visible: true, className: '', closeModal: true },
        danger: { text: 'Eliminar', value: true, visible: true, className: '', closeModal: false }
      }
    }).then((isConfirm) => {
      if (isConfirm) {
        this.datosSocietariosService.deleteVariacion(variacion, this.id).
          subscribe((response: any) => {
            if (response) {
              this.eliminarExitoso();
            }
            else {
              this.forzarDeleteValidacion(variacion);
            }
          }, (error) => {
            swal('¡Lo sentimos!', error.error?.message || ERROR_MESSAGES.GUARDADO, 'error')
          });
      }
    });
  }
  public forzarDeleteValidacion(variacion: Accionista) {
    swal({
      title: 'Se forzará la acción eliminar',
      text: '¿Está seguro de realizar esta acción?',
      icon: 'warning',
      buttons: {
        cancel: { text: 'Cancelar', value: null, visible: true, className: '', closeModal: true },
        danger: { text: 'Eliminar', value: true, visible: true, className: '', closeModal: false }
      }
    }).then((isConfirm) => {
      if (isConfirm)
        this.datosSocietariosService.deleteVariacion(variacion, this.id, true).subscribe(response => {
          this.eliminarExitoso();
        }, (error) => {
          swal('¡Lo sentimos!', error.error?.message || ERROR_MESSAGES.GUARDADO, 'error')
        })
    })
  }

  public changeTipoVariacion() {
    if (this.form.controls.tipoVariacion.value === '04') {
      this.form.controls.socioTransfiere.enable();
    } else this.form.controls.socioTransfiere.disable();
  }
  public calculateValorNominalTotal() {
    if (this.form.controls.cantidadAcciones.value && this.form.controls.valorNominalAccion.value)
      this.form.controls.valorNominalTotal.setValue(this.form.controls.cantidadAcciones.value * this.form.controls.valorNominalAccion.value)
  }
  public calculateTotalVotos() {
    if (this.form.controls.cantidadAcciones.value && this.form.controls.votosPorAccion.value)
      this.form.controls.totalVotos.setValue(this.form.controls.cantidadAcciones.value * this.form.controls.votosPorAccion.value)
  }
  public isDisminucion(variacion: Accionista): boolean {
    return variacion?.dc === "C";
  }

  //*DDJJ Beneficiario
  public submitFormDDJJ() {
    let declaracion: BeneficiarioDdjj = {
      id: this.declaracionSeleccionada ? this.declaracionSeleccionada.id : null,
      nombre: this.formModal.controls.nombre.value,
      fecha: this.unFormatDate(this.formModal.controls.fecha.value),
      clienteFisico: { id: this.id },
      archivo: []
    }
    this.isAlta ? this.documentos.map(d => {
      declaracion.archivo.push({id: null, archivoBase: d})
    }) : null
    
    this.ddjjService[this.isAlta ? 'add' : 'edit'](declaracion).subscribe(() => {
      swal({
        title: "Guardado exitosamente",
        text: `El registro se ${this.isAlta ? 'añadió' : 'modificó'} exitosamente`,
        icon: "success",
        buttons: false,
        timer: 1600
      })
      this.findDeclaraciones()
    }, error => swal("¡Lo sentimos!", error?.error?.message || ERROR_MESSAGES.GUARDADO, "error"))
  }
  public createDeclaracion() {
    this.isAlta = true
    this.declaracionSeleccionada = null
    this.formModal.reset()
    this.cargaDocumentos.reset()
  }
  public editDeclaracion(declaracion) {
    this.isAlta = false
    this.documentos = []
    this.declaracionSeleccionada = declaracion
    this.formModal.controls.nombre.patchValue(declaracion.nombre)
    this.formModal.controls.fecha.patchValue(moment(declaracion.fecha).toDate())
    declaracion.archivo.map(d => {
      this.documentos.push(new Documento(d.archivoBase))
    })
    this.cargaDocumentos.setDocumentos(this.documentos)
  }
  public deleteDeclaracion() {
    this.ddjjService.delete(this.declaracionSeleccionada).subscribe(() => {
      swal({
        title: "Eliminado exitosamente",
        text: `El registro se eliminó exitosamente`,
        icon: "success",
        buttons: false,
        timer: 1600
      })
      this.createDeclaracion()
      this.findDeclaraciones()
    }, error => swal("¡Lo sentimos!", error?.error?.message || ERROR_MESSAGES.GUARDADO, "error"))
  }
  public async openModalDDJJ() {
    this.declaracionSeleccionada = null
    this.isAlta = true
    await this.findDeclaraciones()
    modalUtils.open(this.modalDDJJ)
  }
  public async findDeclaraciones() {
    return new Promise((resolve, reject) => {
      this.declaracionSeleccionada = null
      this.declaraciones = []
      this.ddjjService.findAll(this.id).subscribe(response => {
        this.declaraciones = response
        this.declaraciones.sort((a, b) => (a.fecha < b.fecha) ? 1 : ((b.fecha < a.fecha) ? -1 : 0))
        this.createDeclaracion()
        resolve(true)
      }, error => resolve(error))
    })
  }
  public getDocumentos($event) {
    this.documentos = $event
  }
}
