import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ClienteLite, Cliente, ClienteFisico, ClienteBase } from '../../interfaces/cliente';
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Page } from "../../interfaces/page";
import { AREA } from "src/app/enums/areas";
import { HABILITADO } from "src/app/enums/habilitado";

@Injectable({
  providedIn: "root"
})
export class ClienteService {

  recurso: string = "/clientes";

  constructor(private http: HttpClient) { }
  public habilitado: string = HABILITADO.SI;

  public findById(clienteId: number) {
    return this.http.get<ClienteFisico>(`${environment.apiEndpoint}${this.recurso}/${clienteId}`);
  }

  public findClientesByAreaAndResponsable({ area, responsable, habilitado = HABILITADO.SI, lite = "S" }): Observable<Page<ClienteLite>> {
    return this.http.get<Page<ClienteLite>>(`${environment.apiEndpoint}${this.recurso}/${area}/${responsable}?habilitado=${habilitado}&lite=${lite}`);
  }

  public findclientes({
    pageNumber = null,
    pageSize = null,
    habilitado = HABILITADO.TODOS,
    nombre = null,
    lite = null,
    tipoCliente = null
  } = {}): Observable<Page<any>> {

    let params = new URLSearchParams([["habilitado", habilitado]]);

    if (pageNumber)
      params.append("pageNumber", pageNumber);
    if (pageSize)
      params.append("pageSize", pageSize);
    if (nombre)
      params.append("nombre", nombre);
    if (lite)
      params.append("lite", lite);
    if (tipoCliente)
      params.append("tipoCliente", tipoCliente);

    return this.http.get<Page<any>>(`${environment.apiEndpoint}${this.recurso}?${params.toString()}`);
  }

  public findclientesLite(): Observable<Page<ClienteLite>> {
    return this.http.get<Page<ClienteLite>>(`${environment.apiEndpoint}${this.recurso}?lite=S`);
  }

  public findClientesCuitNombre(): Observable<Array<ClienteLite>> {
    return this.http.get<Array<ClienteLite>>(`${environment.apiEndpoint}/clientes/0/cuitNombre`);
  }

  public findClientesNoAsistidos({
    personalId = null,
    desde = null,
    hasta = null,
  } = {}): Observable<Array<ClienteBase>> {
    let endpoint = '/personalClientesNoAsignados';
    let params = new URLSearchParams();
    if (personalId) params.append('personalId', personalId);
    if (desde) params.append('desde', desde);
    if (hasta) params.append('hasta', hasta);
    return this.http.get<Array<ClienteBase>>(`${environment.apiEndpoint}${endpoint}?${params.toString()}`);
  }

  public create(cliente: ClienteLite) {
    let endpoint = this.recurso;
    return this.http.post(environment.apiEndpoint + endpoint, cliente);
  }
  public edit(cliente: ClienteLite) {
    let endpoint = this.recurso;
    return this.http.put(environment.apiEndpoint + endpoint, cliente);
  }
  public delete(cliente: ClienteLite) {
    let endpoint = this.recurso;
    return this.http.delete(environment.apiEndpoint + endpoint);
  }
  public checkCuit(idCliente: number, cuit: string): Observable<string> {
    return this.http.get<string>(`${environment.apiEndpoint}/clientes/${idCliente || 0}/checkCuit/${cuit}`);
  }

}

