
import { Documento as IDocumento } from "src/app/interfaces/documento";
import regex from "src/app/utils/regex";
export class Documento implements IDocumento {

    id: number = null;

    nombre: string = null;
    url_archivo: string = null;
    formato: string = null;
    fecha_carga: string = null;
    observacion: string = null;
    subcategoria: string = null;
    nivel: string = null;

    modificable: boolean = true;
    eliminable: boolean = true;
    carga_outsourcing: boolean = false;
    soloLectura: boolean = false;
    seleccionado: boolean = true;

    constructor({
        id = null,
        nombre = null,
        url_archivo = null,
        formato = null,
        fecha_carga = null,
        observacion = null,
        subcategoria = null,
        nivel = null,
        seleccionado = true,
        modificable = true,
        eliminable = true,
        soloLectura = false,
        carga_outsourcing = false,
    } = {}) {

        this.id = id;
        this.nombre = nombre;
        this.url_archivo = url_archivo;
        this.formato = formato;
        this.fecha_carga = fecha_carga;
        this.observacion = observacion;
        this.subcategoria = subcategoria;
        this.nivel = nivel;

        this.seleccionado = seleccionado;
        this.modificable = modificable;
        this.eliminable = eliminable;
        this.soloLectura = soloLectura;
        this.carga_outsourcing = carga_outsourcing;
    }

    public documentoVacio(): boolean {
        return Documento.documentoVacio(this);
    }

    public static documentoVacio(documento: IDocumento): boolean {
        return !regex.isURL(documento.url_archivo) && !regex.isDataURI(documento.url_archivo);
    }

    public static getFormato(nombreArchivo: string): string {
        try {
            let [formato] = nombreArchivo.split(".").reverse();
            switch (formato.toUpperCase()) {
                case "CSV": return "EXCEL";
                case "XLS": return "EXCEL";
                case "XLSX": return "EXCEL";
                case "DOC": return "WORD";
                case "DOCX": return "WORD";
                case "RTF": return "WORD";
                case "JPG": return "OTRO";
                case "JPEG": return "OTRO";
                case "SVG": return "IMAGE";
                case "PDF": return "PDF";
                case "TXT": return "TXT";
                default: return "OTRO";
            }
        } catch {
            return "OTRO";
        }
    }
}