import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { IInspecciones } from "../interfaces/iinspecciones";
import { Page } from "../interfaces/page";

@Injectable({
  providedIn: "root"
})
export class InspeccionesService {

  constructor(private http: HttpClient) { }

  public recurso: string = "/inspecciones";

  public findAll({
    pageNumber = 1,
    pageSize = 1000,
    orderCriteria = "DESC",
    orderField = "id",
    clienteId = null,
    estado = null
  } = {}): Observable<Page<IInspecciones>> {
    let params = new URLSearchParams([]);
    if (pageNumber) params.append("pageNumber", pageNumber.toString());
    if (pageSize) params.append("pageSize", pageSize.toString());
    if (orderCriteria) params.append("orderCriteria", orderCriteria);
    if (orderField) params.append("orderField", orderField);
    if (clienteId) params.append("clienteId", clienteId);
    if (estado) params.append("estado", estado);
    return this.http.get<Page<IInspecciones>>(`${environment.apiEndpoint}${this.recurso}?${params.toString()}`);
  }
}
