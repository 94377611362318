import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ModalDirective } from "ngx-bootstrap/modal";
import { CategoriaDocumento } from "src/app/interfaces/categoria-documento";
import { TipoDomicilio } from "src/app/interfaces/domicilio";
import { Domicilio } from "src/app/interfaces/domicilio";
import { Page } from "src/app/interfaces/page";
import { Provincia } from "src/app/interfaces/provincia";
import { DomiciliosService } from "src/app/services/clientes/domicilios.service";
import { Documento as IDocumento } from "src/app/interfaces/documento";
import { Documento } from "src/app/classes/documento";
import { ProvinciasService } from "src/app/services/provincias.service";
import { IdNombre } from "src/app/interfaces/id-nombre";
import { PaisesService } from "src/app/services/paises.service";
import modalUtils from 'src/app/utils/modal';
import { BsDatepickerConfig, BsLocaleService } from "ngx-bootstrap/datepicker";
import * as moment from "moment";
import { HABILITADO } from "src/app/enums/habilitado";
import { MailService } from "src/app/services/mail.service";
import { ERROR_MESSAGES } from "src/app/enums/error-messages";
const swal = require("sweetalert");

@Component({
  selector: 'app-domicilios',
  templateUrl: './domicilios.component.html',
  styleUrls: ['./domicilios.component.scss']
})
export class DomiciliosComponent implements OnInit {

  @ViewChild("modalNuevoDomicilio") modalNuevoDomicilio: ModalDirective;
  @ViewChild("modalVerDocumento") modalVerDocumento: ModalDirective;

  @Input() set idCliente(value) {
    if (value) {
      this.id = value;
      this.init();
    }
  }

  public form: FormGroup;
  public listadoProvincia: Array<{ id: number, text: string }> = [];
  public provincias: Array<Provincia> = [];
  public listNacionalidades: Array<IdNombre> = [];
  public nacionalidades: Array<{ id: number, text: string }> = [];
  public domicilio: Domicilio;
  public domicilios: Array<Domicilio> = [];
  public tipoDomicilios: Array<TipoDomicilio> = [];
  public listadoTipoDomicilios: Array<{ id: string, text: string }> = [];
  public listadoActual: Array<{ id: string, text: string }> = [{ id: HABILITADO.SI, text: "Si" }, { id: HABILITADO.NO, text: "No" }];
  public params;
  public categorias: Array<CategoriaDocumento> = [{ id: "OTROS", nombre: "Archivos de domicilio" }];
  public id: number;
  public idDatosGenerales: number;
  public documentoSeleccionado: IDocumento = new Documento();
  public verDocumento: IDocumento = new Documento();
  bsConfig: Partial<BsDatepickerConfig>;

  constructor(private paisesService: PaisesService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private domiciliosService: DomiciliosService,
    private provinciasService: ProvinciasService,
    private localeService: BsLocaleService,
    private mailService: MailService) {
    this.form = formBuilder.group({
      tipoDomicilio: [null, Validators.required],
      numero: [null],
      manzana: [null],
      calle: [null, Validators.required],
      piso: [null],
      departamento: [null],
      edificio: [null],
      depto_oficina_local: [null],
      localidad: [null],
      barrio: [null],
      provinciaDto: [null],
      nacionalidad: [null],
      cp: [null],
      actual: [null, Validators.required],
      fechaModificacion: [{ value: null, disabled: true }]
    });
    this.bsConfig = Object.assign({},
      {
        containerClass: "theme-angle",
        dateInputFormat: 'DD/MM/YYYY'
      });

    this.localeService.use("es");
  }

  ngOnInit(): void {

    if (!this.id) {
      this.id = parseInt(this.activatedRoute.snapshot.paramMap.get("id")) || null;
      this.init();
    }

    this.findPaises();
    this.findTipoDomicilio();
    this.findAllProvincias();
  }
  public init() {
    if (this.id)
      this.findDomicilios();
  }

  public findDomicilios() {
    this.domiciliosService.idDatosGenerales(this.id)
      .subscribe(
        (response: number) => {
          this.idDatosGenerales = response[0];
          this.domiciliosService.findDomicilios(this.id, this.idDatosGenerales).subscribe((response: Page<Domicilio>) => {
            this.domicilios = response.content;
            this.ordenarNumerico("id");
          });
        });
  }

  public findAllProvincias() {
    this.provinciasService.findAllProvincias().subscribe((response: Page<Provincia>) => {
      this.provincias = response.content;
      this.listadoProvincia = this.provincias.map(p => {
        return { id: p.id, text: p.descripcion };
      });
    });
  }

  public findTipoDomicilio() {
    this.domiciliosService.findTipoDomicilio().subscribe((response: Page<TipoDomicilio>) => {
      this.tipoDomicilios = response.content;
      this.listadoTipoDomicilios = this.tipoDomicilios.map(t => {
        return { id: t.codigo, text: t.descripcion };
      });
    });
  }

  public findPaises() {
    this.paisesService.findPaises().subscribe((response: Page<IdNombre>) => {
      this.listNacionalidades = response.content;
      this.nacionalidades = this.listNacionalidades.map(n => ({ id: n.id, text: n.nombre }));
    });
  }

  public submitForm($event, value: any): void {
    $event.preventDefault();
    for (let c in this.form.controls) {
      this.form.controls[c].markAsTouched();
    }
    if (this.form.valid) {
      this.domicilio.tipoDomicilio = this.tipoDomicilios.find(t => t.codigo === value.tipoDomicilio) || { id: null, codigo: null, descripcion: null };
      this.domicilio.calle = value.calle || null;
      this.domicilio.numero = value.numero || null;
      this.domicilio.localidad = value.localidad || null;
      this.domicilio.departamento = value.departamento || null;
      this.domicilio.provinciaDto = this.provincias.find(p => p.id === value.provinciaDto) || { id: null, codigo: null, descripcion: null, habilitado: null, provinciaid: null };
      this.domicilio.manzana = value.manzana || null;
      this.domicilio.piso = value.piso || null;
      this.domicilio.edificio = value.edificio || null;
      this.domicilio.depto_oficina_local = value.depto_oficina_local || null;
      this.domicilio.barrio = value.barrio || null;
      this.domicilio.nacionalidad = this.listNacionalidades.find(n => n.id === value.nacionalidad) || null;
      this.domicilio.cp = value.cp || null;
      this.domicilio.datos_grales_id = this.idDatosGenerales;
      this.domicilio.habilitado = true;
      this.domicilio.documento = this.documentoSeleccionado;
      this.domicilio.actual = value.actual == HABILITADO.SI ? true : false;
      this.domicilio.fechaActualizacion = value.fechaModificacion;
      (this.domicilio.id ?
        this.domiciliosService.editDomicilio(this.id, this.idDatosGenerales, this.domicilio) :
        this.domiciliosService.newDomicilio(this.id, this.idDatosGenerales, this.domicilio)
      ).subscribe((domicilio: Domicilio) => {
        this.domicilio = domicilio;
        this.setForm(domicilio);
        this.findDomicilios();
        setTimeout(() => {
          this.hideModal(this.modalNuevoDomicilio);
        }, 600);
        swal({
          title: "Guardado",
          text: "El registro se guardó exitosamente.",
          icon: "success",
          buttons: false,
          timer: 1600
        });
      }, (error) => {
        swal("¡Lo sentimos!", error.error?.message, "error");
      });
    }
  }

  public delete(domicilio: Domicilio) {
    swal({
      title: "Eliminar",
      text: "¿Está seguro que quiere eliminar el domicilio?",
      icon: "warning",
      buttons: {
        cancel: { text: "Cancelar", value: null, visible: true, className: "", closeModal: true },
        danger: { text: "Eliminar", value: true, visible: true, className: "", closeModal: false }
      }
    }).then((isConfirm) => {
      if (isConfirm) {
        this.domiciliosService.deleteDomicilios(this.id, this.idDatosGenerales, domicilio)
          .subscribe(() => {
            swal({
              title: "Eliminado",
              text: "El domicilio se eliminó exitosamente.",
              icon: "success",
              buttons: false,
              timer: 1600
            });
            this.findDomicilios();
          },
            error => swal("¡Lo sentimos!", error.error.message, "error")
          );
      }
    });
  }

  public create() {
    this.domicilio = {
      id: null,
      tipoDomicilio:
      {
        id: null,
        codigo: null,
        descripcion: null,
      },
      calle: null,
      numero: null,
      localidad: null,
      departamento: null,
      provinciaDto:
      {
        id: null,
        provinciaid: null,
        codigo: null,
        descripcion: null,
        habilitado: null
      },
      manzana: null,
      piso: null,
      edificio: null,
      depto_oficina_local: null,
      barrio: null,
      nacionalidad:
      {
        id: null,
        nombre: null
      },
      cp: null,
      datos_grales_id: this.idDatosGenerales,
      habilitado: true,
      documento: new Documento(),
      actual: null,
      fechaActualizacion: null
    };
    for (let c in this.form.controls) {
      this.form.controls[c].markAsUntouched();
      this.form.controls[c].markAsPristine();
    }
      this.domicilio.fechaActualizacion = null;
      this.setForm(this.domicilio);
  }

  public edit(domicilio: Domicilio) {
    this.domicilio = domicilio;
    if (this.domicilio.documento) {
      this.domicilio.documento.seleccionado = true;
    } else {
      this.domicilio.documento = new Documento();
    }
    this.setForm(domicilio);
  }

  public onDocumentoAgregado(documento: IDocumento): void {
    documento.seleccionado = true;
    this.documentoSeleccionado = new Documento(documento);
  }

  public onDocumentoEliminado(): void {
    this.documentoSeleccionado = new Documento();
  }

  public setForm(domicilio: Domicilio) {
    this.form.controls.tipoDomicilio.setValue(domicilio.tipoDomicilio?.codigo || null);
    this.form.controls.calle.setValue(domicilio.calle || null);
    this.form.controls.numero.setValue(domicilio.numero || null);
    this.form.controls.localidad.setValue(domicilio.localidad || null);
    this.form.controls.departamento.setValue(domicilio.departamento || null);
    this.form.controls.provinciaDto.setValue(domicilio.provinciaDto?.id || null);
    this.form.controls.manzana.setValue(domicilio.manzana || null);
    this.form.controls.piso.setValue(domicilio.piso || null);
    this.form.controls.edificio.setValue(domicilio.edificio || null);
    this.form.controls.depto_oficina_local.setValue(domicilio.depto_oficina_local || null);
    this.form.controls.barrio.setValue(domicilio.barrio || null);
    this.form.controls.nacionalidad.setValue(domicilio.nacionalidad?.id || null);
    this.form.controls.cp.setValue(domicilio.cp || null);
    this.form.controls.actual.setValue(domicilio.actual ? HABILITADO.SI : HABILITADO.NO);
    this.form.controls.fechaModificacion.setValue(this.formatDate(domicilio.fechaActualizacion, 'YYYY-MM-DD') || null);
    this.documentoSeleccionado = new Documento(this.domicilio.documento);
  }

  public hideModal(template: ModalDirective) {
    template.hide();
  }

  public valueRequiredForm(value: string) {
    return this.form.controls[value].hasError("required") && (this.form.controls[value].dirty || this.form.controls[value].touched);
  }

  public guardaDatos() {
    this.params = Object.assign({}, this.form.value);
    this.params.documento = this.documentoSeleccionado;
  }

  public limpiarFrom() {
    this.form.reset();
    this.documentoSeleccionado = new Documento();
  }
  public ordenarNumerico(campo: string) {
    let orden: number = 1;
    this.domicilios.sort((a, b) => {
      const codigoA = a[campo];
      const codigoB = b[campo];
      if (codigoA < codigoB) {
        return -1 * (orden);
      }
      if (codigoA > codigoB) {
        return 1 * (orden);
      }
      return 0;
    });
  }

  public openModal(template: ModalDirective) {
    modalUtils.open(template);
  }

  public openVerDocumento(domicilio: Domicilio) {
    this.verDocumento = new Documento(domicilio.documento);
    this.openModal(this.modalVerDocumento);
  }

  public formatDate(date: string, format: string) {
    if (date && moment(date, format).isValid())
      return moment(date, format).format('DD/MM/YYYY');
    else return null;
  }

  public descargarMail(domicilio: Domicilio) {
    this.domiciliosService.updateMail(this.id, this.idDatosGenerales, domicilio).subscribe(() => {
      this.mailService.domicilios(domicilio.id)
    }, (error) => swal('¡Lo sentimos!', error.error?.message || ERROR_MESSAGES.GUARDADO, 'error'))
  }
}