import { Pipe, PipeTransform } from '@angular/core';
import { Documento } from '../classes/documento';
import { Documento as IDocumento } from '../interfaces/documento';

@Pipe({
  name: 'documentoModificable'
})
export class DocumentoModificablePipe implements PipeTransform {

  transform(documento: Documento): Documento {
    documento = new Documento(documento)
    Object.assign(documento, { modificable: true }, { eliminable: true }, {seleccionado: true})
    return documento;
  }
}
