import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Page } from '../interfaces/page';
import { Provincia } from '../interfaces/provincia';


@Injectable({
  providedIn: 'root'
})
export class ProvinciasService {

  constructor(private http: HttpClient) { }
  public findAllProvincias(): Observable<Page<Provincia>> {
    let endpoint = '/provincia';
    return this.http.get<Page<Provincia>>(environment.apiEndpoint + endpoint);
  }
}
