import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'cuit'
})
export class CuitPipe implements PipeTransform {
    transform(val: string) {
        if (!val)
            return '-';
        let cuit: string;
        cuit = val.substring(0, 2) + '-' + val.substring(2, val.length - 1) + '-' + val.substring(val.length - 1);
        return cuit;
    }
}