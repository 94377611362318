import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { from,Observable } from "rxjs";
import { Tiposlibros} from "src/app/interfaces/tipos-libros";
import { environment } from "src/environments/environment";
import { HABILITADO } from "../enums/habilitado";
import { IdCodigoDescripcionHabilitado } from "../interfaces/id-codigo-descripcion-habilitado";
import { Page } from "../interfaces/page";

@Injectable({
  providedIn: "root"
})
export class TiposLibrosService {

  public recurso: string = "/tiposLibros";

  constructor(private http: HttpClient) { }

  public findTiposLibros({
    habilitado = HABILITADO.TODOS
  } = {}): Observable<Page<IdCodigoDescripcionHabilitado>> {
    let params = new URLSearchParams([["habilitado", habilitado]]);
    return this.http.get<Page<IdCodigoDescripcionHabilitado>>(`${environment.apiEndpoint}${this.recurso}?${params.toString()}`);
  }

  public newTipoLibro(tipo: Tiposlibros): Observable<Tiposlibros> {
    return this.http.post<Tiposlibros>(`${environment.apiEndpoint}${this.recurso}`, tipo);
  }

  public edit(tipo: Tiposlibros): Observable<Tiposlibros> {
    return this.http.put<Tiposlibros>(`${environment.apiEndpoint}${this.recurso}/`, tipo);
  }

  public delete(tipo: Tiposlibros): Observable<Tiposlibros> {
    return this.http.delete<Tiposlibros>(`${environment.apiEndpoint}${this.recurso}/${tipo.id}`);
  }

}
