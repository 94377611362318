import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Page } from '../../interfaces/page';
import { Cliente as DatosGenerales, ClienteFisico } from "../../interfaces/cliente";
import { DatosPersonales } from 'src/app/interfaces/datos-personales';
import { ClienteDatosGenerales } from 'src/app/interfaces/cliente-datos-generales';

@Injectable({
  providedIn: 'root'
})
export class DatosGeneralesService {

  constructor(private http: HttpClient) { }


  public findDatosGenerales(idCliente: number): Observable<DatosGenerales> {
    return this.http.get<DatosGenerales>(`${environment.apiEndpoint}/clientes/${idCliente}/datosGenerales`);
  }
  public findDatosGeneralesByIdDatosGenerales(idDatosGenerales: number): Observable<ClienteFisico> {
    return this.http.get<ClienteFisico>(`${environment.apiEndpoint}/clientes/0/datosGeneralesByIdDatosGrales/${idDatosGenerales}`);
  }
  public findCuit(){
    return this.http.get(`${environment.apiEndpoint}/clientes/nextCuit`);
  }
  public create(value: DatosGenerales): Observable<DatosGenerales> {
    return this.http.post<DatosGenerales>(`${environment.apiEndpoint}/clientes`, value);
  }
  public edit( value: DatosGenerales): Observable<DatosGenerales> {
    return this.http.put<DatosGenerales>(`${environment.apiEndpoint}/clientes/${value.id}`, value);
  }
  public findDatosGrales(idCliente: number, idDatosGrales: number): Observable<ClienteDatosGenerales> {
    return this.http.get<ClienteDatosGenerales>(`${environment.apiEndpoint}/clientes/${idCliente}/datosGrales/${idDatosGrales}`);
  }

}
