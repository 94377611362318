import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Contacto } from "../interfaces/contacto";
import { ContactoBase } from "../interfaces/contacto-cliente";
import { Page } from "../interfaces/page";

@Injectable({
  providedIn: "root"
})
export class ContactosService {
  recurso: string = "/contacto";
  constructor(private http: HttpClient) { }

  public findContactos({
    pageNumber = 1,
    pageSize = 1000,
    orderField = null,
    orderCriteria = null,
    referente = null,
    area = null,
    segmento = null
  } = {}): Observable<Page<Contacto>> {
    let params = new URLSearchParams([]);
    if (pageNumber) params.append("pageNumber", pageNumber.toString());
    if (pageSize) params.append("pageSize", pageSize.toString());
    if (orderField) params.append("orderField", orderField);
    if (orderCriteria) params.append("orderCriteria", orderCriteria);
    if (referente) params.append("referente", referente);
    if (area) params.append("area", area);
    if (segmento) params.append("segmento", segmento);
    return this.http.get<Page<Contacto>>(`${environment.apiEndpoint}${this.recurso}?${params.toString()}`);
  }
  public findContactosSinClientes({
    pageNumber = 1,
    pageSize = 10000,
    orderField = "ceMail",
    orderCriteria = "ASC",
    area = null,
    clienteId = null,
    descripcion = null,
    habilitado = null,
    grupoEconomico = null,
  } = {}): Observable<Page<ContactoBase>> {
    let params = new URLSearchParams([]);
    if (pageNumber) params.append("pageNumber", pageNumber.toString());
    if (pageSize) params.append("pageSize", pageSize.toString());
    if (orderField) params.append("orderField", orderField);
    if (orderCriteria) params.append("orderCriteria", orderCriteria);
    if (area) params.append("area", area);
    if (clienteId) params.append("clienteId", clienteId.toString());
    if (descripcion) params.append("descripcion", descripcion);
    if (habilitado) params.append("habilitado", habilitado.toString());
    if (grupoEconomico) params.append("grupoEconomico", grupoEconomico.toString());
    return this.http.get<Page<ContactoBase>>(`${environment.apiEndpoint}/reportes/contactosSinClientes?${params.toString()}`);
  }
  public create(contacto: Contacto) {
    return this.http.post(`${environment.apiEndpoint}${this.recurso}`, contacto);
  }
  public edit(contacto: Contacto, reemplazarMail: boolean) {
    return this.http.put(`${environment.apiEndpoint}${this.recurso}/${contacto.id}?reemplazarMail=${reemplazarMail}`, contacto);
  }
  public delete(contacto: Contacto) {
    return this.http.delete(`${environment.apiEndpoint}${this.recurso}/${contacto.id}`);
  }
  public getByEmail(contacto: Contacto | {mail: string}) {
    return this.http.get<Page<Contacto>>(`${environment.apiEndpoint}${this.recurso}/email/${contacto.mail}/`);
  }
  public desvincularContacto(idContacto: number): Observable<void> {
    return this.http.patch<void>(`${environment.apiEndpoint}${this.recurso}/${idContacto}/desvincular`, null);
  }
  public vincularContacto(idContacto: number, idCliente: number): Observable<void> {
    return this.http.patch<void>(`${environment.apiEndpoint}${this.recurso}/${idContacto}/vincular`, idCliente);
  }
  public getContactoById(idContacto: number): Observable<Contacto> {
    return this.http.get<Contacto>(`${environment.apiEndpoint}${this.recurso}/${idContacto}`);
  }
}
