import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ImpuestoActivo } from '../interfaces/cliente-datos-impositivos';
import { IImpuestos } from '../interfaces/iimpuestos';
import { Page } from '../interfaces/page';

@Injectable({
  providedIn: 'root'
})
export class AsignacionObligacionesService {

  recurso: string = "/asignacionObligaciones";

  constructor(private http: HttpClient) { }

  public findClientesObligaciones({
    organismoId = null,
    tipoObligacionId = null,
    obligacionId = null,
    tipoCliente = null,
    grupoEconomicoId = null,
    clienteId = null,
    supervisorId = null,
    responsableId = null,
    asistenteId = null,
    auxiliarId = null
  } = {}): Observable<Array<any>> {
    let params = new URLSearchParams([]);
    if (organismoId) params.append("organismoId", organismoId.toString());
    if (tipoObligacionId) params.append("tipoObligacionId", tipoObligacionId.toString());
    if (obligacionId) params.append("obligacionId", obligacionId.toString());
    if (tipoCliente) params.append("tipoCliente", tipoCliente);
    if (grupoEconomicoId) params.append("grupoEconomicoId", grupoEconomicoId.toString());
    if (clienteId) params.append("clienteId", clienteId.toString());
    if (supervisorId) params.append("supervisorId", supervisorId);
    if (responsableId) params.append("responsableId", responsableId.toString());
    if (asistenteId) params.append("asistenteId", asistenteId.toString());
    if (auxiliarId) params.append("auxiliarId", auxiliarId.toString());
    return this.http.get<Array<any>>(`${environment.apiEndpoint}${this.recurso}?${params.toString()}`);
  }

  public asignarObligaciones(asignacion: IImpuestos): Observable<Array<ImpuestoActivo>> {
    return this.http.post<Array<ImpuestoActivo>>(environment.apiEndpoint + this.recurso, asignacion);
  }

  public editarMasivo(asignaciones: Array<ImpuestoActivo>): Observable<Array<ImpuestoActivo>> {
    return this.http.put<Array<ImpuestoActivo>>(environment.apiEndpoint + this.recurso, asignaciones);
  }

  public setEnviaCliente(cliente: Array<any>): Observable<any> {
    let endpoint = '/enviaCliente';
    return this.http.patch<any>(`${environment.apiEndpoint}${this.recurso}${endpoint}`, cliente);
  }

  public setEnviaObligacion(obligacion: Array<any>): Observable<any> {
    let endpoint = '/enviaOblig';
    return this.http.patch<any>(`${environment.apiEndpoint}${this.recurso}${endpoint}`, obligacion);
  }

}
