import { Pipe, PipeTransform } from '@angular/core';
import { toInteger } from 'lodash';

@Pipe({
  name: 'hora'
})
export class HoraPipe implements PipeTransform {

  transform(minutos: number): number { // recibe cantidad de minutos, transforma formato HH.mm
    if (minutos != 0) {
      let cociente = Math.floor(minutos / 60);
      let resto = (minutos % 60) / 100;
      return parseFloat((cociente + resto + (minutos < 0 ? 1 : 0)).toFixed(2));
    } else return 0;
  }

}
