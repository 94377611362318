import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Gerente, ResponsableEstudio } from "src/app/interfaces/cliente-responsable";
import { IdDescripcionCod } from "src/app/interfaces/id-descripcion-cod";
import { Page } from "src/app/interfaces/page";
import { environment } from "src/environments/environment";
import { HABILITADO } from 'src/app/enums/habilitado';
import { ClienteFisico } from 'src/app/interfaces/cliente';
@Injectable({
  providedIn: "root"
})
export class ResponsablesService {

  constructor(private http: HttpClient) { }


  public findAreas(): Observable<Page<IdDescripcionCod>> {
    let endpoint = "/taopcion/area";
    return this.http.get<Page<IdDescripcionCod>>(environment.apiEndpoint + endpoint);
  }

  public delete(idCliente: number, id: number) {
    return this.http.delete(`${environment.apiEndpoint}/clientes/${idCliente}/responsable/${id}`);
  }

  public cartaPresentacion(idCliente: number, enviar: boolean) {
    return this.http.get(`${environment.apiEndpoint}/clientes/${idCliente}/cartaPresentacion?enviarEmail=${enviar}`);
  }

  /* ====== GERENCIA ====== */
  public findGerentes(idCliente: number): Observable<Page<Gerente>> {
    return this.http.get<Page<Gerente>>(`${environment.apiEndpoint}/clientes/` + idCliente + "/gerencia");
  }
  public editGerente(idCliente: number, value: Gerente) {
    return this.http.put(`${environment.apiEndpoint}/clientes/` + idCliente + "/gerente/" + value.id, value);
  }
  public createGerente(idCliente: number, value: Gerente) {
    return this.http.post(`${environment.apiEndpoint}/clientes/` + idCliente + "/gerente", value);
  }

  /* ====== Responsables áreas del estudio ======= */
  public findPersonalEstudio(idCliente: number): Observable<Page<Gerente>> {
    return this.http.get<Page<Gerente>>(`${environment.apiEndpoint}/clientes/` + idCliente + "/personal");
  }
  public createPersonalEstudio(idCliente: number, value: ResponsableEstudio) {
    return this.http.post(`${environment.apiEndpoint}/clientes/` + idCliente + "/personal", value);
  }
  public editPersonal(idCliente: number, value: ResponsableEstudio) {
    return this.http.put(`${environment.apiEndpoint}/clientes/` + idCliente + "/personal/" + value.id, value);
  }
  public deletePersonal(idCliente: number, id: number) {
    return this.http.delete(`${environment.apiEndpoint}/clientes/` + idCliente + "/personal/" + id);
  }
  public findResponsables(value: string) {
    let endpoint = "/responsables/" + value;
    return this.http.get(environment.apiEndpoint + endpoint);
  }
  /* ====== Responsables áreas del cliente ====== */
  public findPersonalCliente(idCliente: number): Observable<Page<Gerente>> {
    return this.http.get<Page<Gerente>>(`${environment.apiEndpoint}/clientes/` + idCliente + "/responsables");
  }
  public editResponsbleCliente(idCliente: number, value: Gerente) {
    return this.http.put(`${environment.apiEndpoint}/clientes/` + idCliente + "/responsable/" + value.id, value);
  }
  public createResponsbleCliente(idCliente: number, value: Gerente) {
    return this.http.post(`${environment.apiEndpoint}/clientes/` + idCliente + "/responsable", value);
  }
  /* ====== Asignación de responsables ====== */
  public findClientesByPersonal({
    pageNumber = 1,
    pageSize = 1000,
    orderCriteria = "ASC",
    orderField = "clfApeyno",
    area = null,
    supervisor = null,
    responsable = null,
    asistente = null,
    auxiliar = null,
    outsourcing = null,
    grupoEconomico = null,
    tipoCliente = null,
    habilitado = null
  } = {}): Observable<Page<ClienteFisico>> {
    let params = new URLSearchParams([]);
    if (orderField) params.append("orderField", orderField);
    if (orderCriteria) params.append("orderCriteria", orderCriteria);
    if (pageNumber) params.append("pageNumber", pageNumber.toString());
    if (pageSize) params.append("pageSize", pageSize.toString());
    if (area) params.append("area", area);
    if (supervisor) params.append("supervisor", supervisor);
    if (responsable) params.append("responsable", responsable);
    if (asistente!=null) params.append("asistente", asistente);
    if (auxiliar!=null) params.append("auxiliar", auxiliar);
    if (outsourcing!=null) params.append("outsourcing", outsourcing);
    if (grupoEconomico) params.append("grupoEconomico", grupoEconomico);
    if (tipoCliente) params.append("tipoCliente", tipoCliente);
    if (habilitado) params.append("habilitado", habilitado);
    return this.http.get<Page<ClienteFisico>>(`${environment.apiEndpoint}/clientes/byPersonal?${params.toString()}`);
  }

  public editAsignacionResponsables({
    supervisor = null,
    responsable = null,
    asistente = null,
    auxiliar = null,
    area = null,
    clientes = null
  }) {
    let params = new URLSearchParams([]);
    if (supervisor) params.append("supervisor", supervisor);
    if (responsable) params.append("responsable", responsable);
    if (asistente) params.append("asistente", asistente);
    if (auxiliar) params.append("auxiliar", auxiliar);
    if (area) params.append("area", area);
    return this.http.put(`${environment.apiEndpoint}/asignacionResponsables?${params.toString()}`, clientes);
  }
}
