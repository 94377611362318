import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Documento } from 'src/app/classes/documento';
import { Documento as IDocumento } from 'src/app/interfaces/documento';
import { CategoriaDocumento } from 'src/app/interfaces/categoria-documento';
import { ClienteFisico, ClienteLite } from 'src/app/interfaces/cliente';
import { MiembroAdministrativo, Resolucion } from 'src/app/interfaces/cliente-datos-societarios';
import { Contacto } from 'src/app/interfaces/contacto';
import { Page } from 'src/app/interfaces/page';
import { ClienteService } from 'src/app/services/clientes/cliente.service';
import { DatosSocietariosService } from 'src/app/services/clientes/datos-societarios.service';
import { ContactosService } from 'src/app/services/contactos.service';
import { DatosGeneralesService } from 'src/app/services/clientes/datos-generales.service';
import { BuscadorClienteComponent } from 'src/app/components/shared/buscador-cliente/buscador-cliente.component';
import { DocumentacionService } from 'src/app/services/documentacion.service';
import { PaisesService } from 'src/app/services/paises.service';
import { IdNombre } from 'src/app/interfaces/id-nombre';
import { HttpErrorResponse } from '@angular/common/http';
import { IdDescripcionCod } from 'src/app/interfaces/id-descripcion-cod';
import { TaOpcionService } from 'src/app/services/ta-opcion-service';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ClienteDatosGenerales } from 'src/app/interfaces/cliente-datos-generales';
import { ERROR_MESSAGES } from 'src/app/enums/error-messages';
const swal = require('sweetalert');
import modalUtils from "src/app/utils/modal";
import { MailService } from '../../../services/mail.service';

@Component({
  selector: 'app-miembros-administrativos',
  templateUrl: './miembros-administrativos.component.html',
  styleUrls: ['./miembros-administrativos.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MiembrosAdministrativosComponent implements OnInit {

  @Input() set isMiembroFiscalizacion(value: boolean) {
    this._isMiembroFiscalizacion = value;
  }

  @Input() set idCliente(value) {
    if (value) {
      this.id = value;
      this.init();
    }
  }

  public _isMiembroFiscalizacion: boolean = false;

  @ViewChild('modalResolucion') modalResolucion: ModalDirective;
  @ViewChild('modalMiembro') modalMiembro: ModalDirective;
  formResolucion: FormGroup;
  formMiembro: FormGroup;

  public datosGenerales: ClienteDatosGenerales;
  public cliente: ClienteFisico;
  public resoluciones: Array<Resolucion> = [];
  public miembros: Array<MiembroAdministrativo> = [];
  public miembrosFiltrados: Array<MiembroAdministrativo> = [];
  public clientes: Array<ClienteLite> = [];
  public contactos: Array<Contacto> = [];
  public tiposDocumento: Array<IdDescripcionCod> = [];
  public listadoTipoDni: Array<any> = [];
  public listadoCuit: Array<any> = [];
  public listadoDescripcion: Array<any> = [];
  public listadoResolucion: Array<any> = [];
  public listadoCargo: Array<{ id: string, text: string }> = [
    { id: '01', text: 'PRESIDENTE' },
    { id: '02', text: 'VICEPRESIDENTE' },
    { id: '03', text: 'SECRETARIO' },
    { id: '04', text: 'PROSECRETARIO' },
    { id: '05', text: 'TESORERO' },
    { id: '06', text: 'PROTESORERO' },
    { id: '07', text: 'DIRECTOR TITULAR' },
    { id: '08', text: 'DIRECTOR SUPLENTE' },
    { id: '09', text: 'GERENTE TITULAR' },
    { id: '10', text: 'ADMINISTRADOR TITULAR' },
    { id: '11', text: 'SÍNDICO TITULAR' },
    { id: '12', text: 'SÍNDICO SUPLENTE' },
    { id: '13', text: 'MIEMBRO CONSEJO DE VIGILANCIA' },
    { id: '14', text: 'CONSEJERO' },
    { id: '15', text: 'GERENTE SUPLENTE' },
    { id: '16', text: 'ADMINISTRADOR SUPLENTE' },
    { id: '17', text: 'REPRESENTANTE' },
    { id: '18', text: 'VOCAL TITULAR' },
    { id: '19', text: 'VOCAL SUPLENTE' },
    { id: '20', text: 'FISCALIZADOR TITULAR' },
    { id: '21', text: 'FISCALIZADOR SUPLENTE' }];

  public listadoMail: Array<any> = [];
  public nacionalidades: Array<string> = [];
  public listadoSiNo: Array<any> = [{ id: true, text: 'SI' }, { id: false, text: 'NO' }];
  public categoriasArchivosMiembro: Array<CategoriaDocumento> = [{ "id": "ARCHIVOS_DE_MIEMBRO_ADMINISTRATIVO", "nombre": "Archivos de MA" }];
  public categoriasArchivosResolucion: Array<CategoriaDocumento> = [{ "id": "RESOLUCIONES", "nombre": "Resoluciones" }];
  public ordenResolucion: number = 1;
  public ordenMiembro: number = -1;
  public filtraxResolucion: string;
  public filtraxMiembro: string;
  private defaultErrorMessage = "Ocurrió un error al aplicar los cambios.";

  public resolucion: Resolucion;
  public resolucionSeleccionada: Resolucion = null;
  public miembro: MiembroAdministrativo;
  public miembroSeleccionado: MiembroAdministrativo;
  filter: string = "";
  bsConfig: Partial<BsDatepickerConfig>;
  loadingResolucion: boolean;
  loadingMiembro: boolean;

  isCreatingCuit: boolean = false;
  isCreatingDescripcion: boolean = false;
  isCreatingMail: boolean = false;
  public id: number;
  public categoriaArchivo = "dniPdf";

  //componente buscador
  @ViewChild('buscadorCliente') buscadorCliente: BuscadorClienteComponent;

  constructor(private formBuilder: FormBuilder,
    private localeService: BsLocaleService,
    private datosSocietariosService: DatosSocietariosService,
    private activatedRoute: ActivatedRoute,
    private clienteService: ClienteService,
    private contactosService: ContactosService,
    private datosGeneralesService: DatosGeneralesService,
    private documentacionService: DocumentacionService,
    private paisesService: PaisesService,
    private taOpcionService: TaOpcionService,
    private mailService: MailService) {
    this.loadingResolucion = true;
    this.loadingMiembro = true;
    this.formResolucion = formBuilder.group({
      'fechaDesignacion': [null, Validators.required],
      //'fechaInicioMandato': [null],
      'fechaVtoMandato': [null],
      //'legajoNumero': [null],
      //'legajoFecha': [null],
      //'legajoFojas': [null],
      'resolucionNumero': [null],
      'resolucionFecha': [null],
      'resolucionCheck': [false],
      'indeterminadoCheck': [false]
    });
    this.formMiembro = formBuilder.group({
      'nomyape': [null, Validators.required],
      'cuit': [null, Validators.required],
      'mail': [null],
      'telefono': [null],
      'fechaNacimiento': [null],
      'nacionalidad': [null],
      'tipoDocumento': [null, Validators.required],
      'numDocumento': [null, [Validators.required, Validators.maxLength(15)]],
      'resolucion': [null, Validators.required],
      'fechaNombramiento': [null],
      'cargo': [null],
      'fechaMandatoDesde': [null],
      'fechaMandatoHasta': [null],
      'fechaBaja': [null],
      'causa': [null],
      'indeterminado': [false],
    });
    this.bsConfig = Object.assign({},
      {
        containerClass: 'theme-angle',
        dateInputFormat: 'DD/MM/YYYY'
      });
    this.localeService.use('es');
  }

  ngOnInit(): void {

    if (!this.id) {
      this.id = parseInt(this.activatedRoute.snapshot.paramMap.get('id')) || null;
      this.init();
    }

    this.clienteService.findclientes().subscribe((response: Page<any>) => {
      this.clientes = response.content;
      this.listadoCuit = this.clientes.map(o => { return { id: o.cuit } });
      this.listadoDescripcion = this.clientes.map(o => { return { text: o.nombre, id: o.id } });
      // this.cliente = <ClienteFisico><unknown>this.clientes.find(c=>c.id === this.id);
    });
    this.contactosService.findContactos().subscribe((response: Page<Contacto>) => {
      this.contactos = response.content;
      this.listadoMail = this.contactos.map(o => { return { id: o.mail } });
    });
    this.findTipoDocumento();
    this.findPaises();
  }

  public init() {
    if (this.id)
      this.findResoluciones();
    this.findMiembros();
  }

  public findResoluciones() {
    this.datosSocietariosService.findResoluciones(this.id, this._isMiembroFiscalizacion ? 'F' : 'A').subscribe((response: Page<Resolucion>) => {
      this.resoluciones = response.content;
      this.listadoResolucion = this.resoluciones.map(o => { return { id: o.id, text: o.resolucionNumero ? o.resolucionNumero + " - " + this.formatDate(o.fechaDesignacion) : this.formatDate(o.fechaDesignacion) } });
      this.resolucionSeleccionada = null
      this.loadingResolucion = false;
    });
  }
  public findMiembros() {
    this.datosSocietariosService.findMiembros(this.id, this._isMiembroFiscalizacion ? 'F' : 'A').subscribe((response: Page<MiembroAdministrativo>) => {
      this.miembros = response.content;
      this.ordenarMiembro('fechaNombramiento');
      this.loadingMiembro = false;
    });
  }
  public findTipoDocumento() {
    this.taOpcionService.findTipoDocumento().subscribe((response: Page<IdDescripcionCod>) => {
      this.tiposDocumento = response.content;
      this.listadoTipoDni = response.content.map(p => ({ id: p.codigo, text: p.descripcion }));
    });
  }

  public findPaises() {
    this.paisesService.findPaises().subscribe((response: Page<IdNombre>) => {
      this.nacionalidades = response.content.map(p => p.nombre);
    });
  }
  public findCargo(codigo: string): string {
    return this.listadoCargo.find(c => c?.id === codigo)?.text;
  }
  public formatDate(date: string) {
    if (date && moment(date, 'YYYY-MM-DD').isValid())
      return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
    else return null;
  }
  public unFormatDate(date: string) {
    if (date && moment(date, 'DD/MM/YYYY').isValid())
      return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
    else return null;
  }
  public submitFormResolucion($event, value: Resolucion): void {
    $event.preventDefault();
    for (let c in this.formResolucion.controls) {
      this.formResolucion.controls[c].markAsTouched();
    }
    if (this.formResolucion.valid) {
      value = Object.assign({}, value);
      value.fechaDesignacion = this.unFormatDate(value.fechaDesignacion);
      //value.legajoFecha = this.unFormatDate(value.legajoFecha);
      value.fechaInicioMandato = this.unFormatDate(value.fechaDesignacion);
      value.fechaVtoMandato = !value.indeterminadoCheck ? this.unFormatDate(value.fechaVtoMandato) : null;
      value.resolucionFecha = this.unFormatDate(value.resolucionFecha);
      Object.assign(this.resolucion, value);
      this.resolucion.clase = this._isMiembroFiscalizacion ? "F" : "A";
      (this.resolucion.id ?
        this.datosSocietariosService.editResolucion(this.resolucion, this.id) :
        this.datosSocietariosService.createResolucion(this.resolucion, this.id)
      ).subscribe((resolucion: Resolucion) => {
        this.resolucion = resolucion;
        this.setFormResolucion(resolucion);
        this.findResoluciones();
        this.modalResolucion.hide();
        swal({
          title: 'Guardado',
          text: 'El registro se guardó exitosamente.',
          icon: 'success',
          buttons: false,
          timer: 1600
        });
      }, (error) => {
        swal('¡Lo sentimos!', error.error?.message || ERROR_MESSAGES.GUARDADO, 'error');
      });
    }
  }
  public submitFormMiembro($event, value: any): void {
    $event.preventDefault();
    for (let c in this.formMiembro.controls) {
      this.formMiembro.controls[c].markAsTouched();
    }
    if (this.formMiembro.valid) {
      value = Object.assign({}, value);
      value.fechaBaja = this.unFormatDate(value.fechaBaja);
      value.fechaMandatoDesde = this.unFormatDate(value.fechaMandatoDesde);
      value.fechaMandatoHasta = this.unFormatDate(value.fechaMandatoHasta);
      value.fechaNacimiento = this.unFormatDate(value.fechaNacimiento);
      value.fechaNombramiento = this.unFormatDate(value.fechaNombramiento);
      value.tipoDocumento = this.tiposDocumento.find(t => t.codigo === value.tipoDocumento);
      Object.assign(this.miembro, value);
      this.miembro.clase = this._isMiembroFiscalizacion ? "F" : "A";
      this.miembro.resolucion = this.resoluciones.find(r => r.id === value.resolucion);
      this.miembro.cliente = this.cliente ? this.cliente : this.miembro.cliente;
      this.miembro.datosGrales = this.datosGenerales ? this.datosGenerales : this.miembro.datosGrales;

      if (this.miembro.cuit) {
        this.clienteService.checkCuit(this.id, this.miembro.cuit)
          .subscribe((response: string) => {
            this.save();
          }, (error: HttpErrorResponse) => {
            if (error?.error?.text) {
              swal({
                title: 'CUIT INVÁLIDA',
                text: "La CUIT ingresada es inválida, ¿Desea continuar de todos modos?",
                icon: 'warning',
                buttons: {
                  cancel: { text: 'Cancelar', value: null, visible: true, className: '', closeModal: true },
                  confirm: { text: 'Si', value: true, visible: true, className: '', closeModal: false }
                }
              }).then((isConfirm) => {
                if (isConfirm) {
                  this.save();
                } else return;
              });
            }
          }
          )
      }
    }
  }
  public save() {
    (this.miembro.id ?
      this.datosSocietariosService.editMiembro(this.miembro, this.id) :
      this.datosSocietariosService.createMiembro(this.miembro, this.id)
    ).subscribe((miembro: MiembroAdministrativo) => {
      this.miembro = miembro;
      this.setFormMiembro(miembro);
      this.findMiembros();
      this.modalMiembro.hide();
      swal({
        title: 'Guardado',
        text: 'El registro se guardó exitosamente.',
        icon: 'success',
        buttons: false,
        timer: 1600
      });
    }, (error) => {
      swal('¡Lo sentimos!', error.error?.message || ERROR_MESSAGES.GUARDADO, 'error');
    });
  }

  public valueRequiredResolucion(value: string) {
    return this.formResolucion.controls[value].hasError('required') && (this.formResolucion.controls[value].dirty || this.formResolucion.controls[value].touched);
  }
  public valueRequiredMiembro(value: string) {
    return this.formMiembro.controls[value].hasError('required') && (this.formMiembro.controls[value].dirty || this.formMiembro.controls[value].touched);
  }

  public createResolucion() {
    this.resolucion = this.defaultResolucion();
    for (let c in this.formResolucion.controls) {
      this.formResolucion.controls[c].markAsUntouched();
      this.formResolucion.controls[c].markAsPristine();
    }
    this.setFormResolucion(this.resolucion);
  }
  public createMiembro() {
    this.miembro = this.defaultMiembro();
    this.buscadorCliente?.reset();
    for (let c in this.formMiembro.controls) {
      this.formMiembro.controls[c].markAsUntouched();
      this.formMiembro.controls[c].markAsPristine();
    }
    this.setFormMiembro(this.miembro);
  }

  editResolucion(resolucion: Resolucion) {
    this.resolucion = resolucion;
    this.setFormResolucion(this.resolucion);
  }
  editMiembro(miembro: MiembroAdministrativo) {
    this.formMiembro.enable();
    this.miembro = miembro;
    this.miembro.nomyape = this.miembro.nomyape ? this.miembro.nomyape : this.miembro?.datosGrales?.descripcion;
    this.setFormMiembro(this.miembro);
  }

  public deleteResolucion(resolucion: Resolucion) {
    const existenMiembros = this.miembros.some(m => m.resolucion.id === resolucion.id)
    if (existenMiembros)
      swal('¡Lo sentimos!', 'No es posible eliminar la resolución ya que contiene miembros administrativos cargados', 'error')
    else swal({
      title: 'Eliminar resolución',
      text: '¿Está seguro que quiere eliminar esta resolución?',
      icon: 'warning',
      buttons: {
        cancel: { text: 'Cancelar', value: null, visible: true, className: '', closeModal: true },
        danger: { text: 'Eliminar', value: true, visible: true, className: '', closeModal: false }
      }
    }).then((isConfirm) => {
      if (isConfirm) {
        this.datosSocietariosService.deleteResolucion(resolucion, this.id).subscribe((response: any) => {
          this.findResoluciones();
          swal({
            title: 'Eliminado',
            text: 'La resolución se eliminó exitosamente.',
            icon: 'success',
            buttons: false,
            timer: 1600
          });
        }, (error) => {
          swal('¡Lo sentimos!', error.error?.message || ERROR_MESSAGES.GUARDADO, 'error')
        });
      }
    });
  }
  public deleteMiembro(miembro: MiembroAdministrativo) {
    swal({
      title: 'Eliminar miembro administrativo',
      text: '¿Está seguro que quiere eliminar este miembro administrativo?',
      icon: 'warning',
      buttons: {
        cancel: { text: 'Cancelar', value: null, visible: true, className: '', closeModal: true },
        danger: { text: 'Eliminar', value: true, visible: true, className: '', closeModal: false }
      }
    }).then((isConfirm) => {
      if (isConfirm) {
        this.datosSocietariosService.deleteMiembro(miembro, this.id).subscribe((response: any) => {
          this.findMiembros();
          swal({
            title: 'Eliminado',
            text: 'El miembro administrativo se eliminó exitosamente.',
            icon: 'success',
            buttons: false,
            timer: 1600
          });
        }, (error) => {
          swal('¡Lo sentimos!', error.error?.message || ERROR_MESSAGES.GUARDADO, 'error')
        });
      }
    });
  }

  private async setFormResolucion(resolucion: Resolucion) {
    resolucion = Object.assign({}, resolucion);
    resolucion.fechaDesignacion = this.formatDate(resolucion?.fechaDesignacion);
    //resolucion.legajoFecha = this.formatDate(resolucion?.legajoFecha);
    resolucion.resolucionFecha = this.formatDate(resolucion?.resolucionFecha);
    //resolucion.fechaInicioMandato = this.formatDate(resolucion?.resolucionFecha);
    resolucion.fechaVtoMandato = !resolucion.indeterminadoCheck ? this.formatDate(resolucion?.fechaVtoMandato) : null;
    this.formResolucion.patchValue(resolucion);
    resolucion?.documento?.url_archivo ? this.agregarArchivoResolucion(resolucion?.documento) : this.eliminarArchivoResolucion();
    resolucion?.resolucionDoc?.url_archivo ? this.agregarArchivoResolucion(resolucion?.resolucionDoc, 'resolucionDoc') : this.eliminarArchivoResolucion('resolucionDoc');
    /* this.formResolucion.controls.resolucionNumero.value || this.formResolucion.controls.resolucionFecha.value ?
      this.formResolucion.controls.resolucionCheck.setValue(true) :
      this.formResolucion.controls.resolucionCheck.setValue(false); */
    this.changeValueResolucion();
  }
  private async setFormMiembro(miembro: MiembroAdministrativo) {
    miembro = Object.assign({}, miembro);
    miembro.fechaBaja = this.formatDate(miembro?.fechaBaja);
    this.setFechasMandato(miembro.resolucion?.id || null, miembro)
    miembro.fechaNacimiento = this.formatDate(miembro?.fechaNacimiento);
    miembro.fechaNombramiento = this.formatDate(miembro?.fechaNombramiento);
    this.formMiembro.patchValue(miembro);
    this.formMiembro.controls.resolucion.patchValue(miembro?.resolucion?.id);
    if (miembro?.tipoDocumento?.codigo)
      this.formMiembro.controls.tipoDocumento.patchValue(miembro?.tipoDocumento?.codigo);
    else
      this.formMiembro.controls.tipoDocumento.patchValue(this.cliente?.tipoCliente === "j" ? "08" : this.cliente?.tipoDocumento);
    miembro?.docMa ? this.agregarArchivoMiembro(miembro?.docMa) : this.eliminarArchivoMiembro();
    this.setFormFechasMandato();
    this.isCreatingCuit = false;
    this.isCreatingDescripcion = false;
    this.isCreatingMail = false;
  }
  private setFechasMandato(resolucionId: number, miembro: MiembroAdministrativo) {
    const resolucion = this.findResolucion(resolucionId)
    /* if (resolucion) {
      miembro.fechaMandatoDesde = this.formatDate(resolucion?.fechaDesignacion);
      miembro.fechaMandatoHasta = this.formatDate(resolucion?.fechaVtoMandato);
    } else { */
    miembro.fechaMandatoDesde = this.formatDate(miembro?.fechaMandatoDesde || resolucion?.fechaDesignacion);
    miembro.fechaMandatoHasta = this.formatDate(miembro?.fechaMandatoHasta || resolucion?.fechaVtoMandato);
    //}
  }
  private findResolucion(resolucionId: number): Resolucion {
    return this.resoluciones.find(r => r.id === resolucionId)
  }
  public setFormFechasMandato() {
    if (this.miembro.fechaMandatoDesde) {
      this.formMiembro.controls.fechaMandatoDesde.patchValue(this.formatDate(this.miembro?.fechaMandatoDesde))
      this.formMiembro.controls.fechaMandatoHasta.patchValue(this.formatDate(this.miembro?.fechaMandatoHasta))
    } else {
      const resolucion = this.findResolucion(this.formMiembro.value.resolucion)
      this.formMiembro.controls.fechaMandatoDesde.patchValue(this.formatDate(resolucion?.fechaDesignacion))
      this.formMiembro.controls.fechaMandatoHasta.patchValue(this.formatDate(resolucion?.fechaVtoMandato))
      this.formMiembro.controls.indeterminado.patchValue(resolucion?.indeterminadoCheck || false)
    }

    /* this.formMiembro.controls.fechaMandatoDesde.disable()
    this.formMiembro.controls.fechaMandatoHasta.disable()
    this.formMiembro.controls.indeterminado.disable() */
  }

  private defaultResolucion(): Resolucion {
    return {
      id: null,
      fechaDesignacion: null,
      legajoNumero: null,
      legajoFecha: null,
      legajoFojas: null,
      resolucionNumero: null,
      resolucionFecha: null,
      resolucionCheck: null,
      documento: null,
      clase: null,
      cliente: null,
      fechaInicioMandato: null,
      fechaVtoMandato: null,
      resolucionDoc: null,
      indeterminadoCheck: null
    };
  }
  private defaultMiembro(): MiembroAdministrativo {
    return {
      id: null,
      nomyape: null,
      cuit: null,
      mail: null,
      telefono: null,
      fechaNacimiento: null,
      nacionalidad: null,
      tipoDocumento: null,
      numDocumento: null,
      resolucion: this.resolucionSeleccionada,
      fechaNombramiento: null,
      cargo: null,
      fechaMandatoDesde: null,
      fechaMandatoHasta: null,
      fechaBaja: null,
      causa: null,
      docMa: new Documento(),
      clase: null,
      indeterminado: false,
      cliente: null,
      datosGrales: null
    };
  }

  public ordenarResolucion(campo: string) {
    this.filtraxResolucion = campo;
    this.resoluciones.sort((a, b) => {
      const codigoA = (a[campo] || "").toString().toLowerCase();
      const codigoB = (b[campo] || "").toString().toLowerCase();
      if (codigoA < codigoB) {
        return -1 * (this.ordenResolucion);
      }
      if (codigoA > codigoB) {
        return 1 * (this.ordenResolucion);
      }
      return 0;
    });
  }
  public ordenarMiembro(campo: string) {
    this.filtraxMiembro = campo;
    this.miembros.sort((a, b) => {
      const codigoA = (a[campo] || "").toString().toLowerCase();
      const codigoB = (b[campo] || "").toString().toLowerCase();
      if (codigoA < codigoB) {
        return -1 * (this.ordenMiembro);
      }
      if (codigoA > codigoB) {
        return 1 * (this.ordenMiembro);
      }
      return 0;
    });
  }
  public nullifyControl(key: string) {
    this.formMiembro.controls[key].setValue(null);
  }

  public changeValueResolucion() {
    this.formResolucion.controls.resolucionCheck.value ?
      (this.formResolucion.controls.resolucionNumero.enable(), this.formResolucion.controls.resolucionFecha.enable()) :
      (this.formResolucion.controls.resolucionNumero.disable(), this.formResolucion.controls.resolucionFecha.disable())
  }
  public changeValueResolIndeterminado() {
    !this.formResolucion.controls.indeterminadoCheck.value ?
      this.formResolucion.controls.fechaVtoMandato.enable() : this.formResolucion.controls.fechaVtoMandato.disable()
  }
  public changeValueIndeterminado() {
    this.formMiembro.controls.indeterminado.value ?
      (this.formMiembro.controls.fechaMandatoHasta.disable()) :
      (this.formMiembro.controls.fechaMandatoHasta.enable())
  }
  public filtrarMiembros(resolucion: Resolucion) {
    this.resolucionSeleccionada = resolucion
    this.miembrosFiltrados = this.miembros.filter(m => m.resolucion.id === resolucion.id)
  }
  public agregarArchivoMiembro(documento: IDocumento) {
    documento.seleccionado = true;
    documento.modificable = true;
    documento.eliminable = true;
    this.miembro.docMa = new Documento(documento);
  }
  public eliminarArchivoMiembro() {
    this.miembro.docMa = new Documento();
  }
  public agregarArchivoResolucion(documento: IDocumento, campo: string = 'documento') {
    documento.seleccionado = true;
    documento.modificable = true;
    documento.eliminable = true;
    this.resolucion[campo] = new Documento(documento);
  }
  public eliminarArchivoResolucion(campo: string = 'documento') {
    this.resolucion[campo] = new Documento();
  }

  public selecciona(idDatosGenerales: number) {
    if (idDatosGenerales) {
      forkJoin([
        this.datosGeneralesService.findDatosGrales(this.id, idDatosGenerales),
        this.datosGeneralesService.findDatosGeneralesByIdDatosGenerales(idDatosGenerales)
      ]).pipe(finalize(() => {
      })).subscribe(([datosGenerales, cliente]) => {
        this.datosGenerales = datosGenerales;
        this.cliente = cliente;
        let contacto = this.contactos?.find(c => c.cliente === this.cliente.id || c.cuit === this.datosGenerales?.cuit);
        this.miembro = {
          nomyape: this.datosGenerales?.descripcion,
          cuit: this.datosGenerales?.cuit,
          mail: contacto?.mail,
          telefono: contacto?.telefono,
          tipoDocumento: this.tiposDocumento.find(t => t.codigo === this.cliente.tipoDocumento),
          numDocumento: this.cliente?.tipoCliente === "j" ? this.datosGenerales?.cuit : this.datosGenerales?.nroDocumento,
          nacionalidad: this.datosGenerales?.nacionalidad,
          fechaNacimiento: this.cliente.fechaNacimiento || contacto?.fecha_nacimiento,
          docMa: this.datosGenerales?.dniDoc
        }
        this.setFormMiembro(this.miembro);

      }, error => {
        swal("¡Lo sentimos!", error?.error?.message || ERROR_MESSAGES.DEFAULT, "error");
      });
    } else {
      this.miembro = this.defaultMiembro();
      this.datosGenerales = null;
      this.cliente = null;
      this.setFormMiembro(this.miembro);
    }
  }
  public descargarMail(resolucion: Resolucion) {
    this.datosSocietariosService.updateMail(resolucion, this.id).subscribe(() => {
      this.mailService.archivosMiembrosAdministrativos(resolucion.id)
    }, (error) => swal('¡Lo sentimos!', error.error?.message || ERROR_MESSAGES.GUARDADO, 'error'))
  }

  public openModal(template: ModalDirective) {
    modalUtils.open(template);
  }
}