import { Component, Input, OnInit } from '@angular/core';
import { CortesOutsourcing } from 'src/app/interfaces/cortes-outsourcing';

@Component({
  selector: 'app-card-corte',
  templateUrl: './card-corte.component.html',
  styleUrls: ['./card-corte.component.scss']
})
export class CardCorteComponent implements OnInit {

  @Input() corte: CortesOutsourcing;

  ngOnInit(): void {
  }

}
