import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { PersonaFacturacion } from "../classes/valorizacion";
import { CortesAuditoria } from "../interfaces/cortes-auditoria";
import { GrupoEconomico } from "../interfaces/grupo-economico";
import { ITarea, ITareaDetalle, tareaRepetitiva, TareaRepetitivaArchivo } from '../interfaces/itarea';
import { ITareaDesarrollo } from "../interfaces/itarea-desarrollo";
import { Observacion } from "../interfaces/observacion";
import { Page } from "../interfaces/page";
import { Personal } from "../interfaces/personal";
import { Valorizacion } from "../interfaces/valorizacion";
import { Dpj } from "../interfaces/dpj";

@Injectable({
  providedIn: "root"
})
export class TareasService {

  constructor(private http: HttpClient) { }

  public recurso: string = "/tareas";

  public findAll({
    pageNumber = 1,
    pageSize = 10000,
    orderCriteria = "ASC",
    orderField = "cliente.id",
    clienteId = null,
    estado = null,
    fechaVencimientoDesde = null,
    fechaVencimientoHasta = null,
    fechaControlDesde = null,
    fechaControlHasta = null,
    rol = null,
    responsableId = null,
    grupoEconomico = null,
    tareaAuditoria = null,
    tareaDescripcion = null,
    visto = null,
    valorizada = null,
    saldosPendientes = null,
    repetitiva = null
  } = {}): Observable<Page<ITarea>> {
    let params = new URLSearchParams([]);
    if (pageNumber) params.append("pageNumber", pageNumber.toString());
    if (pageSize) params.append("pageSize", pageSize.toString());
    if (orderCriteria) params.append("orderCriteria", orderCriteria);
    if (orderField) params.append("orderField", orderField);
    if (clienteId) params.append("clienteId", clienteId.toString());
    if (estado) params.append("estado", estado);
    if (fechaVencimientoDesde) params.append("fechaVencimientoDesde", fechaVencimientoDesde);
    if (fechaVencimientoHasta) params.append("fechaVencimientoHasta", fechaVencimientoHasta);
    if (fechaControlDesde) params.append("fechaControlDesde", fechaControlDesde);
    if (fechaControlHasta) params.append("fechaControlHasta", fechaControlHasta);
    if (rol) params.append("rol", rol);
    if (responsableId) params.append("responsableId", responsableId.toString());
    if (grupoEconomico) params.append("grupoEconomico", grupoEconomico);
    if (tareaAuditoria) params.append("tareaAuditoria", tareaAuditoria);
    if (tareaDescripcion) params.append("tareaDescripcion", tareaDescripcion);
    if (visto || visto === 0) params.append("visto", visto);
    if (valorizada || valorizada === 0) params.append("valorizada", valorizada);
    if (repetitiva) params.append("repetitiva", repetitiva.toString());

    if (saldosPendientes !== null)
      params.append("saldosPendientes", saldosPendientes)

    return this.http.get<Page<ITarea>>(`${environment.apiEndpoint}${this.recurso}?${params.toString()}`);
  }

  public findResponsables() {
    let endpoint = "/responsables";
    return this.http.get(environment.apiEndpoint + endpoint);
  }

  public findDetalleTarea(idTarea: number): Observable<Array<ITareaDetalle>> {
    let endpoint = "/controlHoras";
    return this.http.get<Array<ITareaDetalle>>(`${environment.apiEndpoint}${this.recurso}/${idTarea}${endpoint}`);
  }

  public findGrupoEconomicos(): Observable<Page<GrupoEconomico>> {
    let endpoint = "/gruposeconomicos?habilitado=S";
    return this.http.get<Page<GrupoEconomico>>(environment.apiEndpoint + endpoint);
  }

  public findPersonalHabilitado(): Observable<Page<Personal>> {
    return this.http.get<Page<Personal>>(`${environment.apiEndpoint}/personal?habilitado=S`);
  }

  public findById(id: number): Observable<ITarea> {
    return this.http.get<ITarea>(`${environment.apiEndpoint}${this.recurso}/${id}`);
  }

  public visualizarTarea(tareaId: number) {
    return this.http.patch(`${environment.apiEndpoint}${this.recurso}/${tareaId}/visto`, null);
  }

  public posponerTarea(tareaId: number) {
    return this.http.patch(`${environment.apiEndpoint}${this.recurso}/${tareaId}/pospuesto`, null);
  }

  public setFechaAutogestion(tarea: ITarea, subTarea: ITareaDesarrollo, fecha: string) {
    return this.http.patch(`${environment.apiEndpoint}${this.recurso}/${tarea.id}/subtarea/${subTarea.id}?fechaAutogestion=${fecha}`, null);
  }

  public createTarea(tarea: ITarea) {
    return this.http.post(`${environment.apiEndpoint}${this.recurso}`, tarea);
  }

  public altaGestionDpj(tareaId: number){
    return this.http.post<Dpj>(`${environment.apiEndpoint}/tareasDpj/${tareaId}`, null);
  }

  public editTarea(tarea: ITarea, notificar: boolean) {
    return this.http.put(`${environment.apiEndpoint}${this.recurso}/${tarea.id}?notificar=${notificar}`, tarea);
  }

  public cerrarTarea(tarea: ITarea) {
    return this.http.put(`${environment.apiEndpoint}${this.recurso}/${tarea.id}/cerrar`, tarea);
  }
  public cerrarMultiplesTareas(tareasIds: Array<number>) {
    let params = new URLSearchParams([["ids", tareasIds.join(",")]]);
    return this.http.put(`${environment.apiEndpoint}${this.recurso}/cerrar?${params.toString()}`, null);
  }

  public abrirTarea(tarea: ITarea) {
    return this.http.put(`${environment.apiEndpoint}${this.recurso}/${tarea.id}/abrir`, tarea);
  }

  public deleteTarea(tarea: ITarea) {
    return this.http.delete(`${environment.apiEndpoint}${this.recurso}/${tarea.id}`);
  }
  public deleteMultiplesTareas(tareasIds: Array<number>) {
    let params = new URLSearchParams([["ids", tareasIds.join(",")]]);
    return this.http.delete(`${environment.apiEndpoint}${this.recurso}?${params.toString()}`);
  }

  public findObservaciones(tareaId: number): Observable<Page<Observacion>> {
    return this.http.get<Page<Observacion>>(`${environment.apiEndpoint}${this.recurso}/${tareaId}/observaciones`);
  }

  public createObservacion(tareaId: number, observacion: Observacion, sendEmail: boolean = false): Observable<Observacion> {
    return this.http.post<Observacion>(`${environment.apiEndpoint}${this.recurso}/${tareaId}/observaciones?sendEmail=${sendEmail}`, observacion);
  }

  public getTareasPendientes(responsableId: number): Observable<Page<ITarea>> {
    return this.http.get<Page<ITarea>>(`${environment.apiEndpoint}${this.recurso}/pendientes/?responsableId=${responsableId}`);
  }

  // valorizacion
  public getPersonalParticipa(tareaId: number): Observable<Array<PersonaFacturacion>> {
    return this.http.get<Array<PersonaFacturacion>>(`${environment.apiEndpoint}${this.recurso}/${tareaId}/personalParticipa`);
  }
  public getPersonalParticipaPresupuesto(tareaId: number): Observable<Array<PersonaFacturacion>> {
    return this.http.get<Array<PersonaFacturacion>>(`${environment.apiEndpoint}${this.recurso}/${tareaId}/personalParticipa/presupuesto`);
  }

  public valorizar(tarea: CortesAuditoria): Observable<Array<Valorizacion>> {
    return this.http.get<Array<Valorizacion>>(`${environment.apiEndpoint}${this.recurso}/${tarea.id}/valorizacion`);
  }

  public valorizarPresupuesto(tareaId: number, valorizacion: number) {
    return this.http.post(`${environment.apiEndpoint}${this.recurso}/${tareaId}/valorizacion/presupuestar`, valorizacion);
  }

  public valorizarFacturar(tareaId: number, factura: Valorizacion): Observable<Valorizacion> {
    return this.http.post<Valorizacion>(`${environment.apiEndpoint}${this.recurso}/${tareaId}/valorizacion`, factura);
  }

  public valorizarFacturarEdit(tarea, factura) {
    return this.http.put(`${environment.apiEndpoint}${this.recurso}/${tarea.id}/valorizacion/${factura.id}`, factura);
  }

  public valorizarFacturarEliminar(tarea, factura) {
    return this.http.delete(`${environment.apiEndpoint}${this.recurso}/${tarea.id}/valorizacion/${factura.id}`);
  }

  public cerrarSubtarea(tarea: ITarea, subtarea: ITareaDesarrollo): Observable<void> {
    return this.http.put<void>(`${environment.apiEndpoint}/tareas/${tarea.id}/subtareaCerrar/${subtarea.id}`, subtarea);
  }

  public abrirSubtarea(tarea: ITarea, subtarea: ITareaDesarrollo): Observable<void> {
    return this.http.put<void>(`${environment.apiEndpoint}/tareas/${tarea.id}/subtareaAbrir/${subtarea.id}`, subtarea);
  }
  public createTareaRepetitiva(tareaDto: tareaRepetitiva) {
    return this.http.post(`${environment.apiEndpoint}${this.recurso}/repetitiva`, tareaDto);
  }
  public getArchivosTareaRepetitiva(idTarea: number): Observable<TareaRepetitivaArchivo> {
    return this.http.get<TareaRepetitivaArchivo>(`${environment.apiEndpoint}${this.recurso}/${idTarea}/repetitiva`);
  }
}
