import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Domicilio, TipoDomicilio } from "src/app/interfaces/domicilio";
import { Page } from "src/app/interfaces/page";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class DomiciliosService {

  public recurso: string = "/clientes";

  constructor(private http: HttpClient) { }

  public idDatosGenerales(idCliente: number): Observable<number> {
    return this.http.get<number>(`${environment.apiEndpoint}${this.recurso}/${idCliente}/datosGralesId`);
  }

  public findTipoDomicilio(): Observable<Page<TipoDomicilio>> {
    let endpoint = "/taopcion/tipoDomicilio";
    return this.http.get<Page<TipoDomicilio>>(environment.apiEndpoint + endpoint);
  }

  /* ----====Domicilios====---- */

  public findDomicilios(idCliente: number, idDatosGenerales: number) {
    return this.http.get(`${environment.apiEndpoint}${this.recurso}/${idCliente}/datosGenerales/${idDatosGenerales}/domicilios`);
  }

  public newDomicilio(idCliente: number, idDatosGenerales: number, newDomicilio: Domicilio): Observable<Domicilio> {
    return this.http.post<Domicilio>(`${environment.apiEndpoint}${this.recurso}/${idCliente}/datosGenerales/${idDatosGenerales}/domicilios`, newDomicilio);
  }

  public editDomicilio(idCliente: number, idDatosGenerales: number, domicilio: Domicilio): Observable<Domicilio> {
    return this.http.put<Domicilio>(`${environment.apiEndpoint}${this.recurso}/${idCliente}/datosGenerales/${idDatosGenerales}/domicilios/${domicilio.id}`, domicilio);
  }
  public deleteDomicilios(idCliente: number, idDatosGenerales: number, domicilio: Domicilio) {
    return this.http.request("DELETE", `${environment.apiEndpoint}${this.recurso}/${idCliente}/datosGenerales/${idDatosGenerales}/domicilios`, { body: domicilio });
  }
  public updateMail(idCliente: number, idDatosGenerales: number, domicilio: Domicilio) {
    return this.http.get(`${environment.apiEndpoint}/clientes/${idCliente}/datosGenerales/${idDatosGenerales}/domicilios/mail/${domicilio.id}`);
  }
}
