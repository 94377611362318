import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Personal as IPersonal, Personal, PersonalAreas, Responsable as IResponsable } from "src/app/interfaces/personal";
import { Observable, of } from "rxjs";
import { AREA } from "src/app/enums/areas";
import { Page } from "../interfaces/page";
import { IdDescripcionCod } from "../interfaces/id-descripcion-cod";
import { PersonalDatosIniciales } from "../interfaces/personal-datos-iniciales";
import { PersonalDatosGenerales } from "../interfaces/personal-datos-generales";
import { PersonalMatricula } from "../interfaces/personal-matricula";

import { HABILITADO } from "../enums/habilitado";
import { AuthService } from "./auth.service";
import { Observacion, ObservacionPersonal } from "../interfaces/observacion";
@Injectable({
  providedIn: "root"
})
export class PersonalService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  public findPersonal(habilitado: HABILITADO = HABILITADO.SI): Observable<Page<Personal>> {
    return this.http.get<Page<Personal>>(`${environment.apiEndpoint}/personal?habilitado=${habilitado}&pageSize=${1000}`);
  }

  public findPersonalAreas({
    pageNumber = 1,
    pageSize = 1000,
    orderField = "pDesc",
    orderCriteria = "ASC",
    habilitado = HABILITADO.TODOS
  } = {}): Observable<Page<PersonalAreas>> {
    if (!habilitado)
      habilitado = HABILITADO.TODOS;
    let params = new URLSearchParams([
      ["pageNumber", pageNumber.toString()],
      ["pageSize", pageSize.toString()],
      ["orderField", orderField],
      ["orderCriteria", orderCriteria],
      ["habilitado", habilitado]
    ]);

    return this.http.get<Page<PersonalAreas>>(`${environment.apiEndpoint}/personalAreas?${params.toString()}`);
  }

  public findPersonalHabilitado(): Observable<Page<Personal>> {
    return this.http.get<Page<Personal>>(`${environment.apiEndpoint}/personal?habilitado=S`);
  }

  public findPersonalArea({ personalId = this.authService.getUserLogged().id }): Observable<Array<IdDescripcionCod>> {
    return this.http.get<Array<IdDescripcionCod>>(`${environment.apiEndpoint}/personal/${personalId.toString()}/areas`);
  }

  public findPersonalByArea({
    pageNumber = 1,
    pageSize = 1000,
    orderCriteria = "ASC",
    orderField = "id",
    codigo = null
  } = {}): Observable<Page<Personal>> {
    let params = new URLSearchParams([
      ["pageNumber", pageNumber.toString()],
      ["pageSize", pageSize.toString()],
      ["orderCriteria", orderCriteria],
      ["orderField", orderField]
    ]);
    return this.http.get<Page<Personal>>(`${environment.apiEndpoint}/area/${codigo}/personal?${params.toString()}`);
  }

  public findResponsables() {
    return this.http.get<Page<Personal>>(`${environment.apiEndpoint}/responsables`);
  }
  public findAllReferentes() {
    let endpoint = '/personal?habilitado=S';
    return this.http.get<{ content: any[] }>(environment.apiEndpoint + endpoint);
  }

  public findResponsablesByArea(area: AREA): Observable<Page<IPersonal>> {
    return this.http.get<Page<IPersonal>>(`${environment.apiEndpoint}/responsables/${area}`);
  }

  public findResponsablesByClienteAndArea(clienteId: number, area: AREA): Observable<Array<IResponsable>> {
    return this.http.get<Array<IResponsable>>(`${environment.apiEndpoint}/responsables/findByClienteAndArea?clienteId=${clienteId}&area=${area}`);
  }

  public findByAccCodigo(accCodigo: string) {
    let endpoint = "/acceso/" + accCodigo;
    return this.http.get(environment.apiEndpoint + endpoint);
  }

  public findUltimoLegajoPersonal() {
    return this.http.get(`${environment.apiEndpoint}/ultimoLegajoPersonal`, { responseType: 'text' });
  }

  public findDatosIniciales(idPersonal: number): Observable<PersonalDatosIniciales> {
    return this.http.get<PersonalDatosIniciales>(`${environment.apiEndpoint}/personal/${idPersonal}/datosIniciales`);
  }

  public createDatosIniciales(datosIniciales: PersonalDatosIniciales) {
    return this.http.post(`${environment.apiEndpoint}/personal`, datosIniciales);
  }

  public editDatosIniciales(idPersonal: number, datosIniciales: PersonalDatosIniciales) {
    return this.http.put(`${environment.apiEndpoint}/personal/${idPersonal}/datosIniciales`, datosIniciales);
  }

  public findDatosGenerales(idPersonal: number): Observable<PersonalDatosGenerales> {
    return this.http.get<PersonalDatosGenerales>(`${environment.apiEndpoint}/personal/${idPersonal}/datosGenerales`);
  }

  public editDatosGenerales(idPersonal: number, datosGenerales: PersonalDatosGenerales) {
    return this.http.put(`${environment.apiEndpoint}/personal/${idPersonal}/datosGenerales`, datosGenerales);
  }
  public findMatriculas(idPersonal: number): Observable<Array<PersonalMatricula>> {
    return this.http.get<Array<PersonalMatricula>>(`${environment.apiEndpoint}/personal/${idPersonal}/personalMatriculas`);
  }
  public createMatricula(matricula: PersonalMatricula) {
    return this.http.post(`${environment.apiEndpoint}/personalMatriculas`, matricula);
  }
  public editMatricula(matricula: PersonalMatricula) {
    return this.http.put(`${environment.apiEndpoint}/personalMatriculas/${matricula.id}`, matricula);
  }
  public deleteMatricula(matricula: PersonalMatricula) {
    return this.http.delete(`${environment.apiEndpoint}/personalMatriculas/${matricula.id}`);
  }
  public findObservaciones(idPersonal: number): Observable<Page<Observacion>> {
    return this.http.get<Page<Observacion>>(`${environment.apiEndpoint}/personal/${idPersonal}/observaciones`);
  }
  public createObservacion(idPersonal: number, observacion: Observacion): Observable<Observacion> {
    return this.http.post<Observacion>(`${environment.apiEndpoint}/personal/${idPersonal}/observaciones`, observacion);
  }

  public personalLideres(): Observable<Array<Personal>> {
    return this.http.get<Array<Personal>>(`${environment.apiEndpoint}/personalLideres`);
  }
  public comiteAmpliado(): Observable<Array<Personal>> {
    return this.http.get<Array<Personal>>(`${environment.apiEndpoint}/comiteAmpliado`);
  }
  public isComiteAmpliado(): Promise<boolean> {
    return new Promise(async (resolve) => {
      this.comiteAmpliado()
        .subscribe(response => {
          resolve(response.findIndex(p => p.id === this.authService.getUserLogged().id) != -1);
        })
    });
  }
  public getPersonalSinUsuario(): Observable<Array<Personal>> {
    return this.http.get<Array<Personal>>(`${environment.apiEndpoint}/personalSinUsuario`);
  }
}