import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { from } from "rxjs";
import { Observable } from "rxjs/Observable";
import { TiposSociedades} from "src/app/interfaces/tipos-sociedades";
import { environment } from "src/environments/environment";
import { Page } from "../interfaces/page";

@Injectable({
  providedIn: "root"
})
export class TiposSociedadesService {

  public recurso: string = "/tipoSociedades";

  constructor(private http: HttpClient) { }

  public findTiposSociedades(): Observable<Page<TiposSociedades>> {
    let endpoint = "/tipoSociedades";
    return this.http.get<Page<TiposSociedades>>(environment.apiEndpoint + endpoint);
  }

  public create(tiposSociedades: TiposSociedades): Observable<TiposSociedades> {
    let endpoint = "/tipoSociedades";
    return this.http.post<TiposSociedades>(environment.apiEndpoint + endpoint, tiposSociedades);
  }
  
  public edit(tiposSociedades: TiposSociedades): Observable<TiposSociedades> {
    return this.http.put<TiposSociedades>(`${environment.apiEndpoint}${this.recurso}/${tiposSociedades.id}`, tiposSociedades);
  }

  public delete(id: number): Observable<TiposSociedades> {
    return this.http.delete<TiposSociedades>(`${environment.apiEndpoint}${this.recurso}/${id}`);
  }

}
