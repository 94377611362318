import { Component, OnDestroy, OnInit } from '@angular/core';
import { IdNombreCodigo } from "src/app/interfaces/id-nombre-codigo";
import { ITarea } from "src/app/interfaces/itarea";
import { TareasService } from "src/app/services/tareas.service";
import { Page } from "src/app/interfaces/page";
import { interval, Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy {

  public lengthTareas: number = 0;
  public usuario: IdNombreCodigo;
  public tareas: Array<ITarea>;
  subscription: Subscription;

  constructor(
    public tareasService: TareasService,
    public authService: AuthService
  ) { }

  ngOnInit(): void {
    this.findTareasBandejaEntrada();
    const source = interval(1000 * 60 * 5);
    source.subscribe(val => this.findTareasBandejaEntrada()
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public findTareasBandejaEntrada() {

    if (this.subscription)
      this.subscription.unsubscribe();

    this.usuario = this.authService.getUserLogged();

    if (!this.usuario)
      return;

    this.subscription = this.tareasService.getTareasPendientes(this.usuario.id)
      .subscribe((response: Page<ITarea>) => {
        this.tareas = response.content;
        this.lengthTareas = this.tareas.length;
      });
  }

}
