import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CortesAuditoria } from 'src/app/interfaces/cortes-auditoria';
import { IdDescripcionCod } from 'src/app/interfaces/id-descripcion-cod';
import { Page } from 'src/app/interfaces/page';
import modalUtils from "src/app/utils/modal";
import { Observable } from 'rxjs';
import { PlantillasObservaciones } from 'src/app/interfaces/plantillas-observaciones';
import { FechaPipe } from 'src/app/pipes/fecha.pipe';
import { MailService } from 'src/app/services/mail.service';
import { AuditoriaService } from 'src/app/services/auditoria.service';

@Component({
  selector: 'app-ejercicios-regulares',
  templateUrl: './ejercicios-regulares.component.html',
  styleUrls: ['./ejercicios-regulares.component.scss']
})
export class EjerciciosRegularesComponent implements OnInit {

  @Input() set clienteIdParameter(value: number) {
    this.idCliente = value;
    this.findAll();
  }

  @ViewChild('modal') modal: ModalDirective;

  public fechaPipe = new FechaPipe();

  public listado: Array<CortesAuditoria> = [];
  public tiposDeCierre: Array<IdDescripcionCod>;
  private idCliente: number = null;
  public categoria: Array<{ id: number, nombre: string }> = [];

  private observacionesObservable$ = null;
  private archivosFinalesSubscriber = null;
  public archivosFinalesConfirmation = new Observable(archivosFinalesSubscriber => {
    this.archivosFinalesSubscriber = archivosFinalesSubscriber;
  });
  public plantillasObservaciones: PlantillasObservaciones = {
    titulo: "Debés indicar un motivo por el cual no se realizó la acción en tiempo y forma",
    mostrar: false,
    plantillas: [
      "Presentado en término pero cumplido fuera de término en el sistema.",
      "Presentado fuera de término por causas ajenas al estudio.",
      "Presentado fuera de término por responsabilidad del estudio.",
      "Otros"
    ]
  };

  constructor(private auditoriaService: AuditoriaService,
    private activatedRoute: ActivatedRoute, private mailService: MailService) { }

  ngOnInit(): void {
    this.auditoriaService.findTipoCierre().subscribe((response: Page<IdDescripcionCod>) => {
      this.tiposDeCierre = response.content;
      this.tiposDeCierre = this.tiposDeCierre.filter(tipo => tipo.codigo === '01' || tipo.codigo === '02');
      this.findAll();
    });
    if (!this.idCliente)
      this.idCliente = parseInt(this.activatedRoute.snapshot.paramMap.get('id')) || null;
  }

  public findAll() {
    if (!this.idCliente)
      return;
    this.auditoriaService.findAll({
      clienteId: this.idCliente,
      tipoCierre: this.tiposDeCierre.map(o => o.codigo).join(",")
    }).subscribe((response: Page<CortesAuditoria>) => {
      this.listado = response.content;
      this.categoria = this.listado.map(corte => ({ id: corte.id, nombre: corte.tipo_cierre.replace('_', ' ') + ' - ' + this.fechaPipe.transform(corte.fecha_cierre) })).reverse();
    });
  }

  public openModalERA() {
    modalUtils.open(this.modal);
  }

  public beforeAddDocumentacion({ padreId, categoria }) {
    let corte = this.listado.find(c => c.id === categoria);
    this.observacionesObservable$ = this.auditoriaService
      .agregarDocumentoRequiereObservacion(corte, padreId)
      .subscribe(([requiereObservacion, mensaje]) => {
        this.archivosFinalesSubscriber.next([requiereObservacion, mensaje]);
      });
  }
  public agregaDocumento($event) {
    this.mailService.archivosAuditoria("addDocumento_PRESENTACION_ORGANO_DE_CONTROLACTAS",$event.categoria.id)
  }
}
