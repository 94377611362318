import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'horas'
})
export class HorasPipe implements PipeTransform {
    transform(val: number) {
        if (val > 0) {
            let horas = Math.floor(val / 60);
            let minutos = val % 60;
            let zero = "0";
            return `${ horas < 10 ? zero : "" }${ horas }:${ minutos < 10 ? zero : "" }${ minutos }`;
        } else {
            return '00:00';
        }
    }
}