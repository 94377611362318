import { ModalDirective } from 'ngx-bootstrap/modal';

export default (() => {

    return {
        open(template: ModalDirective, { backdrop = true, ignoreBackdropClick = true, keyboard = true } = {}): void {
            template.config.backdrop = backdrop;
            template.config.ignoreBackdropClick = ignoreBackdropClick;
            template.config.keyboard = keyboard;
            template.show();
        },
        hide(template: any): void {
            template.hide();
        }
    }
})();