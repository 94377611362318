import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/services/core/settings/settings.service';


@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

    public showBtn = true;
    constructor(public settings: SettingsService,) { }

    ngOnInit() {
    }

    toggleCollapsedSideabar() {
        setTimeout(() => {
            this.showBtn = !this.showBtn;
        }, 150);
        this.settings.toggleLayoutSetting('isCollapsed');
    }
}
