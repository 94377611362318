import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from "@angular/router";
import { DocumentoBase } from "../../../interfaces/documento-base";
import { DocumentacionService } from "../../../services/documentacion.service";
import { Documento } from "../../../interfaces/documento";
import { CategoriaDocumento } from "../../../interfaces/categoria-documento";
import { forkJoin } from 'rxjs';
import { ERROR_MESSAGES } from 'src/app/enums/error-messages';
const swal = require('sweetalert');

@Component({
  selector: 'app-panel-documentacion',
  templateUrl: './panel-documentacion.component.html',
  styleUrls: ['./panel-documentacion.component.scss']
})
export class PanelDocumentacionComponent implements OnInit {

  @Output() agregar = new EventEmitter<DocumentoBase>();
  @Output() eliminar = new EventEmitter<DocumentoBase>();
  @Output() sindocumentacion = new EventEmitter<DocumentoBase>();

  public nuevo: boolean = true;
  @Input() set padreId(value: number) {
    this.init(value);
  }
  @Input() set categorias(value: Array<CategoriaDocumento>) {
    this._categorias = value;
    this.init(this._padreId);
  }

  @Input() set soloLectura(value: boolean) {
    this._soloLectura = value;
  }
  @Input() set multiSelect(value: boolean) {
    this._multiSelect = value;
  }
  @Input() set categoriasVisibles(value: boolean) {
    this._categoriasVisibles = value;
  }
  @Input() set soloUnArchivoPorCategoria(value: boolean) {
    this._soloUnArchivoPorCategoria = value;
  }

  @Input() set update(value: number) {
    this.init(this._padreId);
  }

  @Input() set swalGuardar(value: boolean) {
    this._swalGuardar = value;
  }
  @Input() recurso: string;
  @Input() requireConfirmation: boolean;
  @Input() eliminable: boolean = true;
  @Input() forzarEiminable: boolean = false;

  @Input() set pdf(value: boolean) {
    this._pdf = value;
  }

  public _soloLectura: boolean;
  public _multiSelect: boolean;
  public _categoriasVisibles: boolean = true;
  public _soloUnArchivoPorCategoria: boolean = false;
  public _padreId: number;
  public _categorias: Array<CategoriaDocumento>;
  public _swalGuardar: boolean = true;
  public _pdf: boolean = false;

  public loading: boolean = false;
  public documentaciones = {};
  public categoriaSeleccionada: CategoriaDocumento;

  constructor(private documentacionService: DocumentacionService, public router: Router) { }

  ngOnInit(): void {
  }

  public init(padreId: number) {

    this.reset();

    if (!this.recurso || !padreId || !this._categorias)
      return;

    this.loading = true;
    this._padreId = padreId;
    this.categoriaSeleccionada = this._categoriasVisibles ? null : this.categoriaSeleccionada = this._categorias[0];

    forkJoin(this._categorias.map(c => this.documentacionService.listar(this.recurso, padreId, c.id)))
      .subscribe(documentaciones => {
        documentaciones.forEach(documentacion => {
          if (this.forzarEiminable)
            documentacion.documentos.forEach(d => {
              d.eliminable = true;
            });
          this.documentaciones[documentacion.categoria.id] = documentacion;
          this.removerDocumentosVacios(documentacion.categoria.id);
          this.loading = false;
        })
      });
  }

  private reset(): void {
    this._categorias.forEach(c => {
      this.documentaciones[c.id] = { categoria: c, documentos: [], selected: false };
    });
  }

  public nuevoDocumento(categoria: CategoriaDocumento) {

    if (this.existeDocumentoVacio(categoria.id))
      return;

    if (!this.documentaciones[categoria.id])
      this.documentaciones[categoria.id] = { categoria: categoria, documentos: [], selected: false };

    this.loading = false;
    this.nuevo = false;

    this.documentaciones[categoria.id].documentos.forEach((doc) => doc.seleccionado = false);
    this.documentaciones[categoria.id].documentos.push({
      nombre: '',
      formato: '',
      url_archivo: '',
      carga_outsourcing: false,
      eliminable: true,
      modificable: true,
      fecha_carga: '',
      seleccionado: true
    });
  }

  public agregarDocumento(categoria: CategoriaDocumento, documento: DocumentoBase) {
    this.loading = true;
    if (documento.formato == 'PDF' || !this._pdf) {
      this.documentacionService.agregar(this.recurso, this._padreId, categoria.id, documento)
        .subscribe((response: Documento) => {
          this.loading = false;
          this.nuevo = true;
          response.seleccionado = true;
          if (this.documentaciones[categoria.id].documentos.length == 1) {
            if (this.documentaciones[categoria.id].documentos[0].nombre == "") {
              this.documentaciones[categoria.id].documentos[0] = response;
            } else {
              this.documentaciones[categoria.id].documentos.push(response);
            }
          } else {
            this.documentaciones[categoria.id].documentos.push(response);
          }
          this.documentaciones[categoria.id].documentos = this.documentaciones[categoria.id].documentos.filter(documento => documento.nombre != "");
          this.agregar.emit(response);
          if (this._swalGuardar) {
            swal({ title: 'Guardado', text: 'La operación fue realizada exitosamente', icon: 'success', buttons: false, timer: 1000 });
          }
        }, (error) => {
          this.loading = false;
          swal({ title: '¡Cancelado!', text: error?.error?.message || ERROR_MESSAGES.SUBIR_ARCHIVO, icon: 'error', buttons: false });
        });
    } else {
      this.loading = false;
      swal('Error', 'El formato esperado es PDF.', 'error');
    }
  }

  public eliminarDocumento(categoria: CategoriaDocumento, documento: DocumentoBase) {
    if (this.documentaciones[categoria.id]) {
      swal({
        title: '¿Estás seguro?',
        text: 'El registro se eliminará de forma permanente en el sistema.',
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'No, cancelar!',
            value: null,
            visible: true,
            className: "",
            closeModal: true
          },
          confirm: {
            text: 'Si, continuar!',
            value: true,
            visible: true,
            className: "bg-danger",
            closeModal: false
          }
        }
      }).then((isConfirm) => {
        if (isConfirm) {
          /** Confirmación  **/
          this.documentacionService.eliminar(this.recurso, this._padreId, this.documentaciones[categoria.id].categoria.id, documento).subscribe((response: DocumentoBase) => {
            this.eliminar.emit(response);
            swal({ title: '¡Operación exitosa!', text: 'La operación fue realizada exitosamente.', icon: 'success', buttons: false, timer: 1000 });
            for (let i = 0; i < this.documentaciones[categoria.id].documentos.length; i++) {
              if (this.documentaciones[categoria.id].documentos[i].nombre == response?.nombre || this.documentaciones[categoria.id].documentos[i].nombre == documento?.nombre) {
                this.documentaciones[categoria.id].documentos.splice(i, 1);
                break;
              }
            }
            if (!this.existeDocumentacion(categoria.id)) {
              this.sindocumentacion.emit();
            }
          });
          /** Confirmación  **/
        } else {
          swal('¡Cancelado!', 'La acción se canceló.', 'error');
        }
      });
    }
  }

  public existeDocumentoVacio(categoriaId: string): boolean {
    return this.documentaciones[categoriaId].documentos.some(d => this.documentoVacio(d));
  }

  private documentoVacio(documento: Documento) {
    return !documento.nombre || !/\w/.test(documento.nombre);
  }

  private removerDocumentosVacios(categoriaId: string): void {
    if (this.documentaciones[categoriaId])
      this.documentaciones[categoriaId].documentos = this.documentaciones[categoriaId].documentos.filter(d => !this.documentoVacio(d));
  }

  private existeDocumentacion(categoriaId: string): boolean {
    return this.documentaciones[categoriaId].documentos.length > 0 && !this.existeDocumentoVacio(categoriaId);
  }
}
