import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { ModalDirective } from 'ngx-bootstrap/modal';

import { MswordService } from 'src/app/services/export-data/msword.service';
import { PdfService } from 'src/app/services/export-data/pdf.service';
import { TxtService } from 'src/app/services/export-data/txt.service';
import { environment } from 'src/environments/environment';
import html2canvas from 'html2canvas'
import modalUtils from "src/app/utils/modal";

@Component({
  selector: 'app-exportar-datos',
  templateUrl: './exportar-datos.component.html',
  styleUrls: ['./exportar-datos.component.scss']
})
export class ExportarDatosComponent implements OnInit {

  public modalExportarDatos: ModalDirective;
  public environment = environment.production;

  @Input() fechaDesdeHasta: any;
  @Input() dataGrafico: any;
  @Input() estados: any;
  @Input() data: any = {};
  @Input() config: any = {};
  @Input() sinCabecera: boolean;
  @Input() graficoInforme: boolean = null;
  @Input() encabezadoGrafico: { tipoPersona: string, responsable: string, obligacion: string };//*Encabezado para el grafico sin estados

  public incluyeGrafico: boolean = false;
  public showGrafico: boolean = false;
  public imagenGrafico: any = null;
  public container: any = {};

  constructor(private pdfService: PdfService, private txtService: TxtService, private mswordService: MswordService) { }

  ngOnInit(): void { }

  @ViewChild("modalExportarDatos") modal: ModalDirective;
  public openModal() {
    if (this.graficoInforme!=null) {
      this.incluyeGrafico = false;
      this.changeIncluyeGrafico(null);
    }
    modalUtils.open(this.modal);
  }

  public exportarPdf() {
    this.pdfService.generateTable(this.data, this.config, this.imagenGrafico);
  }

  public setListado(listado: any, config: any){
    this.data = listado
    this.config = config
  }

  public exportarExcel() {
    let dataURL = this.txtService.generateTable(this.data, this.config, !this.sinCabecera);
    if (this.incluyeGrafico) {
      this.descargarGrafico();
    }
    return dataURL;
  }

  public exportarWord() {
    this.mswordService.generateTable(this.data, this.config, this.imagenGrafico);
  }
  
  public changeIncluyeGrafico($event) {
    const idGrafico = this.graficoInforme ? 'grafico' : 'graficoSinEstados'
    if (this.incluyeGrafico) {
      this.showGrafico = true;
      this.container = document.getElementById(idGrafico);
      this.generateImage();
    } else {
      this.imagenGrafico = null;
      this.showGrafico = false;
    }
  }
  private generateImage() {
    let self = this;
    const idGrafico = this.graficoInforme ? 'grafico' : 'graficoSinEstados'
    if (!document.getElementById(idGrafico)?.offsetWidth) {
      window.requestAnimationFrame(this.generateImage.bind(this));
    } else {
      html2canvas(document.getElementById(idGrafico)).then(canvas => {
        let contentDataURL = canvas.toDataURL('image/png')
        self.imagenGrafico = contentDataURL;
      })
    }
  }
  private descargarGrafico() {
    var a = document.createElement("a");
    a.href = this.imagenGrafico;
    a.download = "Grafico.png";
    a.click();
  }
}
