import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BsDatepickerConfig, BsDatepickerViewMode, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Personal } from 'src/app/classes/personal';
import { ERROR_MESSAGES } from 'src/app/enums/error-messages';
import { HABILITADO } from 'src/app/enums/habilitado';
import { Cliente, ClienteBase } from 'src/app/interfaces/cliente';
import { ClienteNotificaciones } from 'src/app/interfaces/cliente-notificaciones';
import { IdDescripcionCod } from 'src/app/interfaces/id-descripcion-cod';
import { IdNombreCodigo } from 'src/app/interfaces/id-nombre-codigo';
import { AuthService } from 'src/app/services/auth.service';
import { ClienteBaseService } from 'src/app/services/clientes/cliente-base.service';
import { ClientesNotificacionesService } from 'src/app/services/clientes/clientes-notificaciones.service';
import { ResponsablesService } from 'src/app/services/clientes/responsables.service';
import { PersonalService } from 'src/app/services/personal.service';
import { TareasService } from 'src/app/services/tareas.service';
import { TiposSociedadesService } from 'src/app/services/tipos-sociedades.service';
const swal = require("sweetalert");
import modalUtils from "src/app/utils/modal";
import * as moment from 'moment';
import { Authorities } from 'src/app/enums/authorities';
import { GrupoEconomico } from 'src/app/interfaces/grupo-economico';
import { Page } from 'src/app/interfaces/page';
import { GrupoEconomicoService } from 'src/app/services/grupo-economico.service';
import { ModalLoadingComponent } from '../modal-loading/modal-loading.component';

@Component({
  selector: 'app-asignacion-notificacion-vencimientos',
  templateUrl: './asignacion-notificacion-vencimientos.component.html',
  styleUrls: ['./asignacion-notificacion-vencimientos.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AsignacionNotificacionVencimientosComponent implements OnInit {

  @ViewChild("asignacionNotifVencimientos") modal: ModalDirective;
  @ViewChild("modalLoading") modalLoading: ModalLoadingComponent;
  public clientesNotificaciones: Array<ClienteNotificaciones> = [];
  public clientesSeleccionadosMensuales: Array<ClienteNotificaciones> = [];
  public clientesSeleccionadosAnuales: Array<ClienteNotificaciones> = [];
  public loadingM = false;

  public checkAllMensual: boolean = false;
  public checkAllAnual: boolean = false;

  public personalLogueado: Personal | IdNombreCodigo;

  //listados
  public listadoAreas: Array<{ id: string, text: string }> = [];
  public listadoTipoPersona: Array<{ id: string, text: string }> = [{ id: "f", text: "FÍSICO" }, { id: "j", text: "JURÍDICO" }];
  public listadoTipoSociedad: Array<{ id: string, text: string }> = [];
  public listadoPersonal: Array<{ id: number, text: string }> = [];
  public listadoGrupoEconomico: Array<{ id: number, text: string }> = [];
  public gruposEconomicos: Array<GrupoEconomico> = [];
  public listadoClientes: Array<{ id: number, text: string }> = [];
  public listadoClientesOriginal: Array<{ id: number, text: string }> = [];
  public personalAreas: Array<IdDescripcionCod> = [];
  public clientes: Array<ClienteBase> = [];

  public bsConfig: Partial<BsDatepickerConfig>;
  public minMode: BsDatepickerViewMode = "month";
  public ordenAsc: boolean = true;
  public loadingFiltros: boolean = true;
  public loading: boolean = true;
  public authNivel4: boolean = false;
  public authLaboralImpuestos: boolean = false;

  public formFiltros: FormGroup;

  constructor(
    private localService: BsLocaleService,
    private responsablesService: ResponsablesService,
    private personalService: PersonalService,
    private tiposSociedadesService: TiposSociedadesService,
    private tareasService: TareasService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private clienteBaseService: ClienteBaseService,
    private clienteNotificacionesService: ClientesNotificacionesService,
    private grupoEconomicoService: GrupoEconomicoService
  ) {
    this.localService.use("es");
    this.bsConfig = Object.assign({},
      {
        containerClass: "theme-angle",
        dateInputFormat: 'MMMM YYYY',
        minMode: this.minMode
      });
    this.personalLogueado = this.authService.getUserLogged();
    this.formFiltros = this.formBuilder.group({
      tipoCliente: [null],
      grupoEconomico: [null],
      area: [{ value: null, disabled: true }],
      supervisor: [null],
      responsable: [null],
      asistente: [null],
      auxiliar: [null],
      tipoSociedad: [null],
      mesCierre: [null],
      nombre: [null], //nombre cliente
    });
  }

  ngOnInit(): void {
    this.setAuthorization();
  }
  public setAuthorization() {
    this.authNivel4 = this.authService.isAuthorized([
      Authorities.gerente,
      Authorities.auditoria_supervisor,
      Authorities.laboral_supervisor,
      Authorities.impuestos_supervisor,
      Authorities.outsourcing_supervisor,
      Authorities.societario_supervisor,
      Authorities.administracion_supervisor,
      Authorities.recursos_humanos_supervisor,
    ]);
    this.authLaboralImpuestos = this.authService.isAuthorized([
      Authorities.gerente,
      Authorities.auditoria_supervisor,
      Authorities.laboral_supervisor,
      Authorities.impuestos_supervisor,
      Authorities.outsourcing_supervisor,
      Authorities.societario_supervisor,
      Authorities.administracion_supervisor,
      Authorities.recursos_humanos_supervisor,
      Authorities.laboral_supervisor,
      Authorities.impuestos_supervisor,
      Authorities.laboral_responsable,
      Authorities.impuestos_responsable,
      Authorities.laboral_asistente,
      Authorities.impuestos_asistente,
      Authorities.laboral_auxiliar,
      Authorities.impuestos_auxiliar,
    ]);
  }

  public openModal() {
    this.findFiltros();
    modalUtils.open(this.modal);
  }
  public findFiltros() {
    this.loadingFiltros = true;
    forkJoin([
      this.responsablesService.findAreas(),
      this.personalService.findPersonalArea({ personalId: this.personalLogueado.id }),
      this.tiposSociedadesService.findTiposSociedades(),
      this.tareasService.findGrupoEconomicos(),
      this.clienteBaseService.findAll({ habilitado: HABILITADO.SI }),
      this.personalService.findPersonal(),
    ]).pipe(finalize(() => {
      this.loadingFiltros = false;
    })).subscribe(([areas, personalAreas, tiposSociedad, gruposEconomicos, clientes, personal]) => {
      this.personalAreas = personalAreas;
      this.listadoTipoSociedad = tiposSociedad.content.map(p => ({ id: p.codigo, text: p.descripcion }));
      this.gruposEconomicos = gruposEconomicos.content;
      this.listadoGrupoEconomico = gruposEconomicos.content.map(g => ({ id: g.id, text: g.nombre }));
      this.listadoPersonal = personal.content.map(g => ({ id: g.id, text: g.nombre }));
      this.listadoClientes = this.listadoClientesOriginal = clientes.content.map(g => ({ id: g.id, text: g.nombre }));
      this.clientes = clientes.content;

      if (this.authNivel4)
        this.listadoAreas = areas.content.map(p => ({ text: p.descripcion, id: p.codigo }));
      else
        this.listadoAreas = this.personalAreas.map(pa => ({ text: pa.descripcion, id: pa.codigo }));

      if (this.listadoAreas.length > 1)
        this.formFiltros.controls.area.enable();
      this.formFiltros.controls.area.patchValue(personalAreas[0].codigo);
      this.submitForm(null, null);
    }, error => {
      swal("¡Lo sentimos!", error?.error?.message || ERROR_MESSAGES.DEFAULT, "error");
    });
  }

  public submitForm($event, value) {
    this.loading = true;
    this.clientesNotificaciones = [];
    this.clienteNotificacionesService.findClientesNotificaciones({
      area: this.formFiltros.value.area,
      grupoEconomico: this.formFiltros.value.grupoEconomico,
      asistente: this.formFiltros.value.asistente,
      auxiliar: this.formFiltros.value.auxiliar,
      nombre: this.findCliente(this.formFiltros.value.nombre),
      responsable: this.formFiltros.value.responsable,
      supervisor: this.formFiltros.value.supervisor,
      tipoCliente: this.formFiltros.value.tipoCliente,
      tipoSociedad: this.formFiltros.value.tipoSociedad,
      orderCriteria: this.ordenAsc ? "ASC" : "DESC"
    }).subscribe(clientes => {
      this.clientesNotificaciones = clientes.content;
      this.clientesNotificaciones.forEach(c => {
        c.cliente = this.clientes.find(cl => cl.id === c.id);
      });
      this.loading = false;
    });
  }
  public findCliente(id: number): string {
    return this.listadoClientesOriginal.find(c => c.id === id)?.text || null;
  }
  public seleccionarCliente(cliente: ClienteNotificaciones, clientesSeleccionados: Array<ClienteNotificaciones>) {
    let ixV = clientesSeleccionados.findIndex(c => c.id === cliente.id);
    if (ixV === -1) {
      clientesSeleccionados.push(cliente);
    } else {
      clientesSeleccionados.splice(ixV, 1);
    }
  }
  public seleccionarTodos(opcion: string) {
    switch (opcion) {
      case "anual": {
        this.checkAllAnual = !this.checkAllAnual;
        if (this.checkAllAnual) {
          this.clientesSeleccionadosAnuales = JSON.parse(JSON.stringify(this.clientesNotificaciones));
        } else {
          this.clientesSeleccionadosAnuales = [];
        }
        break;
      }
      case "mensual": {
        this.checkAllMensual = !this.checkAllMensual;
        if (this.checkAllMensual) {
          this.clientesSeleccionadosMensuales = JSON.parse(JSON.stringify(this.clientesNotificaciones));
        } else {
          this.clientesSeleccionadosMensuales = [];
        }
        break;
      }
    }
  }
  public enviar($event) {
    $event.preventDefault();
    let requests = [];
    if (this.clientesSeleccionadosMensuales.length) {
      if (!this.formFiltros.value.mesCierre) {
        swal("Error", "Ingrese un mes para notificar", "error");
        return;
      }
      let cociente = Math.floor(this.clientesSeleccionadosMensuales.length / 100);
      let cantidadPartes = cociente < this.clientesSeleccionadosMensuales.length / 100 ? cociente + 1 : cociente;
      for (let i = 0; i < cantidadPartes; i++) {
        requests.push(this.clienteNotificacionesService.enviarNotificacionesMensuales(
          moment(this.formFiltros.value.mesCierre).format('YYYY-MM'),
          this.clientesSeleccionadosMensuales.slice(i*100, i*100+100).map(c => c.id))
        );
      }
    }
    if (this.clientesSeleccionadosAnuales.length) {
      let cociente = Math.floor(this.clientesSeleccionadosAnuales.length / 100);
      let cantidadPartes = cociente < this.clientesSeleccionadosAnuales.length / 100 ? cociente + 1 : cociente;
      for (let i = 0; i < cantidadPartes; i++) {
        requests.push(this.clienteNotificacionesService.enviarNotificacionesAnuales(
          this.clientesSeleccionadosAnuales.slice(i*100, i*100+100).map(c => c.id))
        ); 
      }
    }
    this.modalLoading.open();
    forkJoin(requests)
      .pipe(
        finalize(() => {
          this.modalLoading.close();
        }))
      .subscribe(response => {
        swal({
          title: "Guardado",
          text: "Los notificaciones se enviaron exitosamente.",
          icon: "success",
          buttons: false,
          timer: 1600
        });
      },
        error => {
          swal("Lo sentimos!", error?.message || ERROR_MESSAGES.GUARDADO, "error");
        });
  }
  public guardar($event) {
    $event.preventDefault();
    this.clienteNotificacionesService.asignacionNotificaciones(this.clientesNotificaciones).subscribe(clientes => {
      swal({
        title: "Guardado",
        text: "Los registros se guardaron exitosamente.",
        icon: "success",
        buttons: false,
        timer: 1600
      });
    },
      error => {
        swal("Lo sentimos!", error?.message || ERROR_MESSAGES.GUARDADO, "error");
      });
  }
  public checkAll($event, campo: string) {
    this.clientesNotificaciones.forEach(c => {
      c[campo] = $event.currentTarget.checked;
    });
  }
  public filtrarXGrupoEconomico() {
    if (this.formFiltros.value.grupoEconomico) {
      let codigo = this.gruposEconomicos.find(g => g.id == this.formFiltros.value.grupoEconomico).codigo;
      this.grupoEconomicoService.findClientes(codigo, HABILITADO.SI)
        .subscribe((response: Page<ClienteBase>) => {
          this.listadoClientes = response.content.map(g => ({ id: g.id, text: g.nombre }));
        })
    }
    else
      this.listadoClientes = this.listadoClientesOriginal;
  }

}
