export default (() => {

    const regexEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

    const regexURIBase64 = /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{4})$/;

    const regexURL = /^(https?|ftp|file):\/[-a-zA-ZÀ-ÿ0-9+&@#/%?=~_|!:,.;]*[-a-zA-Z0-9+&@#/%=~_|]/;

    return {
        emailIsValid(email: string): boolean {
            return regexEmail.test(email);
        },
        isURL(url: string): boolean {
            return url && regexURL.test(url);
        },
        isDataURI(uri: string): boolean {
            return uri && regexURIBase64.test(uri);
        }
    }
})();