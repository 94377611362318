import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observacion } from "../interfaces/observacion";
import { Observable } from 'rxjs';
import { MailObservaciones } from '../interfaces/mail-observaciones';

@Injectable({
  providedIn: 'root'
})
export class ObservacionesService {

  constructor(private http: HttpClient) { }

  public listar(recurso: string, padreId: number) {
    let endpoint = '/' + recurso + '/' + padreId + '/observaciones';
    return this.http.get(environment.apiEndpoint + endpoint);
  }

  public agregar(recurso: string, padreId: number, observacion: Observacion, sendEmail?: boolean): Observable<Observacion> {
    let endpoint = '/' + recurso + '/' + padreId + '/observaciones' + (sendEmail? '?sendEmail=true' : '');
    return this.http.post<Observacion>(environment.apiEndpoint + endpoint, observacion);
  }
  public createObservacionMail(recurso: string, padreId: number, observacion: MailObservaciones): Observable<Observacion> {
    let endpoint = '/' + recurso + '/' + padreId + '/observaciones';
    return this.http.post<Observacion>(environment.apiEndpoint + endpoint, observacion);
  }
  public findDestinatariosTareas(recurso: string, padreId: number): Observable<Array<string>> {
    let endpoint = '/' + recurso + '/' + padreId + '/observacionesDestinatarios';
    return this.http.get<Array<string>>(environment.apiEndpoint + endpoint);
  }
}
