import { Pipe, PipeTransform } from '@angular/core';
import { Documento } from '../interfaces/documento';
import { DocumentoBase } from '../interfaces/documento-base';

@Pipe({
  name: 'documentoBase'
})
export class DocumentoBasePipe implements PipeTransform {

  transform(documento: Documento): DocumentoBase {
    return {
      nombre: documento.nombre,
      formato: documento.formato,
      url_archivo: documento.url_archivo
    };
  }

}
