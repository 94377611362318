import { ReadKeyExpr } from '@angular/compiler';
import { resolve } from 'dns';
import { reject } from 'lodash';
import * as moment from 'moment';
import { number } from 'ngx-custom-validators/src/app/number/validator';
import { type } from 'os';

export abstract class ExportService {

  constructor() { }

  public abstract generateTable<T>(data: Array<T>, config: any);

  protected convertJSONToTable<T>(
    data: Array<T>,
    {
      aliasColumns = {},
      dateColumns = {},
      showColumns = []
    }, header = true
  ): any[][] {

    if (!data.length)
      return [];

    let keys = Object
      .keys(data[0])
      .filter(k => !(data[0][k] instanceof Object) && typeof data[0][k] !== "function")
      .filter(k => showColumns.includes(k));

    let list = data
      .map(d => keys.map(k => {
        if (typeof d[k] === "boolean")
          return this.mapBooleanValues(d[k]);
        if (typeof d[k] === "number")
          return this.mapNumberValues(d[k]);
        if (k in dateColumns)
          return this.mapDateValues(d[k], dateColumns[k])
        if (typeof d[k] === "string")
          return this.mapStringValues(d[k])
        else
          return this.mapNullOrUndefinedValues(d[k]);
      }))

    return header ? [this.mapAliasColumns(aliasColumns, keys)].concat(list) : list
    /* return [
      this.mapAliasColumns(aliasColumns, keys)
    ].concat(
      data
        .map(d => keys.map(k => {
          if (typeof d[k] === "boolean")
            return this.mapBooleanValues(d[k]);
          if (typeof d[k] === "number")
            return this.mapNumberValues(d[k]);
          if (k in dateColumns)
            return this.mapDateValues(d[k], dateColumns[k])
          if (typeof d[k] === "string")
            return this.mapStringValues(d[k])
          else
            return this.mapNullOrUndefinedValues(d[k]);
        }))
    ); */
  }

  protected mapAliasColumns(aliasColumns: any, headerRow: string[]): string[] {
    let nameColumns = Object.keys(aliasColumns);
    return headerRow.map(c => nameColumns.includes(c) ? aliasColumns[c] : c);
  }

  protected mapBooleanValues(value: boolean): string {
    return value ? "Si" : "No";
  }

  protected mapDateValues(value: string, format: string): string {
    try {
      return moment(value).format(format);
    } catch (error) {
      return value;
    }
  }

  protected mapNullOrUndefinedValues(value: any) {
    return value === null || value === undefined ? "" : value;
  }

  protected mapNumberValues(value: number) {
    return isNaN(value) ? 0 : value;
  }

  protected mapStringValues(value: string): string {
    return value.replace(/\s+/g, " ");
  }

  protected saveFile(blob: Blob): Promise<string> {
    let link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.target = "_blank";
    document.body.appendChild(link);

    let reader = new FileReader();

    return new Promise((resolve, reject) => {

      reader.onload = () => {
        link.click();
        document.body.removeChild(link);
        resolve(reader.result as string);
      };

      reader.onerror = (e) => {
        document.body.removeChild(link);
        reject(e);
      }

      reader.readAsDataURL(blob);
    });
  }
}
