import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';

@Component({
  selector: 'app-card-modulos',
  templateUrl: './card-modulos.component.html',
  styleUrls: ['./card-modulos.component.scss']
})
export class CardModulosComponent implements OnInit {

  @Input() title: string;
  @Input() id: number;
  @Input() idAccesoDirecto: number;
  @Input() enlace: any;
  @Output() deleteItemEvent = new EventEmitter<number>();
  constructor(private router: Router) { }

  ngOnInit(): void { }

  public eliminar() {
    this.deleteItemEvent.emit(this.id);
  }

  public goTo() {
    this.router.navigate([this.enlace]);
  }

  public getModuloClass(): string {
    switch (this.enlace) {
      case "/vencimientos": return 'btn-outline-impuestos';
      case "/laboral": return 'btn-outline-laboral';
      case "/auditoria": return 'btn-outline-auditoria';
      case "/outsourcing": return 'btn-outline-outsourcing';
      case "/recuperos": return 'btn-outline-impuestos';
      case "/tareas": return 'btn-outline-tga';
      case "/personal": return 'btn-outline-rrhh';
      case "/modulo-presupuesto/tablero-monotributo": return 'btn-outline-facturacion';
      case "/modulo-presupuesto/tablero-presupuesto": return 'btn-outline-facturacion';
      case "/modulo-presupuesto/tablero-rendiciones": return 'btn-outline-facturacion';
      case "/modulo-legal-societario/tablero-libros": return 'btn-outline-legal-societario';
      case "/modulo-impuestos/tablero-impuestos-anuales": return 'btn-outline-impuestos';
      case "/funcionalidades-generales/tablero-claves": return 'btn-outline-tga';
      case "/modulo-cronogramas/tablero-cronogramas": return 'btn-outline-cronogramas';
      case "/modulo-cronogramas/tablero-cronograma-grupo-economico": return 'btn-outline-cronogramas';
      case "/modulo-cronogramas/tablero-visitas-referentes": return 'btn-outline-cronogramas';
      case "/modulo-fondos/tablero-fondos": return 'btn-outline-fondos';
      case "/modulo-impuestos/tablero-inspecciones": return 'btn-outline-impuestos';
      case "/modulo-impuestos/tablero-impuestos-anuales": return 'btn-outline-impuestos';
      case "/modulo-legal-societario/tablero-legal-societario": return 'btn-outline-legal-societario';
      case "/modulo-legal-societario/tablero-libros": return 'btn-outline-legal-societario';
      case "/modulo-legal-societario/tablero-dpj": return 'btn-outline-legal-societario';
      case "/modulo-comite/tablero-comite": return 'btn-outline-tga';
      case "/funcionalidades-generales/tablero-internos": return 'btn-outline-tga';
      default: return '';
    }
  }
}
