import { Personal as IPersonal } from "../interfaces/personal";

export class Personal implements IPersonal {

    id: number;
    codigo: string;
    nombre: string;
    email: string;

    constructor(personal?: IPersonal) {
        this.id = personal?.id || null;
        this.codigo = personal?.codigo || null;
        this.nombre = personal?.nombre || null;
        this.email = personal?.email || null;
    }
}