import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IdNombre } from '../interfaces/id-nombre';
import { Page } from '../interfaces/page';

@Injectable({
  providedIn: 'root'
})
export class PaisesService {

  constructor(private http: HttpClient) { }
  public findPaises(): Observable<Page<IdNombre>> {
    let endpoint = '/paises';
    return this.http.get<Page<IdNombre>>(environment.apiEndpoint + endpoint);
  }

}
