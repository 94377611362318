import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import modalUtils from 'src/app/utils/modal';
import { VencimientosService } from 'src/app/services/vencimientos.service';
import { LegalSocietario } from 'src/app/interfaces/Legal-societario';
import { Page } from 'src/app/interfaces/page';
import { CantidadDocumentos, Vencimiento } from 'src/app/interfaces/vencimiento';
import * as moment from 'moment';
import { Laboral } from 'src/app/interfaces/laboral';
import { IdNombre } from 'src/app/interfaces/id-nombre';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PlantillasObservaciones } from 'src/app/interfaces/plantillas-observaciones';
import { MailService } from 'src/app/services/mail.service';
import { Observacion } from 'src/app/interfaces/observacion';
import { ObservacionesService } from 'src/app/services/observaciones.service';
import { forkJoin } from 'rxjs';
const swal = require("sweetalert");
@Component({
  selector: 'app-modal-acciones-participaciones-legal-societario',
  templateUrl: './modal-acciones-participaciones-legal-societario.component.html',
  styleUrls: ['./modal-acciones-participaciones-legal-societario.component.scss']
})
export class ModalAccionesParticipacionesLegalSocietarioComponent implements OnInit {

  @ViewChild('modalAccionesyParticipaciones') modalAccionesyParticipaciones: ModalDirective;
  @ViewChild("modalObservacionesCumplir") modalObservacionesCumplir: ModalDirective;

  @Input() societario: LegalSocietario;

  public obligacion: any;
  public categorias: Array<Vencimiento> = [];
  public categoriaSeleccionada: Vencimiento;
  public orderField: string = "fecha_vencimiento";
  public orderCriteria: string = "DESC";
  public updateDocumentacion: number = 0;
  public mensajeCumplido: string;
  public usuario: IdNombre;
  public responseLaboral: Laboral;
  public itemSeleccionado: string = '';
  public updateObservaciones: number = 0;
  public formObservaciones: FormGroup;
  public plantillaObservaciones: PlantillasObservaciones = {
    titulo: "Observaciones",
    plantillas: ['Presentado en término pero cumplido fuera de término en el sistema.', 'Presentado fuera término por causas ajenas al estudio.', 'Otros'],
    mostrar: true
  };
  public cantDocumentos = []

  constructor(private vencimientosService: VencimientosService,
    private formBuilder: FormBuilder,
    private mailService: MailService,
    private observacionesService: ObservacionesService) {
    this.formObservaciones = this.formBuilder.group({
      mensaje: [null, Validators.required]
    });
  }

  ngOnInit(): void {
    this.usuario = JSON.parse(localStorage.getItem("sige_personal"));
  }

  public findAllObligaciones() {
    return new Promise((resolve) =>
      this.vencimientosService.findAll(null, null, null, null, null, null, this.societario?.cliente?.id, null, 133,
        null, null, null, null, null, null, this.orderField, this.orderCriteria, null, null, true).subscribe((response: Page<Vencimiento>) => {
          this.categorias = response.content;
          this.categoriaSeleccionada = this.categorias[0];
          resolve(true)
        })
    )
  }
  public formatDate(date: string, format: string) {
    if (date && moment(date, format).isValid())
      return moment(date, format).format('YYYY');
    else return null;
  }

  public openModal() {
    this.mensajeCumplido = "";
    forkJoin([
      this.findAllObligaciones(),
      this.findCantidadDocumentos()
    ]).subscribe(() => modalUtils.open(this.modalAccionesyParticipaciones))
  }
  public findCantidadDocumentos() {
    return new Promise((resolve) =>
      this.vencimientosService.findCantidadDocumentos(null, null, null, null, null, null, this.societario?.cliente?.id, null, 133,
        null, null, null, null, null, null, this.orderField, this.orderCriteria, null, null, true).subscribe((response: Array<CantidadDocumentos>) => {
          this.cantDocumentos = response
          resolve(true)
        })
    )
  }

  public cumplir() {
    swal({
      title: "¿Estas seguro de dar a cumplir este vencimiento?",
      text: "Una vez aceptado no se podrá modificar.",
      icon: "warning",
      buttons: {
        cancel: { text: "No", value: null, visible: true, className: "", closeModal: true },
        confirm: { text: "Aceptar", value: true, visible: true, className: "success", closeModal: false }
      }
    }).then((isConfirm) => {
      if (isConfirm) {
        if (this.mensajeCumplido == null) this.mensajeCumplido = " ";
        this.vencimientosService.cumplirSocietario(this.categoriaSeleccionada?.id, this.usuario, this.mensajeCumplido)
          .subscribe(
            (response: Laboral) => {
              let fecha = new Date();
              this.responseLaboral = response;

              const añoActual = fecha.getFullYear();
              let añoVencimiento = parseInt(this.unFormatDate(this.categoriaSeleccionada.fecha_vencimiento).substring(0, 4)) + 1;

              if ((añoVencimiento) < añoActual) {
                this.itemSeleccionado = ''
                modalUtils.open(this.modalObservacionesCumplir, { backdrop: false, ignoreBackdropClick: true });
              }
              else
                this.cumplirVencimiento();
            },
            response => {
              swal("Lo sentimos", response.error.message, "error");
            }
          );
      }
    });
  }
  public unFormatDate(date: string) {
    if (/[^/=*]/.test(date))
      return date.split('/').reverse().join('-');
    else return date;
  }

  public cumplirVencimiento() {
    this.updateObservaciones++;
    swal({
      title: "Guardado",
      text: "Su obligacion se completó exitosamente.",
      icon: "success",
      buttons: false,
      timer: 800
    });

    this.categoriaSeleccionada.cumplido = this.responseLaboral.cumplido;
    this.categoriaSeleccionada.estado = this.responseLaboral.estado;
    this.mensajeCumplido = "";
    this.mailService.cumplirVencimiento(this.categoriaSeleccionada.id);
    this.findAllObligaciones();
  }
  public onSelectPlantillaObservaciones($event): void {
    this.itemSeleccionado = $event.currentTarget.value;
    this.formObservaciones.controls.mensaje.setValue('Impuesto cumplido fuera de término por el motivo: ' + this.itemSeleccionado);
  }
  public agregarObservacion() {
    let recurso = 'vencimientos';
    let observacion = { mensaje: this.formObservaciones.controls.mensaje.value, id: 0, fechahora: "", usuario: JSON.parse(localStorage.getItem("sige_personal")) };
    this.observacionesService.agregar(recurso, this.categoriaSeleccionada.id, observacion, false)
      .subscribe((response: Observacion) => {
        this.formObservaciones.reset();
        this.itemSeleccionado = null;
        swal({
          title: "Guardado",
          text: "La observación se guardó exitosamanete.",
          icon: "success",
          buttons: false,
          timer: 1000
        });
        this.cumplirVencimiento();
      });

    this.modalObservacionesCumplir.hide();
  }
  public cancelarCumplir(vencimientoSeleccionado: Vencimiento) {
    swal({
      title: "¿Estás seguro?",
      text: "Estás por eliminar el cumplimiento de esta obligación.",
      icon: "warning",
      buttons: {
        cancel: { text: "No", value: null, visible: true, className: "", closeModal: true },
        confirm: { text: "Eliminar", value: true, visible: true, className: "bg-danger", closeModal: false }
      }
    }).then((isConfirm) => {
      if (isConfirm) {
        this.vencimientosService.cancelarCumplir(vencimientoSeleccionado)
          .subscribe(
            (response: Vencimiento) => {
              this.updateObservaciones++;
              swal({
                title: "Guardado",
                text: "Se eliminó el cumplimiento exitosamente.",
                icon: "success",
                buttons: false,
                timer: 800
              });
              this.findAllObligaciones();
            },
            error => swal("Lo sentimos!", error.error.message, "error")
          );
      }
    });
  }

}
