export enum AREA {
    IMPUESTOS = "01",
    AUDITORIA = "02",
    LABORAL = "03",
    OUTSOURCING = "04",
    SOCIETARIO = "07"
}

export enum RECURSO {
    VENCIMIENTOS = "vencimientos",
    LABORAL = "laboral"
}