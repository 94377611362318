import { Component, OnInit, Input } from '@angular/core';
import { CortesAuditoria } from 'src/app/interfaces/cortes-auditoria';

@Component({
  selector: 'app-card-auditoria',
  templateUrl: './card-auditoria.component.html',
  styleUrls: ['./card-auditoria.component.scss']
})
export class CardAuditoriaComponent implements OnInit {

  @Input() corte: CortesAuditoria;

  constructor() { }

  ngOnInit(): void {
  }

}
