import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Component, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { fromEvent, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { Cliente } from 'src/app/interfaces/cliente';
import { IMenuContextual } from 'src/app/interfaces/menu-contextual';
import { DatosGeneralesService } from 'src/app/services/clientes/datos-generales.service';
import { ClienteFisico as DatosGenerales } from 'src/app/interfaces/cliente';
@Component({
  selector: 'app-menu-contextual',
  templateUrl: './menu-contextual.component.html',
  styleUrls: ['./menu-contextual.component.scss']
})
export class MenuContextualComponent implements OnInit {

  @ViewChild('userMenu') userMenu: TemplateRef<any>;
  overlayRef: OverlayRef | null;
  sub: Subscription;
  public cliente: Cliente;
  public menuLabels: IMenuContextual = {
    labels: [
      {
        label: 'Clientes',
        onClick: () => { this.navigateToClientes(); }
      },
      {
        label: 'Datos Impositivos',
        onClick: () => { this.navigateToClientes(4, 1); }
      },
      {
        label: 'Detalle Datos Impositivos',
        onClick: () => { this.navigateToClientes(4, 2); }
      },
      // {
      //   label: 'Consulta, Envío de Declaraciones Juradas', //reenvio documentacion
      //   onClick: () => { }
      // },
      {
        label: 'Datos Contables',
        onClick: () => { this.navigateToClientes(3, 1); }
      },
      {
        label: 'Datos Societarios',
        onClick: () => { this.navigateToClientes(5, 1); }
      },
      {
        label: 'Socios',
        onClick: () => { this.navigateToClientes(5, 2); }
      },
      {
        label: 'Acciones',
        onClick: () => { this.navigateToClientes(5, 3); }
      },
      {
        label: 'Órgano Administrativo',
        onClick: () => { this.navigateToClientes(5, 4); }
      },
      {
        label: 'Órgano de Fiscalización',
        onClick: () => { this.navigateToClientes(5, 5); }
      },
      {
        label: 'Tablero de Auditoría',
        onClick: () => { this.navigateToTableroAndFilter("clienteId", "filtros", "/auditoria"); }
      },
      {
        label: 'Tablero de Outsourcing',
        onClick: () => { this.navigateToTableroAndFilter("clienteId", "filtrosOutsourcing", "/outsourcing"); }
      },
      {
        label: 'Tablero de Impuestos',
        onClick: () => { this.navigateToTableroAndFilter("clienteId", "filtrosImpuestos", "/vencimientos"); }
      },
      {
        label: 'Tablero de Monotributo',
        onClick: () => { window.open('/#/modulo-presupuesto/tablero-monotributo', '_blank'); }
      },
      {
        label: 'Tablero de Presupuesto',
        onClick: () => { this.navigateToTableroAndFilter("clienteId", "filtrosPresupuesto", "/modulo-presupuesto/tablero-presupuesto"); }
      },
      {
        label: 'Tablero de Recuperos',
        onClick: () => { this.navigateToTableroAndFilter("clienteId", "filtrosRecupero", "/recuperos"); }
      },
      {
        label: 'Tablero de Laboral',
        onClick: () => { this.navigateToTableroAndFilter("clienteId", "filtrosLaboral", "/laboral"); }
      },
      // {
      //   label: 'Envío de Información',//se abre la acción reenvío de documentación
      //   onClick: () => { this.navigateToClientes(); }
      // },
      {
        label: 'Domicilios',
        onClick: () => { this.navigateToClientes(7); }
      },
      {
        label: 'Responsables',
        onClick: () => { this.navigateToClientes(2, 1); }
      },
      {
        label: 'ABM. Personal',
        onClick: () => { window.open('/#/personal/legajo/0/S', '_blank'); }
      },
      {
        label: 'ABM. Monotributistas',
        onClick: () => { window.open('/#/abmMonotributistas', '_blank'); }
      },
      {
        label: 'Proyección Horas Auditoría',
        onClick: () => { this.navigateToTableroAndFilter("clienteId", "filtrosPHA", "/modulo-outsourcing/proyeccion-horas-auditoria"); }
      },
      {
        label: 'Proyección Horas Outsourcing',
        onClick: () => { this.navigateToTableroAndFilter("clienteId", "filtrosPHO", "/modulo-outsourcing/proyeccion-horas-outsourcing"); }
      },
    ]
  }

  public navigateToTableroAndFilter(fieldName: string, filtrosName: string, tablero: string) {
    let auxParams = {};
    auxParams[fieldName] = this.cliente?.id;
    let params = Object.assign(JSON.parse(localStorage.getItem(filtrosName) || null) || {}, auxParams);
    localStorage.setItem(filtrosName, JSON.stringify(params));
    window.open('/#' + tablero, '_blank');
  }

  constructor(public overlay: Overlay, public viewContainerRef: ViewContainerRef, private router: Router, private datosGeneralesService: DatosGeneralesService) {
  }

  ngOnInit(): void {
  }

  navigateToClientes(pestania?: number, subpestania?: number) {
    let queryParams: any = {};
    if (pestania) queryParams.pestania = pestania;
    if (subpestania) queryParams.subpestania = subpestania;
    let url = '/#' + this.router.serializeUrl(this.router.createUrlTree(['/listarClientes/abm-clientes', this.cliente?.id, this.cliente?.tipoCliente], { queryParams: queryParams }));
    if (!this.cliente?.tipoCliente) {
      this.datosGeneralesService.findDatosGenerales(this.cliente?.id).subscribe((response: DatosGenerales) => {
        this.cliente.tipoCliente = response.tipoCliente;
        url = '/#' + this.router.serializeUrl(this.router.createUrlTree(['/listarClientes/abm-clientes', this.cliente?.id, this.cliente?.tipoCliente], { queryParams: queryParams }));
        window.open(url, '_blank');
      });
    } else window.open(url, '_blank');
  }


  open({ x, y }: MouseEvent, cliente: Cliente) {
    event.preventDefault();
    this.close();
    this.cliente = cliente;
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([{
        originX: 'end',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'bottom',
      },
      {
        originX: 'start',
        originY: 'top',
        overlayX: 'end',
        overlayY: 'bottom',
      },
      {
        originX: 'end',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'bottom',
      },
      {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'end',
        overlayY: 'bottom',
      },
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayRef.attach(new TemplatePortal(this.userMenu, this.viewContainerRef, {
      $implicit: cliente
    }));

    this.sub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.close())

  }

  close() {
    this.sub && this.sub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }
}
