import { environment } from "src/environments/environment";

let dinamicMenu = [];

const Home = {
    text: "Home",
    link: "/home",
    icon: "icon-home"
};
// NIVEL 2

const Tableros = {
    text: "Tableros",
    link: "",
    icon: "icon-grid",
    submenu: []
};

const Modulos = {
    text: "Módulos",
    link: "",
    icon: "icon-grid",
    submenu: []
};

/*const Aplicaciones = {
    text: "Aplicaciones",
    link: "",
    icon: "icon-grid",
    submenu: []
};*/

const Tga = {
    text: "TGA",
    link: "",
    icon: "icon-grid",
    submenu: []
};

// const Clientes = {
//     text: "Clientes",
//     link: "",
//     icon: "icon-grid",
//     submenu: []
// };

// NIVEL 3

// // Clientes

// if (!environment.production) {
//     Clientes.submenu.push({
//         text: "Listado de clientes",
//         link: "/listarClientes"
//     });
// }

// if (!environment.production) {
//     Clientes.submenu.push({
//         text: "Nuevo cliente jurídico",
//         link: "/listarClientes/abm-clientes/0/j"
//     });
// }

// if (!environment.production) {
//     Clientes.submenu.push({
//         text: "Nuevo cliente físico",
//         link: "/listarClientes/abm-clientes/0/f"
//     });
// }

// Modulos
const Impuestos = {
    text: "Impuestos",
    link: "/modulo-impuestos",
    //submenu: []
};
const Laboral = {
    text: "Laboral",
    link: "/modulo-laboral",
    //submenu: []
};
const Outsourcing = {
    text: "Auditoría - Out",
    link: "/modulo-outsourcing",
    //submenu: []
};

// const GrupoEconomico = {
//     text: "Grupos económicos",
//     link: "/grupoEconomico"
// };

const Clientes = {
    text: "Clientes",
    link: "/modulo-clientes",
    //submenu: []
};

// MODULOS/Clientes

/* if (true) {
    Clientes.submenu.push({
        text: "Clientes",
        link: "/listarClientes"
    });
}

if (true) {
    Clientes.submenu.push({
        text: "Grupos económicos",
        link: "/grupoEconomico"
    });
}
if (true) {
    Clientes.submenu.push({
        text: "Listado de clientes",
        link: "/modulo-clientes/listado-clientes"
    });
} */


/*const Archivos = {
    text: "Archivos",
    link: "",
    submenu: []
};*/

const RRHH = {
    text: "RR.HH.",
    link: "/modulo-rrhh",
    // submenu: []
};
const LegalSocietario = {
    text: "Legal societario",
    link: "/modulo-legal-societario",
    // submenu: []
};
const Cronogramas = {
    text: "Cronogramas",
    link: "/modulo-cronogramas",
    // submenu: []
};

const Administracion = {
    text: "Administración",
    link: "/modulo-presupuesto",
    // submenu: []
};
const TGA = {
    text: "TGA",
    link: "/modulo-tga",
    // submenu: []
};
const Fondos = {
    text: "Fondos",
    link: "/modulo-fondos",
    // submenu: []
};

// Tga
const Estudio = {
    text: "Estudio",
    link: "",
    submenu: []
};


// NIVEL 4 / PROCEDIMIENTOS

// Tableros

if (true) {
    Tableros.submenu.push({
        text: "Auditoría",
        link: "/auditoria"
    });
}

if (true) {
    Tableros.submenu.push({
        text: "Outsourcing",
        link: "/outsourcing"
    });
}

if (true) {
    Tableros.submenu.push({
        text: "Impuestos",
        link: "/vencimientos"
    });
}

if (true) {
    Tableros.submenu.push({
        text: "Recuperos",
        link: "/recuperos"
    });
}

if (true) {
    Tableros.submenu.push({
        text: "Tareas",
        link: "/tareas"
    });
}

if (true) {
    Tableros.submenu.push({
        text: "Personal",
        link: "/personal"
    });
}

if (true) {
    Tableros.submenu.push({
        text: "Laboral",
        link: "/laboral"
    });
    Tableros.submenu.push({
        text: "Presupuestos",
        link: "/modulo-presupuesto/tablero-presupuesto"
    });
    Tableros.submenu.push({
        text: "Monotributo",
        link: "/modulo-presupuesto/tablero-monotributo"
    });
}
if (true)
    Tableros.submenu.push({
        text: "Rendiciones",
        link: "/modulo-presupuesto/tablero-rendiciones"
    });
    Tableros.submenu.push({
        text: "Impuestos anuales",
        link: "/modulo-impuestos/tablero-impuestos-anuales"
    });
if (true) {
    Tableros.submenu.push({
        text: "Comité",
        link: "/modulo-comite/tablero-comite"
    });
}
if (true) {
    Tableros.submenu.push({
        text: "Claves",
        link: "/funcionalidades-generales/tablero-claves"
    });
}
    
/* if (!environment.production) { // No hechos
    Tableros.submenu.push({
        text: "Monotributistas",
        link: "#"
    });
} */

// MODULOS/Impuestos - Laboral

/* if (true) {
    Impuestos.submenu.push({
        text: "Plantilla vencimientos",
        link: "/plantillaVencimientos"
    });
}

if (true) {
    Impuestos.submenu.push({
        text: "Program. vencimientos",
        link: "/abmProgramacionAutomatica"
    });
}
if (true) {
    Impuestos.submenu.push({
        text: "Control de obligaciones asignadas",
        link: "/modulo-impuestos-laboral"
    });
}
if (true) {
    Impuestos.submenu.push({
        text: "Control de nómina liquidada",
        link: "/modulo-impuestos-laboral"
    });
} */
//MODULOS/OUTSOURCING
/* if (true) {
    Outsourcing.submenu.push({
        text: "Consulta de comprobantes",
        link: "/modulo-outsourcing/control-comprobantes"
    });
} */

// if (!environment.production) { // No hechos
//     Impuestos.submenu.push({
//         text: "Envía, Consulta DD.JJ. Impuestos",
//         link: "#"
//     });
// }

// if (!environment.production) { // No hechos
//     Impuestos.submenu.push({
//         text: "Envía, Consulta DD.JJ. Laboral",
//         link: "#"
//     });
// }

// MODULOS/Archivos

/*if (true) {
    Archivos.submenu.push({
        text: "Depósitos",
        link: "/depositos"
    });
}

if (true) {
    Archivos.submenu.push({
        text: "Documentos a archivar",
        link: "/documentosAArchivar"
    });
}*/

// if (!environment.production) { // No hechos
//     Archivos.submenu.push({
//         text: "Provincias",
//         link: "#"
//     });
// }
/*
Archivos.submenu.push({
    text: "Claves",
    link: "/funcionalidades-generales/tablero-claves"
});
Archivos.submenu.push({
    text: "Modelos de documentación",
    link: "/funcionalidades-generales/tablero-modelo-documentacion"
});
Archivos.submenu.push({
    text: "Internos",
    link: "/funcionalidades-generales/tablero-internos"
});
if(!environment.production){
    Archivos.submenu.push({
    text: "Informes",
    link: "/funcionalidades-generales/informes"
});
}

if (true) {
    Archivos.submenu.push({
        text: "CUIT países",
        link: "/abmCuitPaises"
    });
}
if (true) {
    Archivos.submenu.push({
        text: "CUIT bancos",
        link: "/abmCuitBancos"
    });
}

if (true) {
    Archivos.submenu.push({
        text: "Monedas",
        link: "/abmMonedas"
    });
}
if (true) {
    Archivos.submenu.push({
        text: "Cotizaciones",
        link: "/abmCotizaciones"
    });
}

if (true) {
    Archivos.submenu.push({
        text: "Tipos de contrato",
        link: "/abmTiposDeContrato"
    });
}

// if (!environment.production) { // No hechos
//     Archivos.submenu.push({
//         text: "Tipos de Documentos",
//         link: "#"
//     });
// }

if (true) {
    Archivos.submenu.push({
        text: "Organismos",
        link: "/abmOrganismos"
    });
}

if (true) {
    Archivos.submenu.push({
        text: "Sub-Organismos",
        link: "/abmSubOrganismos"
    });
}

if (true) {
    Archivos.submenu.push({
        text: "Tipos de obligaciones",
        link: "/abmTiposDeObligaciones"
    });
}

if (true) {
    Archivos.submenu.push({
        text: "Obligaciones",
        link: "/abmObligaciones"
    });
}

if (true) {
    Archivos.submenu.push({
        text: "Tipos de reg. fiscales",
        link: "/abmTiposRegimenFiscal"
    });
}

if (true) {
    Archivos.submenu.push({
        text: "Regímenes fiscales",
        link: "/abmRegimenesFiscales"
    });
}

if (true) {
    Archivos.submenu.push({
        text: "Tipos de empresas",
        link: "/abmTiposDeEmpresa"
    });
}

// if (!environment.production) { // No hechos
//     Archivos.submenu.push({
//         text: "Beneficios Pymes",
//         link: "#"
//     });
// }

if (true) {
    Archivos.submenu.push({
        text: "Escalas Pymes",
        link: "/abmEscalasPymes"
    });
}

if (true) {
    Archivos.submenu.push({
        text: "Actividades AFIP",
        link: "/abmActividadesAfip"
    });
}

if (true) {
    Archivos.submenu.push({
        text: "Actividades rentas",
        link: "/abmActividadesRentas"
    });
}

if (true) {
    Archivos.submenu.push({
        text: "Obligaciones a cumplir",
        link: "/abmObligacionesACumplir"
    });
}

if (true) {
    Archivos.submenu.push({
        text: "Monotributistas",
        link: "/abmMonotributistas"
    });
}

if (true) {
    Archivos.submenu.push({
        text: "Tipos de sociedades",
        link: "/abmTiposSociedades"
    });
}

// if (!environment.production) { // No hechos
//     Archivos.submenu.push({
//         text: "Personal Estudio",
//         link: "#"
//     });
// }

if (true) {
    Archivos.submenu.push({
        text: "Días feriados e inhábiles",
        link: "/feriados"
    });
}

if (true) {
    Archivos.submenu.push({
        text: "Días de vacaciones",
        link: "/vacaciones"
    });
}

if (true) {
    Archivos.submenu.push({
        text: "Obras Sociales",
        link: "/obrasSociales"
    });
}

if (true) {
    Archivos.submenu.push({
        text: "Categorías Personal",
        link: "/categoriasDePersonal"
    });
}

if (true) {
    Archivos.submenu.push({
        text: "Tipos Libros",
        link: "/abmTiposLibros"
    });
}

Archivos.submenu = Archivos.submenu.sort((a, b) => { return a.text.localeCompare(b.text); });*/

// APLICACIONES

/*if (true) {
    Aplicaciones.submenu.push({
        text: "Contactos",
        link: "/abmContactos"
    });
}*/
// TGA

// TGA/Estudio

if (true) {
    Estudio.submenu.push({
        text: "Configuración de usuarios",
        link: "/configuracion-usuarios"
    });
}



// Listado de Menu
// NIVEL 2
Tableros.submenu = Tableros.submenu.sort(function (a, b) { return a.text.localeCompare(b.text); });
dinamicMenu.push(Tableros);
dinamicMenu.push(Modulos);
//dinamicMenu.push(Aplicaciones);
// if (!environment.production)
//     dinamicMenu.push(Clientes);
dinamicMenu.push(Tga);

// NIVEL 3

if (true)
    Modulos.submenu.push(Impuestos);
Modulos.submenu.push(Laboral);
Modulos.submenu.push(Clientes);
Modulos.submenu.push(Outsourcing);
//Modulos.submenu.push(Archivos);
Modulos.submenu.push(RRHH);
Modulos.submenu.push(Cronogramas);
Modulos.submenu.push(LegalSocietario);
Modulos.submenu.push(Administracion);
Modulos.submenu.push(TGA);
Modulos.submenu.push(Fondos);
Modulos.submenu = Modulos.submenu.sort(function (a, b) { return a.text.localeCompare(b.text); });

Tga.submenu.push(Estudio);


export const menu = dinamicMenu;


// dinamicMenu.push(headingMain);
// dinamicMenu.push(Home);
// dinamicMenu.push(Dashboard);
// dinamicMenu.push(Widgets);
// dinamicMenu.push(headingComponents);
// dinamicMenu.push(Material);
// dinamicMenu.push(Elements);
// dinamicMenu.push(Forms);
// dinamicMenu.push(Charts);
// dinamicMenu.push(Tables);
// dinamicMenu.push(Maps);
// dinamicMenu.push(headingMore);
// dinamicMenu.push(Pages);
// dinamicMenu.push(Blog);
// dinamicMenu.push(Ecommerce);
// dinamicMenu.push(Extras);

const Dashboard = {
    text: "Dashboard",
    link: "/dashboard",
    icon: "icon-speedometer",
    submenu: [
        {
            text: "Dashbord v1",
            link: "/dashboard/v1"
        },
        {
            text: "Dashbord v2",
            link: "/dashboard/v2"
        },
        {
            text: "Dashbord v3",
            link: "/dashboard/v3"
        }
    ]
};

const Widgets = {
    text: "Widgets",
    link: "/widgets",
    icon: "icon-grid"
};

const Material = {
    text: "Material",
    link: "/material",
    icon: "fa fa-shield-alt",
    submenu: [
        {
            text: "Widgets",
            link: "/material/widgets"
        },
        {
            text: "Cards",
            link: "/material/cards"
        },
        {
            text: "Forms",
            link: "/material/forms"
        },
        {
            text: "Inputs",
            link: "/material/inputs"
        },
        {
            text: "Lists",
            link: "/material/lists"
        },
        {
            text: "Whiteframe",
            link: "/material/whiteframe"
        },
        {
            text: "Colors",
            link: "/material/colors"
        },
        {
            text: "ng2Material",
            link: "/material/ngmaterial"
        }
    ],
    alert: "new",
    label: "badge badge-primary"
};

const Elements = {
    text: "Elements",
    link: "/elements",
    icon: "icon-chemistry",
    submenu: [
        {
            text: "Buttons",
            link: "/elements/buttons"
        },
        {
            text: "Interaction",
            link: "/elements/interaction"
        },
        {
            text: "Notification",
            link: "/elements/notification"
        },
        {
            text: "SweetAlert",
            link: "/elements/sweetalert"
        },
        {
            text: "Spinners",
            link: "/elements/spinners"
        },
        {
            text: "Dropdown",
            link: "/elements/dropdown"
        },
        {
            text: "Nav Tree",
            link: "/elements/navtree"
        },
        {
            text: "Sortable",
            link: "/elements/sortable"
        },
        {
            text: "Grid",
            link: "/elements/grid"
        },
        {
            text: "Grid Masonry",
            link: "/elements/gridmasonry"
        },
        {
            text: "Typography",
            link: "/elements/typography"
        },
        {
            text: "Font Icons",
            link: "/elements/iconsfont"
        },
        {
            text: "Weahter Icons",
            link: "/elements/iconsweather"
        },
        {
            text: "Colors",
            link: "/elements/colors"
        },
        {
            text: "Infinite Scroll",
            link: "/elements/infinitescroll"
        }
    ]
};

const Forms = {
    text: "Forms",
    link: "/forms",
    icon: "icon-note",
    submenu: [
        {
            text: "Standard",
            link: "/forms/standard"
        },
        {
            text: "Extended",
            link: "/forms/extended"
        },
        {
            text: "Validation",
            link: "/forms/validation"
        },
        {
            text: "Upload",
            link: "/forms/upload"
        },
        {
            text: "Image Crop",
            link: "/forms/cropper"
        }
    ]
};

const Charts = {
    text: "Charts",
    link: "/charts",
    icon: "icon-graph",
    submenu: [
        {
            text: "Flot",
            link: "/charts/flot"
        },
        {
            text: "Radial",
            link: "/charts/radial"
        },
        {
            text: "ChartJS",
            link: "/charts/chartjs"
        }
    ]
};

const Tables = {
    text: "Tables",
    link: "/tables",
    icon: "icon-grid",
    submenu: [
        {
            text: "Standard",
            link: "/tables/standard"
        },
        {
            text: "Extended",
            link: "/tables/extended"
        },
        {
            text: "Angular Grid",
            link: "/tables/aggrid"
        },
        {
            text: "NGxDatatables",
            link: "/tables/ngxdatatable"
        }
    ]
};

const Maps = {
    text: "Maps",
    link: "/maps",
    icon: "icon-map",
    submenu: [
        {
            text: "Google",
            link: "/maps/google"
        },
        {
            text: "Vector",
            link: "/maps/vector"
        }
    ]
};

const Pages = {
    text: "Pages",
    link: "/pages",
    icon: "icon-doc",
    submenu: [
        {
            text: "Login",
            link: "/login"
        },
        {
            text: "Register",
            link: "/register"
        },
        {
            text: "Recover",
            link: "/recover"
        },
        {
            text: "Lock",
            link: "/lock"
        },
        {
            text: "404",
            link: "/404"
        },
        {
            text: "500",
            link: "/500"
        },
        {
            text: "Maintenance",
            link: "/maintenance"
        }
    ]
};

const Blog = {
    text: "Blog",
    link: "/blog",
    icon: "icon-notebook",
    submenu: [
        {
            text: "List",
            link: "/blog/list"
        },
        {
            text: "Post",
            link: "/blog/post"
        },
        {
            text: "Articles",
            link: "/blog/articles"
        },
        {
            text: "Article View",
            link: "/blog/articleview"
        }
    ]
};

const Ecommerce = {
    text: "Ecommerce",
    link: "/ecommerce",
    icon: "icon-basket-loaded",
    submenu: [
        {
            text: "Orders",
            link: "/ecommerce/orders"
        },
        {
            text: "Order View",
            link: "/ecommerce/orderview"
        },
        {
            text: "Products",
            link: "/ecommerce/products"
        },
        {
            text: "Product View",
            link: "/ecommerce/productview"
        },
        {
            text: "Checkout",
            link: "/ecommerce/checkout"
        }
    ]
};

const Extras = {
    text: "Extras",
    link: "/extras",
    icon: "icon-cup",
    submenu: [
        {
            text: "Contacts",
            link: "/extras/contacts"
        },
        {
            text: "Contact details",
            link: "/extras/contactdetails"
        },
        {
            text: "Projects",
            link: "/extras/projects"
        },
        {
            text: "Projects details",
            link: "/extras/projectsdetails"
        },
        {
            text: "Team Viewer",
            link: "/extras/teamviewer"
        },
        {
            text: "Social Board",
            link: "/extras/socialboard"
        },
        {
            text: "Vote links",
            link: "/extras/votelinks"
        },
        {
            text: "Bug tracker",
            link: "/extras/bugtracker"
        },
        {
            text: "Faq",
            link: "/extras/faq"
        },
        {
            text: "Help center",
            link: "/extras/helpcenter"
        },
        {
            text: "Followers",
            link: "/extras/followers"
        },
        {
            text: "Settings",
            link: "/extras/settings"
        },
        {
            text: "Plans",
            link: "/extras/plans"
        },
        {
            text: "File manager",
            link: "/extras/filemanager"
        },
        {
            text: "Forum",
            link: "/extras/forum"
        },
        {
            text: "Mailbox",
            link: "/extras/mailbox"
        },
        {
            text: "Timeline",
            link: "/extras/timeline"
        },
        {
            text: "Calendar",
            link: "/extras/calendar"
        },
        {
            text: "Invoice",
            link: "/extras/invoice"
        },
        {
            text: "Search",
            link: "/extras/search"
        },
        {
            text: "Todo list",
            link: "/extras/todolist"
        },
        {
            text: "Profile",
            link: "/extras/profile"
        },
        {
            text: "Code editor",
            link: "/extras/codeeditor"
        }
    ]
};


const headingMain = {
    text: "Menu principal",
    heading: true
};

const headingComponents = {
    text: "Components",
    heading: true
};

const headingMore = {
    text: "More",
    heading: true
};
