import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import modalUtils from 'src/app/utils/modal';
const swal = require("sweetalert");

import { GrupoEconomicoService } from 'src/app/services/grupo-economico.service';
import { ComprobantesPagosService } from 'src/app/services/comprobantes-pagos.service';

import { Page } from 'src/app/interfaces/page';
import { GrupoEconomico } from 'src/app/interfaces/grupo-economico';
import { ContactoCliente } from 'src/app/interfaces/contacto-cliente';
import { ClienteBase } from 'src/app/interfaces/cliente';
import { ComprobantePago } from 'src/app/interfaces/comprobante-pago';

import { HABILITADO } from 'src/app/enums/habilitado';
import { ERROR_MESSAGES } from 'src/app/enums/error-messages';
import { TIPO_COMPROBANTE } from 'src/app/enums/tipo-comprobante.enum';

import { ListadoContactosComponent } from '../listado-contactos/listado-contactos.component';
import { ExportarDatosComponent } from '../exportar-datos/exportar-datos.component';
import { ClienteBaseService } from 'src/app/services/clientes/cliente-base.service';
@Component({
  selector: 'app-modal-encargado-pagos',
  templateUrl: './modal-encargado-pagos.component.html',
  styleUrls: ['./modal-encargado-pagos.component.scss']
})
export class ModalEncargadoPagosComponent implements OnInit {

  @Input() grupoEconomico: any = undefined;
  @Output() saved = new EventEmitter();

  @ViewChild('modalEncargadoPagos') modalEncargadoPagos: ModalDirective;
  @ViewChild('contactos') contactos: ListadoContactosComponent;
  @ViewChild("exportarDatos") exportarDatos: ExportarDatosComponent;

  public formEncargadoPagos: FormGroup;
  public formSupervisor: FormGroup;
  public formFiltros: FormGroup;
  public flechaOcultarEncargados = true;
  public flechaOcultarSupervisor = true;
  public datosTabla: Array<ComprobantePago> = [];
  public datosSupervisores: Array<ComprobantePago> = [];
  public disabledGrupoEconomico = false;
  public editar: Array<boolean> = [];
  public editarSupervisor: Array<boolean> = [];
  public ordenAscResponsable = false;
  public ordenAscCliente = false;
  public banderaFlecha = true;
  public banderaFlechaTabla = false;
  public filtrar = true

  //*Datos
  public gruposEconomicos: Array<GrupoEconomico> = [];
  public clientes: Array<ClienteBase> = [];
  public abiertoDesde: string;
  public contactoSeleccionado: ContactoCliente = null;
  public comprobantes: Array<ComprobantePago> = [];

  //*Listados
  public listadoGruposEconomicos: Array<{ id: number, text: string }> = [];
  public listadoCodigoFondoFijo: Array<{ id: number, text: string }> = [];
  public listadoClientes: Array<{ id: number, text: string }> = [];

  //*Exportar
  public listadoExportar: Array<any> = [];
  public exportConfig: any = {}

  constructor(private formbuilder: FormBuilder,
    private grupoEconomicoService: GrupoEconomicoService,
    private clienteBaseService: ClienteBaseService,
    private comprobantesPagosService: ComprobantesPagosService) {
    this.formFiltros = formbuilder.group({
      grupoEconomico: [null],
      codigoFondos: [{ value: null, disabled: true }],
      clientes: [null, Validators.required],
    });
    this.formEncargadoPagos = formbuilder.group({
      responsable: [null, Validators.required],
      mail: [null, Validators.required],
      telefono: [null, Validators.required],
      interno: [null],
      celular: [null, Validators.required],
      diaPago: [null],
      politicaPago: [null],
      repiteMail: [null]
    });
    this.formSupervisor = formbuilder.group({
      supervisor: [null],
      mail: [null],
      telefono: [null],
      repiteMail: [null]
    });

    this.findFiltros();
  }

  ngOnInit(): void { }

  public findFiltros() {
    forkJoin([
      this.grupoEconomicoService.findAll({ habilitado: HABILITADO.SI }),
      this.clienteBaseService.findAll({ habilitado: HABILITADO.SI })
    ]).subscribe(([gruposEconomicos, clientes]) => {
      this.gruposEconomicos = gruposEconomicos.content;
      this.clientes = clientes.content;

      this.listadoGruposEconomicos = gruposEconomicos.content.map(ge => ({ id: ge.id, text: ge.nombre }));
      this.listadoCodigoFondoFijo = gruposEconomicos.content.map(c => ({ id: c.id, text: c.codigoFondoFijo }));
      this.listadoClientes = this.clientes.map(c => ({ id: c.id, text: c.nombre }));
    })
  }

  public findAll() {
    this.comprobantesPagosService.findComprobantes({
      tipoComprobante: TIPO_COMPROBANTE.RESPONSABLE_PAGO,
      grupoEconomicoId: this.formFiltros.controls.grupoEconomico.value
    }).subscribe((response: Page<ComprobantePago>) => {
      this.comprobantes = response.content
      this.ordenarXCampo('responsable', true, this.comprobantes)
      this.ordenAscResponsable = true
      this.ordenAscCliente = false
      this.filtrarSupervisores()
      this.inicializarEditar()
    })
  }

  public ordenarXCampo(campo: string, ordenAsc: boolean = true, array: any = this.datosTabla) {
    if (campo === 'cp_super') {
      this.banderaFlechaTabla = false;
    } else {
      this.banderaFlecha = false;
    }
    array.sort((a, b) => {
      if (ordenAsc)
        return (a[campo] > b[campo]) ? 1 : ((b[campo] > a[campo]) ? -1 : 0);
      else
        return (b[campo] > a[campo]) ? 1 : ((a[campo] > b[campo]) ? -1 : 0);
    });
    this.inicializarEditar();

  }

  public ordenarXCampoDoble(campo1: string, campo2: string, ordenAsc: boolean = true, control: string, array: any = this.datosTabla) {
    if (control === 'supervisor') {
      this.banderaFlechaTabla = true;
    } else {
      this.banderaFlecha = true;
    }
    array.sort((a, b) => {
      if (ordenAsc)
        return (a[campo1][campo2] > b[campo1][campo2]) ? 1 : ((b[campo1][campo2] > a[campo1][campo2]) ? -1 : 0);
      else
        return (b[campo1][campo2] > a[campo1][campo2]) ? 1 : ((a[campo1][campo2] > b[campo1][campo2]) ? -1 : 0);
    });
    this.inicializarEditar();

  }

  public inicializarEditar() {
    this.editar = []
    this.editarSupervisor = []
    this.datosTabla.forEach(d => this.editar.push(false))
    this.datosSupervisores.forEach(d => this.editarSupervisor.push(false))
  }

  public filtrarSupervisores() {
    this.datosTabla = this.comprobantes.filter(c => c.responsable != '')
    this.datosSupervisores = this.comprobantes.filter(c => c.responsable === '')
  }

  public findAllByCliente() {
    this.datosTabla = []
    this.datosSupervisores = []
    this.formFiltros.controls.clientes.value.forEach(cliente => {
      this.comprobantes.forEach(comprobante => {
        if (cliente === comprobante.clienteFisico?.id) {
          this.datosTabla.push(comprobante)
          comprobante.responsable === '' ? this.datosSupervisores.push(comprobante) : null
        }
      })
    })
    if (!this.datosTabla.length) {
      this.filtrarSupervisores()
    }
    this.inicializarEditar()
  }

  public filtrarClientes() {
    this.formFiltros.controls.clientes.disable()
    if (this.formFiltros.getRawValue().grupoEconomico) {
      let codigo = this.gruposEconomicos.find(g => g.id == this.formFiltros.getRawValue().grupoEconomico).codigo;
      this.grupoEconomicoService.findClientes(codigo, HABILITADO.SI)
        .subscribe((response: Page<ClienteBase>) => {
          this.listadoClientes = response.content.map(g => ({ id: g.id, text: g.nombre }));
          this.formFiltros.controls.clientes.enable()
        })
    }
    else {
      this.clienteBaseService.findAll({
        habilitado: HABILITADO.SI
      }).subscribe(clientes => {
        this.listadoClientes = clientes.content.map(c => ({ id: c.id, text: c.nombre }));
        this.formFiltros.controls.clientes.enable()
      })
    }
  }
  // public filtrarClientes() {
  //   this.listadoClientes = this.clientes.filter(c => c.grupoEconomicoDto.id === this.formFiltros.controls.grupoEconomico.value).map(g => ({ id: g.id, text: g.nombre }))
  // }

  public async setGrupoEconomico() {
    this.formFiltros.controls.grupoEconomico.patchValue(this.grupoEconomico?.id || null)
    this.formFiltros.controls.grupoEconomico.disable()
  }

  public findGrupoEconomico(): GrupoEconomico {
    return this.gruposEconomicos.find(ge => ge.id === this.formFiltros.controls.grupoEconomico.value)
  }

  public setCodigoFondos() {
    this.formFiltros.controls.codigoFondos.patchValue(this.formFiltros.controls.grupoEconomico.value)
    this.filtrar ? this.filtrarClientes() : null
  }

  public getContacto(event: ContactoCliente) {
    this.closeListadoContactos(event)
    this.contactoSeleccionado = event;
    if (this.contactoSeleccionado != null) {
      if (this.abiertoDesde == 'encargado') {
        this.formEncargadoPagos.controls.responsable.setValue(this.contactoSeleccionado.contacto.descripcion)
        this.formEncargadoPagos.controls.mail.setValue(this.contactoSeleccionado.contacto.email)
      }
      else {
        this.formSupervisor.controls.supervisor.setValue(this.contactoSeleccionado.contacto.descripcion)
        this.formSupervisor.controls.mail.setValue(this.contactoSeleccionado.contacto.email)
      }
    }
  }

  public generateEncargadoPago(grupoEconomico: GrupoEconomico, idCliente: number): ComprobantePago {
    return {
      id: null,
      grupoEconomico: grupoEconomico,
      tipoComprobante: TIPO_COMPROBANTE.RESPONSABLE_PAGO,
      codigo: grupoEconomico?.codigoFondoFijo || '',
      responsable: this.formEncargadoPagos.controls.responsable.value,
      email: this.formEncargadoPagos.controls.mail.value,
      telefono: this.formEncargadoPagos.controls.telefono.value,
      interno: this.formEncargadoPagos.controls.interno?.value || '',
      cp_celular: this.formEncargadoPagos.controls.celular.value,
      habilitado: true,
      politicaPago: this.formEncargadoPagos.controls.politicaPago?.value || '',
      diaPago: this.formEncargadoPagos.controls.diaPago?.value || '',
      cp_super: '',
      superMail: '',
      superTel: '',
      clienteFisico: this.clientes.find(c => c.id === idCliente)
    }
  }

  public generateSupervisor(grupoEconomico: GrupoEconomico, idCliente: number): ComprobantePago {
    return {
      id: null,
      grupoEconomico: grupoEconomico,
      tipoComprobante: TIPO_COMPROBANTE.RESPONSABLE_PAGO,
      codigo: grupoEconomico?.codigoFondoFijo || '',
      responsable: '',
      email: '',
      telefono: '',
      interno: '',
      cp_celular: '',
      habilitado: true,
      politicaPago: '',
      diaPago: '',
      cp_super: this.formSupervisor.controls.supervisor?.value || '',
      superMail: this.formSupervisor.controls.mail?.value || '',
      superTel: this.formSupervisor.controls.telefono?.value || '',
      clienteFisico: this.clientes.find(c => c.id === idCliente)
    }
  }

  public submitFormEncargado() {
    let grupoEconomicoSeleccionado = this.findGrupoEconomico()
    let newComprobante: Array<ComprobantePago> = [];
    if (this.formEncargadoPagos.controls.repiteMail.value)
      this.listadoClientes.forEach(cliente => {
        newComprobante.push(this.generateEncargadoPago(grupoEconomicoSeleccionado, cliente.id))
      })
    else
      this.formFiltros.controls.clientes.value.forEach(idCliente => {
        newComprobante.push(this.generateEncargadoPago(grupoEconomicoSeleccionado, idCliente))
      })
    this.addComprobante(newComprobante)
    this.formEncargadoPagos.reset()
  }

  public addComprobante(newComprobante: Array<ComprobantePago>) {
    forkJoin(
      newComprobante.map(c => this.comprobantesPagosService.addComprobante(c))
    ).subscribe(response => {
      swal({
        title: "Guardado exitosamente",
        text: "El comprobante se guardó exitosamente.",
        icon: "success",
        buttons: false,
        timer: 1600
      })
      //this.formFiltros.controls.clientes.reset()
      this.findAll()
      this.saved.emit()
    }, (error) => {
      swal('¡Lo sentimos!', error.error?.message || ERROR_MESSAGES.GUARDADO, 'error')
    })
  }

  public submitFormSupervisor() {
    let grupoEconomicoSeleccionado = this.findGrupoEconomico()
    let newComprobante: Array<ComprobantePago> = [];
    if (this.formSupervisor.controls.repiteMail.value)
      this.listadoClientes.forEach(cliente => {
        newComprobante.push(this.generateSupervisor(grupoEconomicoSeleccionado, cliente.id))
      })
    else
      this.formFiltros.controls.clientes.value.forEach(idCliente => {
        newComprobante.push(this.generateSupervisor(grupoEconomicoSeleccionado, idCliente))
      })
    this.addComprobante(newComprobante)
    this.formSupervisor.reset()
  }

  public validarCampos(comprobante: ComprobantePago): boolean {
    return comprobante.responsable && comprobante.email && comprobante.clienteFisico?.nombre && comprobante.telefono && comprobante.cp_celular ? true : false
  }

  public editComprobante(comprobante: ComprobantePago, i: number) {
    comprobante.superTel = comprobante?.superTel || ''
    this.comprobantesPagosService.editComprobantePago(comprobante).subscribe(response => {
      swal({
        title: "Guardado exitosamente",
        text: "Las modificaciones se guardaron exitosamente.",
        icon: "success",
        buttons: false,
        timer: 1600
      })
      this.findAll()
    }, (error) => {
      swal('¡Lo sentimos!', error.error?.message || ERROR_MESSAGES.GUARDADO, 'error')
    })
  }

  public valueRequired(value: string, form: FormGroup) {
    return form.controls[value].hasError("required") && (form.controls[value].dirty || form.controls[value].touched);
  }

  public deleteComprobante(comprobante: ComprobantePago) {
    this.comprobantesPagosService.deleteComprobantePago(comprobante).subscribe(response => {
      swal({
        title: "Eliminado exitosamente",
        text: "El comprobante se eliminó exitosamente.",
        icon: "success",
        buttons: false,
        timer: 1600
      })
      this.findAll()
    }, (error) => {
      swal('¡Lo sentimos!', error.error?.message || ERROR_MESSAGES.ELIMINAR, 'error')
    })
  }

  public openModal() {
    modalUtils.open(this.modalEncargadoPagos);
  }
  public resetModal() {
    this.flechaOcultarEncargados = false;
    this.flechaOcultarSupervisor = false;
    this.formFiltros.reset()
    this.formEncargadoPagos.reset()
    this.formSupervisor.reset()
  }
  public setForm() {
    this.filtrar = false
    this.resetModal()
    if (this.grupoEconomico != undefined) {
      this.setGrupoEconomico()
      this.filtrar = true
      this.setCodigoFondos()
    } else this.filtrar = true
  }
  public openModalContactos() {
    modalUtils.hide(this.modalEncargadoPagos);
    setTimeout(() => {
      this.contactos.openModal();
    }, 600);
  }
  public closeListadoContactos(event) {
    setTimeout(() => {
      this.openModal();
    }, 700);
  }

  public armarListadoExportar() {
    this.exportConfig = {
      title: "Clientes - Encargado de pagos",
      showColumns: ["codigo", "responsable", 'mail', 'cliente', 'telefono', 'interno', 'celular'],
      aliasColumns: {
        codigo: "Codigo", responsable: 'Responsable',
        mail: 'Mail', cliente: 'Cliente', telefono: 'Telefono', interno: 'Interno', celular: 'Celular'
      }
    }
    this.listadoExportar = this.datosTabla.map(item => {
      return {
        codigo: item.codigo || '-',
        responsable: item.responsable || '-',
        mail: item?.email,
        cliente: item?.clienteFisico?.nombre || '-',
        telefono: item?.telefono.toString() || '-',
        interno: item?.interno.toString() || '-',
        celular: item?.cp_celular.toString() || '-'
      }
    })
  }

  public armarListadoExportarSupervisores() {
    this.exportConfig = {
      title: "Clientes - Encargado de pagos - Supervisores",
      showColumns: ["codigo", "supervisor", 'mail', 'cliente', 'telefono'],
      aliasColumns: {
        codigo: "Codigo", supervisor: 'Supervisor',
        mail: 'Mail', cliente: 'Cliente', telefono: 'Telefono'
      }
    }
    this.listadoExportar = this.datosSupervisores.map(item => {
      return {
        codigo: item.codigo || '-',
        supervisor: item.cp_super || '-',
        mail: item?.superMail || '-',
        cliente: item?.clienteFisico?.nombre || '-',
        telefono: item?.superTel || '-'
      }
    })
  }

  public showExportarDatos(isSupervisor = false) {
    isSupervisor ? this.armarListadoExportarSupervisores() : this.armarListadoExportar()
    this.exportarDatos.openModal()
  }
}
