import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fecha'
})
export class FechaPipe implements PipeTransform {

  transform(date: string): string {
    let hora = null;
    if (date?.length) {
      if (date.length > 10) {
        hora = date.split(' ')[1];
        date = date.split(' ')[0];
      }
      return date.split('-').reverse().join('/') + (hora ? ' ' + hora : '');
    }
    return '';
  }
}
