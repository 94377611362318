import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { HABILITADO } from "../enums/habilitado";
import { IdCodigoDescripcionHabilitado } from "../interfaces/id-codigo-descripcion-habilitado";
import { Page } from "../interfaces/page";

@Injectable({
  providedIn: "root"
})
export class DepositosService {

  public recurso: string = "/depositos";

  constructor(private http: HttpClient) { }

  public findDepositos({
    habilitado = HABILITADO.TODOS
  } = {}): Observable<Page<IdCodigoDescripcionHabilitado>> {
    let params = new URLSearchParams([["habilitado", habilitado]]);
    return this.http.get<Page<IdCodigoDescripcionHabilitado>>(`${environment.apiEndpoint}${this.recurso}?${params.toString()}`);
  }

  public newDeposito(deposito: IdCodigoDescripcionHabilitado): Observable<IdCodigoDescripcionHabilitado> {
    return this.http.post<IdCodigoDescripcionHabilitado>(`${environment.apiEndpoint}${this.recurso}`, deposito);
  }

  public editDeposito(deposito: IdCodigoDescripcionHabilitado): Observable<IdCodigoDescripcionHabilitado> {
    return this.http.put<IdCodigoDescripcionHabilitado>(`${environment.apiEndpoint}${this.recurso}`, deposito);
  }

  public deleteDeposito(id: number): Observable<IdCodigoDescripcionHabilitado> {
    return this.http.delete<IdCodigoDescripcionHabilitado>(`${environment.apiEndpoint}${this.recurso}/${id}`);
  }
}
