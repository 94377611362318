import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'app-input-inline-editable',
  templateUrl: './input-inline-editable.component.html',
  styleUrls: ['./input-inline-editable.component.scss']
})
export class InputInlineEditableComponent implements OnInit {

  public editInline = false;

  @Input() placeholder: string;

  @Input() dato: string;


  @Input() set typeNumber(value: boolean) {
    this._typeNumber = value;
  }

  @Input() set loading(value: boolean) {
    this._loading = value;
  }

  @Input() set enable(value: boolean) {
    this._enable = value;
  }

  @ViewChild('input') inputElement: ElementRef;

  public _loading: boolean;
  public _enable: boolean;
  public _typeNumber: boolean;


  @Output() guardar = new EventEmitter<String>();

  updating: boolean;

  constructor(private renderer: Renderer2) {
    this.loading = true;
  }

  ngOnInit(): void {
    this.updating = false;
  
    setTimeout(()=>{
      if(this._typeNumber && this._enable)
        this.renderer.setAttribute(this.inputElement.nativeElement, "type", "number");
    },0);

  }
  focusInput(){
    this.editInline = this._enable ? !this.editInline : this.editInline;
    setTimeout(()=>{
      if(this._enable)
        this.inputElement.nativeElement.focus();
    },0);

  }

  init() {

  }

  save(dato) {
      this.guardar.emit(dato);
  }
  public lostFocus() {
    this.editInline = false
  }

  close() {
    this.editInline = false;
  }
}
