import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Documento } from "../../../interfaces/documento";
import { Documentacion } from "../../../interfaces/documentacion";
import { DocumentacionService } from "../../../services/documentacion.service";
import { DocumentoBase } from "../../../interfaces/documento-base";
import { ERROR_MESSAGES } from 'src/app/enums/error-messages';
const swal = require('sweetalert');

@Component({
  selector: 'app-documentacion',
  templateUrl: './documentacion.component.html',
  styleUrls: ['./documentacion.component.scss']
})
export class DocumentacionComponent implements OnInit {

  @Output() agregar = new EventEmitter<DocumentoBase>();
  @Output() eliminar = new EventEmitter<DocumentoBase>();
  @Output() sindocumentacion = new EventEmitter<DocumentoBase>();

  public nuevo: boolean = true;
  public documentacion: Documentacion;

  @Input() params: Map<string, string>;
  @Input() titulo: string;
  @Input() set padreId(value: number) {
    this.init(value);
  }
  @Input() set categoriaId(value: string) {
    this._categoriaId = value;
    this.init(this._padreId);
  }

  @Input() set soloLectura(value: boolean) {
    this._soloLectura = value;
  }

  @Input() set multiSelect(value: boolean) {
    this._multiSelect = value;
  }

  @Input() set update(value: number) {
    this.init(this._padreId);
  }

  @Input() set swalGuardar(value: boolean) {
    this._swalGuardar = value;
  }
  @Input() recurso: string;
  @Input() multiple: boolean;
  @Input() requireConfirmation: boolean;
  @Input() requireCategoryId: boolean;
  @Input() set pdf(value: boolean) {
    this._pdf = value;
  }

  public _soloLectura: boolean;
  public _multiSelect: boolean = false;
  public _padreId: number;
  public _categoriaId: string;
  public _swalGuardar: boolean = true;
  public _pdf: boolean = false;

  public loading: boolean = false;

  public seleccionado: boolean = false;

  constructor(private documentacionService: DocumentacionService) { }

  ngOnInit(): void {
  }

  public init(padreId: number) {
    this.reset();
    if (this.recurso && padreId && (this._categoriaId || !this.requireCategoryId)) {
      this.loading = true;
      this._padreId = padreId;
      this.documentacionService.listar(this.recurso, padreId, this._categoriaId).subscribe((response: Documentacion) => {
        this.documentacion = response;
        if (this.documentacion.documentos[0])
          this.documentacion.documentos[0].seleccionado = true;
        else
          this.nuevoDocumento();
        this.loading = false;
      });
    }
  }

  private reset(): void {
    this.documentacion = {
      categoria: {
        id: this._categoriaId || null,
        nombre: null
      },
      documentos: [],
      selected: true
    }
  }

  public selecionarDocumento(documento: DocumentoBase) {
    if (this.existeDocumentoVacio()) {
      this.removerDocumentosVacios();
      this.nuevo = true;
    }
    this.documentacion.documentos.forEach((doc) => doc.seleccionado = doc.nombre === documento.nombre);
  }

  public nuevoDocumento() {

    if (this.existeDocumentoVacio())
      return;

    this.loading = false;
    this.nuevo = false;

    this.documentacion.documentos.forEach((doc) => doc.seleccionado = false);
    this.documentacion.documentos.push({
      id: null,
      nombre: '',
      formato: '',
      url_archivo: '',
      carga_outsourcing: false,
      eliminable: true,
      modificable: true,
      fecha_carga: '',
      seleccionado: true,
      observacion: '',
      subcategoria: '',
      nivel: ''
    });
  }

  public agregarDocumento(documento: DocumentoBase) {
    this.loading = true;
    if (documento.formato == 'PDF' || !this._pdf) {
      this.documentacionService.agregar(this.recurso, this._padreId, this.documentacion.categoria.id, documento, this.params)
        .subscribe((response: Documento) => {
          this.loading = false;
          this.nuevo = true;
          response.seleccionado = true;
          if (this.documentacion.documentos.length == 1) {
            if (this.documentacion.documentos[0].nombre == "") {
              this.documentacion.documentos[0] = response;
            } else {
              this.documentacion.documentos.push(response);
            }
          } else {
            this.documentacion.documentos.push(response);
          }
          this.documentacion.documentos = this.documentacion.documentos.filter(documento => documento.nombre != "");
          this.agregar.emit(response);
          if (this._swalGuardar) {
            swal({ title: 'Guardado', text: 'El documento se cargó exitosamanete.', icon: 'success', buttons: false, timer: 800 });
          }
        }, (error) => {
          this.loading = false;
          swal({ title: '¡Cancelado!', text: error?.error?.message || ERROR_MESSAGES.SUBIR_ARCHIVO, icon: 'error', buttons: false, timer: 800 });
        });
    } else {
      this.loading = false;
      swal('Error', 'El formato esperado es PDF.', 'error');
    }
  }

  public eliminarDocumento(documento: DocumentoBase) {
    swal({
      title: '¿Estás seguro?',
      text: 'El registro se eliminará de forma permanente en el sistema.',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'No, cancelar!',
          value: null,
          visible: true,
          className: "",
          closeModal: true
        },
        confirm: {
          text: 'Si, continuar!',
          value: true,
          visible: true,
          className: "bg-danger",
          closeModal: false
        }
      }
    }).then((isConfirm) => {
      if (isConfirm) {
        /** Confirmación  **/
        this.documentacionService.eliminar(this.recurso, this._padreId, this.documentacion.categoria.id, documento).subscribe((response: DocumentoBase) => {
          this.eliminar.emit(response);
          swal({
            title: '¡Operación exitosa!',
            text: 'La operación se realizó con exito.',
            icon: 'success',
            buttons: false,
            timer: 800
          });
          for (let i = 0; i < this.documentacion.documentos.length; i++) {
            if (this.documentacion.documentos[i]?.nombre == response.nombre) {
              if (this.documentacion.documentos.length === 1)
                this.documentacion.documentos[i] = { id: null, nombre: "", fecha_carga: "", formato: "", url_archivo: "", modificable: true, carga_outsourcing: true, eliminable: false, seleccionado: true, observacion: "", subcategoria: "", nivel: "" }
              else
                this.documentacion.documentos.splice(i, 1);
              break;
            }
          }
          if (!this.existeDocumentacion()) {
            this.sindocumentacion.emit();
          }
        },
          error => {
            swal('Error', error.error.message, 'error');
          }
        );
        /** Confirmación  **/
      } else {
        swal({ title: '¡Cancelado!', text: 'La acción se canceló', icon: 'error', buttons: false, timer: 800 });
      }
    });

  }

  private existeDocumentoVacio(): boolean {
    return this.documentacion.documentos.some(d => this.documentoVacio(d));
  }

  private documentoVacio(documento: Documento) {
    return !/\w/.test(documento.nombre);
  }

  private removerDocumentosVacios(): void {
    this.documentacion.documentos = this.documentacion.documentos.filter(d => !this.documentoVacio(d));
  }

  private existeDocumentacion(): boolean {
    return this.documentacion.documentos.length > 0 && !this.existeDocumentoVacio();
  }
}
