import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ModalDirective } from "ngx-bootstrap/modal";
import modalUtils from "src/app/utils/modal";
import { Page } from "src/app/interfaces/page";
import { ClienteLite } from "src/app/interfaces/cliente";
import { ResponsablesService } from "src/app/services/clientes/responsables.service";
import { Router } from "@angular/router";
import { IControlHoras } from "src/app/interfaces/icontrol-horas";
import { CortesAuditoria } from "src/app/interfaces/cortes-auditoria";
import { AuditoriaService } from "src/app/services/auditoria.service";
import * as moment from "moment";
import { ControlHorasService } from "src/app/services/control-horas.service";
import { IdDescripcionCod } from "src/app/interfaces/id-descripcion-cod";
import { OutsourcingService } from "src/app/services/outsourcing.service";
import { Recupero } from "src/app/interfaces/recupero";
import { RecuperosService } from "src/app/services/recuperos.service";
import { TareasService } from "src/app/services/tareas.service";
import { ITarea } from "src/app/interfaces/itarea";
import { forkJoin, Observable, of } from "rxjs";
import { IdDescripcion } from "src/app/interfaces/id-descripcion";
import { map, tap } from "rxjs/operators";
import { IInspecciones } from "src/app/interfaces/iinspecciones";
import { InspeccionesService } from "src/app/services/inspecciones.service";
import { ERROR_MESSAGES } from "src/app/enums/error-messages";
import { ControlHorasResumen } from "src/app/interfaces/control-horas-resumen";
import { PersonalService } from "src/app/services/personal.service";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { HABILITADO } from "src/app/enums/habilitado";
import { ClienteBaseService } from "src/app/services/clientes/cliente-base.service";
import intervalUtils from "src/app/utils/intervalUtils";
import { Personal } from "src/app/interfaces/personal";
import { AuthService } from "src/app/services/auth.service";
import { DetalleHonorariosService } from "src/app/services/detalle-honorarios.service";

const swal = require("sweetalert");

@Component({
  selector: "app-control-horario",
  templateUrl: "./control-horario.component.html",
  styleUrls: ["./control-horario.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "(window:resize)": "onResize($event)"
  }
})
export class ControlHorarioComponent implements OnInit, OnDestroy {

  @ViewChild("modalPosponer") modalPosponer: ModalDirective;


  public TIME: number = 1000 * 60 * 30;
  public audio = new Audio("http://www.sonidosmp3gratis.com/sounds/iphone-tono-.mp3");
  public interval: any = null;
  public intervalCierrePeriodoFacturacion: any = null;
  public taskStatus: boolean = null;
  public registro: boolean = false;
  public areas: Array<IdDescripcionCod>;
  public listadoAreas: Array<{ id: string, text: string }> = [];
  public areaPersonal: string;
  public formHorario: FormGroup;
  public clientes: Array<ClienteLite> = [];
  public listadoClientes: Array<{ id: number, text: string }> = [];
  public controlHoras: IControlHoras;
  public tareas: Array<IdDescripcion>;
  public monthEnd: string = moment().endOf("month").format("YYYY-MM-DD").toString();
  public monthStart: string = moment().startOf("month").format("YYYY-MM-DD").toString();
  public cliente: number;
  public tipoArea: string;
  public listadoDeActividades: Array<IControlHoras>;
  private defaultErrorMessage = ERROR_MESSAGES.DEFAULT;
  public scrWidth = window.innerWidth;
  public hour: string;
  public horaActualNotificacion: string;
  public horaPosponer: string = "12:15";
  public horaCierre: string = null;
  public actualDate: Date = new Date();
  public personalLogueado: Personal;
  public notificacionMilliseconds: number;
  public resumen: ControlHorasResumen = {
    objetivoALaFecha: null,
    objetivoMensual: null,
    diferenciaMes: null,
    totalTarea: null,
    totalDiario: null,
    totalMensual: null,
    diferenciaTotal: null
  };

  public filtroHabilitado: HABILITADO = HABILITADO.TODOS;
  public listadoDeHabilitados: Array<{ id: string, text: string }> = [
    { id: HABILITADO.SI, text: "Si" },
    { id: HABILITADO.NO, text: "No" }
  ];

  @ViewChild("modalControlHorario") modalControlHorario: ModalDirective;
  @ViewChild("observaciones") observaciones: ElementRef;

  constructor(private personalService: PersonalService, private localeService: BsLocaleService, private inspeccionesService: InspeccionesService, private tareasService: TareasService, private recuperoService: RecuperosService, private outsourcingService: OutsourcingService, private controlHorasService: ControlHorasService, private auditoriaService: AuditoriaService, private router: Router, private formBuilder: FormBuilder, private clienteBaseService: ClienteBaseService, private responsablesService: ResponsablesService, private authService: AuthService, private detalleHonorariosService: DetalleHonorariosService) {
    this.formHorario = formBuilder.group({
      motivoRegistro: ["CLIENTE", Validators.required],
      cliente: [null],
      area: [null, Validators.required],
      requiereFondos: [null, Validators.required],
      observaciones: [null, Validators.required]
    });
    moment.locale("es-ar");
    this.hour = moment().format("HH:mm:ss");
    this.personalLogueado = this.authService.getUserLogged();
    this.userCierrePeriodoFacturacion();
  }

  ngOnInit() {
    this.personalAreas();
    forkJoin([this.findClientes(),
    this.tiposTarea()]).subscribe(
      (response) => {
        this.listadoActividad();
        this.tareaEnCurso();
      }
    );

    this.audio.addEventListener("canplay", (e) => {
      this.audio.click();
    })
  }

  public play() {
    if (this.audio.readyState === this.audio.HAVE_ENOUGH_DATA)
      this.audio.play()
  }

  
  public async userCierrePeriodoFacturacion() {
    if (this.personalLogueado.codigo === "0008" && this.actualDate.getDate() === 8) { //usuario encargado de cerrar el periodo de facturacion del personal el 25 de cada mes
      this.detalleHonorariosService.findNotificacion(this.actualDate.getFullYear() + "-" + (this.actualDate.getMonth() + 1).toString().padStart(2, '0')).subscribe(notificacion => {
        this.setCierrePeriodoTimeOut(notificacion.hora);
      })
    }
  }

  public async setCierrePeriodoTimeOut(horaNotificacion: string) {
    this.horaActualNotificacion = moment().format("HH:mm");
    this.notificacionMilliseconds = moment(horaNotificacion, 'HH:mm').diff(moment(this.horaActualNotificacion, 'HH:mm'));
    if (this.notificacionMilliseconds && this.notificacionMilliseconds > 0) {
      if(this.notificacionMilliseconds <= 300000) {
        setTimeout(() => {
          this.showCerrar();
        }, 1500);
      } else {
        this.intervalCierrePeriodoFacturacion = setTimeout(() => {
        this.showCerrar();
      }, (this.notificacionMilliseconds - 300000));
      }
    }
  }

  public showCerrar() {
    swal({
      title: "Debe cerrar el período de Facturación",
      text: "¿ESTÁ SEGURO DE REALIZAR ESTA ACCIÓN?",
      icon: "warning",
      timer: ((this.notificacionMilliseconds <= 300000 && this.notificacionMilliseconds > 0) ? this.notificacionMilliseconds : 300000),
      buttons: {
        cancel: { text: "POSPONER", value: false, visible: true, className: "", closeModal: true },
        confirm: { text: "CERRAR", value: true, visible: true, className: "success", closeModal: false }
      },
      closeOnClickOutside: false,
      closeOnEsc: false
    }).then((isConfirm) => {
      if (isConfirm) {
        this.detalleHonorariosService.cerrarFacturacion(this.monthStart.substring(0,7)).subscribe(response => {
          swal({
            title: 'Cerrado',
            text: 'El periodo se cerró exitosamente.',
            icon: 'success',
            buttons: false,
            timer: 1600
          });
        }, (error) =>
          swal('¡Lo sentimos!', error.error?.message || ERROR_MESSAGES.DEFAULT, 'error'));
      } else if (isConfirm === false) {
        modalUtils.open(this.modalPosponer, { backdrop: false, ignoreBackdropClick: true });
      }
    });
  }
  public posponer() {
    swal({
      title: "Se pospondrá el cierre del período",
      text: "¿ESTÁ SEGURO DE REALIZAR ESTA ACCIÓN?",
      icon: "warning",
      buttons: {
        cancel: { text: "NO", value: false, visible: true, className: "", closeModal: true },
        confirm: { text: "SÍ", value: true, visible: true, className: "success", closeModal: false }
      },
      closeOnClickOutside: false,
      closeOnEsc: false
    }).then((isConfirm) => {
      if (isConfirm) {
        let time = this.horaPosponer.split(":");
        let milliseconds = ((+time[0])*1000*60*60)+((+time[1])*1000*60)+300000;
        this.horaCierre = moment.utc(milliseconds).format('HH:mm');
        this.detalleHonorariosService.posponerCierre('"' + this.horaCierre + '"').subscribe(response => {
          this.setCierrePeriodoTimeOut(this.horaCierre);
          swal({
            title: 'Pospuesto',
            text: 'El cierre del período se pospuso exitosamente.',
            icon: 'success',
            buttons: false,
            timer: 1600
          });
          this.modalPosponer.hide();
        }, (error) =>
          swal('¡Lo sentimos!', error.error?.message || ERROR_MESSAGES.DEFAULT, 'error'));
      }
    });
  }

  public resumenTareas() {
    let fecha = moment().format("YYYY-MM-DD").toString();
    this.controlHorasService.resumenTareas(fecha).subscribe((response: ControlHorasResumen) => {
      this.resumen.objetivoALaFecha = this.minutosAHorasMinutos(parseInt(response?.objetivoALaFecha)) || "-";
      this.resumen.totalTarea = this.minutosAHorasMinutos(this.controlHoras?.totalMinutos) || "-";
      this.resumen.objetivoMensual = this.minutosAHorasMinutos(parseInt(response?.objetivoMensual)) || "-";
      let diferenciaMes: number = parseInt(response?.totalMensual) - parseInt(response?.objetivoALaFecha);
      this.resumen.diferenciaMes = this.minutosAHorasMinutos(diferenciaMes) ? "-" + this.minutosAHorasMinutos(diferenciaMes) : "-";
      this.resumen.totalDiario = this.minutosAHorasMinutos(parseInt(response?.totalDiario)) || "-";
      this.resumen.totalMensual = this.minutosAHorasMinutos(parseInt(response?.totalMensual)) || "-";
      let diferenciaTotal: number = parseInt(response?.objetivoMensual) - parseInt(response?.totalMensual);
      this.resumen.diferenciaTotal = this.minutosAHorasMinutos(diferenciaTotal) ? "-" + this.minutosAHorasMinutos(diferenciaTotal) : "-";
    });
  }

  public seleccionDeHabilitados($event) {
    if ($event[0]?.value)
      this.filtroHabilitado = $event[0].value;
    else
      this.filtroHabilitado = HABILITADO.TODOS;
    this.findClientes().subscribe();
  }

  // ngAfterViewInit(): void {
  //   this.scrWidth = window.innerWidth;
  // }

  @HostListener("window:beforeunload", ["$event"])
  beforeUnloadHandler(e: Event) {
    if (this.controlHoras?.id) {
      e.preventDefault();
      return (e.returnValue as any) = "terminar tareas?";
    }
  }

  // @HostListener("window:unload", ["$event"])
  // unloadHandler(e: Event) {
  // Termina tarea al cerrar la ventana del navegardor y darle abandonar sitio.
  // if (this.controlHoras.id)
  //   navigator.sendBeacon(`${environment.apiEndpoint}/controlhoras/${this.controlHoras.id}`);
  // }

  public onResize(event) {
    this.scrWidth = event.target.innerWidth;
  }

  public personalAreas() {
    this.personalService.findPersonalArea({}).subscribe((response: Array<IdDescripcionCod>) => {
      this.areaPersonal = response[0]?.codigo;
    });
  }

  public tiposTarea(): Observable<any> {
    return this.controlHorasService.tiposTarea().pipe(
      tap((response: Array<IdDescripcionCod>) => {
        this.areas = response;
        this.listadoAreas = response.map(a => {
          return { id: a.codigo, text: a.descripcion };
        });
      }));
  }

  public findListadoTareas(): Observable<Array<IdDescripcion>> {
    switch (this.tipoArea) {
      case "02": {
        return this.findAuditoria(this.cliente);
      }
      case "04": {
        return this.findOutsourcing(this.cliente);
      }
      case "05": {
        return this.findRecupero(this.cliente);
      }
      case "06": {
        return this.findInspecciones(this.cliente);
      }
      case "07": {
        return this.findTareas(this.cliente);
      }
      default:
        return of(this.tareas = null);
    }
  }

  public findOutsourcing(value: number) {
    return forkJoin([
      this.outsourcingService.findAll({
        clienteId: value,
        estado: "ABIERTO",
        orderField: "CAU_FEC_CIERRE",
        orderCriteria: "DESC"
      }),
      this.auditoriaService.findAll({
        clienteId: value,
        estado: "ABIERTO",
        orderField: "CAU_FEC_CIERRE",
        orderCriteria: "DESC"
      })
    ]).pipe(
      map(([cortesOut, cortesAud]) => {
        return cortesOut.content.map(c => ({
          id: c.id,
          fecha: moment(c.mes_corte, "MMMM YYYY"),
          descripcion: `CORTE MENSUAL - ${c.mes_corte.toUpperCase()}`
        })).concat(
          cortesAud.content.map(c => ({
            id: c.id,
            fecha: moment(c.fecha_cierre),
            descripcion: ` ${c?.tipo_cierre.replace("_", " ")} - ${moment(c?.fecha_cierre).format("MMMM YYYY").toUpperCase()}`
          })
          )
        ).sort((a, b) => {
          return a.fecha.isSameOrBefore(b.fecha) ? 1 : -1;
        });
      }));
  }

  public findAuditoria(value: number) {
    return this.auditoriaService.findAll({
      clienteId: value,
      estado: "ABIERTO",
      orderField: "CAU_FEC_CIERRE",
      orderCriteria: "DESC"
    }).pipe(
      map((response: Page<CortesAuditoria>) => {
        return response.content.map(c => ({
          id: c.id, descripcion: `${c?.tipo_cierre.replace("_", " ")} - ${moment(c?.fecha_cierre).format("MMMM YYYY").toUpperCase()}`
        }));
      })
    );
  }

  public findInspecciones(value: number) {
    return this.inspeccionesService.findAll({
      clienteId: value,
      estado: "PENDIENTE",
      orderField: "fecha",
      orderCriteria: "DESC"
    }).pipe(
      map((response: Page<IInspecciones>) => {
        return response.content.map(c => ({
          id: c.id, descripcion: `Organismo: ${c?.organismo?.descripcion} - Fecha de inspección: ${moment(c?.fecha).format("DD/MM/YYYY")}`
        }));
      })
    );
  }

  public findTareas(value: number) {
    return this.tareasService.findAll({
      clienteId: value,
      estado: "PENDIENTE",
      orderField: "fechaVencimiento",
      orderCriteria: "DESC"
    }).pipe(
      map((response: Page<ITarea>) => {
        return response.content.map(c => ({
          id: c.id, descripcion: `Descripción: ${c?.descripcion.replace("_", " ")}`
        }));
      })
    );
  }

  public findRecupero(value: number) {
    return this.recuperoService.findAll({
      clienteId: value,
      estado: "ABIERTO",
      orderField: "periodo",
      orderCriteria: "DESC"
    }).pipe(
      map((response: Page<Recupero>) => {
        return response.content.map(c => ({
          id: c.id, descripcion: `Periodo: ${c?.periodo}`
        }));
      })
    );
  }

  public habilitaCliente(value: string) {
    this.formHorario.controls.cliente.patchValue(null);
    this.formHorario.controls.requiereFondos.patchValue(null);
    this.formHorario.controls.observaciones.patchValue(null);
    this.formHorario.controls.area.enable();
    this.formHorario.controls.requiereFondos.enable();
    this.formHorario.controls.cliente.disable();
    this.tareas = null;
    this.cliente = null;
    switch (value) {
      case "CLIENTE":
        this.formHorario.controls.cliente.enable();
        this.formHorario.controls.cliente.setValidators(Validators.required);
        this.formHorario.controls.area.patchValue(this.areaPersonal);
        this.buscaCortes(this.areaPersonal, "area");
        break;
      case "PARTICULAR":
        this.formHorario.controls.cliente.disable();
        this.formHorario.controls.cliente.clearValidators();
        this.formHorario.controls.area.patchValue(null);
        this.formHorario.controls.area.disable();
        this.formHorario.controls.requiereFondos.disable();
        this.tareas = null;
        break;
      case "CAPACITACION":
        this.formHorario.controls.cliente.disable();
        this.formHorario.controls.cliente.clearValidators();
        this.formHorario.controls.area.patchValue(null);
        this.formHorario.controls.area.disable();
        this.formHorario.controls.requiereFondos.disable();
        this.tareas = null;
        break;
      case "TGA":
        this.formHorario.controls.cliente.disable();
        this.formHorario.controls.cliente.clearValidators();
        this.formHorario.controls.area.patchValue("99");
        this.observaciones.nativeElement.focus();
        this.tareas = null;
        break;
      default:
        this.formHorario.controls.cliente.disable();
        this.formHorario.controls.cliente.setValidators(Validators.required);
        this.tareas = null;
        break;
    }
    for (const key in this.formHorario.controls) {
      this.formHorario.get(key).updateValueAndValidity();
    }
  }

  public changeValue(key: string) {
    switch (key) {
      case "observaciones":
        if (this.formHorario.controls.observaciones.value)
          this.formHorario.controls.requiereFondos.clearValidators();
        else
          this.formHorario.controls.requiereFondos.setValidators(Validators.required);
        break;
      case "requiereFondos":
        if (this.formHorario.controls.requiereFondos.value) {
          let descripcion = this.tareas.find(t => t.id == this.formHorario.controls.requiereFondos.value)?.descripcion;
          this.formHorario.controls.observaciones.patchValue(this.formHorario.controls.cliente.value ? this.clientes.find(a => a.id === this.formHorario.controls.cliente.value).nombre + " - " + descripcion : descripcion);
        }
        break;
      default:
        this.formHorario.controls.requiereFondos.setValidators(Validators.required);
        break;
    }
    this.formHorario.controls.observaciones.updateValueAndValidity();
    this.formHorario.controls.requiereFondos.updateValueAndValidity();
  }

  public buscaCortes(value, key: string) {
    this.areas = null;
    this.formHorario.controls.requiereFondos.patchValue(null);
    if (!this.controlHoras?.id)
      this.formHorario.controls.observaciones.patchValue(null);
    key === "cliente" ? this.cliente = value : this.tipoArea = value;
    if (this.cliente && this.tipoArea) {
      if ((this.tipoArea === "01") || (this.tipoArea === "03"))
        this.formHorario.controls.observaciones.patchValue(this.clientes.find(a => a.id === this.formHorario.controls.cliente.value).nombre);
      else
        this.formHorario.controls.observaciones.patchValue(null);
      this.findListadoTareas().subscribe((response: Array<IdDescripcion>) => {
        this.tareas = response;
      });
    } else {
      this.formHorario.controls.observaciones.patchValue(null);
      this.tareas = null;
    }
  }

  public findClientes(): Observable<any> {
    return this.clienteBaseService.findAll({ habilitado: this.filtroHabilitado }).pipe(
      tap((response: Page<ClienteLite>) => {
        this.clientes = response.content;
        this.listadoClientes = this.clientes.map(p => {
          return { id: p.id, text: `${p.cuit} - ${p.nombre}` };
        });
      }));
  }

  public listadoActividad() {
    this.listadoDeActividades = [];
    let date: string = moment().format("YYYY-MM-DD").toString();
    this.controlHorasService.actividades(date).subscribe((response: Array<IControlHoras>) => {
      this.listadoDeActividades = response.sort((a, b) => {
        if (a.horaDesde > b.horaDesde) {
          return 1;
        }
        if (a.horaDesde < b.horaDesde) {
          return -1;
        }
        return 0;
      });
    });
  }

  public minutosAHorasMinutos(totalMinutos: number): string {
    if (totalMinutos > 0) {
      let horas = Math.floor(totalMinutos / 60);
      let minutos = totalMinutos % 60;
      let zero = "0";
      return `${horas < 10 ? zero : ""}${horas}: ${minutos < 10 ? zero : ""}${minutos}`;
    } else {
      return null;
    }
  }

  public closeModal(): void {
    modalUtils.hide(this.modalControlHorario);
  }

  public openModal(): void {
    this.formHorario.reset();
    modalUtils.open(this.modalControlHorario, { backdrop: false, ignoreBackdropClick: true });
    this.tareaEnCurso();
  }

  public openCargaManual() {
    this.closeModal();
    this.router.navigate(["/cargaManual"]);
  }
  public clockMode() {
    this.resumenTareas();
    this.listadoActividad();
    if (this.taskStatus === null)
      return;
    this.openModal();
    for (let c in this.formHorario.controls) {
      this.formHorario.controls[c].markAsUntouched();
      this.formHorario.controls[c].markAsPristine();
    }
    if (this.taskStatus) {
      this.tareas = null;
    }
  }

  public valueRequired(value: string) {
    return this.formHorario.controls[value].hasError("required") && (this.formHorario.controls[value].dirty || this.formHorario.controls[value].touched);
  }

  public newControlHoras(): IControlHoras {
    return {
      id: null,
      tareaId: null,
      motivoRegistro: null,
      fecha: null,
      horaDesde: null,
      horaHasta: null,
      totalMinutos: null,
      observaciones: null,
      cargaManual: false,
      cliente: {
        id: null,
        nombre: null
      },
      personal: {
        id: null,
        codigo: null,
        nombre: null,
        email: null
      },
      tipoTarea: null
    };
  }

  public submit($event, value): void {
    $event.preventDefault();
    for (let c in this.formHorario.controls) {
      this.formHorario.controls[c].markAsTouched();
    }
    if (!this.taskStatus) {
      this.finishTask();
      return;
    }

    if (this.formHorario.invalid)
      return;

    if (this.controlHoras?.id)
      this.controlHoras = this.newControlHoras();

    this.controlHoras.observaciones = value.observaciones || null;
    this.controlHoras.cliente.id = value.cliente;
    this.controlHoras.tipoTarea = value.area;
    this.controlHoras.tareaId = parseInt(value.requiereFondos);
    this.controlHoras.motivoRegistro = value.motivoRegistro || null;
    this.controlHorasService.crearTarea(this.controlHoras).subscribe((response: IControlHoras) => {

      this.modalControlHorario.hide();
      this.controlHoras = response;

      this.taskStatus = false
      this.closeModal()
      this.startTask()

      swal({
        title: "Iniciar tarea",
        text: "La tarea se inicio exitosamente.",
        icon: "success",
        buttons: false,
        timer: 800
      });
    },
      error => swal("¡Lo sentimos!", error?.error?.message || ERROR_MESSAGES.DEFAULT, "error")
    );
  }

  public tareaEnCurso() {
    this.controlHorasService.tareaEnCurso().subscribe((response: IControlHoras) => {
      this.controlHoras = response;
      this.taskStatus = response ? false : true;
      if (!this.taskStatus) {
        this.startTask();
        this.formHorario.controls.motivoRegistro.patchValue(response.motivoRegistro);
        this.formHorario.controls.cliente.patchValue(response.cliente.id);
        this.buscaCortes(response.cliente.id, "cliente");
        this.formHorario.controls.area.patchValue(response.tipoTarea);
        this.buscaCortes(response.tipoTarea, "area");
        this.formHorario.controls.requiereFondos.patchValue(response.tareaId.toString());
        this.formHorario.controls.observaciones.patchValue(response.observaciones);
        this.formHorario.disable();
      } else {
        this.controlHoras = this.newControlHoras();
      }
    });
  }

  public startTask() {
    let self = this;

    if (self.interval)
      return;

    self.interval = setInterval(() => {
      self.controlTimeout();
    }, self.TIME);
  }

  public async controlTimeout() {

    this.play();

    let confirm = await swal({
      title: "Lleva 30 minutos en la misma tarea, ¿sigue trabajando en ella?",
      icon: "warning",
      buttons: {
        cancel: { text: "Continuar", value: true, visible: true, className: "", closeModal: true },
        danger: { text: "Terminar", value: false, visible: true, className: "", closeModal: true }
      }
    });

    if (!confirm)
      this.terminarTarea();
  }

  public async finishTask() {
    let confirm = await swal({
      title: "Está terminando una tarea",
      text: "¿Está seguro que desea terminar la tarea?",
      icon: "warning",
      buttons: {
        cancel: { text: "Cancelar", value: null, visible: true, className: "", closeModal: true },
        danger: { text: "Terminar", value: true, visible: true, className: "", closeModal: true }
      }
    });
    if (confirm)
      this.terminarTarea();
  }

  public async terminarTarea() {

    if (!this.controlHoras?.id)
      return;

    this.controlHorasService.detenerTarea(this.controlHoras?.id).subscribe((response: IControlHoras) => {

      this.listadoActividad();
      this.controlHoras = this.newControlHoras();
      swal({
        title: "Terminada",
        text: "La tarea se terminó exitosamente.",
        icon: "success",
        buttons: false,
        timer: 1600
      });
      this.resumenTareas();
      this.taskStatus = true;
      this.formHorario.enable();
      this.formHorario.reset();
      this.clearInterval();
      this.openModal();
    }, error => {
      swal("Lo sentimos!", error?.error?.message || this.defaultErrorMessage, "error");
    });
  }

  public clearInterval() {
    if (!this.interval)
      return;

    clearInterval(this.interval);
    this.interval = null;
  }

  ngOnDestroy(): void {
    this.clearInterval();
    if (this.intervalCierrePeriodoFacturacion) {
      clearTimeout(this.intervalCierrePeriodoFacturacion);
      this.intervalCierrePeriodoFacturacion = null;
    }
  }
}
