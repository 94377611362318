import { Routes } from "@angular/router";
import { LayoutComponent } from "../layout/layout.component";

import { LoginComponent } from "./users/login/login.component";
import { RegisterComponent } from "./users/register/register.component";
import { RecoverComponent } from "./template/pages/recover/recover.component";
import { LockComponent } from "./template/pages/lock/lock.component";
import { MaintenanceComponent } from "./template/pages/maintenance/maintenance.component";
import { Error404Component } from "./template/pages/error404/error404.component";
import { Error500Component } from "./template/pages/error500/error500.component";
import { AuthGuard } from "../../guards/auth.guard";
import { Authorities } from "src/app/enums/authorities";

export const routes: Routes = [

  {
    path: "",
    component: LayoutComponent,
    children: [
      { path: "", pathMatch: "full", redirectTo: "home" },
      { path: "", redirectTo: "home", pathMatch: "full" },
      { path: "dashboard", loadChildren: () => import("./template/dashboard/dashboard.module").then(m => m.DashboardModule) },
      { path: "widgets", loadChildren: () => import("./template/widgets/widgets.module").then(m => m.WidgetsModule) },
      { path: "material", loadChildren: () => import("./template/material/material.module").then(m => m.MaterialModule) },
      { path: "elements", loadChildren: () => import("./template/elements/elements.module").then(m => m.ElementsModule) },
      { path: "forms", loadChildren: () => import("./template/forms/forms.module").then(m => m.FormsModule) },
      { path: "charts", loadChildren: () => import("./template/charts/charts.module").then(m => m.ChartsModule) },
      { path: "tables", loadChildren: () => import("./template/tables/tables.module").then(m => m.TablesModule) },
      { path: "maps", loadChildren: () => import("./template/maps/maps.module").then(m => m.MapsModule) },
      { path: "blog", loadChildren: () => import("./template/blog/blog.module").then(m => m.BlogModule) },
      { path: "ecommerce", loadChildren: () => import("./template/ecommerce/ecommerce.module").then(m => m.EcommerceModule) },
      { path: "extras", loadChildren: () => import("./template/extras/extras.module").then(m => m.ExtrasModule) },

      {
        path: "home",
        loadChildren: () => import("./home/home.module").then(m => m.HomeModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: { authorities: [] }
      },
      {
        path: "laboral",
        loadChildren: () => import("./laboral/laboral.module").then(m => m.LaboralModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: {
          authorities: []
        }
      },
      {
        path: "outsourcing",
        loadChildren: () => import("./outsourcing/outsourcing.module").then(m => m.OutsourcingModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: { authorities: [
        ] }
      },
      {
        path: "vencimientos",
        loadChildren: () => import("./vencimientos/vencimientos.module").then(m => m.VencimientosModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: {
          authorities: []
        }
      },
      {
        path: "auditoria",
        loadChildren: () => import("./auditoria/auditoria.module").then(m => m.AuditoriaModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: { authorities: [] }
      },
      {
        path: "recuperos",
        loadChildren: () => import("./recuperos/recuperos.module").then(m => m.RecuperosModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: { authorities: [] }
      },
      {
        path: "abmObligaciones",
        loadChildren: () => import("./obligaciones/obligaciones.module").then(m => m.ObligacionesModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: { authorities: [] }
      },
      {
        path: "plantillaVencimientos",
        loadChildren: () => import("./plantilla/plantilla.module").then(m => m.PlantillaModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: { authorities: [
          Authorities.gerente,
          Authorities.laboral_asistente,
          Authorities.laboral_auxiliar,
          Authorities.laboral_responsable,
          Authorities.laboral_supervisor,
          Authorities.impuestos_asistente,
          Authorities.impuestos_auxiliar,
          Authorities.impuestos_responsable,
          Authorities.impuestos_supervisor,
        ] }
      },
      {
        path: "abmOrganismos",
        loadChildren: () => import("./organismos/organismos.module").then(m => m.OrganismosModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: { authorities: [] }
      },
      {
        path: "abmSubOrganismos",
        loadChildren: () => import("./suborganismos/suborganismos.module").then(m => m.SuborganismosModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: { authorities: [] }
      },
      {
        path: "abmTiposDeObligaciones",
        loadChildren: () => import("./tipos-de-obligaciones/tipos-de-obligaciones.module").then(m => m.TiposDeObligacionesModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: { authorities: [] }
      },
      {
        path: "abmRegimenesFiscales",
        loadChildren: () => import("./regimenes-fiscales/regimenes-fiscales.module").then(m => m.RegimenesFiscalesModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: { authorities: [] }
      },
      {
        path: "abmObligacionesACumplir",
        loadChildren: () => import("./obligaciones-a-cumplir/obligaciones-a-cumplir.module").then(m => m.ObligacionesACumplirModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: { authorities: [] }
      },
      {
        path: "abmTiposRegimenFiscal",
        loadChildren: () => import("./tipos-regimen-fiscal/tipos-regimen-fiscal.module").then(m => m.TiposRegimenFiscalModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: { authorities: [] }
      },
      {
        path: "abmTiposDeEmpresa",
        loadChildren: () => import("./tipos-de-empresa/tipos-de-empresa.module").then(m => m.TiposDeEmpresaModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: { authorities: [] }
      },
      {
        path: "grupoEconomico",
        loadChildren: () => import("./grupo-economico/grupo-economico.module").then(m => m.GrupoEconomicoModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: { authorities: [] }
      },
      {
        path: "abmContactos",
        loadChildren: () => import("./contactos/contactos.module").then(m => m.ContactosModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: { authorities: [] }
      },
      {
        path: "abmProgramacionAutomatica",
        loadChildren: () => import("./programacion-autommatica/programacion-autommatica.module").then(m => m.ProgramacionAutommaticaModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: {
          authorities: [
            Authorities.gerente,
            Authorities.laboral_asistente,
            Authorities.laboral_auxiliar,
            Authorities.laboral_responsable,
            Authorities.laboral_supervisor,
            Authorities.impuestos_asistente,
            Authorities.impuestos_auxiliar,
            Authorities.impuestos_responsable,
            Authorities.impuestos_supervisor,
          ]
        }
      },
      {
        path: "abmEscalasPymes",
        loadChildren: () => import("./escalas-pymes/escalas-pymes.module").then(m => m.EscalasPymesModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: { authorities: [] }
      },
      {
        path: "abmCuitBancos",
        loadChildren: () => import("./cuit-bancos/cuit-bancos.module").then(m => m.CuitBancosModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: { authorities: [] }
      },
      {
        path: "abmTiposSociedades",
        loadChildren: () => import("./tipos-sociedades/tipos-sociedades.module").then(m => m.TiposSociedadesModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: { authorities: [] }
      },
      {
        path: "abmCuitPaises",
        loadChildren: () => import("./cuit-paises/cuit-paises.module").then(m => m.CuitPaisesModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: { authorities: [] }
      },
      {
        path: "abmTiposSociedades",
        loadChildren: () => import("./tipos-sociedades/tipos-sociedades.module").then(m => m.TiposSociedadesModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: { authorities: [] }
      },
      {
        path: "abmActividadesRentas",
        loadChildren: () => import("./actividades-rentas/actividades-rentas.module").then(m => m.ActividadesRentasModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: { authorities: [] }
      },
      {
        path: "abmActividadesAfip",
        loadChildren: () => import("./actividades-afip/actividades-afip.module").then(m => m.ActafipModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: { authorities: [] }
      },
      {
        path: "abmTiposLibros",
        loadChildren: () => import("./tipos-libros/tipos-libros.module").then(m => m.TiposLibrosModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: { authorities: [] }
      },
      {
        path: "listarClientes",
        loadChildren: () => import("./clientes/clientes.module").then(m => m.ClientesModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: { authorities: [
          // Authorities.gerente,
          // Authorities.administracion_supervisor,
          // Authorities.administracion_responsable,
          // Authorities.administracion_auxiliar,
          // Authorities.administracion_asistente,
    
          // Authorities.auditoria_supervisor,
          // Authorities.auditoria_responsable,
          // Authorities.auditoria_asistente,
          // Authorities.auditoria_auxiliar,
    
          // Authorities.impuestos_supervisor,
          // Authorities.impuestos_responsable,
          // Authorities.impuestos_asistente,
          // Authorities.impuestos_auxiliar,
    
          // Authorities.laboral_supervisor,
          // Authorities.laboral_responsable,
          // Authorities.laboral_asistente,
          // Authorities.laboral_auxiliar,
    
          // Authorities.outsourcing_supervisor,
          // Authorities.outsourcing_responsable,
          // Authorities.outsourcing_asistente,
          // Authorities.outsourcing_auxiliar,
    
          // Authorities.recursos_humanos_supervisor,
          // Authorities.recursos_humanos_responsable,
          // Authorities.recursos_humanos_asistente,
          // Authorities.recursos_humanos_auxiliar,
    
          // Authorities.societario_supervisor,
          // Authorities.societario_responsable,
          // Authorities.societario_asistente,
          // Authorities.societario_auxiliar
        ] }
      },
      {
        path: "abmMonotributistas",
        loadChildren: () => import("./monotributistas/monotributistas.module").then(m => m.MonotributistasModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: { authorities: [] }
      },
      {
        path: "abmMonedas",
        loadChildren: () => import("./monedas/monedas.module").then(m => m.MonedasModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: { authorities: [] }
      },
      {
        path: "abmCotizaciones",
        loadChildren: () => import("./cotizaciones/cotizaciones.module").then(m => m.CotizacionesModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: { authorities: [] }
      },
      {
        path: "abmTiposDeContrato",
        loadChildren: () => import("./tipos-contrato/tipos-contrato.module").then(m => m.TiposContratoModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: { authorities: [] }
      },
      {
        path: "tareas",
        loadChildren: () => import("./tareas/tareas.module").then(m => m.TareasModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: { authorities: [] }
      },
      {
        path: "cargaManual",
        loadChildren: () => import("./control-horario/control-horario.module").then(m => m.ControlHorarioModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: { authorities: [] }
      },
      {
        path: "documentosAArchivar",
        loadChildren: () => import("./documentos-a-archivar/documentos-a-archivar.module").then(m => m.DocumentosAArchivarModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: { authorities: [] }
      },
      {
        path: "feriados",
        loadChildren: () => import("./feriados/feriados.module").then(m => m.FeriadosModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: { authorities: [] }
      },
      {
        path: "categoriasDePersonal",
        loadChildren: () => import("./categorias-de-personal/categorias-de-personal.module").then(m => m.CategoriasDePersonalModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: { authorities: [] }
      },
      {
        path: "obrasSociales",
        loadChildren: () => import("./obras-sociales/obras-sociales.module").then(m => m.ObrasSocialesModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: { authorities: [] }
      },
      {
        path: "vacaciones",
        loadChildren: () => import("./vacaciones/vacaciones.module").then(m => m.VacacionesModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: { authorities: [] }
      },
      {
        path: "personal",
        loadChildren: () => import("./personal/personal.module").then(m => m.PersonalModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: { authorities: [] }
      },
      {
        path: "configuracion-usuarios",
        loadChildren: () => import("./configuracion-usuarios/configuracion-usuarios.module").then(m => m.ConfiguracionUsuariosModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: { authorities: [] }
      },
      {
        path: "depositos",
        loadChildren: () => import("./depositos/depositos.module").then(m => m.DepositosModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: { authorities: [] }
      },
      {
        path: "modulo-clientes",
        loadChildren: () => import("./modulo-clientes/modulo-clientes.module").then(m => m.ModuloClientesModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: { authorities: [] }
      },
      {
        path: "modulo-outsourcing",
        loadChildren: () => import("./modulo-outsourcing/modulo-outsourcing.module").then(m => m.ModuloOutsourcingModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: { authorities: [] }
      },
      {
        path: "modulo-rrhh",
        loadChildren: () => import("./modulo-rrhh/modulo-rrhh.module").then(m => m.ModuloRrhhModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: { authorities: [] }
      },
      {
        path: "modulo-impuestos",
        loadChildren: () => import("./modulo-impuestos/modulo-impuestos.module").then(m => m.ModuloImpuestosModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: { authorities: [] }
      },
      {
        path: "modulo-laboral",
        loadChildren: () => import("./modulo-laboral/modulo-laboral.module").then(m => m.ModuloLaboralModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: { authorities: [] }
      },
      {
        path: "modulo-legal-societario",
        loadChildren: () => import("./modulo-legal-societario/modulo-legal-societario.module").then(m => m.ModuloLegalSocietarioModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: { authorities: [] }
      },
      {
        path: "modulo-presupuesto",
        loadChildren: () => import("./modulo-presupuesto/modulo-presupuesto.module").then(m => m.ModuloPresupuestoModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: { authorities: [] }
      },{
        path: "funcionalidades-generales",
        loadChildren: () => import("./funcionalidades-generales/funcionalidades-generales.module").then(m => m.FuncionalidadesGeneralesModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: { authorities: [] }
      },
      {
        path: "modulo-cronogramas",
        loadChildren: () => import("./modulo-cronogramas/modulo-cronogramas.module").then(m => m.ModuloCronogramasModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: { authorities: [] }
      },
      {
        path: "modulo-comite",
        loadChildren: () => import("./modulo-comite/modulo-comite.module").then(m => m.ModuloComiteModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: { authorities: [] }
      },
      {
        path: "modulo-tga",
        loadChildren: () => import("./modulo-tga/modulo-tga.module").then(m => m.ModuloTgaModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: { authorities: [] }
      },
      {
        path: "modulo-fondos",
        loadChildren: () => import("./modulo-fondos/modulo-fondos.module").then(m => m.ModuloFondosModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: { authorities: [] }
      }
    ]
  },

  // Not lazy-loaded routes
  { path: "login", component: LoginComponent },
  { path: "register", component: RegisterComponent },
  { path: "recover", component: RecoverComponent },
  { path: "lock", component: LockComponent },
  { path: "maintenance", component: MaintenanceComponent },
  { path: "404", component: Error404Component },
  { path: "500", component: Error500Component },

  // Not found
  { path: "", pathMatch: "full", redirectTo: "home" },
  { path: "**", redirectTo: "home" }

];
