import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { environment } from 'src/environments/environment';
import { Organismo } from '../interfaces/organismo';
import { Page } from '../interfaces/page';

@Injectable({
  providedIn: 'root'
})
export class OrganismoService {

  constructor(private http: HttpClient) { }


  public findOrganismos(): Observable<Page<Organismo>> {
    let endpoint = '/organismos';
    return this.http.get<Page<Organismo>>(environment.apiEndpoint + endpoint);
  }
  public create(organismo: Organismo) {
    let endpoint = '/organismos';
    return this.http.post(environment.apiEndpoint + endpoint, organismo);
  }
  public edit(organismo: Organismo) {
    let endpoint = '/organismos';
    return this.http.put(environment.apiEndpoint + endpoint, organismo);
  }


}
