import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Accionista, ClienteDatosSocietarios, Libro, MiembroAdministrativo, Modificacion, Movimiento, Resolucion, Socio } from "src/app/interfaces/cliente-datos-societarios";
import { Page } from "src/app/interfaces/page";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class DatosSocietariosService {

  constructor(private http: HttpClient) { }

  public findDatosGenerales(idCliente: number): Observable<ClienteDatosSocietarios> {
    return this.http.get<ClienteDatosSocietarios>(`${environment.apiEndpoint}/clientes/` + idCliente + "/datosSocietarios");
  }
  public create(cliente: ClienteDatosSocietarios, idCliente: number) {
    return this.http.post(`${environment.apiEndpoint}/clientes/` + idCliente + "/datosSocietarios", cliente);
  }
  public edit(cliente: ClienteDatosSocietarios, idCliente: number) {
    return this.http.put(`${environment.apiEndpoint}/clientes/` + idCliente + "/datosSocietarios", cliente);
  }
  public findModificaciones(idCliente: number): Observable<Page<Modificacion>> {
    return this.http.get<Page<Modificacion>>(`${environment.apiEndpoint}/clientes/` + idCliente + "/contratoConstitutivo");
  }
  public createModificacionContrato(modificacion: Modificacion, idCliente: number) {
    return this.http.post(`${environment.apiEndpoint}/clientes/` + idCliente + "/contratoConstitutivo", modificacion);
  }
  public editModificacionContrato(modificacion: Modificacion, idCliente: number) {
    return this.http.put(`${environment.apiEndpoint}/clientes/` + idCliente + "/contratoConstitutivo/" + modificacion.id, modificacion);
  }
  public deleteModificacionContrato(modificacion: Modificacion, idCliente: number) {
    return this.http.delete(`${environment.apiEndpoint}/clientes/` + idCliente + "/contratoConstitutivo/" + modificacion.id);
  }
  public findSocios(idCliente: number): Observable<Page<Socio>> {
    return this.http.get<Page<Socio>>(`${environment.apiEndpoint}/clientes/` + idCliente + "/socios");
  }
  public findSocioById(idCliente: number, idSocio): Observable<Socio> {
    return this.http.get<Socio>(`${environment.apiEndpoint}/clientes/` + idCliente + "/socios/" + idSocio);
  }
  public createSocio(socio: Socio, idCliente: number) {
    return this.http.post(`${environment.apiEndpoint}/clientes/` + idCliente + "/socios", socio);
  }
  public editSocio(socio: Socio, idCliente: number) {
    return this.http.put(`${environment.apiEndpoint}/clientes/` + idCliente + "/socios/" + socio.id, socio);
  }
  public deleteSocio(socio: Socio, idCliente: number) {
    return this.http.delete(`${environment.apiEndpoint}/clientes/` + idCliente + "/socios/" + socio.id);
  }
  public findAccionistas(idCliente: number): Observable<Array<Accionista>> {
    return this.http.get<Array<Accionista>>(`${environment.apiEndpoint}/clientes/` + idCliente + "/sociosAcciones/accionistas");
  }
  public findVariaciones(idCliente: number): Observable<Page<Accionista>> {
    return this.http.get<Page<Accionista>>(`${environment.apiEndpoint}/clientes/` + idCliente + "/sociosAcciones");
  }
  public findSociosAccionesTotal(idCliente: number): Observable<number> {
    return this.http.get<number>(`${environment.apiEndpoint}/clientes/` + idCliente + "/sociosAcciones/sociosAccionesTotal");
  }
  public createVariacion(variacion: Accionista, idCliente: number) {
    return this.http.post(`${environment.apiEndpoint}/clientes/` + idCliente + "/sociosAcciones", variacion);
  }
  public deleteVariacion(variacion: Accionista, idCliente: number, forzar=false) {
    return this.http.delete(`${environment.apiEndpoint}/clientes/` + idCliente + "/sociosAcciones/" + variacion.id + `?forzar=${forzar}`);
  }
  public findResoluciones(idCliente: number, clase: string): Observable<Page<Resolucion>> {
    return this.http.get<Page<Resolucion>>(`${environment.apiEndpoint}/clientes/${idCliente}/resoluciones?clase=${clase}`);
  }
  public createResolucion(resolucion: Resolucion, idCliente: number) {
    return this.http.post(`${environment.apiEndpoint}/clientes/` + idCliente + "/resoluciones", resolucion);
  }
  public editResolucion(resolucion: Resolucion, idCliente: number) {
    return this.http.put(`${environment.apiEndpoint}/clientes/` + idCliente + "/resoluciones/" + resolucion.id, resolucion);
  }
  public deleteResolucion(resolucion: Resolucion, idCliente: number) {
    return this.http.delete(`${environment.apiEndpoint}/clientes/` + idCliente + "/resoluciones/" + resolucion.id);
  }
  public findMiembros(idCliente: number, clase: string): Observable<Page<MiembroAdministrativo>> {
    return this.http.get<Page<MiembroAdministrativo>>(`${environment.apiEndpoint}/clientes/${idCliente}/miembros?clase=${clase}`);
  }
  public createMiembro(miembro: MiembroAdministrativo, idCliente: number) {
    return this.http.post(`${environment.apiEndpoint}/clientes/${idCliente}/miembros`, miembro);
  }
  public editMiembro(miembro: MiembroAdministrativo, idCliente: number) {
    return this.http.put(`${environment.apiEndpoint}/clientes/${idCliente}/miembros/${miembro.id}`, miembro);
  }
  public deleteMiembro(miembro: MiembroAdministrativo, idCliente: number) {
    return this.http.delete(`${environment.apiEndpoint}/clientes/${idCliente}/miembros/${miembro.id}`);
  }
  public updateMail(resolucion: Resolucion, idCliente: number) {
    return this.http.get(`${environment.apiEndpoint}/clientes/` + idCliente + "/resoluciones/mail/" + resolucion.id);
  }

  /* ----====Libros====---- */

  public findLibros(idCliente: number): Observable<Page<Libro>> {
    return this.http.get<Page<Libro>>(`${environment.apiEndpoint}/clientes/${idCliente}/libros`);
  }
  public createLibro(libro: Libro, idCliente: number) {
    return this.http.post(`${environment.apiEndpoint}/clientes/${idCliente}/libros`, libro);
  }
  public editLibro(libro: Libro, idCliente: number) {
    return this.http.put(`${environment.apiEndpoint}/clientes/${idCliente}/libros/${libro.id}`, libro);
  }
  public deleteLibro(libro: Libro, idCliente: number) {
    return this.http.delete(`${environment.apiEndpoint}/clientes/${idCliente}/libros/${libro.id}`);
  }

  /* ----====Movimiento====---- */

  public createMovimiento(idCliente: number, libro: Libro, movimiento: Movimiento, editar = false) {
    return this.http.post(`${environment.apiEndpoint}/clientes/${idCliente}/libros/${libro.id}/librosMovimientos?editar=${editar}`, movimiento);
  }
  public editMovimiento(idCliente: number, libro: Libro, movimiento: Movimiento) {
    return this.http.put(`${environment.apiEndpoint}/clientes/${idCliente}/libros/${libro.id}/librosMovimientos`, movimiento);
  }
  public deleteMovimiento(idCliente: number, libro: Libro, movimiento: Movimiento) {
    return this.http.delete(`${environment.apiEndpoint}/clientes/${idCliente}/libros/${libro.id}/librosMovimientos/${movimiento.id}`);
  }

}
