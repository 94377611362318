import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { DocumentoBase } from "../interfaces/documento-base";
import { Observable } from "rxjs";
import { Documentacion } from "../interfaces/documentacion";
import { Documento } from "../interfaces/documento";
import { Observacion } from "../interfaces/observacion";
import { map, tap } from "rxjs/operators";
import base64Utils from "../utils/base64";

@Injectable({
  providedIn: "root"
})
export class DocumentacionService {

  constructor(private http: HttpClient) { }

  public query(params?: Map<string, string>) {
    let queryParams = "";
    if (params) {
      let paramsArray: Array<string> = [];
      params.forEach((value: string, key: string) => {
        paramsArray.push(key + "=" + value);
      });
      queryParams = "?" + paramsArray.join("&");
    }
    return queryParams;
  }
  public joinParams(params?: Map<string, string>) {
    let queryParams = "";
    if (params) {
      let paramsArray: Array<string> = [];
      params.forEach((value: string, key: string) => {
        paramsArray.push(key + "/" + value);
      });
      queryParams = "/" + paramsArray.join("/");
    }
    return queryParams;
  }
  /* rubrosAuditoria/{idRubro}/documentos */
  public listar(recurso: string, padreId: number|string, categoriaId: string, params?: Map<string, string>): Observable<Documentacion> {
    let observable: Observable<Documentacion> = null;
    if (categoriaId)
      observable = this.http.get<Documentacion>(`${environment.apiEndpoint}/${recurso}/${padreId}/documentacion/${categoriaId}/documentos${this.query(params)}`);
    else
      observable = this.http.get<Documento[]>(`${environment.apiEndpoint}/${recurso}/${padreId}/documentos`)
        .pipe(
          map((documentos) => ({
            categoria: {
              id: null,
              nombre: ""
            },
            documentos,
            selected: false
          }))
        );

    return observable.pipe(
      tap((documentacion: Documentacion) => {
        documentacion.documentos.sort((dA, dB) => {
          try {
            return dA.id > dB.id ? -1 : 1;
          } catch {
            return 0;
          }
        });
      })
    );
  }
  public findDocumento(recurso: string, padreId: number, params?: Map<string, string>, subRecurso?: string, hijoId?: number, subSubRecurso?: string, nietoId?: number): Observable<Documento> {
    let endpoint;
    if (subRecurso) {
      endpoint = `/${recurso}/${padreId}/${subRecurso}/${hijoId}`;
      if (subSubRecurso)
        endpoint += `/${subSubRecurso}/${nietoId}`;
      endpoint += `/documento${this.query(params)}`;
    } else
      endpoint = `/${recurso}/${padreId}/documento`;
    return this.http.get<Documento>(environment.apiEndpoint + endpoint);
  }

  public agregar(recurso: string, padreId: number, categoriaId: string, documento: DocumentoBase, params?: Map<string, string>, subRecurso?: string, hijoId?: number, subSubRecurso?: string, nietoId?: number) {
    let endpoint;
    if (subRecurso) {
      endpoint = `/${recurso}/${padreId}/${subRecurso}/${hijoId}`;
      if (subSubRecurso)
        endpoint += `/${subSubRecurso}/${nietoId}`;
      endpoint += `/documento${this.query(params)}`;
    } else
      if (categoriaId)
        endpoint = "/" + recurso + "/" + padreId + "/documentacion/" + categoriaId + "/documentos" + this.query(params);
      else
        endpoint = `/${recurso}/${padreId}/documentos`;
    return this.http.post(environment.apiEndpoint + endpoint, documento);
  }

  public eliminar(recurso: string, padreId: number, categoriaId: string, documento: DocumentoBase, subRecurso?: string, hijoId?: number, subSubRecurso?: string, nietoId?: number) {
    let endpoint;
    if (subRecurso) {
      endpoint = `/${recurso}/${padreId}/${subRecurso}/${hijoId}`;
      if (subSubRecurso)
        endpoint += `/${subSubRecurso}/${nietoId}`;
      endpoint += `/documento`;
    } else
      if (categoriaId)
        endpoint = "/" + recurso + "/" + padreId + "/documentacion/" + categoriaId + "/documentos/" + base64Utils.encode(documento.nombre);
      else
        endpoint = "/rubrosAuditoria/" + padreId + "/documentos/" + base64Utils.encode(documento.nombre);
    return this.http.delete(environment.apiEndpoint + endpoint);
  }

  public agregarDocumento(recurso: string, padreId: number|string, categoriaId: string|number, documento: Documento, params?: Map<string, string>): Observable<Documento> {
    let endpoint = "/" + recurso + "/" + padreId + "/documentacion/" + categoriaId + "/documentos" + this.query(params);
    return this.http.post<Documento>(environment.apiEndpoint + endpoint, documento);
  }
  public agregarCustomDocumento(recurso: string, padreId: number, nombreDocumentacion: string, categoriaId: string, documento: DocumentoBase | Documento, params?: Map<string, string>): Observable<Documento> {
    let endpoint = `/${recurso}/${padreId}/${nombreDocumentacion}/${categoriaId}${this.joinParams(params)}`;
    return this.http.put<Documento>(environment.apiEndpoint + endpoint, documento);
  }
  public getCustomDocumento(recurso: string, padreId: number, nombreDocumentacion: string, categoriaId: string, params?: Map<string, string>): Observable<any> {
    let endpoint = `/${recurso}/${padreId}/${nombreDocumentacion}/${categoriaId}${this.joinParams(params)}`;
    return this.http.get<Documento>(environment.apiEndpoint + endpoint);
  }

  public eliminarAuditoria(recurso: string, padreId: number, categoriaId: string|number, documento: Documento) {
    let endpoint = "/" + recurso + "/" + padreId + "/documentacion/" + categoriaId + "/documentos/" + base64Utils.encode(documento.nombre);
    return this.http.delete(environment.apiEndpoint + endpoint);
  }

  public agregarObservacion(recurso: string, padreId: number, categoriaId: string, documento: Documento, params?: Map<string, string>): Observable<Observacion> {
    let endpoint = "/" + recurso + "/" + padreId + "/documentacion/" + categoriaId + "/documentos" + this.query(params);
    return this.http.put<Observacion>(environment.apiEndpoint + endpoint, documento);
  }

  public setVencimientoEECC(idAuditoria: number) {
    const endpoint = `/vencimientos/${idAuditoria}/EECC`;
    return this.http.put(environment.apiEndpoint + endpoint, null);
  }

  public reverseEECC(idAuditoria: number) {
    const endpoint = `/vencimientos/${idAuditoria}/revertirEECC`;
    return this.http.put(environment.apiEndpoint + endpoint, null);
  }
}
