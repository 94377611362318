import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'badge'
})
export class BadgePipe implements PipeTransform {

  transform(value: string): string {
    if (value != null && value.length > 0)
      return value.charAt(0);
    else
      return "D";
  }

}
