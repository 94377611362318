import { Component, OnInit, Input } from '@angular/core';
import { CortesAuditoria } from "src/app/interfaces/cortes-auditoria";
import { Valorizacion, PersonaFacturacion } from "src/app/classes/valorizacion";
import { Valorizacion as IValorizacion, PersonaFacturacion as IPersonalFacturacion } from "src/app/interfaces/valorizacion";
import { AuditoriaService } from "src/app/services/auditoria.service";
import { forkJoin } from 'rxjs';
import { ERROR_MESSAGES } from 'src/app/enums/error-messages';
import { TareasService } from 'src/app/services/tareas.service';
import { TIPO_TAREA } from 'src/app/enums/tipo-tareas.enum';
import { ITareaDetalle } from 'src/app/interfaces/itarea';
import { Personal } from '../../../classes/personal';
const swal = require("sweetalert");

@Component({
  selector: 'app-detalle-de-horas',
  templateUrl: './detalle-de-horas.component.html',
  styleUrls: ['./detalle-de-horas.component.scss']
})
export class DetalleDeHorasComponent {

  @Input() set corte(value: CortesAuditoria) {
    this._corte = value;
  }

  @Input() set tipoTarea(value: TIPO_TAREA) {
    this._tipoTarea = value;
  }

  @Input() set update(value: number) {
    this.cargarDetalleHoras();
  }

  public tieneValorizacion: boolean = false;
  public _tipoTarea: TIPO_TAREA = TIPO_TAREA.AUDITORIA;
  public _corte: CortesAuditoria = null;
  public presupuesto: Valorizacion = new Valorizacion();
  public detalleTarea: Array<ITareaDetalle> = [];
  public detalleTareaXPersonal: Array<ITareaDetalle> = [];
  public tareaColapsada: any = null;

  constructor(private auditoriaService: AuditoriaService, private tareasService: TareasService) { }

  private cargarDetalleHoras(): void {
    this.reset();

    if (!this._corte)
      return;

    if ([TIPO_TAREA.AUDITORIA, TIPO_TAREA.TAREA].indexOf(this._tipoTarea) === -1) {
      console.error(`${this._tipoTarea} no es un tipo de tarea vàlido`);
    }

    forkJoin(
      this._tipoTarea === TIPO_TAREA.TAREA ? [
        this.tareasService.valorizar(this._corte),
        this.tareasService.getPersonalParticipa(this._corte.id),
        this.tareasService.findDetalleTarea(this._corte.id)
      ] : [
        this.auditoriaService.valorizar(this._corte),
        this.auditoriaService.getPersonalParticipa(this._corte.id),
        this.tareasService.findDetalleTarea(this._corte.id)
      ]
    ).subscribe(([valorizacion, personalParticipa, detalleTarea]) => {
      valorizacion as Array<IValorizacion>;
      if (!valorizacion.length)
        valorizacion[0] = {} as any;
      this.presupuesto = new Valorizacion(Object.assign(valorizacion[0], { personalFacturacion: personalParticipa }));
      this.presupuesto.setPorcentajes();
      this.detalleTarea = detalleTarea;
      this.calcularPorcantajeDetalle();
    }, error => {
      swal("¡Lo sentimos!", (error?.error?.message || ERROR_MESSAGES.DEFAULT), "error");
    });
  }

  public calcularPorcantajeDetalle(){
    this.presupuesto.personalFacturacion.forEach(x => {
      this.detalleTarea.filter(y => y.personal.id === x.personal.id).forEach(d =>{
        d.porcentaje = (d.totalMinutos * 100) / (x.horasMinutos[0] * 60 + x.horasMinutos[1]);
      });
    });
  }

  private reset(): void {
    this.presupuesto = new Valorizacion();
  }

  public colapsarTarea(personal: Personal) {
    this.tareaColapsada = JSON.parse(JSON.stringify(personal));
    this.detalleTareaXPersonal = this.detalleTarea.filter(x => x.personal.id === personal.id);
  }

  public mostrarDetalle(personal: Personal) {
    if (this.tareaColapsada?.id === personal?.id)
      this.tareaColapsada = null;
    else this.colapsarTarea(personal);
  }
}
