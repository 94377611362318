import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { IdNombre } from "../interfaces/id-nombre";
import { CantidadDocumentos, ObligacionesPorEstado, Vencimiento } from "../interfaces/vencimiento";
import { IdNombreCodigo } from "../interfaces/id-nombre-codigo";
import { Observable } from "rxjs";
import { Page } from "../interfaces/page";
import { Personal } from "../interfaces/personal";

@Injectable()
export class VencimientosService {

  constructor(private http: HttpClient) { }

  public recurso: string = "/vencimientos";

  public findAll(pageNumber: number, pageSize: number, q: string, responsableCod: string, asistente: string, subAsistente: string,
    clienteId: number, organismoId: number, obligacionId: number, periodicidad: string, fechaDesde: string, fechaHasta: string,
    estado: string, tipoCumplimiento: string, grupoEconomicoCod: string, orderField: string, orderCriteria: string,
    tipoObligacionId: number, clienteHabilitado?: string, societario?: boolean): Observable<Page<Vencimiento>> {

    let params = new URLSearchParams([]);
    if (orderField) params.append("orderField", orderField);
    if (orderCriteria) params.append("orderCriteria", orderCriteria);
    if (pageNumber) params.append("pageNumber", pageNumber.toString());
    if (pageSize) params.append("pageSize", pageSize.toString());
    if (q) params.append("q", q);
    if (responsableCod) params.append("responsableId", responsableCod);
    if (asistente) params.append("asistenteId", asistente);
    if (subAsistente) params.append("subAsistenteId", subAsistente);
    if (clienteId) params.append("clienteId", clienteId.toString());
    if (obligacionId) params.append("obligacionId", obligacionId.toString());
    if (tipoObligacionId) params.append("tipoObligacionId", tipoObligacionId.toString());
    if (organismoId) params.append("organismoId", organismoId.toString());
    if (periodicidad) params.append("periodicidad", periodicidad);
    if (fechaDesde) params.append("fechaDesde", fechaDesde);
    if (fechaHasta) params.append("fechaHasta", fechaHasta);
    if (estado) params.append("estado", estado);
    if (tipoCumplimiento) params.append("tipoCumplimiento", tipoCumplimiento);
    if (grupoEconomicoCod) params.append("grupoEconomicoCod", grupoEconomicoCod);
    if (clienteHabilitado) params.append("clienteHabilitado", clienteHabilitado);
    if (societario) params.append("societario", societario.toString());

    return this.http.get<Page<Vencimiento>>(`${environment.apiEndpoint}${this.recurso}?${params.toString()}`);
  }
  public findCantidadDocumentos(pageNumber: number, pageSize: number, q: string, responsableCod: string, asistente: string, subAsistente: string,
    clienteId: number, organismoId: number, obligacionId: number, periodicidad: string, fechaDesde: string, fechaHasta: string,
    estado: string, tipoCumplimiento: string, grupoEconomicoCod: string, orderField: string, orderCriteria: string,
    tipoObligacionId: number, clienteHabilitado?: string, societario?: boolean): Observable<Array<CantidadDocumentos>> {

    let params = new URLSearchParams([]);
    if (orderField) params.append("orderField", orderField);
    if (orderCriteria) params.append("orderCriteria", orderCriteria);
    if (pageNumber) params.append("pageNumber", pageNumber.toString());
    if (pageSize) params.append("pageSize", pageSize.toString());
    if (q) params.append("q", q);
    if (responsableCod) params.append("responsableId", responsableCod);
    if (asistente) params.append("asistenteId", asistente);
    if (subAsistente) params.append("subAsistenteId", subAsistente);
    if (clienteId) params.append("clienteId", clienteId.toString());
    if (obligacionId) params.append("obligacionId", obligacionId.toString());
    if (tipoObligacionId) params.append("tipoObligacionId", tipoObligacionId.toString());
    if (organismoId) params.append("organismoId", organismoId.toString());
    if (periodicidad) params.append("periodicidad", periodicidad);
    if (fechaDesde) params.append("fechaDesde", fechaDesde);
    if (fechaHasta) params.append("fechaHasta", fechaHasta);
    if (estado) params.append("estado", estado);
    if (tipoCumplimiento) params.append("tipoCumplimiento", tipoCumplimiento);
    if (grupoEconomicoCod) params.append("grupoEconomicoCod", grupoEconomicoCod);
    if (clienteHabilitado) params.append("clienteHabilitado", clienteHabilitado);
    if (societario) params.append("societario", societario.toString());

    return this.http.get<Array<CantidadDocumentos>>(`${environment.apiEndpoint}${this.recurso}/cantidad?${params.toString()}`);
  }
  public findObligacionesPorEstado({
    responsableId = null,
    asistenteId = null,
    subAsistenteId = null,
    clienteId = null,
    organismoId = null,
    periodicidad = null,
    tipoObligacionId = null,
    obligacionId = null,
    tipoCumplimiento = null,
    grupoEconomicoCod = null,
    fechaDesde = null,
    fechaHasta = null,
  } = {}): Observable<ObligacionesPorEstado> {
    let params = new URLSearchParams([]);
    let recurso = '/reportes/impuestos/obligacionesPorEstado'
    if (responsableId) params.append("responsableId", responsableId);
    if (asistenteId) params.append("asistenteId", asistenteId);
    if (subAsistenteId) params.append("subAsistenteId", subAsistenteId);
    if (clienteId) params.append("clienteId", clienteId);
    if (organismoId) params.append("organismoId", organismoId);
    if (periodicidad) params.append("periodicidad", periodicidad);
    if (tipoObligacionId) params.append("tipoObligacionId", tipoObligacionId);
    if (obligacionId) params.append("obligacionId", obligacionId);
    if (tipoCumplimiento) params.append("tipoCumplimiento", tipoCumplimiento);
    if (grupoEconomicoCod) params.append("grupoEconomicoCod", grupoEconomicoCod);
    if (fechaDesde) params.append("fechaDesde", fechaDesde);
    if (fechaHasta) params.append("fechaHasta", fechaHasta);
    return this.http.get<ObligacionesPorEstado>(`${environment.apiEndpoint}${recurso}?${params.toString()}`);
  }

  public notificarAsistente(id: number, asistente: IdNombre) {
    let endpoint = "/vencimientos/" + id + "/notificar/" + asistente.id;
    return this.http.get(environment.apiEndpoint + endpoint);
  }
  public asistenteAsignado(id: number) {
    let endpoint = "/vencimientos/" + id + "/asistente";
    return this.http.get(environment.apiEndpoint + endpoint);
  }
  public findAsistentes(): Observable<Page<Personal>> {
    let endpoint = "/asistentes/01";
    return this.http.get<Page<Personal>>(environment.apiEndpoint + endpoint);
  }
  public findAsistentesOtrasAreas() {
    let endpoint = "/asistentes";
    return this.http.get(environment.apiEndpoint + endpoint);
  }
  public findClientes(): Observable<Page<IdNombre>> {
    let personal: IdNombreCodigo = JSON.parse(localStorage.getItem("sige_personal"));
    let endpoint = "/clientes/01/" + personal.codigo + "?habilitado=S";
    return this.http.get<Page<IdNombre>>(environment.apiEndpoint + endpoint);
  }
  public findResponsables(): Observable<Page<Personal>> {
    let endpoint = "/responsables/01";
    return this.http.get<Page<Personal>>(environment.apiEndpoint + endpoint);
  }
  public findSubAsistente(): Observable<Page<Personal>> {
    let endpoint = "/subasistentes/01";
    return this.http.get<Page<Personal>>(environment.apiEndpoint + endpoint);
  }
  public findObligaciones() {
    let endpoint = "/obligaciones/01?habilitado=S";
    return this.http.get(environment.apiEndpoint + endpoint);
  }
  public findOrganismos() {
    let endpoint = "/organismos?habilitado=S";
    return this.http.get(environment.apiEndpoint + endpoint);
  }
  public findGrupoEconomicos() {
    let endpoint = "/gruposeconomicos?habilitado=S";
    return this.http.get(environment.apiEndpoint + endpoint);
  }
  public cumplir(id: number, usuario: IdNombre, mensaje: string) {
    let endpoint = "/vencimientos/" + id + "/cumplir/" + usuario.id;
    return this.http.post(environment.apiEndpoint + endpoint, mensaje);
  }
  public cumplirSocietario(id: number, usuario: IdNombre, mensaje: string) {
    let endpoint = "/vencimientos/" + id + "/cumplir/societario/" + usuario.id;
    return this.http.post(environment.apiEndpoint + endpoint, mensaje);
  }
  public cancelarCumplir(vencimiento: Vencimiento) {
    let personal: IdNombre = JSON.parse(localStorage.getItem("sige_personal"));
    let endpoint = "/vencimientos/" + vencimiento.id + "/cancelarCumplimiento/" + personal.id;
    return this.http.get(environment.apiEndpoint + endpoint, {});
  }
  public rectificar(vencimiento: Vencimiento) {
    let endpoint = "/vencimientos/" + vencimiento.id + "/rectificar";
    return this.http.get(environment.apiEndpoint + endpoint, {});
  }
  public eliminar(vencimiento: Vencimiento) {
    let endpoint = "/vencimientos/" + vencimiento.id;
    return this.http.delete(environment.apiEndpoint + endpoint, {});
  }
  public addRecepcion(vencimientoId: number, fecha: string) {
    let personal: IdNombre = JSON.parse(localStorage.getItem("sige_personal"));
    let endpoint = "/vencimientos/" + vencimientoId + "/recibirDocumentacion/" + personal.id + "?fechaRecepcion=" + fecha;
    return this.http.get(environment.apiEndpoint + endpoint, {});
  }
}




