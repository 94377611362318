import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Contacto } from 'src/app/interfaces/contacto';
import modalUtils from 'src/app/utils/modal';
import * as moment from "moment";
const swal = require("sweetalert");
import { ClienteLite } from 'src/app/interfaces/cliente';
import { ContactosService } from 'src/app/services/contactos.service';
import { finalize } from "rxjs/operators";
import { BsDatepickerConfig, BsDatepickerViewMode, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Page } from 'src/app/interfaces/page';
import { ClienteService } from 'src/app/services/clientes/cliente.service';
import { GrupoEconomicoService } from 'src/app/services/grupo-economico.service';
import { IdNombreCodigo } from 'src/app/interfaces/id-nombre-codigo';

@Component({
  selector: 'app-nuevo-contacto',
  templateUrl: './nuevo-contacto.component.html',
  styleUrls: ['./nuevo-contacto.component.scss']
})
export class NuevoContactoComponent implements OnInit {

  @ViewChild("modal") modal: ModalDirective;

  @Input() listadoMails: Array<any> = [];
  @Output() onCloseModalNuevoContacto = new EventEmitter();
  
  form: FormGroup;
  bsConfig: Partial<BsDatepickerConfig>;
  viewMode: BsDatepickerViewMode = "year";
  loadingGuardado = false;
  loading: boolean;
  loadingCollapse: boolean;

  public contacto: Contacto = null;
  public contactos: Array<Contacto> = [];
  public clientes: Array<ClienteLite> = [];
  public reemplazarMail: boolean;
  public contactos_result: Array<Contacto> = [];
  public contactoColapsado: Contacto;
  public contactosColapsados: Array<Contacto> = [];
  public filtrax: string;
  public orden: number = 1;
  public listadoClientes: Array<any> = [];
  public personal: Array<IdNombreCodigo> = [];
  public listadoPersonal: Array<any> = [];

  isCreatingMail: boolean = false;
  isCreatingEmpresa: boolean = false;

  private defaultErrorMessage = "Ocurrió un error al aplicar los cambios.";

  constructor(private formBuilder: FormBuilder,
    private localeService: BsLocaleService,
    private contactoService: ContactosService,
    private clienteService: ClienteService,
    private grupoEconomicoService: GrupoEconomicoService) {
    this.form = formBuilder.group({
      descripcion: [null, Validators.required],
      personal: [null],
      telefono: [null],
      novedad: [true],
      eventos: [true],
      cumple: [true],
      fecha_nacimiento: [null],
      mail: [null, Validators.required],
      empresa: [null],
      cliente: [null],
      // 'area': [null],
      cuit: [null]
    });
    this.bsConfig = Object.assign({},
      {
        containerClass: "theme-angle",
        dateInputFormat: "DD/MM/YYYY",
        startView: this.viewMode
      });
    this.localeService.use("es");
    this.loading = true;
    this.loadingCollapse = false;
  }

  ngOnInit(): void {
    this.grupoEconomicoService.findReferentes().subscribe((response: Page<IdNombreCodigo>) => {
      this.personal = response.content.filter(r => (r.codigo === "0001" || r.codigo === "0003" || r.codigo === "0004" || r.codigo === "0005"));
      this.listadoPersonal = this.personal.map(p => {
        return { id: p.id, text: p.nombre };
      });
    });
    this.clienteService.findclientesLite().subscribe((response: Page<ClienteLite>) => {
      this.clientes = response.content;
      this.listadoClientes = this.clientes.map(p => {
        return { id: p.id, text: p.nombre };
      });
    });
    this.create();
    this.loading = false;
  }

  public openNuevoContacto() {
    modalUtils.open(this.modal);
  }

  public submitForm($event, value: Contacto): void {
    $event.preventDefault();
    for (let c in this.form.controls) {
      this.form.controls[c].markAsTouched();
    }
    if (this.form.valid && !this.loadingGuardado && !this.loading) {//!
      this.loadingGuardado = true;
      value.fecha_nacimiento = moment(value.fecha_nacimiento).isValid() ? moment(value.fecha_nacimiento).format("YYYY-MM-DD") : "";
      value.empresa = (value.cliente ? this.clientes.find(c => c.id === value.cliente).nombre : value.empresa || "");
      value.cliente = value.cliente ? value.cliente : 0;
      value.personal = value.personal ? value.personal : 0;
      Object.assign(this.contacto, value);
      (this.contacto?.id ?
        this.contactoService.edit(this.contacto, this.reemplazarMail) :
        this.contactoService.create(this.contacto)
      ).pipe(
        finalize(() => {
          this.loadingGuardado = false;
        })).subscribe((contacto: Contacto) => {
          let ixC = this.contactos.findIndex(c => c.id === this.contacto.id);
          ixC > -1 ?
            this.contactos[ixC] = contacto :
            (this.contactoColapsado = this.contactos.find(c => c.mail === this.contacto.mail)) ?
              this.colapsarContacto(this.contactoColapsado) :
              (this.contactos.push(contacto), this.ordenarDescripcion("descripcion"));
          this.contacto = contacto;
          if (!this.listadoMails.includes(this.contacto.mail)) {
            this.listadoMails.push(this.contacto.mail);
          }
          this.modal.hide();
          swal({
            title: "Guardado",
            text: "El registro se guardó exitosamente.",
            icon: "success",
            buttons: false,
            timer: 1600
          });
        }, (error) => {
          swal("¡Lo sentimos!", error.error?.message || this.defaultErrorMessage, "error");
        });
    }
  }
  public valueRequired(value: string) {
    return this.form.controls[value].hasError("required") && (this.form.controls[value].dirty || this.form.controls[value].touched);
  }

  public colapsarContacto(contacto: Contacto) {
    this.loadingCollapse = true;
    this.contactoColapsado = JSON.parse(JSON.stringify(contacto));
    this.contactoColapsado.mail = this.contactoColapsado.mail.split(";")[0];
    this.contactoService.getByEmail(this.contactoColapsado).subscribe((response: Page<Contacto>) => {
      this.contactosColapsados = response.content;
      this.loadingCollapse = false;
    });
  }
  public ordenarDescripcion(value: string) {
    this.filtrax = value;
    this.contactos_result.sort((a, b) => {
      let codigoA = (a[value] || "").toLowerCase();
      let codigoB = (b[value] || "").toLowerCase();
      return codigoA.localeCompare(codigoB) * (this.orden);
    });
  }
  public nullifyControl(key: string) {
    this.form.controls[key].setValue(null);
  }

  public create() {
    for (let c in this.form.controls) {
      this.form.controls[c].markAsUntouched();
      this.form.controls[c].markAsPristine();
    }
    this.contacto = this.defaultObject();
    this.reemplazarMail = false;
    this.setForm(this.contacto);
  }
  private async setForm(contacto: Contacto) {
    this.form.controls.descripcion.setValue(contacto?.descripcion || null);
    this.form.controls.personal.setValue(contacto?.personal || 0);
    this.form.controls.telefono.setValue(contacto?.telefono || "");
    this.form.controls.novedad.setValue(contacto?.novedad);
    this.form.controls.eventos.setValue(contacto?.eventos);
    this.form.controls.cumple.setValue(contacto?.cumple);
    this.form.controls.fecha_nacimiento.setValue(moment(contacto?.fecha_nacimiento).isValid() ? this.formatDate(contacto?.fecha_nacimiento) : "");
    this.form.controls.mail.setValue(contacto?.mail || null);
    this.form.controls.empresa.setValue(contacto?.empresa || null);
    this.form.controls.cliente.setValue(contacto?.cliente || 0);
    // this.form.controls['area'].setValue(contacto?.area || null);
    this.form.controls.cuit.setValue(contacto?.cuit || "");

    this.isCreatingEmpresa = (contacto?.cliente || !contacto?.empresa) ? false : true;
    this.isCreatingMail = false;

  }
  public formatDate(date: string) {
    return date.split("-").reverse().join("/");
  }
  private defaultObject(): Contacto {
    return {
      id: null,
      descripcion: null,
      personal: null,
      telefono: null,
      novedad: true,
      eventos: true,
      cumple: true,
      fecha_nacimiento: null,
      mail: null,
      empresa: null,
      cliente: 0,
      area: "",
      cuit: ""
    };
  }
  public edit(contacto: Contacto) {
    for (let c in this.form.controls) {
      this.form.controls[c].markAsUntouched();
      this.form.controls[c].markAsPristine();
    }
    this.contacto = contacto;
    this.reemplazarMail = false;
    this.setForm(this.contacto);
    this.openNuevoContacto();
  }

  public onCloseModal(){
    modalUtils.hide(this.modal);
    this.onCloseModalNuevoContacto.emit();
  }
}
