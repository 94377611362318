import { Personal as IPersonal } from "../interfaces/personal";
import { Personal } from "./personal";
import { Valorizacion as IValorizacion, PersonaFacturacion as IPersonaFacturacion } from "../interfaces/valorizacion";
import { ACCION, PROCEDENCIA } from "src/app/enums/valorizacion";
import mathUtils from "src/app/utils/mathUtils";

export class Valorizacion implements IValorizacion {

    id: number;
    detalle: string;
    dc: ACCION;
    accion: PROCEDENCIA;
    _monto: number;
    montoMaximo: number;
    personalFacturacion: Array<PersonaFacturacion>;
    fecha: string;

    public set monto(value: number) {
        if (value < 0)
            value = 0;
        else if (this.montoMaximo !== null && this.montoMaximo < value)
            value = this.montoMaximo;
        this._monto = value;
        this.personalFacturacion.forEach(p => {
            p.monto = this._monto;
        });
    }

    public get monto(): number {
        return this._monto;
    }

    constructor(valorizacion?: IValorizacion) {
        this.id = valorizacion?.id || 0;
        this.detalle = valorizacion?.detalle || null;
        this.dc = valorizacion?.dc || ACCION.FACTURAR;
        this.accion = valorizacion?.accion || PROCEDENCIA.FACTURA;
        this.fecha = valorizacion?.fecha || new Date().toISOString().substring(0, 10);
        this.personalFacturacion = [];
        this.montoMaximo = null;
        this.monto = valorizacion?.monto || 0;

        if (Array.isArray(valorizacion?.personalFacturacion)) {
            valorizacion.personalFacturacion.forEach(p => {
                this.addPersonal(p);
            });
        }
    }

    public addPersonal(personal?: IPersonaFacturacion): void {
        if (personal)
            personal.monto = this.monto;
        this.personalFacturacion.push(new PersonaFacturacion(Object.assign({ monto: this.monto }, personal)));

        if (!this.monto)
            this.setPorcentajes();
    }

    public removePersonal(personal: PersonaFacturacion): void {
        let ix = this.personalFacturacion.indexOf(personal);
        if (ix !== -1)
            this.personalFacturacion.splice(ix, 1)
    }

    public clearPersonal(): void {
        this.personalFacturacion = [];
    }

    public getTotalPorcentaje(): number {
        return this.personalFacturacion.length ?
            this.personalFacturacion
                .map(p => p.porcentaje)
                .reduce((acum, currentValue) => mathUtils.round(acum + currentValue, 2)) : 0;
    }

    public setPorcentajes(): void {
        let totalMinutos = this.personalFacturacion.length ?
            this.personalFacturacion.map(p => p.getTotalMinutos()).reduce((acum, currValue) => acum + currValue) :
            0;
        this.personalFacturacion.forEach(p => {
            p.setPorcentajes(totalMinutos);
        });
    }

    public getTotalHorasMinutos(): [number, number] {
        if (!this.personalFacturacion.length)
            return [0, 0];

        let horas = this.personalFacturacion.map(p => p.horasMinutos[0]).reduce((acum, currentValue) => acum + currentValue);
        let minutos = this.personalFacturacion.map(p => p.horasMinutos[1]).reduce((acum, currentValue) => acum + currentValue);

        horas += Math.floor(minutos / 60);
        minutos = minutos % 60;

        return [horas, minutos];
    }
}

export class PersonaFacturacion implements IPersonaFacturacion {
    id: number;
    _porcentaje: number;
    _monto: number;
    personal: IPersonal;
    horasMinutos: [number, number];

    public get porcentaje(): number {
        return this._porcentaje;
    }

    public set porcentaje(value: number) {
        if (value < 0)
            value = 0;
        else if (value > 100)
            value = 100;
        this._porcentaje = mathUtils.round(value, 2);
    }

    public set monto(value: number) {
        this._monto = value;
    }

    public get monto(): number {
        return mathUtils.round((this._monto * this._porcentaje) / 100, 2);
    }

    constructor(personalFacturacion?: IPersonaFacturacion) {
        this.id = personalFacturacion?.id || null;
        this.porcentaje = personalFacturacion?.porcentaje || 0;
        this._monto = personalFacturacion?.monto || 0;
        this.personal = new Personal(personalFacturacion?.personal);
        this.horasMinutos = Array.isArray(personalFacturacion?.horasMinutos) ? personalFacturacion?.horasMinutos : [0, 0];
    }


    public getTotalMinutos(): number {
        return this.horasMinutos[0] * 60 + this.horasMinutos[1];
    }

    public setPorcentajes(totalMinutos: number): void {
        this.porcentaje = totalMinutos ? (this.getTotalMinutos() * 100 / totalMinutos) : 0;
    }
}