import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DetalleHonorarios } from '../interfaces/detalle-honorarios';
import { Notificacion } from '../interfaces/notificacion';

@Injectable({
  providedIn: 'root'
})
export class DetalleHonorariosService {

  recurso = '/detalleHonorarios';

  constructor(private http: HttpClient) { }

  public findAll({
    grupoEconomico = null,
    cliente = null,
    clienteHabilitado = null,
    tipoPrestacion = null,
    monedaId = null,
    anio = null,
    actualizacion = null,
  } = {}): Observable<Array<DetalleHonorarios>> {
    let params = new URLSearchParams([]);
    grupoEconomico ? params.set('grupoEconomico', grupoEconomico) : null;
    cliente ? params.set('cliente', cliente) : null;
    clienteHabilitado ? params.set('clienteHabilitado', clienteHabilitado) : null;
    tipoPrestacion ? params.set('tipoPrestacion', tipoPrestacion) : null;
    monedaId ? params.set('monedaId', monedaId) : null;
    anio ? params.set('anio', anio) : null;
    actualizacion ? params.set('actualizacion', actualizacion) : null;
    return this.http.get<Array<DetalleHonorarios>>(`${environment.apiEndpoint}${this.recurso}?${params.toString()}`);
  }
  public findNotificacion(periodo: string): Observable<Notificacion> {
    return this.http.get<Notificacion>(`${environment.apiEndpoint}${this.recurso}/periodo/${periodo}/notificacion`);
  }
  public cerrarFacturacion(periodo: string): Observable<void> {
    return this.http.patch<void>(`${environment.apiEndpoint}${this.recurso}/periodo/${periodo}/cerrar`, null);
  }
  public abrirFacturacion(periodo: string): Observable<void> {
    return this.http.patch<void>(`${environment.apiEndpoint}${this.recurso}/periodo/${periodo}/abrir`, null);
  }
  public posponerCierre(hora: string): Observable<void> {
    return this.http.patch<void>(`${environment.apiEndpoint}${this.recurso}/periodo/posponer`, hora, {headers : new HttpHeaders({ 'Content-Type': 'application/json' })});
  }
  
}
