export enum Authorities {
    gerente = "gerente",
    administracion_supervisor = "administracion_supervisor",
    administracion_responsable = "administracion_responsable",
    administracion_asistente = "administracion_asistente",
    administracion_auxiliar = "administracion_auxiliar",
    auditoria_supervisor = "auditoria_supervisor",
    auditoria_responsable = "auditoria_responsable",
    auditoria_asistente = "auditoria_asistente",
    auditoria_auxiliar = "auditoria_auxiliar",
    impuestos_supervisor = "impuestos_supervisor",
    impuestos_responsable = "impuestos_responsable",
    impuestos_asistente = "impuestos_asistente",
    impuestos_auxiliar = "impuestos_auxiliar",
    laboral_supervisor = "laboral_supervisor",
    laboral_responsable = "laboral_responsable",
    laboral_asistente = "laboral_asistente",
    laboral_auxiliar = "laboral_auxiliar",
    outsourcing_supervisor = "outsourcing_supervisor",
    outsourcing_responsable = "outsourcing_responsable",
    outsourcing_asistente = "outsourcing_asistente",
    outsourcing_auxiliar = "outsourcing_auxiliar",
    recursos_humanos_supervisor = "recursos_humanos_supervisor",
    recursos_humanos_responsable = "recursos_humanos_responsable",
    recursos_humanos_asistente = "recursos_humanos_asistente",
    recursos_humanos_auxiliar = "recursos_humanos_auxiliar",
    societario_supervisor = "societario_supervisor",
    societario_responsable = "societario_responsable",
    societario_asistente = "societario_asistente",
    societario_auxiliar = "societario_auxiliar"
}