import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Page } from '../interfaces/page';
import { TipoDeObligacion } from '../interfaces/tipo-de-obligacion';


@Injectable({
  providedIn: 'root'
})
export class TiposDeObligacionService {

  constructor(private http: HttpClient) { }

  public findTipoDeObligacion(): Observable<Page<TipoDeObligacion>> {
    return this.http.get<Page<TipoDeObligacion>>(`${environment.apiEndpoint}/tipoObligacion`);
  }

  public findOrganismos() {
    let endpoint = '/organismos';
    return this.http.get(environment.apiEndpoint + endpoint);
  }

  public create(tipoDeObligacion: TipoDeObligacion) {
    let endpoint = '/tipoObligacion';
    return this.http.post(environment.apiEndpoint + endpoint, tipoDeObligacion);
  }

  public edit(tipoDeObligacion: TipoDeObligacion) {
    let endpoint = '/tipoObligacion';
    return this.http.put(environment.apiEndpoint + endpoint, tipoDeObligacion);
  }

  /* public edit(tipoDeObligacion:TipoDeObligacion) {
    let endpoint = '/tipoObligacion';
    return this.http.post(environment.apiEndpoint + endpoint, tipoDeObligacion);
  } */


}
