export default (() => {

    return {
        make({ text = "", type = "text/html" }): string {
            var data = new Blob([text], { type });
            return window.URL.createObjectURL(data);
        },
        makeEml({ to = "", subject = "", body = "" }): string {
            let templateEml = `To: ${to}\nSubject: ${subject}\nX-Unsent: 1\nContent-Type: text/html\n<html>\n\n<body>${body}</body>\n\n</html>`
            return this.make({ text: templateEml });
        },
        download(filename: string, file: string) {
            let a = document.createElement("a");
            a.href = file;
            a.target = "_blank";
            a.download = filename;
            a.click();
        }
    }
})();