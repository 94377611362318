import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { ToastrModule } from "ngx-toastr";
import { FileUploadModule } from "ng2-file-upload";
import { MatNativeDateModule } from "@angular/material/core";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatStepperModule } from "@angular/material/stepper";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatRippleModule } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSliderModule } from "@angular/material/slider";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";

import { AccordionModule } from "ngx-bootstrap/accordion";
import { AlertModule } from "ngx-bootstrap/alert";
import { ButtonsModule } from "ngx-bootstrap/buttons";
import { CarouselModule } from "ngx-bootstrap/carousel";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { ProgressbarModule } from "ngx-bootstrap/progressbar";
import { RatingModule } from "ngx-bootstrap/rating";
import { TabsModule } from "ngx-bootstrap/tabs";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { PopoverModule } from "ngx-bootstrap/popover";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { DatepickerModule } from "ngx-bootstrap/datepicker";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";

import { FlotDirective } from "./directives/flot/flot.directive";
import { SparklineDirective } from "./directives/sparkline/sparkline.directive";
import { EasypiechartDirective } from "./directives/easypiechart/easypiechart.directive";
import { ColorsService } from "./colors/colors.service";
import { CheckallDirective } from "./directives/checkall/checkall.directive";
import { VectormapDirective } from "./directives/vectormap/vectormap.directive";
import { NowDirective } from "./directives/now/now.directive";
import { ScrollableDirective } from "./directives/scrollable/scrollable.directive";
import { JqcloudDirective } from "./directives/jqcloud/jqcloud.directive";
import { DocumentoComponent } from "./documento/documento.component";
import { DocumentacionComponent } from "./documentacion/documentacion.component";
import { CardObligacionComponent } from "./card-obligacion/card-obligacion.component";
import { CardVencimientoComponent } from "./card-vencimiento/card-vencimiento.component";
import { ObservacionesComponent } from "./observaciones/observaciones.component";
import { EllipsisPipe } from "src/app/pipes/ellipsis.pipe";
import { ThousandsPipe } from "src/app/pipes/thousands.pipe";
import { LoadingComponent } from "./loading/loading.component";
import { BadgePipe } from "src/app/pipes/badge/badge.pipe";
import { CuitPipe } from 'src/app/pipes/cuit.pipe';
import { HorasPipe } from "src/app/pipes/horas.pipe";
import { HoraPipe } from "src/app/pipes/hora.pipe";
import { FechaPipe } from "src/app/pipes/fecha.pipe";
import { DateInlineEditableComponent } from "./date-inline-editable/date-inline-editable.component";
import { CardAuditoriaComponent } from "./card-auditoria/card-auditoria.component";
import { CardCorteComponent } from "./card-corte/card-corte.component";
import { PanelDocumentacionComponent } from "./panel-documentacion/panel-documentacion.component";
import { InputInlineEditableComponent } from "./input-inline-editable/input-inline-editable.component";
import { EnviarDocumentacionComponent } from "./enviar-documentacion/enviar-documentacion.component";
import { CardTareaComponent } from "./card-tarea/card-tarea.component";
import { ClockComponent } from "./clock/clock.component";
import { ControlHorarioComponent } from "./control-horario/control-horario.component";
import { DetalleDeHorasComponent } from "./detalle-de-horas/detalle-de-horas.component";
import { BuscadorClienteComponent } from "./buscador-cliente/buscador-cliente.component";
import { NgxSelectModule } from "ngx-select-ex";
import { ValorizacionComponent } from "./valorizacion/valorizacion.component";
import { CardRecuperosComponent } from "./card-recuperos/card-recuperos.component";
import { MenuContextualComponent } from './menu-contextual/menu-contextual.component';
import { ExportarDatosComponent } from './exportar-datos/exportar-datos.component';
import { GraficoInformeComponent } from './grafico-informe/grafico-informe.component';
import { MailObservacionesComponent } from './mail-observaciones/mail-observaciones.component';
import { CKEditorModule } from "ckeditor4-angular";
import { NotificationsComponent } from './notifications/notifications.component';
import { ControlObligacionesAsignadasComponent } from './control-obligaciones-asignadas/control-obligaciones-asignadas.component';
import { AsignacionNotificacionVencimientosComponent } from './asignacion-notificacion-vencimientos/asignacion-notificacion-vencimientos.component';
import { ControlObligacionesAutomaticasComponent } from './control-obligaciones-automaticas/control-obligaciones-automaticas.component';
import { CardModulosComponent } from './card-modulos/card-modulos.component';
import { ArchivosFinalesComponent } from "./archivos-finales/archivos-finales.component";
import { ListadoContactosComponent } from './listado-contactos/listado-contactos.component';
import { ModalRecepcionComprobantesComponent } from './modal-recepcion-comprobantes/modal-recepcion-comprobantes.component';
import { ModalEncargadoPagosComponent } from './modal-encargado-pagos/modal-encargado-pagos.component';
import { NuevoContactoComponent } from './nuevo-contacto/nuevo-contacto.component';
import { ModalLoadingComponent } from './modal-loading/modal-loading.component';
import { ModalAltaEditarVerPedidoDeFondosComponent } from "./modal-alta-editar-ver-pedido-de-fondos/modal-alta-editar-ver-pedido-de-fondos.component";
import { ModalAccionesParticipacionesLegalSocietarioComponent } from './modal-acciones-participaciones-legal-societario/modal-acciones-participaciones-legal-societario.component';
import { ModalConstitucionesModificacionesComponent } from './modal-constituciones-modificaciones/modal-constituciones-modificaciones.component';
import { DomiciliosComponent } from './domicilios/domicilios.component';
import { LibrosComponent } from './libros/libros.component';
import { TenenciaDeAccionesComponent } from "./tenencia-de-acciones/tenencia-de-acciones.component";
import { MiembrosAdministrativosComponent } from './miembros-administrativos/miembros-administrativos.component';
import { EjerciciosRegularesComponent } from './ejercicios-regulares/ejercicios-regulares.component';
import { CargaDocumentosComponent } from './carga-documentos/carga-documentos.component';
import { GraficoSinFiltrosComponent } from './grafico-sin-filtros/grafico-sin-filtros.component';

// https://angular.io/styleguide#!#04-10
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    AccordionModule.forRoot(),
    AlertModule.forRoot(),
    ButtonsModule.forRoot(),
    CarouselModule.forRoot(),
    CollapseModule.forRoot(),
    DatepickerModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    ProgressbarModule.forRoot(),
    RatingModule.forRoot(),
    TabsModule.forRoot(),
    TimepickerModule.forRoot(),
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    TypeaheadModule.forRoot(),
    ToastrModule.forRoot(),
    // Material Modules
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatTableModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatSnackBarModule,
    MatSortModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatNativeDateModule,
    MatStepperModule,
    NgxSelectModule,
    FileUploadModule,
    CKEditorModule,
  ],
  providers: [
    ColorsService
  ],
  declarations: [
    FlotDirective,
    SparklineDirective,
    EasypiechartDirective,
    CheckallDirective,
    VectormapDirective,
    NowDirective,
    ScrollableDirective,
    JqcloudDirective,
    DocumentoComponent,
    CardObligacionComponent,
    CardVencimientoComponent,
    ObservacionesComponent,
    DocumentacionComponent,
    EllipsisPipe,
    ThousandsPipe,
    LoadingComponent,
    BadgePipe,
    CuitPipe,
    HorasPipe,
    HoraPipe,
    FechaPipe,
    DateInlineEditableComponent,
    CardAuditoriaComponent,
    CardCorteComponent,
    PanelDocumentacionComponent,
    InputInlineEditableComponent,
    EnviarDocumentacionComponent,
    CardTareaComponent,
    ClockComponent,
    ControlHorarioComponent,
    DetalleDeHorasComponent,
    ValorizacionComponent,
    BuscadorClienteComponent,
    CardRecuperosComponent,
    MenuContextualComponent,
    GraficoInformeComponent,
    MailObservacionesComponent,
    ExportarDatosComponent,
    NotificationsComponent,
    ControlObligacionesAsignadasComponent,
    AsignacionNotificacionVencimientosComponent,
    ControlObligacionesAutomaticasComponent,
    CardModulosComponent,
    ArchivosFinalesComponent,
    ListadoContactosComponent,
    ModalRecepcionComprobantesComponent,
    ModalEncargadoPagosComponent,
    NuevoContactoComponent,
    ModalLoadingComponent,
    ModalAltaEditarVerPedidoDeFondosComponent,
    ModalAccionesParticipacionesLegalSocietarioComponent,
    ModalConstitucionesModificacionesComponent,
    DomiciliosComponent,
    TenenciaDeAccionesComponent,
    LibrosComponent,
    MiembrosAdministrativosComponent,
    EjerciciosRegularesComponent,
    CargaDocumentosComponent,
    GraficoSinFiltrosComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    AccordionModule,
    AlertModule,
    ButtonsModule,
    CarouselModule,
    CollapseModule,
    DatepickerModule,
    BsDatepickerModule,
    BsDropdownModule,
    ModalModule,
    PaginationModule,
    ProgressbarModule,
    RatingModule,
    TabsModule,
    TimepickerModule,
    TooltipModule,
    PopoverModule,
    TypeaheadModule,
    ToastrModule,
    FlotDirective,
    SparklineDirective,
    EasypiechartDirective,
    CheckallDirective,
    VectormapDirective,
    NowDirective,
    ScrollableDirective,
    JqcloudDirective,
    // Material Modules
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatTableModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatSnackBarModule,
    MatSortModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatNativeDateModule,
    MatStepperModule,
    DocumentoComponent,
    CardObligacionComponent,
    CardVencimientoComponent,
    CardCorteComponent,
    ObservacionesComponent,
    DocumentacionComponent,
    EllipsisPipe,
    ThousandsPipe,
    LoadingComponent,
    BadgePipe,
    CuitPipe,
    HorasPipe,
    HoraPipe,
    FechaPipe,
    DateInlineEditableComponent,
    InputInlineEditableComponent,
    CardAuditoriaComponent,
    PanelDocumentacionComponent,
    EnviarDocumentacionComponent,
    CardTareaComponent,
    ControlHorarioComponent,
    DetalleDeHorasComponent,
    ValorizacionComponent,
    BuscadorClienteComponent,
    CardRecuperosComponent,
    MenuContextualComponent,
    ExportarDatosComponent,
    GraficoInformeComponent,
    MailObservacionesComponent,
    CKEditorModule,
    NotificationsComponent,
    ControlObligacionesAsignadasComponent,
    AsignacionNotificacionVencimientosComponent,
    ControlObligacionesAutomaticasComponent,
    CardModulosComponent,
    ArchivosFinalesComponent,
    ListadoContactosComponent,
    ModalRecepcionComprobantesComponent,
    ModalEncargadoPagosComponent,
    NuevoContactoComponent,
    ModalLoadingComponent,
    ModalAltaEditarVerPedidoDeFondosComponent,
    ModalAccionesParticipacionesLegalSocietarioComponent,
    ModalConstitucionesModificacionesComponent,
    DomiciliosComponent, 
    TenenciaDeAccionesComponent,
    LibrosComponent,
    MiembrosAdministrativosComponent,
    EjerciciosRegularesComponent,
    CargaDocumentosComponent,
    GraficoSinFiltrosComponent
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule
    };
  }
}
