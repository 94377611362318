import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { CortesAuditoria } from "src/app/interfaces/cortes-auditoria";
import { Personal } from "src/app/interfaces/personal";
import { Valorizacion as IValorizacion, PersonaFacturacion as IPersonaFacturacion } from "src/app/interfaces/valorizacion";
import { PersonaFacturacion, Valorizacion } from "src/app/classes/valorizacion";
import { AuditoriaService } from "src/app/services/auditoria.service";
import { ACCION, PROCEDENCIA } from "src/app/enums/valorizacion";
import { finalize, ignoreElements } from "rxjs/operators";
import { PersonalService } from "src/app/services/personal.service";
import { Page } from "src/app/interfaces/page";
import { forkJoin } from "rxjs";
import { TareasService } from "src/app/services/tareas.service";
import { ITarea } from "src/app/interfaces/itarea";

const swal = require("sweetalert");

@Component({
  selector: "app-valorizacion",
  templateUrl: "./valorizacion.component.html",
  styleUrls: ["./valorizacion.component.scss"]
})
export class ValorizacionComponent implements OnInit {

  @Input() set corte(value: CortesAuditoria) {
    if (this._corte = value)
      this.tieneValorizacion = value.valorizacion === 0 ? true : false;
  }

  @Input() set tableroTareas(value: boolean) {
    this._tableroTareas = value;
  }

  @Input() set update(value: number) {
    this.valorizar();
  }

  @Input() set tarea(value: ITarea) {
    this._tareaSeleccionada = value;
  }

  @Output() valoriza: EventEmitter<number> = new EventEmitter();

  public _corte: CortesAuditoria = null;
  public facturas: Array<Valorizacion> = [];
  public facturaSeleccionada: Valorizacion = null;
  public montoPresupuesto: number = null;
  public presupuesto: Valorizacion = null;
  public editar: boolean = false;
  public montoAux: number;
  public _tableroTareas: boolean = false;
  public _tareaSeleccionada: ITarea = null;

  public listadoPersonal: Array<{ id: number, codigo: string, text: string }> = [];
  public listadoProcedencia: Array<{ id: string, text: string }> = [];
  public personalDefault: Array<IPersonaFacturacion> = [];

  public cargando: boolean = true;
  public tieneValorizacion: boolean = false;

  public openForm: boolean = false;
  public saveForm: boolean = false;
  public view: boolean = false;

  constructor(private auditoriaService: AuditoriaService, public personalService: PersonalService, private tareasService: TareasService) {
    for (let k in PROCEDENCIA) {
      this.listadoProcedencia.push({ id: PROCEDENCIA[k], text: k });
    }
  }

  ngOnInit(): void {
    this.personalService.findPersonalHabilitado().subscribe((response: Page<Personal>) => {
      this.listadoPersonal = response.content.map(v => ({ id: v.id, codigo: v.codigo, text: v.nombre }));
      this.listadoPersonal.sort();
    });
  }
  private valorizacionPresupuesto(){
   if( this._tableroTareas && this._corte.presupuestoId > 0)  
      this.tareasService.getPersonalParticipaPresupuesto(this._corte.id).subscribe(response => {
        this.personalDefault = response
      })
  }
  private valorizar(): void {
    this.reset();

    if (!this._corte)
      return;
    forkJoin(this._tableroTareas ? [
      this.tareasService.valorizar(this._corte),
      this.tareasService.getPersonalParticipa(this._corte.id),
    ] : [
      this.auditoriaService.valorizar(this._corte),
      this.auditoriaService.getPersonalParticipa(this._corte.id),
    ]
    ).pipe(finalize(() => {
      this.cargando = false
    })).subscribe(([valorizacion, personal]) => {
      valorizacion as Array<IValorizacion>;
      if (!valorizacion.length)
        return;

      this.presupuesto = new Valorizacion(valorizacion[0]);
      this.montoAux = this.presupuesto.monto;
      this.facturas = valorizacion.slice(1).map(f => new Valorizacion(f));
      if (this.presupuesto.monto)
        this.valoriza.emit(this.presupuesto.monto);

      this.personalDefault = personal;
      this.valorizacionPresupuesto();

    }, error => {
      swal("¡Lo sentimos!", error.error.message, "error");
    });
  }

  public porcentajesValidos(): boolean {
    return Array.isArray(this.facturaSeleccionada?.personalFacturacion)
      &&
      this.facturaSeleccionada.personalFacturacion.length
      &&
      this.facturaSeleccionada.getTotalPorcentaje() === 100
      &&
      this.facturaSeleccionada.personalFacturacion.every(p => p.porcentaje > 0);
  }

  public personalValido(): boolean {
    return Array.isArray(this.facturaSeleccionada?.personalFacturacion) && this.facturaSeleccionada.personalFacturacion.every(p => p.personal?.id);
  }

  public agregarFactura(): void {
    this.editarFactura(null);
  }

  public editarFactura(factura: IValorizacion): void {
    let nuevaFactura = new Valorizacion(factura);
    //nuevaFactura.montoMaximo = this.presupuesto.monto - this.getTotalCreditos();
    if (!factura)
      
      this.personalDefault.forEach((p, index) => {
        if(this._tableroTareas && this._corte.presupuestoId > 0){
          nuevaFactura.personalFacturacion.push(new PersonaFacturacion(Object.assign({ monto: p.monto }, p)))
          nuevaFactura.personalFacturacion[index]._porcentaje = p.porcentaje;}
        else{
          nuevaFactura.addPersonal(p);
        }
      });
      
    this.facturaSeleccionada = nuevaFactura;
    this.facturaSeleccionada.detalle = this._tableroTareas ? this._tareaSeleccionada?.descripcion || null : (this._corte?.cliente_fisico?.nombre || '') + ' - ' + (this._corte?.tipo_cierre || '');//*Agrego detalle de tarea/corte
    this.openForm = true;
  }

  public editarPresupuesto() {
    let backupMonto = this.presupuesto.monto;
    this.montoAux;
    this.presupuesto.monto = this.montoAux;
    let data = {
      id: this.presupuesto.id,
      dc: "D",
      detalle: this.presupuesto.detalle,
      monto: this.presupuesto.monto,
    };
    (this._tableroTareas ?
      this.tareasService.valorizarFacturarEdit(this._corte, data) :
      this.auditoriaService.valorizarFacturarEdit(this._corte, data)
    ).subscribe(
      (response: CortesAuditoria) => {
        this._corte.valorizacion = response.valorizacion;
        this.valorizar;
        this.editar = false;
        swal({
          title: "Guardado",
          text: "El presupuesto se actualizo exitosamente.",
          icon: "success",
          buttons: false,
          timer: 1000
        });
      },
      error => {
        this.presupuesto.monto = backupMonto;
        this.montoAux = backupMonto;
        this.editar = false;
        swal("¡Lo sentimos!", error.error.message, "error");
      }
    );
  }

  public eliminarFactura(factura: IValorizacion): void {
    let ix = this.facturas.findIndex(f => f.id === factura.id);
    this.facturas.splice(ix, 1);
  }

  public getTotalCreditos(): number {
    return this.facturas.length ? this.facturas.map(f => f.monto).reduce((acum, currentValue) => acum + currentValue) : 0;
  }

  public creditosExcedenDebitos(): boolean {
    let totalCreditos = this.getTotalCreditos();

    if (!this.facturaSeleccionada.id)
      totalCreditos += this.facturaSeleccionada.monto;

    return totalCreditos > this.presupuesto.monto;
  }

  private reset(): void {
    this.editar = false;
    this.montoPresupuesto = null
    this.presupuesto = null;
    this.facturas = [];
    this.personalDefault = [];
    this.openForm = false;
  }

  public valorizarPresupuesto(): void {
    if (!this.montoPresupuesto) {
      swal("Error", "Debe ingresar un monto mayor a cero.", "error");
      return;
    }
    (this._tableroTareas ?
      this.tareasService.valorizarPresupuesto(this._corte.id, this.montoPresupuesto) :
      this.auditoriaService.valorizarPresupuesto(this._corte.id, this.montoPresupuesto)
    ).subscribe(
      (response: CortesAuditoria) => {
        this._corte.valorizacion = response.valorizacion;
        swal({
          title: "Guardado",
          text: "El corte puede ser valorizado.",
          icon: "success",
          buttons: false,
          timer: 1000
        });
        this.valorizar();
      },
      error => {
        swal("¡Lo sentimos!", error.error.message, "error");
      }
    );

  }

  openTabla(value: boolean) {
    this.openForm = value;
  }

  saveTabla(value: boolean): void {
    this.saveForm = value;
  }

  public async submit(): Promise<void> {

    if (this.cargando || !this.porcentajesValidos() || !this.personalValido())
      return;


    if (this.creditosExcedenDebitos()) {
      let confirm = await swal({
        title: "Atención",
        text: "Los créditos son mayores a los débitos, ¿desea guardar de todos modos?",
        icon: "warning",
        buttons: {
          cancel: { text: "Cancelar", value: null, visible: true, className: "", closeModal: true },
          confirm: { text: "Continuar", value: true, visible: true, className: "success", closeModal: true }
        }
      });

      if (!confirm)
        return;
    }

    this.cargando = true;

    let data = {
      id: this.facturaSeleccionada.id,
      accion: this.facturaSeleccionada.accion,
      dc: this.facturaSeleccionada.dc,
      detalle: this.facturaSeleccionada.detalle ? this.facturaSeleccionada.detalle : '.',
      monto: this.facturaSeleccionada.monto,
      fecha: this.facturaSeleccionada.fecha,
      personalFacturacion: this.facturaSeleccionada.personalFacturacion.map(p => ({
        id: p.id,
        porcentaje: p.porcentaje,
        monto: p.monto,
        personal: { id: p.personal.id, codigo: this.listadoPersonal.find(f => f.id == p.personal.id).codigo, email: p.personal.email, nombre: p.personal.nombre }
      }))
    };
    (this._tableroTareas ?
      this.tareasService.valorizarFacturar(this._corte.id, data) :
      this.auditoriaService.valorizarFacturar(this._corte.id, data)
    ).pipe(finalize(() => this.cargando = false))
      .subscribe((response) => {
        this.facturas.push(new Valorizacion(response));
        this.openForm = false;
        this.valorizar();
        swal({
          title: "Guardado",
          text: "El registro se guardó exitosamente.",
          icon: "success",
          buttons: false,
          timer: 1000
        });
      },
        error => swal("¡Lo sentimos!", error.error.message, "error"));
  }

  public eliminarValorizacion(factura: Valorizacion) {
    if (factura.dc === "D") {
      swal({
        title: "Eliminar Facturas",
        text: "¿Estás por eliminar todas las facturas, estás seguro?",
        icon: "warning",
        buttons: {
          cancel: { text: "Cancelar", value: null, visible: true, className: "", closeModal: true },
          confirm: { text: "Eliminar", value: true, visible: true, className: "success", closeModal: false }
        }
      }).then((isConfirm) => {
        if (isConfirm) {
          (this._tableroTareas ?
            this.tareasService.valorizarFacturarEliminar(this._corte, factura) :
            this.auditoriaService.valorizarFacturarEliminar(this._corte, factura)
          ).subscribe(
            (response: any) => {
              swal({
                title: "Guardado",
                text: "Las facturas se eliminaron exitosamente.",
                icon: "success",
                buttons: false,
                timer: 1000
              });
              this.valorizar();
              this._corte.valorizacion = 0;
            },
            error => swal("¡Lo sentimos!", error.error.message, "error")
          );
        }
      });
    } else {
      swal({
        title: "Eliminar factura",
        text: "¿Estás por eliminar una factura, estás seguro?",
        icon: "warning",
        buttons: {
          cancel: { text: "Cancelar", value: null, visible: true, className: "", closeModal: true },
          confirm: { text: "Eliminar", value: true, visible: true, className: "success", closeModal: false }
        }
      }).then((isConfirm) => {
        if (isConfirm) {
          (this._tableroTareas ?
            this.tareasService.valorizarFacturarEliminar(this._corte, factura) :
            this.auditoriaService.valorizarFacturarEliminar(this._corte, factura)
          ).subscribe(
            (response: any) => {
              swal({
                title: "Guardado",
                text: "La factura se eliminó exitosamente.",
                icon: "success",
                buttons: false,
                timer: 1000
              });
              this.valorizar();
            },
            error => swal("¡Lo sentimos!", error.error.message, "error")
          );
        }
      });
    }
  }
}
