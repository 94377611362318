import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Documento } from 'src/app/classes/documento';
@Component({
  selector: 'app-carga-documentos',
  templateUrl: './carga-documentos.component.html',
  styleUrls: ['./carga-documentos.component.scss']
})
export class CargaDocumentosComponent implements OnInit {

  public documento: Documento = new Documento();
  public seleccionado = false;
  public documentos: Array<Documento> = []

  @Output() listadoDocumentos = new EventEmitter()

  constructor() { }

  ngOnInit(): void { }

  public reset() {
    this.documentos = []
  }

  public onDocumentoEliminado(documentoSeleccionado: Documento): void {
    Object.assign(documentoSeleccionado, new Documento())
    this.emitirEvento()
  }
  
  public setDocumentos(documentos: Array<Documento>) {
    if (documentos)
      this.documentos = documentos
  }

  public nuevoDocumento() {
    this.documentos.push(new Documento())
    this.emitirEvento()
  }

  public emitirEvento() {
    this.listadoDocumentos.emit(this.documentos)
  }

  public getDocumentos() {
    return this.documentos
  }

  public eliminarVacio() {
    this.documentos = this.documentos.filter(d => d.url_archivo != null)
    this.emitirEvento()
  }

  public agregarDocumentoFinalizadoHandler(documentosLeidos: Documento) {
    this.documentos = this.documentos.concat(documentosLeidos)
    this.eliminarVacio()
    this.emitirEvento()
  }
}
