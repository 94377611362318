import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { CortesOutsourcing } from "../interfaces/cortes-outsourcing";
import { IdNombre } from "../interfaces/id-nombre";
import { IdNombreCodigo } from "../interfaces/id-nombre-codigo";
import { Page } from "../interfaces/page";

@Injectable({
  providedIn: "root"
})
export class OutsourcingService {

  recurso: string = "/outsourcing";

  constructor(private http: HttpClient) { }

  public findAll({
    pageNumber = 1,
    pageSize = 1000,
    q = null,
    responsableId = null,
    asistenteId = null,
    auxiliarId = null,
    traeDocumentacion = null,
    clienteId = null,
    digitoVerificador = null,
    recepcionDocumentacion = null,
    grupoEconomicoId = null,
    desdeFechaLimiteCarga = null,
    hastaFechaLimiteCarga = null,
    estado = null,
    mesDesdeCorte = null,
    mesHastaCorte = null,
    orderField = null,
    orderCriteria = null,
  } = {}): Observable<Page<CortesOutsourcing>> {
    let params = new URLSearchParams([]);
    if (pageNumber) params.append("pageNumber", pageNumber.toString());
    if (pageSize) params.append("pageSize", pageSize.toString());
    if (q) params.append("q", q);
    if (responsableId) params.append("responsableId", responsableId);
    if (asistenteId) params.append("asistenteId", asistenteId);
    if (auxiliarId) params.append("subasistenteId", auxiliarId);
    if (traeDocumentacion) params.append("traeDocumentacion", traeDocumentacion);
    if (clienteId) params.append("clienteId", clienteId);
    if (digitoVerificador) params.append("digitoVerificador", digitoVerificador);
    if (recepcionDocumentacion) params.append("recepcionDocumentacion", recepcionDocumentacion);
    if (grupoEconomicoId) params.append("grupoEconomicoId", grupoEconomicoId);
    if (desdeFechaLimiteCarga) params.append("desdeFechaLimiteCarga", desdeFechaLimiteCarga);
    if (hastaFechaLimiteCarga) params.append("hastaFechaLimiteCarga", hastaFechaLimiteCarga);
    if (estado) params.append("estado", estado);
    if (mesDesdeCorte) params.append("mesDesdeCorte", mesDesdeCorte);
    if (mesHastaCorte) params.append("mesHastaCorte", mesHastaCorte);
    if (orderField) params.append("orderField", orderField);
    if (orderCriteria) params.append("orderCriteria", orderCriteria);
    return this.http.get<Page<CortesOutsourcing>>(`${environment.apiEndpoint}${this.recurso}?${params.toString()}`);
  }

  public notificarDocumentacion(corte: CortesOutsourcing, traeDocumentacion: boolean) {
    let personal: IdNombre = JSON.parse(localStorage.getItem("sige_personal"));
    if (traeDocumentacion) {
      let endpoint = this.recurso + "/" + corte.id + "/traeDocumentacion/" + personal.id;
      return this.http.get(environment.apiEndpoint + endpoint, {});
    } else {
      let endpoint = this.recurso + "/" + corte.id + "/noTraeDocumentacion/" + personal.id;
      return this.http.get(environment.apiEndpoint + endpoint, {});
    }
  }

  public cargaFechaRecepcion(corte: CortesOutsourcing, fecha: string) {
    let personal: IdNombre = JSON.parse(localStorage.getItem("sige_personal"));
    let endpoint = this.recurso + "/" + corte.id + "/recibirDocumentacion/" + personal.id + "?fechaHoraRecepcion=" + fecha;
    return this.http.get(environment.apiEndpoint + endpoint, {});
  }

  public cerrarCorte(corte: CortesOutsourcing, ultimoAsiento: number) {
    let personal: IdNombre = JSON.parse(localStorage.getItem("sige_personal"));
    let endpoint;
    if (ultimoAsiento) {
      endpoint = this.recurso + "/" + corte.id + "/cerrar/" + personal.id + "?ultimoAsiento=" + ultimoAsiento;
    } else {
      endpoint = this.recurso + "/" + corte.id + "/cerrar/" + personal.id;
    }

    return this.http.get(environment.apiEndpoint + endpoint, {});
  }

  public abrirCorte(corte: CortesOutsourcing) {
    let endpoint = this.recurso + "/" + corte.id + "/abrir";
    return this.http.get(environment.apiEndpoint + endpoint, {});
  }

  public documentacionOtrasAreas(corte: CortesOutsourcing) {
    let endpoint = this.recurso + "/" + corte.id + "/documentacionOtrasAreas";
    return this.http.get(environment.apiEndpoint + endpoint);
  }

  public findGrupoEconomicos() {
    let endpoint = "/gruposeconomicos?habilitado=S";
    return this.http.get(environment.apiEndpoint + endpoint);
  }

  public findClientes() {
    let endpoint = "/clientes?habilitado=S";
    return this.http.get(environment.apiEndpoint + endpoint);
  }

  public findResponsables() {
    let endpoint = "/responsables/04";
    return this.http.get(environment.apiEndpoint + endpoint);
  }

  public findAsistentes() {
    let endpoint = "/asistentes/04";
    return this.http.get(environment.apiEndpoint + endpoint);
  }
}
