import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef, ViewEncapsulation } from "@angular/core";
import { PlantillasObservaciones } from "../../../interfaces/plantillas-observaciones";
import { Observacion } from "../../../interfaces/observacion";
import { ObservacionesService } from "../../../services/observaciones.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MailService } from "src/app/services/mail.service";
import { Page } from "src/app/interfaces/page";
const swal = require("sweetalert");

@Component({
  selector: "app-observaciones",
  templateUrl: "./observaciones.component.html",
  styleUrls: ["./observaciones.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class ObservacionesComponent implements OnInit {

  @ViewChild("modalCumplir") modalCumplir: ElementRef;
  @ViewChild("modalObservaciones") modalObservaciones: ElementRef;

  public form: FormGroup;

  @Input() set padreId(value: number) {
    this.init(value);
  }

  @Input() set update(value: number) {
    this.init(this._padreId);
  }
  @Input() mailObservaciones: boolean = false;


  @Input() categoriaId: string;
  @Input() recurso: string;
  @Input() plantillas: PlantillasObservaciones;
  @Input() plantillaSeleccionada: string = null;
  @Input() reversed: boolean = false;
  @Input() sendEmail: boolean = false;
  @Input() editable: boolean = true;

  @Output() agregar = new EventEmitter();

  @Output() openModalMail = new EventEmitter();

  public _padreId: number;
  public caso: string = null;

  observaciones: Array<Observacion>;

  constructor(formBuilder: FormBuilder, private observacionesService: ObservacionesService, private mailService: MailService) {
    this.form = formBuilder.group({
      mensaje: [null, Validators.required],
      plantilla: [null, false]
    });
  }

  ngOnInit(): void {
  }

  public init(padreId: number) {
    this._padreId = null;
    this.observaciones = [];
    this.form.reset();
    if (this.recurso && padreId) {
      this._padreId = padreId;
      this.observacionesService.listar(this.recurso, padreId)
        .subscribe((response: Array<Observacion> | Page<Observacion>) => {
          if (Array.isArray(response))
            this.observaciones = this.reversed ? response.reverse() : response;
          else this.observaciones = this.reversed ? response.content.reverse() : response.content;

          if (this.observaciones) {
            this.observaciones.forEach(element => {
              element.mensaje = element.mensaje.replace(/<img .*?>/g, " ");
            });
          }
        })
    };
  }

  public agregarObservacion(sendEmail: boolean) {

    if (this.form.invalid)
      return;

    let observacion = { mensaje: this.form.controls.mensaje.value, id: 0, fechahora: "", usuario: JSON.parse(localStorage.getItem("sige_personal")) };
    this.observacionesService.agregar(this.recurso, this._padreId, observacion, sendEmail)
      .subscribe((response: Observacion) => {
        this.reversed ? this.observaciones.splice(0, 0, response) : this.observaciones.push(response);
        this.form.reset();
        this.plantillaSeleccionada = null;
        swal({
          title: "Guardado",
          text: "La observación se guardó exitosamanete.",
          icon: "success",
          buttons: false,
          timer: 800
        });
        if (sendEmail)
          setTimeout(() => {
            this.mailService.observacionAuditoria(response.id);
          }, 1600);
        this.agregar.emit(response);
      });
  }

  public onSelectPlantillaObservaciones($event): void {
    this.form.controls.mensaje.setValue(this.form.controls.plantilla.value);
  }
  public openModalMailObservaciones($event) {
    this.openModalMail.emit();
  }
}
