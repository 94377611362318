import { Component, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import modalUtils from 'src/app/utils/modal';
import { ContactosClientesService } from '../../../services/clientes/contactos-clientes.service';
import { ContactoCliente } from '../../../interfaces/contacto-cliente';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import * as moment from 'moment';
import { NuevoContactoComponent } from '../nuevo-contacto/nuevo-contacto.component';
import { ContactosService } from 'src/app/services/contactos.service';
import { Page } from 'src/app/interfaces/page';
import { Contacto } from 'src/app/interfaces/contacto';

@Component({
  selector: 'app-listado-contactos',
  templateUrl: './listado-contactos.component.html',
  styleUrls: ['./listado-contactos.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ListadoContactosComponent implements OnInit {

  @Output() contactoSeleccionado = new EventEmitter<ContactoCliente>();
  @Output() closeModalContactos = new EventEmitter();
  @Output() contactosSeleccionados = new EventEmitter<Array<ContactoCliente>>();
  @Output() onCloseModalContactos = new EventEmitter();

  @ViewChild('listadoClientes') listadoClientes: ModalDirective;
  @ViewChild('modalAltaContacto') modalAltaContacto: ModalDirective;
  @ViewChild("ModaNuevoContacto") ModaNuevoContacto: NuevoContactoComponent; 

  public loadingModal = false;
  public formBuscar: FormGroup;
  public formAlta: FormGroup;
  public bsConfig: Partial<BsDatepickerConfig>;
  public bsValue = moment(new Date()).format("DD/MM/YYYY");
  //*Datos
  public listado: Array<ContactoCliente>;
  public mailsSeleccionados: Array<ContactoCliente>;
  public listadoMails:any;

  //*Paginacion
  public currentPage = 1;
  public userPageSize = 20;
  public totalPages = 0;
  public totalElements = 0;

  //*Seleccion
  public selectAll = false;
  public text: string;

  constructor(private localeService: BsLocaleService,
    private formBuilder: FormBuilder,
    private contactosClientesService: ContactosClientesService,
    private contactoService: ContactosService) {
    this.formBuscar = this.formBuilder.group({
      cliente: [null]
    });
    this.formAlta = this.formBuilder.group({
      apellidoNombre: [null, Validators.required],
      cuit: [null],
      fechaNacimiento: [null],
      mail: [null,Validators.required],
      telefono: [null],
      empresa: [null],
      referente: [null],
      novedades: [null],
      eventos: [null],
      cumpleanos: [null],

    });
    this.bsConfig = Object.assign({},
      {
        containerClass: "theme-angle",
        dateInputFormat: 'DD/MM/YYYY'
      });
      this.localeService.use("es");
    this.mailsSeleccionados = [];
    this.text = '';
  }

  ngOnInit(): void {
  }

  public findAll() {
    this.loadingModal = true;
    this.selectAll = false;
    this.contactosClientesService.findClientesContactos({
      pageNumber: this.currentPage,
      pageSize: this.userPageSize,
      agrupado:true,
      descripcion: this.formBuscar.get('cliente').value
    }).subscribe(response => {
      this.listado = response.content;
      this.totalPages = response.totalPages;
      this.totalElements = response.totalElements;
      this.setFalse();
      this.loadingModal = false;
    });
  }

  public openModal() {
    modalUtils.open(this.listadoClientes);
    this.mailsSeleccionados = [];
    this.text = '';
    this.findAll();
  }

  public pageChanged(event: any) {
    this.currentPage = event.page;
    this.findAll();
    if (window.scrollY) {
      window.scroll(0, 0);
    }
  }

  public setFalse() {
    this.listado.forEach(c => {
      c.seleccionado = false;
    });
  }

  public selectAllEmails() {
    this.text = '';
    if (this.selectAll)
      this.listado.forEach(c => {
        this.mailsSeleccionados.splice(this.mailsSeleccionados.indexOf(c), 1);
      });
    else
      this.listado.forEach(c => {
        this.mailsSeleccionados.push(c);
      });
    this.listado.forEach(c => {
      c.seleccionado = !this.selectAll;
      if (c.seleccionado)
        this.text = this.text.concat(c.contacto.email+'; ');
    })
  }

  public selectEmail(item: ContactoCliente) {
    this.text = '';
    item.seleccionado = !item.seleccionado;
    if (item.seleccionado)
      this.mailsSeleccionados.push(item);
    else this.mailsSeleccionados = this.mailsSeleccionados.filter(c => c !== item);
    this.text = this.text.concat(this.mailsSeleccionados.map(c => c.contacto.email+'; ').join(""));
  }

  public closeModal() {
    this.text = this.text.split(" ").join(""); //Elimina espacios en blanco
    this.contactosSeleccionados.emit(this.mailsSeleccionados)
    this.closeModalContactos.emit(this.text);
    this.contactoSeleccionado.emit(this.mailsSeleccionados[0]);
    this.formBuscar.reset();
    modalUtils.hide(this.listadoClientes);
  }
  public altaContacto(){
    this.findContactos();
    modalUtils.hide(this.listadoClientes);
    setTimeout(() => {
      this.ModaNuevoContacto.openNuevoContacto();
    }, 600);
  }
  public valueRequired(value: string, form: FormGroup) {
    return form.controls[value].hasError("required") && (form.controls[value].dirty || form.controls[value].touched);
  }
  public findContactos() {
    this.contactoService.findContactos({
      pageNumber: this.currentPage,
      pageSize: 10000
    }).subscribe((response: Page<Contacto>) => {
      this.listadoMails = Array.from(new Set(response.content.map(s => s.mail)));
    });
  }

  public onCloseModal(){
    this.listadoClientes.hide();
    this.onCloseModalContactos.emit();
    this.formBuscar.reset();
  }
  public closeNuevoContacto(event){
    setTimeout(() => {
      this.openModal();
    }, 700);
  }
}
