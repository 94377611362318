import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BeneficiarioDdjj } from '../interfaces/beneficiario-ddjj';

@Injectable({
  providedIn: 'root'
})
export class DdjjService {

  recurso = '/beneficiarioDJ'

  constructor(private http: HttpClient) { }

  public findAll(clienteId: number): Observable<Array<BeneficiarioDdjj>> {
    return this.http.get<Array<BeneficiarioDdjj>>(`${environment.apiEndpoint}${this.recurso}/cliente/${clienteId}`)
  }
  public findById(beneficiario: BeneficiarioDdjj): Observable<BeneficiarioDdjj> {
    return this.http.get<BeneficiarioDdjj>(`${environment.apiEndpoint}${this.recurso}/${beneficiario.id}`)
  }
  public add(beneficiario: BeneficiarioDdjj): Observable<BeneficiarioDdjj> {
    return this.http.post<BeneficiarioDdjj>(`${environment.apiEndpoint}${this.recurso}`, beneficiario)
  }
  public edit(beneficiario: BeneficiarioDdjj): Observable<BeneficiarioDdjj> {
    return this.http.put<BeneficiarioDdjj>(`${environment.apiEndpoint}${this.recurso}/${beneficiario.id}`, beneficiario)
  }
  public delete(beneficiario: BeneficiarioDdjj): Observable<any> {
    return this.http.delete<any>(`${environment.apiEndpoint}${this.recurso}/${beneficiario.id}`)
  }
}
