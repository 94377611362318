import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import modalUtils from 'src/app/utils/modal';
import { forkJoin } from 'rxjs';
const swal = require("sweetalert");
import { ListadoContactosComponent } from '../listado-contactos/listado-contactos.component';

//*Servicios
import { GrupoEconomicoService } from 'src/app/services/grupo-economico.service';
import { ComprobantesPagosService } from '../../../services/comprobantes-pagos.service';

//*Interfaces
import { GrupoEconomico } from '../../../interfaces/grupo-economico';
import { ClienteBase } from '../../../interfaces/cliente';
import { ContactoCliente } from '../../../interfaces/contacto-cliente';
import { ComprobantePago } from 'src/app/interfaces/comprobante-pago';

//*Enums
import { TIPO_COMPROBANTE } from 'src/app/enums/tipo-comprobante.enum';
import { ERROR_MESSAGES } from 'src/app/enums/error-messages';
import { HABILITADO } from 'src/app/enums/habilitado';
import { ExportarDatosComponent } from '../exportar-datos/exportar-datos.component';
import { ClienteBaseService } from 'src/app/services/clientes/cliente-base.service';
import { Page } from 'src/app/interfaces/page';

@Component({
  selector: 'app-modal-recepcion-comprobantes',
  templateUrl: './modal-recepcion-comprobantes.component.html',
  styleUrls: ['./modal-recepcion-comprobantes.component.scss']
})
export class ModalRecepcionComprobantesComponent implements OnInit {

  @Input() grupoEconomico: any = undefined;
  @Output() saved = new EventEmitter();

  @ViewChild('modalRecepcionComprobante') modalRecepcionComprobante: ModalDirective;
  @ViewChild('contactos') contactos: ListadoContactosComponent;
  @ViewChild("exportarDatos") modalExportarDatos: ExportarDatosComponent;

  public formRecepcionComprobantes: FormGroup;
  public formFiltros: FormGroup;
  public formTabla: FormGroup;
  public flechaOcultar: boolean = true;
  public datosTabla: Array<ComprobantePago>;
  public pageNumber: number = 1;
  public pageSize: number = 1000;
  public orderCriteria: string = "ASC";
  public orderField: string = "pDesc";
  public tipoComprobante: string = "RecepcionComprobante";
  public grupoEconomicoId: number = null;
  public ordenAsc: boolean;
  public campoOrden: string;
  public filtrar = true

  //*Datos
  public gruposEconomicos: Array<GrupoEconomico> = [];
  public clientes: Array<ClienteBase>;
  public contactoSeleccionado: ContactoCliente = null;
  public comprobantesPagos: Array<ComprobantePago>;

  //*Listados
  public listadoGruposEconomicos: Array<{ id: number, text: string }> = [];
  public listadoCodigoFondoFijo: Array<{ id: number, text: string }> = [];
  public listadoClientes: Array<{ id: number, text: string }> = [];

  //*Exportar
  public listadoExportar: Array<any>;
  public exportConfig: any;

  constructor(private formbuilder: FormBuilder,
    private grupoEconomicoService: GrupoEconomicoService,
    private clienteBaseService: ClienteBaseService,
    private comprobantesPagosService: ComprobantesPagosService) {
    this.formFiltros = this.formbuilder.group({
      grupoEconomico: [null, Validators.required],
      codigoFondos: [{ value: null, disabled: true }],
      cliente: [null, Validators.required],
    });

    this.formRecepcionComprobantes = this.formbuilder.group({
      responsable: [null, Validators.required],
      mail: [null, Validators.required],
      telefono: [null, Validators.required],
      interno: [null],
      celular: [null, Validators.required],
      repiteMail: [null]
    });
    this.formTabla = this.formbuilder.group({
      codigo: [{ value: null, disabled: true }],
      responsable: [{ value: null, disabled: true }],
      mail: [{ value: null, disabled: true }],
      cliente: [{ value: null, disabled: true }],
      telefono: [{ value: null, disabled: true }],
      interno: [{ value: null, disabled: true }],
      celular: [{ value: null, disabled: true }],
    });


  }

  ngOnInit(): void {
    this.findFiltros();
  }

  public findFiltros() {
    forkJoin([
      this.grupoEconomicoService.findAll({ habilitado: HABILITADO.SI }),
      this.clienteBaseService.findAll({ habilitado: HABILITADO.SI })
    ]).subscribe(([gruposEconomicos, clientes]) => {
      this.gruposEconomicos = gruposEconomicos.content;
      this.clientes = clientes.content;

      this.listadoGruposEconomicos = gruposEconomicos.content.map(ge => ({ id: ge.id, text: ge.nombre }));
      this.listadoCodigoFondoFijo = gruposEconomicos.content.map(c => ({ id: c.id, text: c.codigoFondoFijo }));
      this.listadoClientes = this.clientes.map(c => ({ id: c.id, text: c.nombre }));
    });
  }

  public findAllTabla() {
    this.grupoEconomicoId = this.formFiltros.controls.grupoEconomico.value;
    forkJoin([
      this.comprobantesPagosService.findComprobantes({
        tipoComprobante: TIPO_COMPROBANTE.RECEPCION_COMPROBANTE,
        grupoEconomicoId: this.grupoEconomicoId
      })
    ]).subscribe(([comprobantePago]) => {
      this.comprobantesPagos = comprobantePago.content;
      this.datosTabla = comprobantePago.content;
      this.datosTabla.forEach(dato => {
        dato.editar = true;
      })
      this.campoOrden = 'nombre'
      this.ordenAsc = true
      this.ordenarXCampoDoble('clienteFisico', 'nombre')
    })
  }

  public findAllaFiltroCliente() {
    this.datosTabla = [];
    this.formFiltros.controls.cliente.value.forEach(cliente => {
      this.comprobantesPagos.forEach(comprobante => {
        if (cliente === comprobante.clienteFisico?.id) {
          comprobante.editar = true;
          this.datosTabla.push(comprobante);
        }
      })
    })
    if (!this.datosTabla.length) {
      this.datosTabla = this.comprobantesPagos;
      this.datosTabla.forEach(dato => {
        dato.editar = true;
      })
    }
  }
  public clearTabla() {
    this.datosTabla = [];
  }
  public deleteComprobante(objeto: any) {
    let indice = this.datosTabla.indexOf(objeto);
    this.comprobantesPagosService.deleteComprobantePago(this.datosTabla[indice]).subscribe((comprobante: ComprobantePago) => {
      swal({
        title: "Guardado",
        text: "El registro se guardó exitosamente.",
        icon: "success",
        button: false,
        timer: 1600
      }), (error) => {
        swal("¡Lo sentimos!", error.error?.message || ERROR_MESSAGES.GUARDADO, 'error')
      }
    });
    this.datosTabla.splice(indice, 1);
  }

  public editComprobante(objeto: any) {
    let indice = this.datosTabla.indexOf(objeto);
    if (this.datosTabla[indice].editar) {
      this.formTabla.enable();
      this.formTabla.controls.codigo.setValue(this.datosTabla[indice].codigo);
      this.formTabla.controls.responsable.setValue(this.datosTabla[indice].responsable);
      this.formTabla.controls.mail.setValue(this.datosTabla[indice].email);
      this.formTabla.controls.cliente.setValue(this.datosTabla[indice].clienteFisico?.nombre);
      this.formTabla.controls.telefono.setValue(this.datosTabla[indice].telefono);
      this.formTabla.controls.interno.setValue(this.datosTabla[indice].interno);
      this.formTabla.controls.celular.setValue(this.datosTabla[indice].cp_celular);
      this.datosTabla[indice].editar = false
    } else {
      this.datosTabla[indice].editar = true
      this.datosTabla[indice].codigo = this.formTabla.controls.codigo.value || "";
      this.datosTabla[indice].responsable = this.formTabla.controls.responsable.value || "";
      this.datosTabla[indice].email = this.formTabla.controls.mail.value || "";
      this.datosTabla[indice].clienteFisico.nombre = this.formTabla.controls.cliente.value || "";
      this.datosTabla[indice].telefono = this.formTabla.controls.telefono.value || "";
      this.datosTabla[indice].interno = this.formTabla.controls.interno.value || "";
      this.datosTabla[indice].cp_celular = this.formTabla.controls.celular.value || "";
      this.datosTabla[indice].superTel = "";
      this.formTabla.reset();
      this.formTabla.disable();
      this.comprobantesPagosService.editComprobantePago(this.datosTabla[indice]).subscribe((comprobante: ComprobantePago) => {
        swal({
          title: "Guardado",
          text: "El registro se guardó exitosamente.",
          icon: "success",
          button: false,
          timer: 1600
        }), (error) => {
          swal("¡Lo sentimos!", error.error?.message || ERROR_MESSAGES.GUARDADO, 'error')
        }
      });
    }
  }

  // public filtrarClientes() {
  //   this.listadoClientes = this.clientes.filter(c => c.grupoEconomicoDto.id === this.formFiltros.controls.grupoEconomico.value).map(g => ({ id: g.id, text: g.nombre }))
  // }

  public filtrarClientes() {
    this.formFiltros.controls.cliente.disable()
    if (this.formFiltros.getRawValue().grupoEconomico) {
      let codigo = this.gruposEconomicos.find(g => g.id == this.formFiltros.getRawValue().grupoEconomico).codigo;
      this.grupoEconomicoService.findClientes(codigo, HABILITADO.SI)
        .subscribe((response: Page<ClienteBase>) => {
          this.listadoClientes = response.content.map(g => ({ id: g.id, text: g.nombre }));
          this.formFiltros.controls.cliente.enable()
        })
    }
    else {
      this.clienteBaseService.findAll({
        habilitado: HABILITADO.SI
      }).subscribe(clientes => {
        this.listadoClientes = clientes.content.map(c => ({ id: c.id, text: c.nombre }));
        this.formFiltros.controls.cliente.enable()
      })
    }
  }

  public async setGrupoEconomico() {
    this.formFiltros.controls.grupoEconomico.patchValue(this.grupoEconomico?.id || null)
    this.formFiltros.controls.grupoEconomico.disable()
  }

  public findGrupoEconomico(): GrupoEconomico {
    return this.gruposEconomicos.find(ge => ge.id === this.formFiltros.controls.grupoEconomico.value)
  }

  public setCodigoFondos() {
    this.formFiltros.controls.codigoFondos.patchValue(this.formFiltros.controls.grupoEconomico.value)
    this.filtrar ? this.filtrarClientes() : null
  }

  public generateComprobantePago(grupoEconomico: GrupoEconomico, idCliente: number): ComprobantePago {
    return {
      id: null,
      grupoEconomico: grupoEconomico,
      tipoComprobante: TIPO_COMPROBANTE.RECEPCION_COMPROBANTE,
      codigo: grupoEconomico?.codigoFondoFijo || '',
      responsable: this.formRecepcionComprobantes.controls.responsable.value,
      email: this.formRecepcionComprobantes.controls.mail.value,
      telefono: this.formRecepcionComprobantes.controls.telefono.value,
      interno: this.formRecepcionComprobantes.controls.interno?.value || '',
      cp_celular: this.formRecepcionComprobantes.controls.celular.value,
      habilitado: true,
      politicaPago: '',
      diaPago: '',
      cp_super: '',
      superMail: '',
      superTel: '',
      clienteFisico: this.clientes.find(c => c.id === idCliente)
    }
  }

  public getContacto(event: ContactoCliente) {
    this.closeListadoContactos(event)
    this.contactoSeleccionado = event;
    if (this.contactoSeleccionado) {
      this.formRecepcionComprobantes.controls.responsable.patchValue(this.contactoSeleccionado.contacto.descripcion)
      this.formRecepcionComprobantes.controls.mail.patchValue(this.contactoSeleccionado.contacto.email)
    }
  }

  public submitForm() {
    let grupoEconomicoSeleccionado = this.findGrupoEconomico()
    let newComprobante: Array<ComprobantePago> = [];
    if (this.formRecepcionComprobantes.controls.repiteMail.value)
      this.listadoClientes.forEach(cliente => {
        newComprobante.push(this.generateComprobantePago(grupoEconomicoSeleccionado, cliente.id))
      })
    else
      this.formFiltros.controls.cliente.value.forEach(idCliente => {
        newComprobante.push(this.generateComprobantePago(grupoEconomicoSeleccionado, idCliente))
      })
    forkJoin(
      newComprobante.map(c => this.comprobantesPagosService.addComprobante(c))
    ).subscribe(response => {
      this.findAllTabla()
      swal({
        title: "Guardado exitosamente",
        text: "El comprobante se guardó exitosamente.",
        icon: "success",
        buttons: false,
        timer: 1600
      })
      this.formRecepcionComprobantes.reset()
      //this.formFiltros.controls.cliente.reset()
      this.saved.emit()
    }, (error) => {
      swal('¡Lo sentimos!', error.error?.message || ERROR_MESSAGES.GUARDADO, 'error')
    })
  }

  public valueRequired(value: string, form: FormGroup) {
    return form.controls[value].hasError("required") && (form.controls[value].dirty || form.controls[value].touched);
  }

  public openModal() {
    modalUtils.open(this.modalRecepcionComprobante);
  }
  public resetModal() {
    this.flechaOcultar = false;
    this.formFiltros.reset()
    this.formRecepcionComprobantes.reset()
  }
  public setForm() {
    this.filtrar = false
    this.resetModal()
    if (this.grupoEconomico != undefined) {
      this.setGrupoEconomico()
      this.filtrar = true
      this.setCodigoFondos()
    } else this.filtrar = true
  }

  public openModalContactos() {
    modalUtils.hide(this.modalRecepcionComprobante);
    setTimeout(() => {
      this.contactos.openModal();
    }, 600);
  }

  public closeListadoContactos(event) {
    setTimeout(() => {
      this.openModal();
    }, 700);
  }

  public obtenerListadoExportar() {
    this.exportConfig = {
      title: "Recepcion de comprobantes",
      "showColumns": ["codigo", "responsable", "mail", "cliente", "telefono", "interno", "celular"],
      "aliasColumns": {
        codigo: "Codigo", responsable: "Responsable", mail: "Mail",
        cliente: "Cliente", telefono: "Telefono", interno: "Interno", celular: "Celular"
      }
    }
    this.listadoExportar = this.datosTabla.map(item => {
      return {
        codigo: item?.codigo || '-',
        responsable: item?.responsable || '-',
        mail: item?.email || '-',
        cliente: item?.clienteFisico?.nombre || '-',
        telefono: item?.telefono || '-',
        interno: item?.interno || '-',
        celular: item?.cp_celular || '-'
      }
    })
  }

  public exportarTabla() {
    this.obtenerListadoExportar();
    this.modalExportarDatos.openModal();
  }
  public ordenarXCampo(campo: string) {
    this.comprobantesPagos.sort((a, b) => {
      if (this.ordenAsc)
        return (a[campo] > b[campo]) ? 1 : ((b[campo] > a[campo]) ? -1 : 0);
      else
        return (b[campo] > a[campo]) ? 1 : ((a[campo] > b[campo]) ? -1 : 0);
    });
  }

  public ordenarXCampoDoble(campo1: string, campo2: string) {
    this.comprobantesPagos.sort((a, b) => {
      if (this.ordenAsc)
        return (a[campo1][campo2] > b[campo1][campo2]) ? 1 : ((b[campo1][campo2] > a[campo1][campo2]) ? -1 : 0);
      else
        return (b[campo1][campo2] > a[campo1][campo2]) ? 1 : ((a[campo1][campo2] > b[campo1][campo2]) ? -1 : 0);
    });
  }

  public cambiarOrden(campo1: string, campo2: string = null) {
    this.campoOrden = campo2 ? campo2 : campo1
    this.ordenAsc = !this.ordenAsc
    campo2 ? this.ordenarXCampoDoble(campo1, campo2) : this.ordenarXCampo(campo1)
  }
}
