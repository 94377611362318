export default (() => {


    return {
        /**
         * 
         * @param value valor a redondear
         * @param precision cantidad máxima de decimales. Debe ser mayor que 0. 
         * @returns 
         */
        round(value: number, precision: number): number {
            let auxPresicion = Math.pow(10, precision);
            return Math.round(value * auxPresicion) / auxPresicion;
        }
    }
})();