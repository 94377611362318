import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Router } from "@angular/router";
import { catchError, switchMap, tap } from "rxjs/operators";
import { TokenResponse } from "../interfaces/token-response";
import { Observable, throwError } from "rxjs";
import { IdNombreCodigo } from "../interfaces/id-nombre-codigo";
import { Page } from "../interfaces/page";
import { User } from "../interfaces/user";
import { Password } from "../interfaces/password";
import { Authority } from "../interfaces/authority";
import { AuthoritiesResponse } from "../interfaces/authorities-response";
import { Authorities } from "../enums/authorities";
import { GoogleSheetService } from "./google-sheet.service";

const swal = require("sweetalert");

@Injectable({
  providedIn: "root"
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private googleSheetService: GoogleSheetService) {
  }

  public login(username: string, password: string): Observable<TokenResponse> {

    this.setCredentials(username, password);

    return this.http.post<TokenResponse>(
      `${environment.apiEndpoint}/oauth/token?grant_type=password&username=${username}&password=${password}`,
      {},
      { headers: this.getCredentials() }
    );
  }

  public authenticate(username: string, password: string): Observable<any> {
    return this.login(username, password).pipe(
      switchMap((responseToken: TokenResponse) => {
        this.setAuthentication(responseToken);
        return this.checkToken(responseToken.access_token)
      }),
      switchMap((responseAuthorities: AuthoritiesResponse) => {
        this.setAuthorization(responseAuthorities);
        return this.http.get(`${environment.apiEndpoint}/acceso/${responseAuthorities.client_id}`);
      }),
      tap((personal: IdNombreCodigo) => {
        this.setUserLogged(personal);
      })
    );
  }

  public checkToken(accesstoken: string): Observable<AuthoritiesResponse> {
    return this.http.post<AuthoritiesResponse>(
      `${environment.apiEndpoint}/oauth/check_token?token=${accesstoken}`,
      {},
      { headers: this.getCredentials() }
    );
  }

  public refreshToken(): Observable<TokenResponse> {
    return this.http.post<TokenResponse>(
      `${environment.apiEndpoint}/oauth/token?grant_type=refresh_token&refresh_token=${this.getRefreshToken()}`,
      {},
      { headers: this.getCredentials() }
    ).pipe(
      tap((data: TokenResponse) => {
        this.setAuthentication(data);
      }),
      catchError(error => {
        debugger;
        return throwError(error);
      })
    );
  }

  public getAccessToken(): string {
    let datosAcceso = this.getAuthentication();
    if (datosAcceso && datosAcceso.access_token) {
      return datosAcceso.access_token;
    } else {
      return null;
    }
  }

  private getRefreshToken(): string {
    let datosAcceso = this.getAuthentication();
    if (datosAcceso && datosAcceso.refresh_token) {
      return datosAcceso.refresh_token;
    } else {
      return null;
    }
  }

  public isAuthorized(authority: Authorities | String | Authorities[] | String[]): boolean {

    if (!this.userHasAuthorities())
      return false;

    let authorities = (Array.isArray(authority) ? authority : [authority])

    return this.getAuthorization()
      .authorities
      .find(a =>
        authorities.indexOf(a) !== -1
      ) !== undefined;
  }

  private setCredentials(username: string, password: string): void {
    sessionStorage.setItem("credentials", btoa(username + ':' + password));
  }

  private getCredentials(): HttpHeaders {
    return new HttpHeaders({
      Authorization: "Basic " + sessionStorage.getItem("credentials")
    })
  }

  public isUserLoggedIn(): boolean {
    return this.getAuthentication() !== null;
  }

  public userHasAuthorities(): boolean {
    return this.getAuthorization() !== null;
  }

  public userHaveCredentials(): boolean {
    return sessionStorage.getItem("credentials") !== null;
  }

  public userCanRefreshToken(): boolean {
    return this.userHaveCredentials() && this.getRefreshToken() !== null;
  }

  getHomeByAuthorities() {
    return ["home"];
  }

  public setAuthentication(tokenResponse: TokenResponse): void {
    localStorage.setItem("sige_authentication", JSON.stringify(tokenResponse));
  }

  public getAuthentication(): TokenResponse {
    let sige_authentication = localStorage.getItem("sige_authentication") || null;
    return sige_authentication ? JSON.parse(sige_authentication) : null;
  }

  public setAuthorization(authoritiesResponse: AuthoritiesResponse): void {
    localStorage.setItem("sige_authorization", JSON.stringify(authoritiesResponse));
  }

  public getAuthorization(): AuthoritiesResponse {
    let sige_authorization = localStorage.getItem("sige_authorization") || null;
    return sige_authorization ? JSON.parse(localStorage.getItem("sige_authorization")) as AuthoritiesResponse : null;
  }

  public setUserLogged(personal: IdNombreCodigo): void {
    localStorage.setItem('sige_personal', JSON.stringify(personal));
  }

  public getUserLogged(): IdNombreCodigo {
    let sige_personal = localStorage.getItem("sige_personal");
    return sige_personal ? JSON.parse(sige_personal) as IdNombreCodigo : null;
  }

  logout() {
    localStorage.removeItem("filtrosClientes");
    localStorage.removeItem("filtrosTareas");
    localStorage.removeItem("filtros");
    localStorage.removeItem("filtrosAuditoria");
    localStorage.removeItem("filtrosGrupoEconomico");
    localStorage.removeItem("filtrosRecupero");
    localStorage.removeItem("filtrosLaboral");
    localStorage.removeItem("filtrosOutsourcing");
    localStorage.removeItem("filtrosImpuestos");
    localStorage.removeItem("sige_authorization");
    localStorage.removeItem("sige_authentication");
    localStorage.removeItem("sige_personal");
    this.googleSheetService.signOut();
    this.router.navigate(["login"]);
  }

  public findUsers({
    pageNumber = null,
    pageSize = null,
    orderCriteria = null,
    orderField = null,
    habilitado = "TODOS"
  } = {}): Observable<Page<User>> {

    let params = new URLSearchParams([]);

    if (pageNumber)
      params.append("pageNumber", pageNumber);
    if (pageSize)
      params.append("pageSize", pageSize);
    if (orderCriteria)
      params.append("orderCriteria", orderCriteria);
    if (orderField)
      params.append("orderField", orderField);
    if (habilitado)
      params.append("habilitado", habilitado);

    return this.http.get<Page<User>>(`${environment.apiEndpoint}/accesos?${params.toString()}`);
  }
  public createUsuario(usuario: User) {
    let endpoint = "/accesos";
    return this.http.post(environment.apiEndpoint + endpoint, usuario);
  }
  public editUsuario(usuario: User) {
    let endpoint = "/accesos/" + usuario.id;
    return this.http.put(environment.apiEndpoint + endpoint, usuario);
  }
  public changePassword(usuario: User, password: Password) {
    let endpoint = "/accesos/" + usuario.id + "/password";
    return this.http.put(environment.apiEndpoint + endpoint, password);
  }
  public findAuthorities(): Observable<Page<Authority>> {
    return this.http.get<Page<Authority>>(`${environment.apiEndpoint}/authorities`);
  }
  public setAuthorities(idCliente: number, authorities: Array<number>): Observable<boolean> {
    let params = new URLSearchParams([["authoritiesIds", authorities.join(",")]]);
    return this.http.get<boolean>(`${environment.apiEndpoint}/users/${idCliente}/setAuthorities?${params.toString()}`);
  }
}
