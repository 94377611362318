import { Component, Input, OnInit } from '@angular/core';

import { AREA, RECURSO } from "src/app/enums/areas";
import { CategoriaDocumento } from 'src/app/interfaces/categoria-documento';
import { VencimientoBase } from 'src/app/interfaces/vencimiento';
import { DocumentacionService } from "src/app/services/documentacion.service";
import { PersonalService } from "src/app/services/personal.service";
import { Documentacion } from 'src/app/interfaces/documentacion';
import { Documento } from 'src/app/interfaces/documento';
import { Responsable } from "src/app/interfaces/personal";
import txtFile from "src/app/utils/txtFile"
import regex from "src/app/utils/regex";

import { forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-enviar-documentacion',
  templateUrl: './enviar-documentacion.component.html',
  styleUrls: ['./enviar-documentacion.component.scss']
})

export class EnviarDocumentacionComponent implements OnInit {

  private loading: boolean = false;
  private hrefEmail: string = null;

  public _vencimientos: Array<VencimientoBase> = [];
  public _categorias: Array<CategoriaDocumento> = [];
  private _recurso: RECURSO = null;
  private area: AREA = null;
  private responsables: Array<Responsable> = [];

  public obligacionesSeleccionadas: Array<any> = [];

  @Input() set vencimientos(value: Array<VencimientoBase>) {
    this._vencimientos = value.map(v => ({
      id: v.id,
      cliente_fisico: v.cliente_fisico,
      obligacion: v.obligacion,
      fecha_vencimiento: v.fecha_vencimiento,
      documentacion: []
    }));
    this._init();
  }
 
  @Input() set categorias(value: Array<CategoriaDocumento>) {
    this._categorias = value.map(c => ({ id: c.id, nombre: c.nombre }));
    this._categorias.sort((cA, cB) => this.sortCategorias(cA, cB));
    this._init();
  }

  @Input() set recurso(value: RECURSO) {
    this._recurso = value;
    this._init();
  }

  constructor(private documentacionService: DocumentacionService, private personalService: PersonalService) { }

  ngOnInit(): void {
    this._init();
  }

  private _init(): void {

    this.reset();

    if (!this._vencimientos.length || !this._categorias.length || !this._recurso)
      return;

    this.loading = true;

    this.setArea(this._recurso);

    let auxVencimientos: Map<number, Documentacion[]> = new Map<number, Documentacion[]>();

    let observables: Array<any> = this._vencimientos.map(v => {
      auxVencimientos.set(v.id, []);
      return forkJoin(this._categorias.map(categoria =>
        this.documentacionService
          .listar(this._recurso, v.id, categoria.id)
          .pipe(tap((documentacion: Documentacion) => {
            documentacion.selected = false;
            auxVencimientos.get(v.id).push(documentacion);
          }))
      )).pipe(tap(() => {
        auxVencimientos.get(v.id).sort((dA, dB) => this.sortCategorias(dA.categoria, dB.categoria));
      }))
    });

    observables.push(this.personalService.findResponsablesByClienteAndArea(this._vencimientos[0].cliente_fisico.id, this.area))

    forkJoin(observables).subscribe((response: Array<any>) => {
      this._vencimientos.forEach(v => {
        v.documentacion = auxVencimientos.get(v.id);
      });
      this.responsables = response[response.length - 1];
      this.loading = false;
    });
  }

  private documentoVacio(documento: Documento): boolean {
    return !/\w/.test(documento.url_archivo);
  }

  public tieneDocumentos(documentacion: Documentacion): boolean {
    return documentacion.documentos.some(d => !this.documentoVacio(d));
  }

  public vencimientoTieneDocumentacion(vencimiento: VencimientoBase, categoria: CategoriaDocumento) {
    return Array.isArray(vencimiento.documentacion) &&
      vencimiento.documentacion
        .filter(d => (d.categoria.nombre === categoria.nombre))
        .some(d => this.tieneDocumentos(d));
  }

  private getReferenciasArchivos(documentacion: Documentacion): Array<string> {
    return documentacion.documentos
      .filter(d => !this.documentoVacio(d))
      .map(d => `<a href="${d.url_archivo}" target="_blank">${documentacion.categoria.nombre}</a>`);
  }

  private setArea(recurso: RECURSO) {
    switch (recurso) {
      case RECURSO.VENCIMIENTOS:
        this.area = AREA.IMPUESTOS;
        break;
      case RECURSO.LABORAL:
        this.area = AREA.LABORAL;
        break;
    }
  }

  private setEmail(): void {

    if (!this._vencimientos.some(v => v.documentacion.some(d => d.selected)))
      return;

    this.hrefEmail = txtFile.makeEml({
      to: this.responsables.map(r => r.email).filter(email => regex.emailIsValid(email)).join(","),
      subject: "Reenvío De Documentación",
      body: `<h3><b>Cliente:</b>&nbsp;${this._vencimientos[0].cliente_fisico.nombre}</h3>` + this._vencimientos
        .filter(v => v.documentacion.some(d => d.selected))
        .map(v => {
          return `<p><h5><b>Obligaci&oacute;n:&nbsp;</b>${v.obligacion.nombre}</h5><h5><b>Fecha de vencimiento:&nbsp;</b>${v.fecha_vencimiento}</h5><ul><li>
          ${v.documentacion
              .filter(documentacion => documentacion.selected)
              .map(documentacion => this.getReferenciasArchivos(documentacion))
              .join("</li><li>")
            }
          </li></ul></p>`;
        }).join("\n")
    });
  }

  private sortCategorias(ca: CategoriaDocumento, cb: CategoriaDocumento): number {
    return ca.id.localeCompare(cb.id)
  }

  private reset(): void {
    this.loading = false;
    this.hrefEmail = null;
    this.responsables = [];
    this._vencimientos.forEach(v => {
      v.documentacion = this._categorias.map(c => {
        return {
          categoria: {
            id: c.id,
            nombre: c.nombre
          },
          documentos: [],
          selected: false
        };
      });
    });
  }

  public seleccionarTodos(e: any, categoria: CategoriaDocumento): void {
    let checked = e.currentTarget.checked;
    this._vencimientos.forEach(v => {
      v.documentacion.forEach(d => {
        if (d.categoria.id === categoria.id && this.tieneDocumentos(d))
          d.selected = checked;
      });
    });
  }

  public seleccionarDocumentacion(e: any, documentacion: Documentacion): void {
    documentacion.selected = e.currentTarget.checked;
  }

  public descargarEmail(): void {
    this.setEmail();
    txtFile.download("reenviodocumentacion.eml", this.hrefEmail);
  }
  public seleccionarVencimiento($event, vencimiento: any) {
    let checked = $event.currentTarget.checked;
      vencimiento.documentacion.forEach(d => {
      if (this.tieneDocumentos(d))
        d.selected = checked;
    });
  }
  public seleccionarTodosFila($event) {
    this._vencimientos.forEach(v => {
      this.seleccionarVencimiento($event, v)
    });
  }
}