import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
const swal = require("sweetalert");

@Injectable({
  providedIn: "root"
})
export class MailService {

  constructor() { }

  public cumplirVencimiento(id: number) {
    this.downloadEml("impuestos", "cumplir", id);
  }

  public novedadesLaboral(id: number) {
    this.downloadEml("laboral", "novedades", id);
  }

  public envioPreLiquidacionLaboral(id: number) {
    this.downloadEml("laboral", "envioPreLiquidacion", id);
  }

  public confirmacionLaboral(id: number) {
    this.downloadEml("laboral", "confirmacion", id);
  }

  public liquidacionLaboral(id: number) {
    this.downloadEml("laboral", "liquidacion", id);
  }

  public cumplirLaboral(id: number) {
    this.downloadEml("laboral", "cumplir", id);
  }

  public observacionTareas(id: number) {
    this.downloadEml("tareas", "observacion", id);
  }
  public observacionAuditoria(id: number) {
    this.downloadEml("auditoria", "observacion", id);
  }
  public observacionGenerica(recurso: string, id: number) {
    this.downloadEml(recurso, "observacion", id);
  }
  public observacionFyC(recurso: string, id: number) {
    this.downloadEml(recurso, "cobranza", id);
  }
  public solicitudAltaDeFondos( id: number) {
    this.downloadEml("solicitud", "fondo", id);
  }
  public archivosAuditoria(documento: string, id: number) {
    this.downloadEml("02", documento, id);
  }

  public archivosMiembrosAdministrativos(id: number) {
    this.downloadEml("miembros", "adm", id);
  }
  public domicilios(idDomicilio: number) {
    this.downloadEml("domicilio", "cliente", idDomicilio);
  }

  public librosMovimientos(movimientoLibroId: number) {
    this.downloadEml("movimientos", "libros", movimientoLibroId);
  }

  public clienteDeshabilitado(id: number) {
    this.downloadEml("clientes", "deshabilitado", id);
  }

  public cartaPresentacion(id: number) {
    this.downloadEml("clientes", "carta_presentacion", id);
  }

  public rendicionesComprobantes(numeroRendicion: number){
    this.downloadEml('administracion','rendicion', numeroRendicion);
  }

  public comiteTema(idTema: number){
    this.downloadEml('comite','tema', idTema);
  }

  public informe(idInforme: number){
    this.downloadEml('informes','html', idInforme);
  }

  public saldos(codigoGe: string){
    this.downloadEml('saldos','cta', Number(codigoGe));
  }

  public verDPJ(idTarea: number) {
    this.downloadEml('dpj','historial', idTarea);
  }

  public conResolucionDPJ(idTarea: number) {
    this.downloadEml('dpj','con_resolucion', idTarea);
  }

  public recuperoPasos(key: number, id: number) {
    switch (key) {
      case 1:
        this.downloadEml("recuperos", "aceptacion", id);
        break;
      case 2:
        this.downloadEml("recuperos", "aprobacion", id);
        break;
      case 3:
        this.downloadEml("recuperos", "acreditacion", id);
        break;
      case 3:
        this.downloadEml("recuperos", "acreditacion", id);
        break;
      case 4:
        this.downloadEml("recuperos", "facturacion", id);
        break;
    }
  }
  public registrarVisitasCronograma( id: number) {
    this.downloadEml("VisitaLider", "GE", id);
  }
  public notificarCronogramaGE( id: number) {
    this.downloadEml("cronograma", "html", id);
  }

  private downloadEml(tablero: string, accion: string, id: number) {
  // Aquí agregamos un retraso para asegurar que el archivo esté disponible
  setTimeout(() => {
    let a = document.createElement("a");
    a.target = "_blank";
    a.href = `${environment.urlBucket}/${environment.name}/Emails/mail_${tablero}_${accion}_${id}.eml`;
    a.click();
  }, 2000); // Espera de 2 segundos
  }
}
