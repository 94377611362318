import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-grafico-sin-filtros',
  templateUrl: './grafico-sin-filtros.component.html',
  styleUrls: ['./grafico-sin-filtros.component.scss']
})
export class GraficoSinFiltrosComponent implements OnInit {

  @Input() dataEncabezado: { tipoPersona: string, responsable: string, obligacion: string }
  @Input() set dataGrafico(value: any) {
    if (value) {
      this.data = []
      this.total = 0
      value.map(() => this.total++)
      this.estados.map((e, index) => {
        const estadoCantidad = this.getData(e, value)
        const porcentaje = (estadoCantidad[1] * 100 / this.total).toFixed(0) + '%'
        this.data.push({
          label: `${e.text}: ${porcentaje}`, color: this.getStateColor(index), stack: true, data: [estadoCantidad]
        })
      })
    }
  }
  @Output() canvas = new EventEmitter()

  public data = []
  public total = 0
  public estados = [{ id: 'NO_PRESENTA', text: 'No presenta' }, { id: 'SIN_AVANCE', text: 'Sin avances' }, { id: 'TRAJO_DOC', text: 'Trajo doc./EECC Excel' }, { id: 'PRELIQ', text: 'Preliquidadas' }, { id: 'CUMPLIDO', text: 'Cumplidas' }]

  public tipoPersonaSeleccionado = ' '
  public responsableSeleccionado = ' '
  public obligacionSeleccionada = ''

  public barStackedOptions = {
    series: {
      bars: {
        align: 'center',
        lineWidth: 0,
        show: true,
        barWidth: 0.6,
        fill: 0.9
      }
    },
    grid: {
      borderColor: '#eee',
      borderWidth: 1,
      hoverable: true,
      backgroundColor: '#fcfcfc'
    },
    tooltip: true,
    tooltipOpts: {
      content: ((label, x, y) => {
        return y + ' - ' + (y * 100 / this.total).toFixed(0) + '%';
      })
    },
    xaxis: {
      tickColor: '#fcfcfc',
      mode: 'categories'
    },
    yaxis: {
      min: 0,
      tickColor: '#eee'
    },
    shadowSize: 0,
    legend: {
      show: true,
      labelBoxBorderColor: '#5CC5E8',
      position: "ne",
      container: null,
      sorted: null,
    }
  }

  public getData(estado: any, data: any) {
    let cantidad = 0
    data.map(d => {
      d.estado === estado.id ? cantidad++ : null
    })
    return [estado.text, cantidad]
  }

  public getStateColor(i: number) {
    switch (i) {
      case 0:
        return '#178BC1'
      case 1:
        return '#FF9800'
      case 2:
        return '#0B5B8C'
      case 3:
        return '#4CAF50'
      case 4:
        return '#00635a'

      default:'#263238'
        break;
    }
  }

  constructor() { }

  ngOnInit(): void {
  }

}
