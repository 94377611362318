import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../../../services/core/settings/settings.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../../../services/auth.service';
import { PersonalService } from "../../../../services/personal.service";
import { Router } from "@angular/router";
import { environment } from 'src/environments/environment';
const swal = require('sweetalert');

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    public loading: boolean = false;

    public environment: boolean = environment.production;

    valForm: FormGroup;

    constructor(
        private personalService: PersonalService,
        private authService: AuthService,
        public settings: SettingsService,
        private router: Router,
        fb: FormBuilder) {
        this.valForm = fb.group({
            'username': [null, Validators.required],
            'password': [null, Validators.required]
        });
    }

    login() {
        this.loading = true;
        this.authService.authenticate(this.valForm.value.username, this.valForm.value.password)
            .subscribe(() => {
                this.router.navigate(this.authService.getHomeByAuthorities());
            }, error => {
                this.loading = false;
                if (error?.status === 401)
                    swal('Credenciales incorrectas!', "Usuario o contraseña incorrecto", 'error');
                else
                    swal("Algo va mal", "Ocurrió un error al procesar la solicitud", 'error');
            });
    }

    submitForm($ev, value: any) {
        $ev.preventDefault();
        for (let c in this.valForm.controls) {
            this.valForm.controls[c].markAsTouched();
        }
        if (this.valForm.valid) {
            this.login()
        }
    }
    ngOnInit() {
    }
}
