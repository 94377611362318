import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ClienteNotificaciones } from 'src/app/interfaces/cliente-notificaciones';
import { Page } from 'src/app/interfaces/page';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientesNotificacionesService {

  constructor(private http: HttpClient) { }
  private recurso: string = "/clientesNotificaciones";

  public findClientesNotificaciones({
    pageNumber = "1",
    pageSize = "1000",
    orderCriteria = "ASC",
    orderField = null,
    habilitado = "S",
    nombre = null,
    tipoCliente = null,
    grupoEconomico = null,
    area = null,
    lider = null,
    supervisor = null,
    responsable = null,
    asistente = null,
    auxiliar = null,
    tipoSociedad = null,
    regimenFiscal = null,
    mesCierre = null,
  } = {}): Observable<Page<ClienteNotificaciones>> {
    let params = new URLSearchParams();
    if (pageNumber) params.append('pageNumber', pageNumber);
    if (pageSize) params.append('pageSize', pageSize);
    if (orderCriteria) params.append('orderCriteria', orderCriteria);
    if (orderField) params.append('orderField', orderField);
    if (habilitado) params.append('habilitado', habilitado);
    if (nombre) params.append('nombre', nombre);
    if (tipoCliente) params.append('tipoCliente', tipoCliente);
    if (grupoEconomico) params.append('grupoEconomico', grupoEconomico);
    if (area) params.append('area', area);
    if (lider) params.append('lider', lider);
    if (supervisor) params.append('supervisor', supervisor);
    if (responsable) params.append('responsable', responsable);
    if (asistente) params.append('asistente', asistente);
    if (auxiliar) params.append('auxiliar', auxiliar);
    if (tipoSociedad) params.append('tipoSociedad', tipoSociedad);
    if (regimenFiscal) params.append('regimenFiscal', regimenFiscal);
    if (mesCierre) params.append('mesCierre', mesCierre);
    return this.http.get<Page<ClienteNotificaciones>>(`${environment.apiEndpoint}${this.recurso}?${params.toString()}`);
  }
  public asignacionNotificaciones(clientes: Array<ClienteNotificaciones>): Observable<Array<ClienteNotificaciones>> {
    return this.http.patch<Array<ClienteNotificaciones>>(`${environment.apiEndpoint}${this.recurso}`, clientes);
  }
  public enviarNotificacionesMensuales(anioMes: string, clientes: Array<number>): Observable<any> {
    let params = new URLSearchParams();
    if (anioMes) params.append('anioMes', anioMes);
    return this.http.post<any>(`${environment.apiEndpoint}${this.recurso}MailMensual?${params.toString()}`, clientes);
  }
  public enviarNotificacionesAnuales(clientes: Array<number>): Observable<any> {
    return this.http.post<any>(`${environment.apiEndpoint}${this.recurso}MailAnual?`, clientes);
  }
}
