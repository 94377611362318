import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ERROR_MESSAGES } from 'src/app/enums/error-messages';
import { MailObservaciones } from 'src/app/interfaces/mail-observaciones';
import { Personal } from 'src/app/interfaces/personal';
import { MailService } from 'src/app/services/mail.service';
import { ObservacionesService } from 'src/app/services/observaciones.service';
const swal = require("sweetalert");
import * as moment from "moment";
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ListadoContactosComponent } from '../listado-contactos/listado-contactos.component';
@Component({
  selector: 'app-mail-observaciones',
  templateUrl: './mail-observaciones.component.html',
  styleUrls: ['./mail-observaciones.component.scss']
})
export class MailObservacionesComponent implements OnInit {

  @Input() recurso: string;
  @Input() padreId: number;

  @Input() asuntoData: any;

  @Output() closeModalMail = new EventEmitter();
  @Output() agregar = new EventEmitter();

  @ViewChild('contactos') contactos: ListadoContactosComponent;

  public form: FormGroup;
  public loading: boolean = false;
  public mail: MailObservaciones;
  public usuarioLogueado: Personal;
  public mensaje: "<p></p>";
  public destinatarios: Array<string>;
  public inputEmail: string;
  constructor(formBuilder: FormBuilder, private observacionesService: ObservacionesService, private mailService: MailService) {
    this.form = formBuilder.group({
      para: [null, Validators.required],
      cc: [null],
      cco: [null],
      asunto: [null],
      enviarMail: [false],
      editarMail: [false],
      requiereAviso: [false],
      importanciaAlta: [false],
      incluyeHistoral: [false],
    });
  }

  ngOnInit(): void {
    this.usuarioLogueado = JSON.parse(localStorage.getItem("sige_personal"));
  }
  ngOnChanges() {
    this.form.reset();
    this.mensaje = "<p></p>";
    this.changeEnviarMail();
  }
  public submitForm($event, value) {
    $event.preventDefault();
    this.form.markAllAsTouched();
    if (this.form.invalid || this.loading || !this.recurso || !this.padreId || this.mensaje === "<p></p>" || !this.mensaje)
      return
    this.mail = Object.assign({}, value, { usuario: this.usuarioLogueado, mensaje: this.mensaje });
    if (value.enviarMail) {
      this.mail.para = value.para ? value.para.split(";") : null;
      this.mail.cc = value.cc ? value.cc.split(";") : null;
      this.mail.cco = value.cco ? value.cco.split(";") : null;
    }
    if (this.mail.editarMail)
      this.mail.enviarMail = false;
    this.observacionesService.createObservacionMail(this.recurso, this.padreId, this.mail).subscribe(response => {
      if (this.mail.editarMail)
        setTimeout(() => {
          this.mailService.observacionGenerica(this.recurso, response.id);
        }, 1600);
      this.ngOnChanges();

      swal({
        title: 'Guardado',
        text: 'El registro se guardó exitosamente.',
        icon: 'success',
        buttons: false,
        timer: 1600
      });
      this.closeModalMail.emit();
      this.agregar.emit(response);
    }, (error) => {
      swal('¡Lo sentimos!', error.error?.message || ERROR_MESSAGES.GUARDADO, 'error');
    });
  }
  public getAsuntoGenerico() {
    switch (this.recurso) {
      case "personal": return this.asuntoData?.nombre + " - " + moment().format('DD/MM/YYYY HH:mm:ss');
      case "auditoria": return this.asuntoData?.cliente_fisico?.nombre + " - " + this.asuntoData?.tipo_cierre + " - " + moment(this.asuntoData?.fecha_cierre, 'YYYY-MM-DD').format('DD/MM/YYYY');
      case "recuperos": return "Observacion de Recupero: " + this.asuntoData?.cliente_fisico.nombre + " - " + this.asuntoData?.periodo;
      case "tareas": return this.asuntoData?.clienteFisico?.nombre + " - " + this.asuntoData?.descripcion + " - " + moment(this.asuntoData?.fechaVencimiento, 'YYYY-MM-DD').format('DD/MM/YYYY');
      case "outsourcing": return "Observación de Outsourcing: " + moment().format('DD/MM/YYYY HH:mm:ss');
      case "vencimientos": return this.asuntoData?.cliente_fisico?.nombre + " - " + this.asuntoData?.obligacion?.nombre;
      case "laboral": return "Laboral - observación - " + this.padreId;
      case "societarioObs": return this.asuntoData.cliente?.nombre + ' - ' || ''
    }
  }
  public async changeEnviarMail() {
    this.form.markAsUntouched();
    this.form.controls.para.reset();
    if (this.form.value.enviarMail) {
      this.form.controls.editarMail.enable();
      this.form.controls.requiereAviso.enable();
      this.form.controls.importanciaAlta.enable();
      this.form.controls.incluyeHistoral.enable();
      this.form.controls.cc.reset();
      this.form.controls.cco.reset();
      if (this.recurso === "tareas") {
        await this.getObservacionesDestinatarios();
        if (this.destinatarios.length)
          this.form.controls.para.patchValue(this.destinatarios.join("; "));
      }
      this.form.controls.asunto.patchValue(this.getAsuntoGenerico());
      this.form.controls.para.setValidators(Validators.required);
    } else {
      this.form.controls.editarMail.disable();
      this.form.controls.requiereAviso.disable();
      this.form.controls.importanciaAlta.disable();
      this.form.controls.incluyeHistoral.disable();
      this.form.controls.para.clearValidators();
    }
    for (const key in this.form.controls) {
      this.form.get(key).updateValueAndValidity();
    }
  }
  public valueRequired(value: string) {
    return this.form.controls[value].hasError("required") && (this.form.controls[value].dirty || this.form.controls[value].touched);
  }
  public getObservacionesDestinatarios(): Promise<Array<string>> {
    return new Promise((resolve, rejected) => {
      this.observacionesService.findDestinatariosTareas(this.recurso, this.padreId).subscribe((response: Array<string>) => {
        this.destinatarios = response;
        resolve(response);
      }, error => {
        swal("¡Lo sentimos!", error.error.message || ERROR_MESSAGES.DEFAULT, "error");
      });
    })
  }
  public openModalContactos(input: string) {
    this.inputEmail = input;
    this.contactos.openModal();
  }
  public onEmailsAgregados(event) {
    this.form.controls[this.inputEmail].patchValue(this.eliminarMailsRepetidos((this.form.value[this.inputEmail] || "") + event));
  }
  public eliminarMailsRepetidos(mails: string) {
    return [...new Set(mails.replace(/\s+/g, "").split(";"))].join("; ")
  }
}
