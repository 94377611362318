import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslatorService } from '../../services/core/translator/translator.service';
import { MenuService } from '../../services/core/menu/menu.service';
import { SharedModule } from '../shared/shared.module';
import { PagesModule } from './template/pages/pages.module';
import { menu } from './menu';
import { routes } from './routes';
import { LoginComponent } from './users/login/login.component';

import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';
import {AuthGuard} from "../../guards/auth.guard";
import { RegisterComponent } from './users/register/register.component';

defineLocale('es', esLocale);

@NgModule({
    imports: [
        SharedModule,
        RouterModule.forRoot(routes, {useHash:true}),
        PagesModule,
        BrowserModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        BsDatepickerModule.forRoot(),
    ],
    providers:[
      AuthGuard
    ],
    declarations: [LoginComponent, RegisterComponent],
    exports: [
        RouterModule
    ]
})

export class RoutesModule {
    constructor(public menuService: MenuService, tr: TranslatorService) {
        menuService.addMenu(menu);
    }
}
