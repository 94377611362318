import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { forkJoin, Observable, observable } from 'rxjs';
import { FormGroup, FormBuilder } from '@angular/forms';
import { map } from 'rxjs/operators';
import { ExportarDatosComponent } from '../exportar-datos/exportar-datos.component';
const swal = require('sweetalert');
import modalUtils from "src/app/utils/modal";

//*Servicios
import { OrganismoService } from '../../../services/organismo.service';
import { TiposDeObligacionService } from '../../../services/tipos-de-obligacion.service';
import { AbmObligacionesService } from '../../../services/abm-obligaciones.service';
import { ClienteBaseService } from 'src/app/services/clientes/cliente-base.service';
import { GrupoEconomicoService } from 'src/app/services/grupo-economico.service';
import { PersonalService } from 'src/app/services/personal.service';
import { AsignacionObligacionesService } from '../../../services/asignacion-obligaciones.service';

//*Interfaces
import { Organismo } from '../../../interfaces/organismo';
import { TipoDeObligacion } from '../../../interfaces/tipo-de-obligacion';
import { AltaObligacion } from 'src/app/interfaces/alta-obligacion';
import { ClienteBase } from 'src/app/interfaces/cliente';
import { GrupoEconomico } from 'src/app/interfaces/grupo-economico';
import { Personal } from 'src/app/interfaces/personal';
import { Page } from '../../../interfaces/page';
import { ImpuestoActivo } from 'src/app/interfaces/cliente-datos-impositivos';

//*Enums
import { HABILITADO } from 'src/app/enums/habilitado';
import { ERROR_MESSAGES } from 'src/app/enums/error-messages';
import { AuthService } from 'src/app/services/auth.service';
import { Authorities } from 'src/app/enums/authorities';

@Component({
  selector: 'app-control-obligaciones-automaticas',
  templateUrl: './control-obligaciones-automaticas.component.html',
  styleUrls: ['./control-obligaciones-automaticas.component.scss']
})
export class ControlObligacionesAutomaticasComponent implements OnInit {

  @ViewChild("controlObligacionesAutomaticas") modal: ModalDirective;
  @ViewChild("exportarDatos") exportarDatos: ExportarDatosComponent;
  public formCOA: FormGroup;
  public authLaboralImpuestos: boolean = false;
  public ordenAsc: boolean = true;
  public ordenAscObligacion: boolean = true;
  public loadingModal = false;
  public checkAllCliente;
  public checkAllOblig;
  public checkAllCumpleC;
  public checkAllCumpleA;

  //*Datos
  public organismos: Array<Organismo>;
  public tipoObligaciones: Array<TipoDeObligacion>;
  public obligaciones: Array<AltaObligacion>;
  public gruposEconomicos: Array<GrupoEconomico>;
  public clientes: Array<ClienteBase>;
  public personal: Array<Personal>;
  public obligacionesXCliente: Array<ImpuestoActivo> = [];
  public obligacionesXClienteOriginal: Array<ImpuestoActivo>;
  public clienteSeleccionados: Array<any> = [];
  public obligSeleccionadas: Array<any> = [];

  //*Listados
  public listadoOrganismo: Array<{ id: number, text: string }> = [];
  public listadoTipoObligaciones: Array<{ id: number, text: string }> = [];
  public listadoObligaciones: Array<{ id: number, text: string }> = [];
  public listadoTipoPersona = [{ id: 'f', text: 'FÍSICA' }, { id: 'j', text: 'JURÍDICA' }];
  public listadoGruposEconomicos: Array<{ id: number, text: string }>;
  public listadoClientes: Array<{ id: number, text: string }> = [];
  public listadoPersonal: Array<{ id: number, text: string }> = [];

  //*Exportar
  public listadoExportar: Array<any> = [];
  public exportConfig: any;

  constructor(private formBuilder: FormBuilder,
    private authService: AuthService,
    private organismoService: OrganismoService,
    private tipoObligacionService: TiposDeObligacionService,
    private abmObligacionesService: AbmObligacionesService,
    private clienteBaseService: ClienteBaseService,
    private grupoEconomicoService: GrupoEconomicoService,
    private personalService: PersonalService,
    private asignacionObligacionesService: AsignacionObligacionesService) {
    this.checkAllCliente = false;
    this.checkAllOblig = false;
    this.checkAllCumpleA = false;
    this.checkAllCumpleC = false;
    this.obligacionesXCliente = [];
    this.obligacionesXClienteOriginal = [];
    this.clienteSeleccionados = [];
    this.obligSeleccionadas = [];
    forkJoin([
      this.organismoService.findOrganismos(),
      this.tipoObligacionService.findTipoDeObligacion(),
      abmObligacionesService.findObligaciones(),
      grupoEconomicoService.findAll(),
      clienteBaseService.findAll(),
      personalService.findPersonalAreas({ habilitado: HABILITADO.SI })
    ]).subscribe(([organismos, tipoObligaciones, obligaciones, grupoEconomico, clientes, personal]) => {
      this.organismos = organismos.content;
      this.tipoObligaciones = tipoObligaciones.content;
      this.obligaciones = obligaciones.content;
      this.gruposEconomicos = grupoEconomico.content;
      this.clientes = clientes.content;
      this.personal = personal.content.filter(p => p.areas.find(area => area.descripcion == 'IMPUESTOS' || 'LABORAL'));

      this.listadoOrganismo = this.organismos.map(organismo => ({ id: organismo.id, text: organismo.descripcion }));
      this.listadoTipoObligaciones = this.tipoObligaciones.map(tipoObligacion => ({ id: tipoObligacion.id, text: tipoObligacion.descripcion }));
      this.listadoObligaciones = this.obligaciones.map(obligacion => ({ id: obligacion.id, text: obligacion.denominacion + " - " + obligacion.tipoObligacion.descripcion }));
      this.listadoGruposEconomicos = this.gruposEconomicos.map(grupoEconomico => ({ id: grupoEconomico.id, text: grupoEconomico.nombre }));
      this.listadoClientes = this.clientes.map(cliente => ({ id: cliente.id, text: cliente.nombre }));
      this.listadoPersonal = this.personal.map(personal => ({ id: personal.id, text: personal.nombre }));
    });
  }

  ngOnInit(): void {
    this.setAuthorization();
    this.formCOA = this.formBuilder.group({
      organismo: [null],
      tipoObligacion: [null],
      obligacion: [null],
      tipoPersona: [null],
      grupoEconomico: [null],
      cliente: [null],
      supervisor: [null],
      responsable: [null],
      asistente: [null],
      auxiliar: [null]
    });
  }

  public setAuthorization() {
    this.authLaboralImpuestos = this.authService.isAuthorized([
      Authorities.gerente,
      Authorities.auditoria_supervisor,
      Authorities.laboral_supervisor,
      Authorities.impuestos_supervisor,
      Authorities.outsourcing_supervisor,
      Authorities.societario_supervisor,
      Authorities.administracion_supervisor,
      Authorities.recursos_humanos_supervisor,
      Authorities.laboral_supervisor,
      Authorities.impuestos_supervisor,
      Authorities.laboral_responsable,
      Authorities.impuestos_responsable,
      Authorities.laboral_asistente,
      Authorities.impuestos_asistente,
      Authorities.laboral_auxiliar,
      Authorities.impuestos_auxiliar,
    ]);
  }

  public openModalA() {
    modalUtils.open(this.modal);
  }

  public filtrarXGrupoEconomico() {
    if (this.formCOA.get('grupoEconomico').value) {
      let codigo = this.gruposEconomicos.find(g => g.id == this.formCOA.get('grupoEconomico').value).codigo;
      this.grupoEconomicoService.findClientes(codigo, HABILITADO.SI)
        .subscribe((response: Page<ClienteBase>) => {
          this.clientes = response.content;
          this.listadoClientes = this.clientes.map(g => ({ id: g.id, text: g.nombre }));
        })
    }
    else {
      this.clienteBaseService.findAll({
        habilitado: HABILITADO.SI
      }).subscribe(clientes => {
        this.clientes = clientes.content;
        this.listadoClientes = this.clientes.map(c => ({ id: c.id, text: c.nombre }));
      })
    }
  }

  public filtrarXTipoObligacion() {
    this.filtrarObligaciones(this.formCOA.value.tipoObligacion);
  }

  public filtrarObligaciones(value) {
    this.listadoObligaciones = this.obligaciones.map(p => {
      if (p.tipoObligacion.id === value || !value)
        return { text: p.denominacion + " - " + p.tipoObligacion.descripcion, id: p.id };
    });
  }

  public findAll() {
    this.loadingModal = true;
    this.checkAllCliente = false;
    this.checkAllOblig = false;
    this.checkAllCumpleA = false;
    this.checkAllCumpleC = false;
    this.obligacionesXCliente = [];
    this.clienteSeleccionados = [];
    this.obligSeleccionadas = [];
    this.asignacionObligacionesService.findClientesObligaciones({
      organismoId: this.formCOA.get('organismo').value,
      tipoObligacionId: this.formCOA.get('tipoObligacion').value,
      obligacionId: this.formCOA.get('obligacion').value,
      tipoCliente: this.formCOA.get('tipoPersona').value,
      grupoEconomicoId: this.formCOA.get('grupoEconomico').value,
      clienteId: this.formCOA.get('cliente').value,
      supervisorId: this.formCOA.get('supervisor').value,
      responsableId: this.formCOA.get('responsable').value,
      asistenteId: this.formCOA.get('asistente').value,
      auxiliarId: this.formCOA.get('auxiliar').value
    }).subscribe((response: Array<ImpuestoActivo>) => {
      this.obligacionesXCliente = response;
      this.ordenAsc = true;
      this.ordenarXCampoDoble(this.obligacionesXCliente, 'cliente', 'nombre', this.ordenAsc);
      this.obligacionesXClienteOriginal = JSON.parse(JSON.stringify(this.obligacionesXCliente));
      this.loadingModal = false;
    })
  }

  public ordenarXCampoDoble(array: any, campo1: string, campo2: string, ordenAsc: boolean = true) {
    array.sort((a, b) => {
      if (ordenAsc) {
        return (a[campo1][campo2] > b[campo1][campo2]) ? 1 : ((b[campo1][campo2] > a[campo1][campo2]) ? -1 : 0);
      } else {
        return (b[campo1][campo2] > a[campo1][campo2]) ? 1 : ((a[campo1][campo2] > b[campo1][campo2]) ? -1 : 0);
      }
    });
  }

  public verificaCumpleUnico(item: any, cumpleSeleccionado: string, cumpleADeseleccionar: string) {
    item[cumpleSeleccionado] ? null : item[cumpleADeseleccionar] = false;
  }

  public seleccionarTodos(cod: string) {
    if (this.obligacionesXCliente.length > 0)
      switch (cod) {
        case 'cumpleCliente': {
          this.obligacionesXCliente.forEach(o => o.cumpleCliente = !this.checkAllCumpleC);
          if (!this.checkAllCumpleC) {//*Si se selecciona todos los cumpleCliente, se deselecciona todos los cumpleA
            this.obligacionesXCliente.forEach(o => o.cumpleAutomaticamente = this.checkAllCumpleC);
            this.checkAllCumpleA = this.checkAllCumpleC;
          }
          break;
        }
        case 'cumpleAutom': {
          this.obligacionesXCliente.forEach(o => o.cumpleAutomaticamente = !this.checkAllCumpleA);
          if (!this.checkAllCumpleA) {//*Si se selecciona todos los cumpleAutomaticamente, se deselecciona todos los cumpleCliente
            this.obligacionesXCliente.forEach(o => o.cumpleCliente = this.checkAllCumpleA);
            this.checkAllCumpleC = this.checkAllCumpleA;
          }
          break;
        }
        case 'enviaCliente': {
          this.obligacionesXCliente.forEach(c => {
            c.cliente.enviaArchivosCumplidos = !this.checkAllCliente
            let i = this.clienteSeleccionados.findIndex(cs => c.cliente.id == cs.id);
            if (i != -1)
              this.clienteSeleccionados[i].boolean = !this.checkAllCliente;
            else this.clienteSeleccionados.push({ id: c.cliente.id, booleano: !this.checkAllCliente });
          });
          break;
        }
        case 'enviaOblig': {
          this.obligacionesXCliente.forEach(o => {
            o.obligacion.enviaComprobantes = !this.checkAllOblig
            let i = this.obligSeleccionadas.findIndex(os => o.obligacion.id == os.id);
            if (i != -1)
              this.obligSeleccionadas[i].boolean = !this.checkAllOblig;
            else this.obligSeleccionadas.push({ id: o.obligacion.id, booleano: !this.checkAllOblig });
          });
          break;
        }
      }
  }

  public comprobarGuardado() {
    if (JSON.stringify(this.obligacionesXClienteOriginal) !== JSON.stringify(this.obligacionesXCliente)) {
      swal({
        title: 'Hay cambios sin guardar',
        text: '¿Desea salir de todos modos?',
        icon: 'warning',
        buttons: {
          cancel: { text: 'No', value: null, visible: true, className: '', closeModal: true },
          confirm: { text: 'Sí', value: true, visible: true, className: '', closeModal: true }
        }
      }).then((isConfirm) => {
        if (isConfirm) {
          this.modal.hide();
        } else return;
      });
    }
    else this.modal.hide();
  }

  public guardar() {
    if (this.clienteSeleccionados.length > 0 || this.obligSeleccionadas.length > 0) {//*Cuando se modifican las dos ultimas columnas
      swal({
        title: 'Se modificará la configuración de la obligación y el cliente',
        text: '¿Está seguro de realizar esta acción?',
        icon: 'warning',
        buttons: {
          cancel: { text: 'No', value: null, visible: true, className: '', closeModal: true },
          danger: { text: 'Sí', value: true, visible: true, className: '', closeModal: false }
        }
      }).then((isConfirm) => {
        if (isConfirm) {
          forkJoin([
            this.guardarObligaciones(),
            this.asignacionObligacionesService.setEnviaCliente(this.clienteSeleccionados),
            this.asignacionObligacionesService.setEnviaObligacion(this.obligSeleccionadas)
          ]).subscribe(() => {
            this.obligacionesXClienteOriginal = JSON.parse(JSON.stringify(this.obligacionesXCliente));
            swal({
              title: 'Guardado',
              text: 'Las obligaciones se guardaron exitosamente.',
              icon: 'success',
              buttons: false,
              timer: 1600
            });
            this.modal.hide();
          }, error => swal("¡Lo sentimos!", error?.message || ERROR_MESSAGES.GUARDADO, "error"));
        } else return;
      })
    } else this.guardarObligaciones().subscribe(() => {
      this.obligacionesXClienteOriginal = JSON.parse(JSON.stringify(this.obligacionesXCliente));
      swal({
        title: 'Guardado',
        text: 'Las obligaciones se guardaron exitosamente.',
        icon: 'success',
        buttons: false,
        timer: 1600
      });
      this.modal.hide();
    }, error => swal("¡Lo sentimos!", error.error?.message || ERROR_MESSAGES.GUARDADO, "error"));
  }

  public guardarObligaciones(): Observable<Array<ImpuestoActivo>> {
    return this.asignacionObligacionesService.editarMasivo(this.obligacionesXCliente).pipe(
      map((response: Array<ImpuestoActivo>) => {
        this.obligacionesXCliente = response;
        this.ordenAsc = true;
        this.ordenarXCampoDoble(this.obligacionesXCliente, 'cliente', 'nombre', this.ordenAsc);
        this.loadingModal = false;
        return this.obligacionesXCliente;
      })
    );
  }

  public seleccionarItem(item: any, isCliente: boolean) {
    if (isCliente) {
      this.obligacionesXCliente.forEach(c => {
        (c.cliente.id == item.cliente.id && c != item) ? c.cliente.enviaArchivosCumplidos = !item.cliente.enviaArchivosCumplidos : null;
      })
      let i = this.clienteSeleccionados.findIndex(c => c.id === item.cliente.id);
      if (i != -1) {//Si esta en el arreglo
        this.clienteSeleccionados[i].boolean = !item.cliente.enviaArchivosCumplidos
      } else {//Si no esta en el arreglo
        this.clienteSeleccionados.push({ id: item.cliente.id, booleano: !item.cliente.enviaArchivosCumplidos });
      }
    } else {
      this.obligacionesXCliente.forEach(o => {
        (o.obligacion.id == item.obligacion.id && o != item) ? o.obligacion.enviaComprobantes = !item.obligacion.enviaComprobantes : null;
      })
      let i = this.obligSeleccionadas.findIndex(o => o.id === item.obligacion.id);
      if (i != -1) {//Si esta en el arreglo
        this.obligSeleccionadas[i].boolean = !item.obligacion.enviaComprobantes
      } else {//Si no esta en el arreglo
        this.obligSeleccionadas.push({ id: item.obligacion.id, booleano: !item.obligacion.enviaComprobantes });
      }
    }
  }

  public exportar() {
    this.exportConfig = {
      title: "Control de obligaciones automaticas",
      "showColumns": ["cliente", "obligacion", "cumpleCliente", "cumpleAutomaticamente", "enviaArchivosCumplidos", "enviaComprobantes"],
      "aliasColumns": {
        cliente: "Cliente", obligacion: "Obligacion", cumpleCliente: "Cumple cliente", cumpleAutomaticamente: "Cumple Autom.", enviaArchivosCumplidos: "Envia asiento/Cliente", enviaComprobantes: "Envia asiento/Oblig."
      }
    }

    this.mapListadoClientesNoAsistidos();
    this.exportarDatos.openModal();
  }

  public mapListadoClientesNoAsistidos() {
    this.listadoExportar = this.obligacionesXCliente.map(o => {
      return {
        cliente: o?.cliente?.nombre || '-',
        obligacion: o?.organismo?.descripcion || '-',
        cumpleCliente: o.cumpleCliente ? 'Si' : '-',
        cumpleAutomaticamente: o.cumpleAutomaticamente ? 'Si' : '-',
        enviaArchivosCumplidos: o.cliente.enviaArchivosCumplidos ? 'Si' : '-',
        enviaComprobantes: o.obligacion.enviaComprobantes ? 'Si' : '-'
      }
    });
    this.listadoExportar.push({
      cliente: 'Total',
      enviaComprobantes: this.obligacionesXCliente.length
    })
  }
}
