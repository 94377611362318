export enum CategoriaDocumentoIdEnum {
  LIBRO_IVA_COMPRA = "LIBRO_IVA_COMPRA",
  LIBRO_IVA_VENTA = "LIBRO_IVA_VENTA",
  VEP = "VEP",
  ACUSE = "ACUSE",
  ASIENTO = "ASIENTO",
  DDJJ = "DDJJ",
  SICORE = "SICORE",
  OTROS = "OTROS",
  DDJJ_F931 = "DDJJ_F931",
  ACUSE_DDJJ = "ACUSE_DDJJ",
  ASIENTO_SUELDOS = "ASIENTO_SUELDOS",
  RECIBOS_1_Q = "RECIBOS_1_Q",
  RECIBOS_2_Q = "RECIBOS_2_Q",
  RECIBOS_SUELDOS_MENSUAL = "RECIBOS_SUELDOS_MENSUAL",
  RECIBOS_SAC = "RECIBOS_SAC",
  RECIBOS_LIQ_FINAL = "RECIBOS_LIQ_FINAL",
  LIBRO_SUELDOS_1_Q = "LIBRO_SUELDOS_1_Q",
  LIBRO_SUELDOS_2_Q = "LIBRO_SUELDOS_2_Q",
  LIBRO_SUELDOS_MENSUAL = "LIBRO_SUELDOS_MENSUAL",
  LIBRO_SUELDOS_SAC = "LIBRO_SUELDOS_SAC",
  LIBRO_SUELDOS_LIQ_FINAL = "LIBRO_SUELDOS_LIQ_FINAL",
  NOMINA_DDJJ = "NOMINA_DDJJ",
  PAPELES_DE_TRABAJO = "PAPELES_DE_TRABAJO",
  SINDICATOS = "SINDICATOS",
  DDJJ_RG3279 = "DDJJ_RG3279",
  ACUSE_DDJJ_RG3279 = "ACUSE_DDJJ_RG3279",
  CARTA_DE_RECOMENDACION = "CARTA_DE_RECOMENDACION",
  CARTA_DE_LA_GERENCIA = "CARTA_DE_LA_GERENCIA",
  PRESENTACION_ORGANO_DE_CONTROLACTAS = "PRESENTACION_ORGANO_DE_CONTROLACTAS",
  EECC_EXCEL = "EECC_EXCEL",
  EECC_WORD = "EECC_WORD",
  EECC_PDF = "EECC_PDF",
  EECC_CERTIFICADO = "EECC_CERTIFICADO",
  EECC_CON_FIRMA_LITOGRAFIADA = "EECC_CON_FIRMA_LITOGRAFIADA",
  EECC_NOTA_FIRMA_LITOGRAFIADA = "EECC_NOTA_FIRMA_LITOGRAFIADA",
  EECC_INFORME = "EECC_INFORME",
  EECC_PRESENTACION_WEB_BALANCE = "EECC_PRESENTACION_WEB_BALANCE",
  LIBRO_DIARIO = "LIBRO_DIARIO",
  INVENTARIOS = "INVENTARIOS",
  ESQUEMA_SOCIETARIO = "ESQUEMA_SOCIETARIO",
  MEMORIA_DE_INICIO = "MEMORIA_DE_INICIO",
  SUMAS_Y_SALDOS = "SUMAS_Y_SALDOS"
}

export interface CategoriaDocumento {
  id: any;
  nombre: string;
}