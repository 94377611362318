import { Component, Input, OnInit } from "@angular/core";
import { Recupero } from "src/app/interfaces/recupero";

@Component({
  selector: "app-card-recuperos",
  templateUrl: "./card-recuperos.component.html",
  styleUrls: ["./card-recuperos.component.scss"]
})
export class CardRecuperosComponent implements OnInit {

  @Input() recupero: Recupero;

  constructor() { }

  ngOnInit(): void {
  }

}
