import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'thousands'
})
export class ThousandsPipe implements PipeTransform {

  public transform(value: any) {
    let string: string = value.toString();
    string = /\.\d{1}/g.test(string) ? parseFloat(string).toFixed(2) : string;
    return string.replace(/\./g, ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

}
