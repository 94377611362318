import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ContactoCliente } from "src/app/interfaces/contacto-cliente";
import { Page } from "src/app/interfaces/page";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ContactosClientesService {

  recurso = '/reportes/clientesContactos';

  constructor(private http: HttpClient) { }

  public findClientesContactos({
    pageNumber = 1,
    pageSize = 100,
    orderCriteria = "ASC",
    orderField = "nombre",
    area = null,
    clienteId = null,
    descripcion = null,
    clienteHabilitado = null,
    grupoEconomico = null,
    agrupado=null
  } = {}): Observable<Page<ContactoCliente>> {
    let params = new URLSearchParams([]);
      pageNumber ? params.append("pageNumber", pageNumber.toString()) : null,
      pageSize ? params.append("pageSize", pageSize.toString()) : null,
      orderCriteria ? params.append("orderCriteria", orderCriteria) : null,
      orderField ? params.append("orderField", orderField) : null,
      area ? params.append("area", area) : null,
      clienteId ? params.append("clienteId", clienteId) : null,
      descripcion ? params.append("descripcion", descripcion) : null,
      clienteHabilitado ? params.append("clienteHabilitado", clienteHabilitado.toString()) : null,
      grupoEconomico ? params.append("grupoEconomico", grupoEconomico) : null
      agrupado ? params.append("agrupado", agrupado) : null
    return this.http.get<Page<ContactoCliente>>(`${environment.apiEndpoint}${this.recurso}?${params.toString()}`);
  }
}
