import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, take, tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { switchMap } from "rxjs/operators";
import { Router } from "@angular/router";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private authService: AuthService,
    private router: Router
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!request.headers.get('Authorization')) {
      request = this.setToken(request);
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {

        if (error.status !== 401)
          return throwError(error);

        if (/invalid_token/i.test(JSON.stringify(error.error))) {
          if (this.authService.userCanRefreshToken())
            return this.handleAuthorizationError(request, next).pipe(
              catchError(error => {
                return throwError(error);
              })
            );
          else
            this.authService.logout();
        }

        return throwError(error);
      })
    );
  }

  private setToken(request: HttpRequest<any>): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.authService.getAccessToken()}`
      }
    });
  }

  private handleAuthorizationError(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      this.authService.refreshToken()
        .pipe(
          finalize(() => this.isRefreshing = false)
        )
        .subscribe(({ access_token }) => {
          this.refreshTokenSubject.next(access_token);
          next.handle(this.setToken(request))
        })
    }

    return this.refreshTokenSubject.pipe(
      filter(accessToken => accessToken != null),
      take(1),
      finalize(() =>
        this.isRefreshing = false
      ),
      switchMap(accessToken =>
        next.handle(this.setToken(request))
      )
    );
  }
}
