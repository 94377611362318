import { Component, OnInit } from "@angular/core";

import { UserblockService } from "./userblock.service";
import {IdNombre} from "../../../../interfaces/id-nombre";
import { Documento } from "src/app/classes/documento";
import { PersonalService } from "src/app/services/personal.service";
import { PersonalDatosGenerales } from "src/app/interfaces/personal-datos-generales";

@Component({
    selector: "app-userblock",
    templateUrl: "./userblock.component.html",
    styleUrls: ["./userblock.component.scss"]
})
export class UserblockComponent implements OnInit {

    public fotoSeleccionada = new Documento();
    user: any;
    personal: IdNombre;
    constructor(public userblockService: UserblockService, private personalService: PersonalService) {
    }

    ngOnInit() {
      this.personal = JSON.parse(localStorage.getItem("sige_personal"));
      this.findDatosGenerales();
    }

    public findDatosGenerales() {
        this.personalService.findDatosGenerales(this.personal.id).subscribe((datosGenerales: PersonalDatosGenerales) => {
          this.fotoSeleccionada = datosGenerales?.foto?.url_archivo ? new Documento(datosGenerales?.foto) : new Documento();
        });
      }

    userBlockIsVisible() {
        return this.userblockService.getVisibility();
    }

}
