import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { IdNombre } from "../interfaces/id-nombre";
import { Page } from "../interfaces/page";
import { IAceptacion } from "../interfaces/iaceptacion";
import { IAcreditacion } from "../interfaces/iacreditacion";
import { IAprobacion } from "../interfaces/iaprobacion";
import { Recupero } from "../interfaces/recupero";
import { RecuperoAlta } from "../interfaces/recupero-alta";
import { RecuperoRequerimientos } from "../interfaces/recupero-requerimientos";
import { Personal } from "../interfaces/personal";
import { IFacturacion } from "../interfaces/ifacturacion";

@Injectable({
  providedIn: "root"
})
export class RecuperosService {

  recurso: string = environment.apiEndpoint + "/recuperos";

  constructor(private http: HttpClient) { }

  public findAll({
    pageNumber = 1,
    pageSize = 1000,
    q = null,
    fechaDesde = null,
    fechaHasta = null,
    estado = null,
    responsableId = null,
    clienteId = null,
    orderCriteria = null,
    orderField = null
  } = {}) {
    let params = new URLSearchParams([]);
    if (pageNumber) params.append("pageNumber", pageNumber.toString());
    if (pageSize) params.append("pageSize", pageSize.toString());
    if (q) params.append("q", q);
    if (fechaDesde) params.append("fechaDesde", fechaDesde);
    if (fechaHasta) params.append("fechaHasta", fechaHasta);
    if (estado) params.append("estado", estado);
    if (responsableId) params.append("responsableId", responsableId);
    if (clienteId) params.append("clienteId", clienteId);
    if (orderCriteria) params.append("orderCriteria", orderCriteria);
    if (orderField) params.append("orderField", orderField);
    return this.http.get(`${this.recurso}?${params.toString()}`);
  }

  public clienteRecupero(recuperoId: number) {
    return this.http.get(`${environment.apiEndpoint}/clientesRecuperos/${recuperoId}/recupero`);
  }

  public findResponsables(): Observable<Page<Personal>> {
    return this.http.get<Page<Personal>>(`${environment.apiEndpoint}/area/01/personal`);
  }

  public abrirRecupero(recupero: Recupero) {
    return this.http.put(`${this.recurso}/${recupero.id}/abrir`, recupero);
  }

  public cerrarRecupero(recupero: Recupero) {
    return this.http.put(`${this.recurso}/${recupero.id}/cierreSupervisor`, recupero);
  }

  public abrirResponsable(recupero: Recupero) {
    return this.http.put(`${this.recurso}/${recupero.id}/abrirResponsable`, recupero);
  }

  public cerrarResponsable(recupero: Recupero) {
    return this.http.put(`${this.recurso}/${recupero.id}/cierreResponsable`, recupero);
  }
  public eliminarRecupero(recuperoId: number) {
    return this.http.delete(`${this.recurso}/${recuperoId}`);
  }

  public rectificarRecupero(recuperoId: number): Observable<Recupero> {
    return this.http.put<Recupero>(`${this.recurso}/${recuperoId}/rectificar`, null);
  }

  public createRecuperos(recupero: RecuperoAlta): Observable<Array<RecuperoAlta>> {
    return this.http.post<Array<RecuperoAlta>>(`${this.recurso}`, recupero);
  }

  public editRecuperos(recupero: RecuperoAlta): Observable<RecuperoAlta> {
    return this.http.put<RecuperoAlta>(`${this.recurso}/${recupero.id}`, recupero);
  }

  public setFechaVencimiento(recupero: RecuperoAlta): Observable<RecuperoAlta> {
    return this.http.put<RecuperoAlta>(`${this.recurso}/${recupero.id}/vencimiento`, recupero.fechaVencimiento);
  }

  public deleteRecupero(recupero: RecuperoAlta): Observable<RecuperoAlta> {
    return this.http.delete<RecuperoAlta>(`${this.recurso}/${recupero.id}`);
  }

  public findAltaRecuperoById(recupero: Recupero) {
    return this.http.get(`${this.recurso}/${recupero.id}`);
  }

  public findAceptacion(id: number): Observable<IAceptacion> {
    return this.http.get<IAceptacion>(`${this.recurso}/${id}/aceptacion`);
  }

  public editAceptacion(aceptacion: IAceptacion): Observable<IAceptacion> {
    return this.http.put<IAceptacion>(`${this.recurso}/${aceptacion.id}/aceptacion`, aceptacion);
  }

  public findAprobacion(id: number): Observable<IAprobacion> {
    return this.http.get<IAprobacion>(`${this.recurso}/${id}/aprobacion`);
  }

  public editAprobacion(aprobacion: IAprobacion): Observable<IAprobacion> {
    return this.http.put<IAprobacion>(`${this.recurso}/${aprobacion.id}/aprobacion`, aprobacion);
  }

  public findAcreditacion(id: number): Observable<IAcreditacion> {
    return this.http.get<IAcreditacion>(`${this.recurso}/${id}/acreditacion`);
  }

  public editAcreditacion(acreditacion: IAcreditacion): Observable<IAcreditacion> {
    return this.http.put<IAcreditacion>(`${this.recurso}/${acreditacion.id}/acreditacion`, acreditacion);
  }

  public findNotificaciones(recuperoId: number): Observable<Array<RecuperoRequerimientos>> {
    return this.http.get<Array<RecuperoRequerimientos>>(`${this.recurso}/${recuperoId}/requerimientos`);
  }

  public createNotificacion(recuperoId: number, notificacion: RecuperoRequerimientos): Observable<RecuperoRequerimientos> {
    return this.http.post<RecuperoRequerimientos>(`${this.recurso}/${recuperoId}/requerimientos`, notificacion);
  }

  public editNotificacion(recuperoId: number, notificacion: RecuperoRequerimientos): Observable<RecuperoRequerimientos> {
    return this.http.put<RecuperoRequerimientos>(`${this.recurso}/${recuperoId}/requerimientos/${notificacion.id}`, notificacion);
  }

  public deleteNotificacion(recuperoId: number, notificacion: RecuperoRequerimientos): Observable<RecuperoRequerimientos> {
    return this.http.delete<RecuperoRequerimientos>(`${this.recurso}/${recuperoId}/requerimientos/${notificacion.id}`);
  }

  public traerPaso(id: number, paso: number) {
    return this.http.get(`${this.recurso}/${id}/facturar/${paso}`);
  }

  public facturaList(recuperoId: number): Observable<Array<IFacturacion>> {
    return this.http.get<Array<IFacturacion>>(`${this.recurso}/${recuperoId}/facturacion`);
  }

  public facturaListOne(recuperoId: number, factura) {
    return this.http.get(`${this.recurso}/${recuperoId}/facturacion,${factura.id}`);
  }

  public facturaSave(recuperoId: number, factura: IFacturacion) {
    return this.http.post(`${this.recurso}/${recuperoId}/facturacion`, factura);
  }
}
