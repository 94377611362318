import { Component, Input, OnInit } from '@angular/core';
import { ClienteLite } from 'src/app/interfaces/cliente';

@Component({
  selector: 'app-card-tarea',
  templateUrl: './card-tarea.component.html',
  styleUrls: ['./card-tarea.component.scss']
})
export class CardTareaComponent implements OnInit {

  @Input() tarea: {cliente: ClienteLite, vencimiento: string, estado: string, descripcion};

  constructor() {
    
   }

  ngOnInit(): void {
  }

}
