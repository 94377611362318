import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ClienteLite } from 'src/app/interfaces/cliente';
import { Page } from 'src/app/interfaces/page';
import { ClienteService } from 'src/app/services/clientes/cliente.service';

@Component({
  selector: 'app-buscador-cliente',
  templateUrl: './buscador-cliente.component.html',
  styleUrls: ['./buscador-cliente.component.scss']
})
export class BuscadorClienteComponent implements OnInit {

  @Output() select = new EventEmitter<number>();


  @ViewChild('search') searchElement: ElementRef;
  public clientes: Array<ClienteLite> = [];
  public listadoClientes: Array<{ id: number, text: string }> = [];
  public cliente: number;
  constructor(private clienteService: ClienteService) { }

  ngOnInit(): void {
    this.clienteService.findClientesCuitNombre().subscribe((response: Array<ClienteLite>) => {
      this.clientes = response;
      this.listadoClientes = this.clientes.map(c => { return { id: c.id, text: c.cuit + ' - ' + c.nombre } })
    });
  }
  public selecciona() {
    this.select.emit(this.cliente);
  }
  public reset() {
    this.cliente = null;
    this.select.emit(this.cliente);
  }
  public setCliente(idCliente: number) {
    this.cliente = idCliente;
    this.select.emit(this.cliente);
  }


}
