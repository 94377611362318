import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ControlHorasResumen } from "../interfaces/control-horas-resumen";
import { IControlHoras, IControlHorasBase } from "../interfaces/icontrol-horas";
import { IdDescripcionCod } from "../interfaces/id-descripcion-cod";

@Injectable({
  providedIn: "root"
})
export class ControlHorasService {

  public recurso: string = "/controlhoras";

  constructor(private http: HttpClient) { }

  public crearTarea(controlHoras: IControlHoras): Observable<IControlHoras> {
    return this.http.post<IControlHoras>(`${environment.apiEndpoint}${this.recurso}`, controlHoras);
  }

  public tareaEnCurso(): Observable<IControlHoras> {
    return this.http.get<IControlHoras>(`${environment.apiEndpoint}${this.recurso}/tareaencurso`);
  }

  public tiposTarea(): Observable<Array<IdDescripcionCod>> {
    return this.http.get<Array<IdDescripcionCod>>(`${environment.apiEndpoint}${this.recurso}/tipotareas`);
  }

  public actividades(fecha: string): Observable<Array<IControlHoras>> {
    return this.http.get<Array<IControlHoras>>(`${environment.apiEndpoint}${this.recurso}?fecha=${fecha}`);
  }

  public resumenTareas(fecha: string): Observable<ControlHorasResumen> {
    return this.http.get<ControlHorasResumen>(`${environment.apiEndpoint}${this.recurso}/resumenmes?fecha=${fecha}`);
  }

  public detenerTarea(id: number): Observable<IControlHoras> {
    return this.http.patch<IControlHoras>(`${environment.apiEndpoint}${this.recurso}/${id}`, {});
  }
  public eliminarTarea(id: number): Observable<IControlHoras> {
    return this.http.delete<IControlHoras>(`${environment.apiEndpoint}${this.recurso}/${id}`);
  }

  public findDiasAutorizados(personalId: number): Observable<Array<IControlHorasBase>> {
    return this.http.get<Array<IControlHorasBase>>(`${environment.apiEndpoint}${this.recurso}/diasAutorizo/${personalId}`);
  }
  public confirmarDiasAutorizados(personalId: number, diasAutorizados: Array<string>): Observable<boolean> {
    return this.http.post<boolean>(`${environment.apiEndpoint}${this.recurso}/diasAutorizo/${personalId}`, diasAutorizados);
  }
}
