import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Documento } from 'src/app/classes/documento';
import { DocumentoBase } from 'src/app/classes/Documento-base';
import { Modificacion } from 'src/app/interfaces/cliente-datos-societarios';
import { Page } from 'src/app/interfaces/page';
import { DocumentoModificablePipe } from 'src/app/pipes/documento-modificable.pipe';
import { DatosSocietariosService } from 'src/app/services/clientes/datos-societarios.service';
import { Documento as IDocumento } from 'src/app/interfaces/documento';
import * as moment from 'moment';
import { ClienteFisico } from 'src/app/interfaces/cliente';
import { ModalDirective } from 'ngx-bootstrap/modal';
const swal = require('sweetalert');

@Component({
  selector: 'app-modal-constituciones-modificaciones',
  templateUrl: './modal-constituciones-modificaciones.component.html',
  styleUrls: ['./modal-constituciones-modificaciones.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModalConstitucionesModificacionesComponent implements OnInit {

  @ViewChild('modalConstitucionesModificaciones') modalConstitucionesModificaciones: ModalDirective;

  @Input() set id(value) {
    if(value){
      this.idCliente = value;
      this.init();
    }
  }
  @Input() titulo: string;

  formModal: FormGroup;
  public modificaciones: Array<Modificacion> = [];
  public modificacion: Modificacion;
  bsConfig: Partial<BsDatepickerConfig>;
  public contratoSeleccionado = new Documento();
  private defaultErrorMessage = "Ocurrió un error al aplicar los cambios.";
  public idCliente: number;

  constructor(private formBuilder: FormBuilder,
              private localeService: BsLocaleService,
              private datosSocietariosService: DatosSocietariosService) {

    this.formModal = formBuilder.group({
      'nombre': [null, Validators.required],
      'fecha': [null, Validators.required]
    });
    this.bsConfig = Object.assign({},
      {
        containerClass: 'theme-angle',
        dateInputFormat: 'DD/MM/YYYY'
      });
    this.localeService.use('es');
   }

  ngOnInit(): void {
    this.init();
  }
  public init (){
    if(this.idCliente)
    this. findModificaciones();
  }
  public submitFormModal($event, value: any): void {
    $event.preventDefault();
    for (let c in this.formModal.controls) {
      this.formModal.controls[c].markAsTouched();
    }

    if (this.formModal.valid) {

      value.fecha = this.unFormatDate(value.fecha);
      this.modificacion = Object.assign({}, this.modificacion);
      Object.assign(this.modificacion, value);
      this.modificacion.documento = new DocumentoBase(this.contratoSeleccionado);
      this.modificacion.cliente = this.defaultCliente();
      (this.modificacion.id ?
        this.datosSocietariosService.editModificacionContrato(this.modificacion, this.idCliente) :
        this.datosSocietariosService.createModificacionContrato(this.modificacion, this.idCliente)
      ).subscribe((modificacion: Modificacion) => {
        this.findModificaciones();
        this.modificacion = modificacion;
        this.setFormModal(modificacion);
        swal({
          title: 'Guardado',
          text: 'El registro se guardó exitosamente.',
          icon: 'success',
          buttons: false,
          timer: 1600
        });
      }, (error) => {
        swal('¡Lo sentimos!', error.error?.message || this.defaultErrorMessage, 'error');
      });
    }
  }
  public findModificaciones() {
    this.datosSocietariosService.findModificaciones(this.idCliente).subscribe((response: Page<Modificacion>) => {
      this.modificaciones = response.content;
    })
  }


  public createModificacion() {
    this.modificacion = this.defaultModificacion();
    for (let c in this.formModal.controls) {
      this.formModal.controls[c].markAsUntouched();
      this.formModal.controls[c].markAsPristine();
    }
    this.setFormModal(this.modificacion);
    this.modalConstitucionesModificaciones.show();
  }

  editModificacion(modificacion: Modificacion) {
    this.modificacion = modificacion;
    this.setFormModal(this.modificacion);
  }

  private async setFormModal(modificacion: Modificacion) {
    this.formModal.controls['fecha'].setValue(modificacion?.fecha ? this.formatDate(modificacion?.fecha) : null);
    this.formModal.controls['nombre'].setValue(modificacion?.nombre || null);
    if (this.modificacion?.documento)
      this.contratoSeleccionado = DocumentoModificablePipe.prototype.transform(new Documento(this.modificacion.documento));
    else this.contratoSeleccionado = new Documento();
  }

  private defaultModificacion(): Modificacion {
    return {
      'id': null,
      'fecha': null,
      'detalles': null,
      'nombre': null,
      'documento': null,
      'version': null,
      'cliente': null
    };
  }

  public valueRequiredModal(value: string) {
    return this.formModal.controls[value].hasError('required') && (this.formModal.controls[value].dirty || this.formModal.controls[value].touched);
  }
  public deleteModificacion() {
    swal({
      title: 'Eliminar modificación de contrato constitutivo.',
      text: '¿Está seguro que quiere eliminar esta modificación?',
      icon: 'warning',
      buttons: {
        cancel: { text: 'Cancelar', value: null, visible: true, className: '', closeModal: true },
        danger: { text: 'Eliminar', value: true, visible: true, className: '', closeModal: false }
      }
    }).then((isConfirm) => {
      if (isConfirm) {
        this.datosSocietariosService.deleteModificacionContrato(this.modificacion, this.idCliente).
          subscribe((response: any) => {
            this.findModificaciones();
            this.createModificacion();
            swal({
              title: 'Eliminado',
              text: 'La modificación se eliminó exitosamente.',
              icon: 'success',
              buttons: false,
              timer: 1600
            });
          }, (error) => {
            swal('¡Lo sentimos!', error.error?.message || this.defaultErrorMessage, 'error')
          });
      }
    });
  }

   public onContratoAgregado(documento: IDocumento): void {
    documento.seleccionado = true;
    this.contratoSeleccionado = new Documento(documento);
  }

  public onContratoEliminado(): void {
    this.contratoSeleccionado = new Documento();
  }
  public formatDate(date: string) {
    if (date && moment(date, 'YYYY-MM-DD').isValid())
      return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
    else return null;
  }
  public unFormatDate(date: string) {
    if (date && moment(date, 'DD-MM-YYYY').isValid())
      return moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD');
    else return null;
  }

  public defaultCliente(): ClienteFisico {
    return {
      id: this.idCliente,
      nombre: null,
      cuit: null,
      clienteActivo: null,
      clienteEventual: null,
      codigoFondoFijo: null,
      codigoHonorario: null,
      fecha: null,
      fechaConstitucion: null,
      grupoEconomicoDto: null,
      mesAltaLiquidacionEstudio: null,
      mesCierre: null,
      notificaResponsable: null,
      notificaVisitas: null,
      requiereFondos: null,
      sinCuit: null,
      tipoSociedad: null,
      tipoCliente: null,
      vigencia: null,
      tipoDocumento: null,
      numDocumento: null,
      nacionalidad: null,
      profesion: null,
      fechaNacimiento: null,
      fechaFallecimiento: null,
      declaratoriaHerederos: null,
      participacionDiciembre: null
    }
  }
}
