import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { DataGrafico } from 'src/app/interfaces/data-grafico';
import * as moment from "moment";
import html2canvas from 'html2canvas'

@Component({
  selector: 'app-grafico-informe',
  templateUrl: './grafico-informe.component.html',
  styleUrls: ['./grafico-informe.component.scss']
})
export class GraficoInformeComponent implements OnInit {

  @Output() canvas = new EventEmitter();
  @Input() set mesDesdeHasta([mesAnioDesde, mesAnioHasta]) {
    this.generateArrayMeses(moment(mesAnioDesde, "DD/MM/YYYY").format("MM/YY"), moment(mesAnioHasta, "DD/MM/YYYY").format("MM/YY"));
  }
  @Input() estados: Array<string>;
  @Input() set dataGrafico(value: Array<{ estado: string, fecha_vencimiento: string }>) {
    if (value && this.estados) {
      this.data = null;
      let estado: DataGrafico;
      let fechaAux: any;
      let data = [];
      for (let i = 0; i < this.estados.length; i++) {
        data.push({ label: this.estados[i], color: this.getStateColor(this.estados[i]), stack: true, data: JSON.parse(JSON.stringify(this.meses)) })
      }
      value.forEach(v => {
        estado = data.find(d => d.label === v.estado);
        fechaAux = moment(v.fecha_vencimiento, "DD/MM/YYYY").format("MM/YY")
        estado.data.find(d => d[0] === fechaAux)[1]++;
      });
      data.forEach(d => {
        d.label = d.label.replaceAll("_", " ");
      })
      this.data = data;
      this.data.forEach(d => {
        d.data.forEach((m, i) => {
          this.mesesTotales[i][1] += m[1];
        });
      });
    }
  }
  public data: Array<DataGrafico>;
  public meses: Array<Array<any>>;
  public mesesTotales: Array<Array<any>>;
  public startOfYear = moment().startOf('year').format('MM/YY');
  public endOfYear = moment().endOf('year').format('MM/YY');
  public mesAnioDesde: string;
  public mesAnioHasta: string;

  // BAR STACKED
  // -----------------------------------

  public barStackedOptions = {
    series: {
      stack: true,
      bars: {
        align: 'center',
        lineWidth: 0,
        show: true,
        barWidth: 0.6,
        fill: 0.9
      }
    },
    grid: {
      borderColor: '#eee',
      borderWidth: 1,
      hoverable: true,
      backgroundColor: '#fcfcfc'
    },
    tooltip: true,
    tooltipOpts: {
      content: ((label, x, y) => {
        return y + ' - ' + (y / this.mesesTotales.find(m => m[0] === x)[1] * 100).toFixed(0) + '%';
      })
    },
    xaxis: {
      tickColor: '#fcfcfc',
      mode: 'categories'
    },
    yaxis: {
      min: 0,
      //max: 200, // optional: use it for a clear represetation
      // position: ($scope.app.layout.isRTL ? 'right' : 'left'),
      tickColor: '#eee'
    },
    shadowSize: 0
  };

  constructor(public http: HttpClient) {
  }
  getChartData(url): Observable<any> {
    return this.http.get(url);
  }

  ngOnInit(): void {
  }
  getStateColor(estado: string): string {
    switch (estado) {
      case "CUMPLIDO":
        return "#4caf50"
      case "VENCIDO":
        return "#f44336"
      case "A_VENCER":
        return "#ff9800"
    }
  }
  generateArrayMeses(mesAnioDesde = this.startOfYear, mesAnioHasta = this.endOfYear) {
    this.mesAnioDesde = mesAnioDesde;
    this.mesAnioHasta = mesAnioHasta;
    this.meses = [];
    let diferencia = moment(mesAnioHasta, "MM/YY").diff(moment(mesAnioDesde, "MM/YY"), "months", false) + 1;
    if (diferencia < 12)
      diferencia = 12;
    let mes: any = mesAnioDesde;
    for (let i = 0; i < diferencia; i++) {
      this.meses.push([moment(mes, "MM/YY").format("MM/YY"), 0]);
      mes = moment(mes, "MM/YY").add(1, "months");
    }
    this.mesesTotales = JSON.parse(JSON.stringify(this.meses))
  }
}
