import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { environment } from "src/environments/environment";
import { CodigoDescripcion } from "../interfaces/codigo-descripcion";
import { IdDescripcionCod } from "../interfaces/id-descripcion-cod";
import { Page } from "../interfaces/page";


@Injectable({
    providedIn: "root"
})
export class TaOpcionService {

    public recurso: string = "/taopcion";

    constructor(private http: HttpClient) { }

    public findAreas(): Observable<Page<CodigoDescripcion>> {
        return this.http.get<Page<CodigoDescripcion>>(`${environment.apiEndpoint}${this.recurso}/area`);
    }

    public findDeptoLiquida(): Observable<Page<CodigoDescripcion>> {
        return this.http.get<Page<CodigoDescripcion>>(`${(environment.apiEndpoint)}${this.recurso}/deptoliq`);
    }

    public findPeriodicidad(): Observable<Page<CodigoDescripcion>> {
        return this.http.get<Page<CodigoDescripcion>>(`${environment.apiEndpoint}${this.recurso}/periodicidad`);
    }

    public findTipoCliente(): Observable<Page<CodigoDescripcion>> {
        return this.http.get<Page<CodigoDescripcion>>(`${(environment.apiEndpoint)}${this.recurso}/tipocliente`);
    }

    public findTipoDocumento(): Observable<Page<CodigoDescripcion>> {
        return this.http.get<Page<CodigoDescripcion>>(`${(environment.apiEndpoint)}${this.recurso}/tiposDocumentos`);
    }

    public categoriaFideicomisos(): Observable<Page<CodigoDescripcion>> {
        return this.http.get<Page<CodigoDescripcion>>(`${(environment.apiEndpoint)}${this.recurso}/categoriasFideicomisos`);
    }

    public categoriaBeneficiario(): Observable<Page<CodigoDescripcion>> {
        return this.http.get<Page<CodigoDescripcion>>(`${(environment.apiEndpoint)}${this.recurso}/titularBeneficiario`);
    }

    public findUnidadTiempo(): Observable<Page<IdDescripcionCod>> {
        return this.http.get<Page<CodigoDescripcion>>(`${(environment.apiEndpoint)}${this.recurso}/unidadTiempo`);
    }

    public findTipoCierre(): Observable<Page<IdDescripcionCod>> {
        return this.http.get<Page<CodigoDescripcion>>(`${(environment.apiEndpoint)}${this.recurso}/tipocierre`);
    }

    public findTipoCalculo(): Observable<Page<IdDescripcionCod>> {
        return this.http.get<Page<CodigoDescripcion>>(`${(environment.apiEndpoint)}${this.recurso}/recuperosTipoCalculo`);
    }

    public findTipoPresentacion(): Observable<Page<IdDescripcionCod>> {
        return this.http.get<Page<CodigoDescripcion>>(`${(environment.apiEndpoint)}${this.recurso}/tipoPresentacion`);
    }

    public findByTaGrupo(taGrupo: string): Observable<Page<IdDescripcionCod>> {
        return this.http.get<Page<CodigoDescripcion>>(`${(environment.apiEndpoint)}${this.recurso}/${taGrupo}`);
    }

    public findMeses(): Observable<Page<IdDescripcionCod>> {
        return this.http.get<Page<CodigoDescripcion>>(`${(environment.apiEndpoint)}${this.recurso}/meses`);
    }

}
