import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { from, Observable } from "rxjs";
import { AREA } from "src/app/enums/areas";
import { ClienteDatosContables } from "src/app/interfaces/cliente-datos-contables";
import { IdDescripcionCod } from "src/app/interfaces/id-descripcion-cod";
import { Page } from "src/app/interfaces/page";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class DatosContablesService {
  constructor(private http: HttpClient) { }

  public findDatosContables(idCliente: number): Observable<ClienteDatosContables> {
    return this.http.get<ClienteDatosContables>(`${environment.apiEndpoint}/clientes/${idCliente}/datosContables`);
  }

  public findAlcanceAuditoria(): Observable<Array<IdDescripcionCod>> {
    return this.http.get<Array<IdDescripcionCod>>(`${environment.apiEndpoint}/tiposComplejidad?area=${AREA.AUDITORIA}`);
  }

  public findComplejidadOutsourcing(): Observable<Array<IdDescripcionCod>> {
    return this.http.get<Array<IdDescripcionCod>>(`${environment.apiEndpoint}/tiposComplejidad?area=${AREA.OUTSOURCING}`);
  }

  public findTiposContabilidad() {
    let promise = new Promise(resolve => resolve({
      content: [
        { id: 150, codigo: "01", descripcion: "COPIATIVO", habilitado: true },
        { id: 151, codigo: "02", descripcion: "HOJAS MOVILES", habilitado: true }
      ]
    }));
    return from(promise);
  }
  public editDatosContables(cliente: ClienteDatosContables, idCliente: number) {
    return this.http.put(`${environment.apiEndpoint}/clientes/${idCliente}/datosContables`, cliente);
  }
}
