import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HABILITADO } from 'src/app/enums/habilitado';
import { ClienteBase } from 'src/app/interfaces/cliente';
import { Page } from 'src/app/interfaces/page';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClienteBaseService {

  recurso: string = "clientesBase";

  constructor(private http: HttpClient) { }

  public findAll({
    orderCriteria = "ASC",
    orderField = "nombre",
    pageNumber = "1",
    pageSize = "10000",
    habilitado = HABILITADO.TODOS
  } = {}): Observable<Page<ClienteBase>> {
    let params = new URLSearchParams([
      ["orderCriteria", orderCriteria],
      ["orderField", orderField],
      ["pageNumber", pageNumber],
      ["pageSize", pageSize],
      ["habilitado", habilitado]
    ]);
    return this.http.get<Page<ClienteBase>>(`${environment.apiEndpoint}/${this.recurso}?${params.toString()}`);
  }

  public findClientesByResponsableAndArea({
    orderCriteria = "ASC",
    orderField = "nombre",
    pageNumber = "1",
    pageSize = "1000",
    area, responsable
  }): Observable<Page<ClienteBase>> {
    let params = new URLSearchParams([
      ["orderCriteria", orderCriteria],
      ["orderField", orderField],
      ["pageNumber", pageNumber],
      ["pageSize", pageSize]
    ]);
    return this.http.get<Page<ClienteBase>>(`${environment.apiEndpoint}/responsables/${responsable}/area/${area}/${this.recurso}?${params.toString()}`);
  }

}
