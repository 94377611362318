import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ComprobantePago } from '../interfaces/comprobante-pago';
import { Page } from 'src/app/interfaces/page';

@Injectable({
  providedIn: 'root'
})
export class ComprobantesPagosService {

  recurso = '/comprobantePago'

  constructor(private http: HttpClient) { }
  
  public findComprobantes({
    pageNumber = 1,
    pageSize = 1000,
    orderCriteria = 'Desc',
    orderField = 'id',
    tipoComprobante = null,
    grupoEconomicoId = null,
    codigo = null
  } = {}): Observable<Page<ComprobantePago>> {
    let params = new URLSearchParams([])
    pageNumber ? params.append("pageNumber", pageNumber.toString()) : null;
    pageSize ? params.append("pageSize", pageSize.toString()) : null;
    orderCriteria ? params.append("orderCriteria", orderCriteria) : null;
    orderField ? params.append("orderField", orderField) : null;
    tipoComprobante ? params.append("tipoComprobante", tipoComprobante) : null;
    grupoEconomicoId ? params.append("grupoEconomicoId", grupoEconomicoId) : null;
    codigo ? params.append("codigo", codigo) : null;
    return this.http.get<Page<ComprobantePago>>(`${environment.apiEndpoint}${this.recurso}?${params.toString()}`);
  }

  public addComprobante(comprobante: ComprobantePago): Observable<ComprobantePago> {
    return this.http.post<ComprobantePago>(`${environment.apiEndpoint}${this.recurso}`, comprobante);
  }

  public editComprobantePago(comprobante: ComprobantePago): Observable<ComprobantePago> {
    return this.http.put<ComprobantePago>(`${environment.apiEndpoint}${this.recurso}/${comprobante.id}`, comprobante);
  }
  
  public deleteComprobantePago(comprobante: ComprobantePago) {
    return this.http.delete(`${environment.apiEndpoint}${this.recurso}/${comprobante.id}`);
  }

}
