import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";

@Component({
  selector: 'app-date-inline-editable',
  templateUrl: './date-inline-editable.component.html',
  styleUrls: ['./date-inline-editable.component.scss']
})
export class DateInlineEditableComponent implements OnInit {

  public editInline = false;

  @Input() placeholder: string;

  @Input() fecha: string;

  @Input() set loading(value: boolean) {
    this._loading = value;
  }

  @Input() set enable(value: boolean) {
    this._enable = value;
  }

  public _loading: boolean;
  public _enable: boolean;

  datePickerConfig: Partial<BsDatepickerConfig>;

  public bsValue = null;

  @Output() guardar = new EventEmitter<String>();

  updating: boolean;

  constructor() {
    // Datepicker
    this.loading = true;
    this.datePickerConfig = Object.assign({},
      {
        containerClass: 'theme-angle',
        dateInputFormat: 'DD/MM/YYYY',
      });
  }

  ngOnInit(): void {
    this.updating = false;
  }

  init() {
    this.fecha = null;
    if (this.fecha && !this._loading) {
      let date = this.fecha.split("/");
      if (date.length === 3) {
        this.bsValue = new Date(new Date(parseInt(date[2]), parseInt(date[1]), parseInt(date[0]), 0, 0, 0, 0));
        // this.updating = false;
      }
    }
    else {
      this.bsValue = null;
    }
  }

  save(fecha) {
    if (!this._loading && fecha && !moment(fecha).isSame(this.bsValue, "day"))
      this.guardar.emit(fecha.getFullYear() + '-' + (fecha.getMonth() + 1 < 10 ? '0' + (fecha.getMonth() + 1) : fecha.getMonth() + 1) + '-' + (fecha.getDate() < 10 ? '0' + fecha.getDate() : fecha.getDate()));
    // this.updating = false;
  }

  close() {
    this.editInline = false;
  }
}
