import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import * as moment from "moment";
import { BsDatepickerConfig, BsLocaleService } from "ngx-bootstrap/datepicker";
import { ModalDirective } from "ngx-bootstrap/modal";
import { forkJoin } from "rxjs";
import { HABILITADO } from "src/app/enums/habilitado";
import { LibroSocietario as Libro, Movimiento } from "src/app/interfaces/cliente-datos-societarios";
import { IdCodigoDescripcionHabilitado } from "src/app/interfaces/id-codigo-descripcion-habilitado";
import { IdDescripcionCod } from "src/app/interfaces/id-descripcion-cod";
import { Page } from "src/app/interfaces/page";
import { Personal } from "src/app/interfaces/personal";
import { DatosContablesService } from "src/app/services/clientes/datos-contables.service";
import { DatosSocietariosService } from "src/app/services/clientes/datos-societarios.service";
import { DepositosService } from "src/app/services/depositos.service";
import { OrganismoService } from "src/app/services/organismo.service";
import { PersonalService } from "src/app/services/personal.service";
import { TiposLibrosService } from "src/app/services/tipos-libros.service";
import { LibrosServiceService } from "src/app/services/libros-service.service";
import { MailService } from "src/app/services/mail.service";
const swal = require("sweetalert");

@Component({
  selector: "app-libros",
  templateUrl: "./libros.component.html",
  styleUrls: ["./libros.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class LibrosComponent implements OnInit {

  @ViewChild("modalLibro") modalLibro: ModalDirective;
  @ViewChild("modalMovimiento") modalMovimiento: ModalDirective;

  @Input() set idCliente(value) {
    if(value){
      this.id = value;
      this.init();
    }
  }

  public formLibro: FormGroup;
  public formMovimiento: FormGroup;
  public responsables: Array<Personal> = [];
  public libros: Array<Libro> = [];
  public depositos: Array<IdCodigoDescripcionHabilitado>;
  public listadoDepositos: Array<{ id: number, text: string }> = [];
  public backupDepositos: Array<{ id: number, text: string }> = [];
  public tiposLibros: Array<IdCodigoDescripcionHabilitado> = [];
  public listadoTiposLibro: Array<{ id: number, text: string }> = [];
  public organismos: Array<IdCodigoDescripcionHabilitado> = [];
  public listadoOrganismos: Array<{ id: number, text: string }> = [];
  public sistemas: Array<IdDescripcionCod>;
  public listadoSistemas: Array<{ id: number, text: string }> = [];
  public personal: Array<Personal>;
  public listadoResponsable: Array<{ id: number, text: string }> = [];
  public orden: number = 1;
  public filtrax: string = "organismo";
  private defaultErrorMessage = "Ocurrió un error al aplicar los cambios.";
  public showMovimiento: boolean = false;

  public libro: Libro;
  public movimiento: Movimiento;
  public libroSeleccionado: Libro;
  public filter: string = "";
  public bsConfig: Partial<BsDatepickerConfig>;
  public loading: boolean;
  public id: number;
  public isCreatingName: boolean = false;

  constructor(private datosContablesService: DatosContablesService, private tiposLibrosService: TiposLibrosService, private depositosService: DepositosService, private formBuilder: FormBuilder, private personalService: PersonalService, private localeService: BsLocaleService, private datosSocietariosService: DatosSocietariosService, private activatedRoute: ActivatedRoute, private organismosService: OrganismoService, private librosService: LibrosServiceService, private mailService: MailService) {
    this.loading = true;
    this.formLibro = formBuilder.group({
      deposito: [null, Validators.required],
      organismo: [null, Validators.required],
      tipoLibro: [null, Validators.required],
      sistema: [null, Validators.required],
      numeroLibros: [null, Validators.required],
      cantidadFojas: [null],
      fechaAprobacion: [null],
      numeroResolucion: [null]
    });
    this.formMovimiento = formBuilder.group({
      movimiento: [{ value: null, disabled: true }, Validators.required],
      deposito: [null, Validators.required],
      responsable: [null, Validators.required],
      quienRetira: [{ value: null, disabled: true }, Validators.required],
      motivo: [null, Validators.required],
      editar: [null]
    });
    this.bsConfig = Object.assign({},
      {
        containerClass: "theme-angle",
        dateInputFormat: "DD/MM/YYYY"
      });
    this.localeService.use("es");
  }
  public muestraMovimientos(libro: Libro) {
    if (this.libroSeleccionado?.id === libro?.id || this.showMovimiento === false)
      this.showMovimiento = !this.showMovimiento;
    this.libroSeleccionado = libro;
    this.listadoDepositos = this.backupDepositos.map(d => ({ id: d.id, text: d.text }));
    this.listadoDepositos.splice(this.listadoDepositos.findIndex((l) => l.id === this.libroSeleccionado?.deposito.id), 1);
  }

  ngOnInit(): void {
    this.loading = false;

    if(!this.id){
      this.id = parseInt(this.activatedRoute.snapshot.paramMap.get("id")) || null;
      this.init();
    }
    
    this.findOrganismo();
    this.findPersonal();
    this.findDepositos();
    this.findTiposLibros();
    this.findDatosContables();
  }

  public init (){
    if(this.id)
      this.findLibros();
  }

  public findDepositos() {
    this.depositosService.findDepositos({ habilitado: HABILITADO.SI })
      .subscribe((response: Page<IdCodigoDescripcionHabilitado>) => {
        this.depositos = response.content;
        this.backupDepositos = response.content.map(d => ({ id: d.id, text: d.descripcion }));
        this.listadoDepositos = this.backupDepositos.map(d => ({ id: d.id, text: d.text }));
      });
  }
  public findDatosContables() {
    this.datosContablesService.findTiposContabilidad()
      .subscribe((response: Page<IdCodigoDescripcionHabilitado>) => {
        this.sistemas = response.content;
        this.listadoSistemas = this.sistemas.map(d => ({ id: d.id, text: d.descripcion }));
      });
  }
  public findTiposLibros() {
    this.tiposLibrosService.findTiposLibros({ habilitado: HABILITADO.SI })
      .subscribe((response: Page<IdCodigoDescripcionHabilitado>) => {
        this.tiposLibros = response.content;
        this.listadoTiposLibro = this.tiposLibros.map(d => ({ id: d.id, text: d.descripcion }));
      });
  }

  public findLibros() {
    this.datosSocietariosService.findLibros(this.id).subscribe((response: Page<Libro>) => {
      this.libros = response.content;
      this.libros.forEach(l => {
        l.movimientos = l.movimientos.reverse();
      });
      this.loading = false;
    });
  }
  public findOrganismo() {
    this.organismosService.findOrganismos().subscribe((response: Page<IdCodigoDescripcionHabilitado>) => {
      this.organismos = response.content;
      this.listadoOrganismos = this.organismos.map(o => ({ text: o.descripcion, id: o.id }));
    });
  }

  public findPersonal() {
    this.personalService.findPersonal().subscribe((response: Page<Personal>) => {
      this.responsables = response.content;
      this.listadoResponsable = this.responsables.map(r => ({ id: r.id, text: r.nombre }));
    });
  }

  public submitFormLibro($event, value: any): void {
    $event.preventDefault();
    for (let c in this.formLibro.controls) {
      this.formLibro.controls[c].markAsTouched();
    }
    if (this.formLibro.valid) {
      this.libro.deposito = this.depositos.find(d => d.id === value.deposito) || null;
      this.libro.organismo = this.organismos.find(o => o.id === value.organismo) || null;
      this.libro.tipoLibro = this.tiposLibros.find(t => t.id === value.tipoLibro) || null;
      this.libro.sistema = this.sistemas.find(s => s.id === value.sistema) || null;
      this.libro.numeroLibros = value.numeroLibros || null;
      this.libro.cantidadFojas = value.cantidadFojas || null;
      this.libro.fechaAprobacion = value.fechaAprobacion ? moment(value.fechaAprobacion).format("YYYY-MM-DD").toString() : null;
      this.libro.cliente = {
        id: this.id,
        cuit: null,
        nombre: null
      };
      if (!this.libro.id)
        this.libro.fechaAlta = moment().format("YYYY-MM-DD");
      this.libro.numeroResolucion = value.numeroResolucion || null;
      (this.libro.id ?
        this.librosService.editLibro(this.libro) :
        this.librosService.createLibro(this.libro)
      ).subscribe((libro: Libro) => {
        this.libro = libro;
        this.setFormLibro(libro);
        this.findLibros();
        this.modalLibro.hide();
        swal({
          title: "Guardado",
          text: "El registro se guardó exitosamente.",
          icon: "success",
          buttons: false,
          timer: 1600
        });
      }, (error) => {
        swal("¡Lo sentimos!", error.error?.message || this.defaultErrorMessage, "error");
      });
    }
  }
  public submitFormMovimiento($event, value: any): void {
    $event.preventDefault();
    for (let c in this.formMovimiento.controls) {
      this.formMovimiento.controls[c].markAsTouched();
    }
    if (this.formMovimiento.valid) {
      this.movimiento.ingresa = value.ingresa === "ENTRADA" ? true : false;
      this.movimiento.deposito = this.depositos.find(d => d.id === value.deposito) || null;
      this.movimiento.motivo = value.motivo;
      if (value.responsable) {
        this.movimiento.responsable = this.responsables.find(r => r.id === value.responsable) || null;
        this.movimiento.quienRetira = this.movimiento.responsable.nombre || null;
      } else {
        this.movimiento.quienRetira = value.quienRetira || null;
      }
      if (!this.movimiento.id) this.movimiento.fechahora = moment().format("YYYY-MM-DD HH:mm:ss");
      
        this.datosSocietariosService.createMovimiento(this.id, this.libroSeleccionado, this.movimiento, value?.editar || false).subscribe((movimiento: Movimiento) => {
        this.formMovimiento.controls.editar.value ? this.mailService.librosMovimientos(movimiento.id) : null
        if (!this.movimiento.id)
          this.libroSeleccionado.movimientos.splice(0, 0, movimiento);
        this.movimiento = movimiento;
        this.findLibros();
        this.muestraMovimientos(this.libroSeleccionado);
        this.modalMovimiento.hide();
        swal({
          title: "Guardado",
          text: "El registro se guardó exitosamente.",
          icon: "success",
          buttons: false,
          timer: 1600
        });
      }, (error) => {
        swal("¡Lo sentimos!", error.error?.message || this.defaultErrorMessage, "error");
      });
    }
  }

  public valueRequiredLibro(value: string) {
    return this.formLibro.controls[value].hasError("required") && (this.formLibro.controls[value].dirty || this.formLibro.controls[value].touched);
  }
  public valueRequiredMovimiento(value: string) {
    return this.formMovimiento.controls[value].hasError("required") && (this.formMovimiento.controls[value].dirty || this.formMovimiento.controls[value].touched);
  }
  public createLibro() {
    this.modalLibro.show();
    this.libro = this.defaultObjectLibro();
    this.formLibro.controls.deposito.enable();
    this.setFormLibro(this.libro);
    for (let c in this.formLibro.controls) {
      this.formLibro.controls[c].markAsUntouched();
      this.formLibro.controls[c].markAsPristine();
    }
  }
  public createMovimiento() {
    this.isCreatingName = false;
    this.modalMovimiento.show();
    this.movimiento = this.defaultObjectMovimiento();
    this.formMovimiento.reset();
    this.setFormMovimiento(this.movimiento);
  }
  public editLibro(libro: Libro) {
    this.modalLibro.show();
    this.libro = libro;
    this.formLibro.controls.deposito.disable();
    this.setFormLibro(this.libro);
  }

  public deleteLibro(libro: Libro) {
    swal({
      title: "Eliminar libro",
      text: "¿Está seguro que quiere eliminar este libro?",
      icon: "warning",
      buttons: {
        cancel: { text: "Cancelar", value: null, visible: true, className: "", closeModal: true },
        danger: { text: "Eliminar", value: true, visible: true, className: "", closeModal: false }
      }
    }).then((isConfirm) => {
      if (isConfirm) {
        this.datosSocietariosService.deleteLibro(libro, this.id).
          subscribe((response: any) => {
            this.findLibros();
            this.showMovimiento = false;
            swal({
              title: "Eliminado",
              text: "El libro se eliminó exitosamente.",
              icon: "success",
              buttons: false,
              timer: 1600
            });
          }, (error) => {
            swal("¡Lo sentimos!", error.error?.message || this.defaultErrorMessage, "error");
          });
      }
    });
  }
  public deleteMovimiento(movimiento: Movimiento) {
    swal({
      title: "Eliminar movimiento",
      text: "¿Está seguro que quiere eliminar este movimiento?",
      icon: "warning",
      buttons: {
        cancel: { text: "Cancelar", value: null, visible: true, className: "", closeModal: true },
        danger: { text: "Eliminar", value: true, visible: true, className: "", closeModal: false }
      }
    }).then((isConfirm) => {
      if (isConfirm) {
        this.datosSocietariosService.deleteMovimiento(this.id, this.libroSeleccionado, movimiento).
          subscribe((response: Movimiento) => {
            this.libroSeleccionado.movimientos.splice(this.libroSeleccionado.movimientos.findIndex(m => m.id === response.id), 1);
            swal({
              title: "Eliminado",
              text: "El movimiento se eliminó exitosamente.",
              icon: "success",
              buttons: false,
              timer: 1600
            });
          }, (error) => {
            swal("¡Lo sentimos!", error.error?.message || this.defaultErrorMessage, "error");
          });
      }
    });
  }
  private async setFormLibro(libro: Libro) {
    this.formLibro.controls.deposito.patchValue(libro?.deposito ? this.backupDepositos.find(o => o.id === libro?.deposito.id)?.id : null);
    this.formLibro.controls.organismo.patchValue(libro?.organismo ? this.listadoOrganismos.find(o => o.id === libro?.organismo.id)?.id : null);
    this.formLibro.controls.tipoLibro.patchValue(libro?.tipoLibro ? this.listadoTiposLibro.find(o => o.id === libro?.tipoLibro.id)?.id : null);
    this.formLibro.controls.sistema.patchValue(libro?.sistema ? this.listadoSistemas.find(o => o.id === libro?.sistema.id)?.id : null);
    this.formLibro.controls.numeroLibros.patchValue(libro?.numeroLibros || null);
    this.formLibro.controls.cantidadFojas.patchValue(libro?.cantidadFojas || null);
    this.formLibro.controls.fechaAprobacion.patchValue(libro?.fechaAprobacion ? moment(libro?.fechaAprobacion).format("DD/MM/YYYY") : null);
    this.formLibro.controls.numeroResolucion.patchValue(libro?.numeroResolucion || null);
  }
  private async setFormMovimiento(movimiento: Movimiento) {
    if (this.movimiento.id) {
      this.movimiento.responsable.id ? this.isCreatingName = false : this.isCreatingName = true;
      this.setInput(this.isCreatingName);
    }
    this.formMovimiento.controls.movimiento.patchValue(movimiento?.ingresa ? "ENTRADA" : "SALIDA");
    this.formMovimiento.controls.quienRetira.patchValue(movimiento?.quienRetira || null);
    this.formMovimiento.controls.responsable.patchValue(movimiento?.responsable.id || null);
    this.formMovimiento.controls.deposito.patchValue(movimiento?.deposito ? this.backupDepositos.find(o => o.id === movimiento?.deposito.id)?.id : null);
    this.formMovimiento.controls.motivo.patchValue(movimiento?.motivo || null);
  }

  private defaultObjectLibro(): Libro {
    return {
      id: null,
      organismo:
      {
        id: null,
        codigo: null,
        descripcion: null,
        habilitado: null
      },
      tipoLibro:
      {
        id: null,
        codigo: null,
        descripcion: null,
        habilitado: null
      },
      sistema:
      {
        id: null,
        codigo: null,
        descripcion: null
      },
      deposito:
      {
        id: null,
        codigo: null,
        descripcion: null,
        habilitado: null
      },
      numeroLibros: null,
      cantidadFojas: null,
      fechaAprobacion: null,
      numeroResolucion: null,
      fechaAlta: null,
      movimientos: null,
      cliente: {
        id: this.id,
        cuit: null,
        nombre: null
      }
    };
  }
  private defaultObjectMovimiento(): Movimiento {
    let ingresac = (this.libroSeleccionado.deposito.codigo === "01" ? false : true);
    return {
      id: null,
      ingresa: ingresac,
      fechahora: null,
      motivo: null,
      quienRetira: null,
      responsable:
      {
        id: null,
        codigo: null,
        nombre: null,
        email: null
      },
      deposito:
      {
        id: null,
        codigo: null,
        descripcion: null,
        habilitado: null
      }
    };
  }

  public setInput(value: boolean) {
    this.isCreatingName = value;
    if (value) {
      this.formMovimiento.controls.quienRetira.enable();
      this.formMovimiento.controls.responsable.disable();
    } else {
      this.formMovimiento.controls.responsable.enable();
      this.formMovimiento.controls.quienRetira.disable();
    }
    this.formMovimiento.controls.responsable.reset();
    this.formMovimiento.controls.quienRetira.reset();
    this.formMovimiento.updateValueAndValidity();
  }

  public ordenar(campo: string) {
    this.filtrax = campo;
    this.libros.sort((a, b) => {
      const codigoA = a[campo]?.descripcion ? a[campo]?.descripcion.toString().toLowerCase() : a[campo].toString().toLowerCase();
      const codigoB = b[campo]?.descripcion ? b[campo]?.descripcion.toString().toLowerCase() : b[campo].toString().toLowerCase();
      if (codigoA < codigoB) {
        return -1 * (this.orden);
      }
      if (codigoA > codigoB) {
        return 1 * (this.orden);
      }
      return 0;
    });
  }

}

