import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import modalUtils from 'src/app/utils/modal';

@Component({
  selector: 'app-modal-loading',
  templateUrl: './modal-loading.component.html',
  styleUrls: ['./modal-loading.component.scss']
})
export class ModalLoadingComponent implements OnInit {

  public loadingModal: ModalDirective;

  @ViewChild("loadingModal") modal: ModalDirective;
  constructor() { }

  ngOnInit(): void {
  }

  public open() {
    modalUtils.open(this.modal);
  }

  public close(){
    modalUtils.hide(this.modal);
  }
}
