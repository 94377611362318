import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { forkJoin } from 'rxjs';
import { HABILITADO } from 'src/app/enums/habilitado';
import { ClienteBase } from 'src/app/interfaces/cliente';
import { ContactoCliente } from 'src/app/interfaces/contacto-cliente';
import { IdNombreCodigo } from 'src/app/interfaces/id-nombre-codigo';
import { Personal } from 'src/app/interfaces/personal';
import { AuthService } from 'src/app/services/auth.service';
import { ClienteBaseService } from 'src/app/services/clientes/cliente-base.service';
import { ContactosClientesService } from 'src/app/services/clientes/contactos-clientes.service';
import modalUtils from 'src/app/utils/modal';
import { ListadoContactosComponent } from 'src/app/components/shared/listado-contactos/listado-contactos.component';
import { MailService } from 'src/app/services/mail.service';
import { fondoDetalle, Fondos } from 'src/app/interfaces/fondos';
import { FondosService } from 'src/app/services/fondos.service';
import { GoogleSheetService } from 'src/app/services/google-sheet.service';
import { ConsultaSaldoGrupoEconomico, SaldoGrupoEconomico } from 'src/app/interfaces/saldo-grupo-economico';
import { ClienteService } from 'src/app/services/clientes/cliente.service';
import { ERROR_MESSAGES } from 'src/app/enums/error-messages';
import { ModalLoadingComponent } from '../modal-loading/modal-loading.component';
const swal = require("sweetalert");
@Component({
  selector: 'app-modal-alta-editar-ver-pedido-de-fondos',
  templateUrl: './modal-alta-editar-ver-pedido-de-fondos.component.html',
  styleUrls: ['./modal-alta-editar-ver-pedido-de-fondos.component.scss']
})
export class ModalAltaEditarVerPedidoDeFondosComponent implements OnInit {
  @ViewChild('modalAltaEditarVerPedidoDeFondos') modalAltaEditarVerPedidoDeFondos: ModalDirective;
  @ViewChild('contactos') contactos: ListadoContactosComponent;

  @Input() tituloModal: string;
  @Input() fondoSeleccionado: Fondos;
  @Output() guardado = new EventEmitter<Fondos>();
  @ViewChild("modalLoading") modalLoading: ModalLoadingComponent;

  public formFitros: FormGroup;
  public formNuevoPedido: FormGroup;
  public fondo: Fondos;
  public actualDate: Date = new Date();

  public clientes: Array<ClienteBase>;
  public listadoClientes: Array<{ id: number, text: string }> = [];
  public solicitadoA: Array<ContactoCliente>;
  public listadoSolicitadoA: Array<{ id: number, text: string }> = [];;
  public editarActivo: boolean = true;
  public editarDetalles: boolean;
  public editarDetallesBotonGuardar: boolean = false;
  public ver: boolean = false;
  public personalLogueado: Personal | IdNombreCodigo;
  public listaNuevoPedido: Array<fondoDetalle> = [];
  public contactosSeleccionados: Array<ContactoCliente> = [];
  public saldosClientes: SaldoGrupoEconomico[] = [];
  public saldoCliente: ConsultaSaldoGrupoEconomico = this.defaultObjectConsultaSaldos();

  constructor(private formbuilder: FormBuilder,
    private authService: AuthService,
    private clienteBaseService: ClienteBaseService,
    private contactosClientesService: ContactosClientesService,
    private fondosService: FondosService,
    private mailService: MailService,
    private googleSheetService: GoogleSheetService,
    private clienteService: ClienteService) {
    this.formFitros = formbuilder.group({
      responsable: [{ value: null, disabled: true }],
      cliente: [null]
    });
    this.formNuevoPedido = formbuilder.group({
      tramite: [null, Validators.required],
      solicitado: [null, Validators.required],
      mail: [null, Validators.required],
      concepto: [null, Validators.required],
      importe: [null, Validators.required],
      cpraCli: [null],
      totalPedido: [{ value: null, disabled: true }],
      // fondosDisponibles: [{ value: null, disabled: true }],
      editarMail: [null],
    });
  }

  ngOnInit(): void {
    this.personalLogueado = this.authService.getUserLogged();
    this.findCliente();
    this.findAllContacto();
  }

  public findAllContacto() {

    this.contactosClientesService.findClientesContactos({
      pageNumber: 1,
      pageSize: 1000,

    }).subscribe(response => {
      this.solicitadoA = response.content;
      this.listadoSolicitadoA = this.solicitadoA.map(c => ({ id: c.contacto.id, text: c.contacto.descripcion }));

    });
  }
  public findCliente() {
    forkJoin([
      this.clienteBaseService.findAll({ habilitado: HABILITADO.SI }),

    ]).subscribe(([clientes]) => {

      this.clientes = clientes.content;
      this.listadoClientes = this.clientes.map(c => ({ id: c.id, text: c.nombre }));
    });
  }
  public sumaTotalPedidoActual() {
    let acum = 0;
    this.listaNuevoPedido.forEach(pedido => {
      if (!pedido.cpraCli) {
        acum += pedido.importe;
      }
    })
    this.formNuevoPedido.controls.totalPedido.setValue(acum);
  }

  public altaFondo() {
    this.ver = false;
    this.formFitros.reset();
    this.formNuevoPedido.reset();
    this.formFitros.enable();
    this.formNuevoPedido.enable();
    this.formFitros.controls.responsable.disable();
    // this.formNuevoPedido.controls.fondosDisponibles.disable();
    this.formFitros.controls.responsable.setValue(this.personalLogueado.nombre);
    this.listaNuevoPedido = [];
    this.fondo = this.defaultObjectFondo();
    this.editarDetalles = false;
    this.openModalFondo();
  }
  public openModalFondo() {
    this.modalLoading.open();
    this.googleSheetService.findConsultaSaldosDeFondo().then(response => {
      (response.result.values as []).splice(0, 1);
      this.saldosClientes = (response.result.values as [string[]]).map(v => {
        return {
          codigoGrupoEconomico: v[0] || "-",
          codigoFondoFijo: v[1] || "-",
          cliente: v[2] || "-",
          saldo: v[4] || "0.00",
          fondoFijoAcordado: v[5] || "0.00",
        }
      });
      this.saldoCliente = this.defaultObjectConsultaSaldos();
      this.modalLoading.close();
      setTimeout(() => {
        modalUtils.open(this.modalAltaEditarVerPedidoDeFondos);
      }, 600);
    }).catch(error => {
      this.modalLoading.close();
      swal("¡Lo sentimos!", error?.result?.error?.message || ERROR_MESSAGES.CARGAR, "error");
    });
  }

  public openModalContactos() {
    this.contactos.openModal();
  }
  public editarTabla() {
    if (this.editarActivo) {
      this.editarActivo = false;
      this.formNuevoPedido.controls.concepto.enable();
      this.formNuevoPedido.controls.importe.enable();
      this.formNuevoPedido.controls.cpraCli.enable();
      this.editarDetallesBotonGuardar = true;
    }
    else {
      this.editarActivo = true;
      this.editarDetallesBotonGuardar = false;
      let detalle: fondoDetalle = { concepto: this.formNuevoPedido.controls.concepto.value, importe: this.formNuevoPedido.controls.importe.value, cpraCli: this.formNuevoPedido.controls.cpraCli.value, editar: true, recibio: 0, rindeCli: 0 };
      if (this.editarDetalles) {
        detalle.cpraCli = detalle.cpraCli ? 1 : 0;
        this.fondosService.createFondoDetalle(detalle, this.fondo.id).subscribe((fondo: Fondos) => {
          detalle.id = fondo.id;
        }
        );
      }
      this.listaNuevoPedido.push(detalle);
      this.formNuevoPedido.controls.concepto.reset();
      this.formNuevoPedido.controls.importe.reset();
      this.formNuevoPedido.controls.cpraCli.reset();
      this.formNuevoPedido.controls.concepto.disable();
      this.formNuevoPedido.controls.importe.disable();
      this.formNuevoPedido.controls.cpraCli.disable();

    }
    this.sumaTotalPedidoActual();
  }

  public editarCampoTabla(i: any) {
    let indice = this.listaNuevoPedido.indexOf(i);
    if (this.listaNuevoPedido[indice].editar) {
      this.formNuevoPedido.controls.concepto.enable();
      this.formNuevoPedido.controls.importe.enable();
      this.formNuevoPedido.controls.cpraCli.enable();
      this.formNuevoPedido.controls.concepto.setValue(this.listaNuevoPedido[indice].concepto);
      this.formNuevoPedido.controls.importe.setValue(this.listaNuevoPedido[indice].importe);
      this.formNuevoPedido.controls.cpraCli.setValue(this.listaNuevoPedido[indice].cpraCli);
      this.listaNuevoPedido[indice].editar = false;
      this.editarDetallesBotonGuardar = true;
    }
    else {
      this.listaNuevoPedido[indice].editar = true;
      this.editarDetallesBotonGuardar = false;
      this.listaNuevoPedido[indice].concepto = this.formNuevoPedido.controls.concepto.value;
      this.listaNuevoPedido[indice].importe = this.formNuevoPedido.controls.importe.value;
      this.listaNuevoPedido[indice].cpraCli = this.formNuevoPedido.controls.cpraCli.value;
      this.formNuevoPedido.controls.concepto.reset();
      this.formNuevoPedido.controls.importe.reset();
      this.formNuevoPedido.controls.cpraCli.reset();
      this.formNuevoPedido.controls.concepto.disable();
      this.formNuevoPedido.controls.importe.disable();
      this.formNuevoPedido.controls.cpraCli.disable();
      if (this.editarDetalles) {
        this.listaNuevoPedido[indice].cpraCli = this.listaNuevoPedido[indice].cpraCli ? 1 : 0;
        this.fondosService.editFondoDetalle(this.listaNuevoPedido[indice]).subscribe((fondo: Fondos) => {
          swal({
            title: "Guardado",
            text: "El registro se guardó exitosamente.",
            icon: "success",
            buttons: false,
            timer: 1600
          }), (error) => {
            swal("¡Lo sentimos!", error.error?.message || "Ocurrió un error al aplicar los cambios.", "error");
          }
        }
        );
      }
    }
    this.sumaTotalPedidoActual()
  }
  public eliminarFilaTabla(objeto: any) {
    let indice = this.listaNuevoPedido.indexOf(objeto);
    if (this.editarDetalles) {
      this.fondosService.deleteDetalle(this.listaNuevoPedido[indice]).subscribe((fondo: Fondos) => {
        swal({
          title: "Guardado",
          text: "El registro se guardó exitosamente.",
          icon: "success",
          buttons: false,
          timer: 1600
        }), (error) => {
          swal("¡Lo sentimos!", error.error?.message || "Ocurrió un error al aplicar los cambios.", "error");
        }
      }
      );
    }
    this.listaNuevoPedido.splice(indice, 1);
    this.sumaTotalPedidoActual();

  }
  public closeEditar(){
    this.editarActivo=!this.editarActivo;
    this.editarDetallesBotonGuardar = false;
    this.formNuevoPedido.controls.concepto.reset();
    this.formNuevoPedido.controls.importe.reset();
    this.formNuevoPedido.controls.cpraCli.reset();
    this.formNuevoPedido.controls.concepto.disable();
    this.formNuevoPedido.controls.importe.disable();
    this.formNuevoPedido.controls.cpraCli.disable();
  }
  public valueRequired(value: string, formNuevoPedido: FormGroup) {
    return formNuevoPedido.controls[value].hasError("required") && (formNuevoPedido.controls[value].dirty || formNuevoPedido.controls[value].touched);
  }
  public getContacto(contactosSeleccionados: Array<ContactoCliente>) {
    this.closeListadoContactos(contactosSeleccionados)
    let contactos = { nombres: '', emails: '' }
    contactosSeleccionados.map(c => {
      contactos.nombres = contactos.nombres + (contactos.nombres != '' ? ', ' + c.contacto.descripcion : c.contacto.descripcion)
      contactos.emails = contactos.emails + (contactos.emails ? ', ' : '') + c.contacto.email
    })
    contactos.nombres = this.eliminarNombresRepetidos(contactos.nombres)
    contactos.emails = this.eliminarMailsRepetidos(contactos.emails)
    this.formNuevoPedido.controls.mail.setValue(contactos.emails)
    this.formNuevoPedido.controls.solicitado.setValue(contactos.nombres)
  }
  public eliminarMailsRepetidos(mails: string) {
    return [...new Set(mails.replace(/\s+/g, "").split(","))].join(", ")
  }
  public eliminarNombresRepetidos(data: string) {
    let nombres = data.split(', ')
    nombres = nombres.filter((item, index) => {
      return nombres.indexOf(item) === index;
    })
    return nombres.join(', ')
  }
  public closeListadoContactos(event) {
    setTimeout(() => {
      this.openModalFondo();
      this.selectCliente();
    }, 700);

  }
  public editarFondo() {
    this.editarDetalles = true;
    this.ver = false;
    this.formFitros.enable();
    this.formNuevoPedido.enable();
    this.formFitros.controls.responsable.disable();
    // this.formNuevoPedido.controls.fondosDisponibles.disable();
    this.formFitros.setValue({
      responsable: this.fondoSeleccionado.personal?.nombre,
      cliente: this.fondoSeleccionado.clienteFisico?.id,
    });

    this.formNuevoPedido.setValue({
      tramite: this.fondoSeleccionado.tramite,
      solicitado: this.fondoSeleccionado.pedidoA,
      mail: this.fondoSeleccionado.mail,
      totalPedido: this.fondoSeleccionado.total,
      concepto: null,
      importe: null,
      cpraCli: null,
      // fondosDisponibles: null,
      editarMail: null,
    });
    this.formNuevoPedido.controls.concepto.disable();
    this.formNuevoPedido.controls.importe.disable();
    this.listaNuevoPedido = this.fondoSeleccionado.detalle;
    this.listaNuevoPedido.forEach(detalle => {
      detalle.editar = true;
    })
    this.fondo = this.fondoSeleccionado;
    modalUtils.open(this.modalAltaEditarVerPedidoDeFondos);
  }

  public verPedidoFondos() {
    this.ver = true;
    this.formFitros.setValue({
      responsable: this.fondoSeleccionado.personal?.nombre,
      cliente: this.fondoSeleccionado.clienteFisico?.id,
    });

    this.formNuevoPedido.setValue({
      tramite: this.fondoSeleccionado.tramite,
      solicitado: this.fondoSeleccionado.pedidoA,
      mail: this.fondoSeleccionado.mail,
      totalPedido: this.fondoSeleccionado.total,
      concepto: null,
      importe: null,
      cpraCli: null,
      // fondosDisponibles: null,
      editarMail: null,
    });
    this.listaNuevoPedido = this.fondoSeleccionado.detalle;
    this.listaNuevoPedido.forEach(detalle => {
      detalle.editar = true;
    })
    this.formFitros.disable();
    this.formNuevoPedido.disable();
    modalUtils.open(this.modalAltaEditarVerPedidoDeFondos);
  }

  private defaultObjectFondo(): Fondos {
    return {
      id: null,
      fecha: null,
      clienteFisico: {
        id: null,
        cuit: null,
        nombre: null,
      },
      grupo: null,
      tramite: null,
      personal: {
        id: null,
        codigo: null,
        nombre: null,
        email: null,
        firma: null
      },
      pedidoA: null,
      mail: null,
      firma: null,
      subGrupo: null,
      recibe: null,
      total: null,
      detalle: [],
      amarillo: null,
      rojo: null
    };
  }
  public guardarAlta($event, value: any, valueFiltro: any): void {
    $event.preventDefault();
    this.formNuevoPedido.markAllAsTouched();
    if (this.formNuevoPedido.valid) {

      this.fondo.tramite = value.tramite;
      this.fondo.personal = this.personalLogueado;
      this.fondo.pedidoA = value.solicitado;
      this.fondo.clienteFisico = this.clientes.find(c => c.id === valueFiltro.cliente) || null;
      this.fondo.mail = value.mail;
      this.listaNuevoPedido.forEach(detalle => {
        detalle.cpraCli = detalle.cpraCli ? 1 : 0;
      });
      this.fondo.total = value.totalPedido;
      this.fondo.recibe = 0;

      if (!this.fondo.id) {
        this.fondo.detalle = this.listaNuevoPedido;
      }
      this.guardar();
    }
  }
  public alertaEnvioSi(fondo: Fondos){
    swal({
      title: 'Guardado',
      text: 'El fondo se guardó correctamente.',
      icon: 'success',
      buttons: false,
      timer: 1600
    });
    this.guardado.emit(fondo);
    this.modalAltaEditarVerPedidoDeFondos.hide();
  }
  public alertaEnvioNo(fondo: Fondos){
    swal({
      title: 'Guardado',
      text: 'El fondo se guardó correctamente.',
      icon: 'success',
      buttons: false,
      timer: 1600
    });
    this.modalAltaEditarVerPedidoDeFondos.hide();
    setTimeout(() => {
      this.mailService.solicitudAltaDeFondos(fondo.id);
    }, 1600);
    this.guardado.emit(fondo);
  }
  public guardar() {
    if(this.fondo?.id){
      if(!this.formNuevoPedido.value.editarMail){
        swal({
          title: "¿Desea informar a través del envió de mails automáticos la edición realizada?",
          icon: "warning",
          buttons: {
            cancel: { text: "No", value: null, visible: true, className: "", closeModal: true },
            confirm: { text: "Si", value: true, visible: true, className: "bg-blue", closeModal: false }
          }
        }).then((isConfirm) => {
          if (isConfirm) {
              this.fondosService.editFondo(this.fondo, false, true).subscribe((fondo: Fondos) => {
                this.alertaEnvioSi(fondo);
              },
                error => swal('¡Lo sentimos!', error.error.message, 'error'));
          } else {
            this.fondosService.editFondo(this.fondo, false, false).subscribe((fondo: Fondos) => {
              this.alertaEnvioSi(fondo);
            },
              error => swal('¡Lo sentimos!', error.error.message, 'error'));
        }
        });
      }else{
        this.fondosService.editFondo(this.fondo, true, true).subscribe((fondo: Fondos) => {
          this.alertaEnvioNo(fondo);
        },
          error => swal('¡Lo sentimos!', error.error.message, 'error'));
      }
    }else{
      if (!this.formNuevoPedido.value.editarMail) {
      swal({
        title: "Se enviará el mail de alta de fondos al cliente de manera automática",
        text: "¿Estás seguro de realizar esta acción?",
        icon: "warning",
        buttons: {
          cancel: { text: "Cancelar", value: null, visible: true, className: "", closeModal: true },
          confirm: { text: "Enviar", value: true, visible: true, className: "bg-blue", closeModal: false }
        }
      }).then((isConfirm) => {
        if (isConfirm) {
            this.fondosService.createFondo(this.fondo, false).subscribe((fondo: Fondos) => {
              this.alertaEnvioSi(fondo);
            },
              error => swal('¡Lo sentimos!', error.error.message, 'error'));
        }
      });
    } else {
        this.fondosService.createFondo(this.fondo, true).subscribe((fondo: Fondos) => {
          this.alertaEnvioNo(fondo);
        },
          error => swal('¡Lo sentimos!', error.error.message, 'error'));
    }
    }
  }
  public selectCliente() {
    if(this.tituloModal == 'Alta pedido de fondos'){
    this.saldoCliente = this.defaultObjectConsultaSaldos();
    if (this.formFitros.value.cliente) {
      forkJoin([
        this.clienteService.findById(this.formFitros.value.cliente),
        this.fondosService.findAll({ clienteId: this.formFitros.value.cliente })
      ]).subscribe(([cliente, tramites]) => {
        let clienteSaldo = this.saldosClientes.find(c => c.codigoFondoFijo.replace(/\s/g, '') === cliente.codigoFondoFijo);
        this.saldoCliente.saldoAFavor = clienteSaldo?.saldo ? +clienteSaldo.saldo.replace(/,/g, '') : 0;
        this.saldoCliente.fondoFijoAcordado = clienteSaldo?.fondoFijoAcordado ? +clienteSaldo.fondoFijoAcordado.replace(/,/g, '') : 0;
        tramites.content.forEach(tramite => {
          if (!tramite.firma) {
            this.saldoCliente.tramites.push(tramite);
            this.saldoCliente.fondoPendienteIngreso += tramite.total;
          }
        });
        this.saldoCliente.fondoDisponibles = this.saldoCliente.saldoAFavor + this.saldoCliente.fondoFijoAcordado - this.saldoCliente.fondoPendienteIngreso;
      });
    }
  }
  }
  public defaultObjectConsultaSaldos(): ConsultaSaldoGrupoEconomico {
    return {
      grupoEconomico: null,
      saldoAFavor: 0,
      fondoFijoAcordado: 0,
      fondoDisponibles: 0,
      fondoPendienteIngreso: 0,
      tramites: []
    }
  }
}
