export class DocumentoBase {

    id: number;
    nombre: string = null;
    url_archivo: string = null;
    formato: string = null;
    observacion: string;

    constructor({
        id = null,
        nombre = null,
        url_archivo = null,
        formato = null,
        observacion = null
    } = {}) {
        this.id = id;
        this.nombre = nombre;
        this.url_archivo = url_archivo;
        this.formato = formato;
        this.observacion = observacion;
    }
}
