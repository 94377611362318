import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { forkJoin, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { Documento } from 'src/app/classes/documento';
import { CategoriaDocumento } from 'src/app/interfaces/categoria-documento';
import { DocumentoBase } from 'src/app/interfaces/documento-base';
import { DocumentacionService } from 'src/app/services/documentacion.service';
import { Documento as IDocumento } from "../../../interfaces/documento";
const swal = require('sweetalert');

@Component({
  selector: 'app-documento',
  templateUrl: './documento.component.html',
  styleUrls: ['./documento.component.scss']
})
export class DocumentoComponent implements OnInit {

  public uploader: FileUploader = new FileUploader({});

  public fileInputId: string;

  @ViewChild('inputUploader') inputUploader: ElementRef;

  @Input() set documento(value: IDocumento) {
    this._documento = value;
  }
  @Input() requireConfirmation: boolean;
  @Input() eliminable: boolean = true;

  public _documento: IDocumento;

  public _loading: boolean;
  public _params: Array<{ categoria: string, id: number }>;
  public _uploadSingle: boolean = false;
  public _categoriaId: string;

  @Input() set loading(value: boolean) {
    this._loading = value;
  }
  @Input() set categoriaId(value: string) {
    this._categoriaId = value;
  }
  @Input() set uploadSingle(value: boolean) {
    this._uploadSingle = value;
  }
  @Input() set params(value: Array<{ categoria: string, id: number }>) {
    this._params = value;
  }

  public _seleccionado: boolean;
  public _soloLectura: boolean;
  public _multiSelect: boolean;

  @Input() set soloLectura(value: boolean) {
    this._soloLectura = value;
  }
  @Input() set seleccionado(vaule: boolean) {
    this._seleccionado = vaule;
  }
  @Input() set multiSelect(value: boolean) {
    this._multiSelect = value;
  }

  @Output() agregarInicia = new EventEmitter<File[]>();
  @Output() agregar = new EventEmitter<IDocumento>();
  @Output() agregarFinalizado = new EventEmitter<IDocumento[]>();
  @Output() agregarError = new EventEmitter<string>();

  @Output() seleccionar = new EventEmitter<IDocumento>();

  @Output() eliminar = new EventEmitter<IDocumento>();

  @Output() editar = new EventEmitter<IDocumento>();

  public editInline;

  constructor(private documentacionService: DocumentacionService) {

    if (this.documento?.nombre) {
      this.fileInputId = this.documento?.nombre ? this.documento?.nombre : 'fileId';
    }
  }

  ngOnInit(): void { }

  public seleccionarDocumento(documento: IDocumento) {
    this.seleccionar.emit(documento);
  }

  public agregarDocumento(documento: IDocumento) {
    if (this._uploadSingle && this._params) {
      documento.seleccionado = true;
      this.uploadDocumento(documento);
    }
    this.agregar.emit(documento);
  }

  public eliminarDocumento(documento: IDocumento) {
    if (this._uploadSingle && this._params) {
      this.deleteDocumento(documento);
    }
    this.eliminar.emit(documento);
  }

  public uploadDocumento(documento: DocumentoBase) {
    this.loading = true;
    this.documentacionService.agregar(this._params[0]?.categoria, this._params[0]?.id, this._categoriaId, documento, null, this._params[1]?.categoria, this._params[1]?.id, this._params[2]?.categoria, this._params[2]?.id)
      .subscribe((response: IDocumento) => {
        this.loading = false;
        response.seleccionado = true;
        documento = response;
        this._documento = response;
        swal({ title: 'Guardado', text: 'La operación fue realizada exitosamente', icon: 'success', buttons: false, timer: 1000 });
      });
  }

  public deleteDocumento(documento: DocumentoBase) {
    swal({
      title: '¿Estás seguro?',
      text: 'El registro se eliminará de forma permanente en el sistema.',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'No, cancelar!',
          value: null,
          visible: true,
          className: "",
          closeModal: true
        },
        confirm: {
          text: 'Si, continuar!',
          value: true,
          visible: true,
          className: "bg-danger",
          closeModal: false
        }
      }
    }).then((isConfirm) => {
      if (isConfirm) {
        /** Confirmación  **/
        this.documentacionService.eliminar(this._params[0]?.categoria, this._params[0]?.id, this._categoriaId, documento, this._params[1]?.categoria, this._params[1]?.id, this._params[2]?.categoria, this._params[2]?.id).subscribe((response: DocumentoBase) => {
          swal({ title: '¡Operación exitosa!', text: 'La operación fue realizada exitosamente.', icon: 'success', buttons: false, timer: 1000 });
          this._documento = new Documento();
        });

        /** Confirmación  **/
      } else {
        swal('¡Cancelado!', 'La acción se canceló.', 'error');
      }
    });

  }

  /** Upload */
  public async onSelected(files: FileList) {

     let auxFiles = Array.from(files)
      .filter(f => f.size)
      .filter(f => f.name.split(".").length > 1);

    if (auxFiles.length !== files.length) {
      swal('Lo sentimos!', 'Alguno de los archivos está vacío o no tiene extensión.', 'error');
    }

    if (!auxFiles.length)
      return;

      let confirm = this.requireConfirmation ?
      await swal({
        title: 'Estás seguro?',
        text: 'El registro se subirá al sistema.\n' + auxFiles.map(f=> 'Nombre: ' + f.name + '\nTamaño: ' + Math.round(f.size/1024) + 'KB').join(',\n'),
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'No, cancelar!',
            value: null,
            visible: true,
            className: "",
            closeModal: true
          },
          confirm: {
            text: 'Si, continuar!',
            value: true,
            visible: true,
            className: "bg-danger",
            closeModal: true
          }
        }
      }) : true;

    if (!confirm)
      return;
    this.agregarInicia.emit(auxFiles);

    forkJoin(
      auxFiles.map((f: File) => {
        return this.readFile(f)
          .pipe(
            tap((d) => { this.agregarDocumento(d); }),
            catchError((error) => {
              this.agregarError.emit(error);
              return of(null);
            })
          )
      })).subscribe((documentos: Documento[]) => {
        let documentosAgregados = documentos.filter(d => d instanceof Documento);
        if (auxFiles.length !== documentosAgregados.length) {
          swal('Lo sentimos!', 'La operación no pudo ser completada con exito.', 'error');
        }
        this.agregarFinalizado.emit(documentosAgregados);
      });
  }

  public readFile(file: File): Observable<IDocumento> {

    return new Observable((observer) => {

      var reader = new FileReader();

      let documento: IDocumento = new Documento({
        nombre: file.name,
        formato: Documento.getFormato(file.name),
        fecha_carga: new Date().toJSON().substring(0, 10),
      });

      reader.addEventListener("load", () => {
        documento.url_archivo = reader.result;
        observer.next(documento);
        observer.complete();
      });

      reader.addEventListener("error", (event) => {
        observer.error();
      });

      reader.readAsDataURL(file);
    });
  }

  public getFormato(nombreArchivo: string): string {
    return Documento.getFormato(nombreArchivo);
  }
}
