import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {  from, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LibroSocietario, Movimiento } from '../interfaces/cliente-datos-societarios';
import { Page } from '../interfaces/page';

@Injectable({
  providedIn: 'root'
})
export class LibrosServiceService {
  recurso="/libros"

  constructor(private http: HttpClient) { 

    
  }
  public findAll({
    pageNumber = 1,
    pageSize = 1000,
    orderCriteria = "ASC",
    orderField = null,
    grupoEconomico = null,
    cliente = null,
    clienteHabilitado = null,
    organismo = null,
    tipoLibro = null,
    deposito = null,
    tipoMovimiento = null
  

  } = {}): Observable<Page<LibroSocietario>> {
    let params = new URLSearchParams([]);
    if (pageNumber) params.append("pageNumber", pageNumber.toString());
    if (pageSize) params.append("pageSize", pageSize.toString());
    if (orderCriteria) params.append("orderCriteria", orderCriteria);
    if (orderField) params.append("orderField", orderField);
    if (grupoEconomico) params.append("grupoEconomico", grupoEconomico.toString());
    if (cliente) params.append("cliente", cliente.toString());
    if (clienteHabilitado) params.append("clienteHabilitado", clienteHabilitado.toString());
    if (organismo) params.append("organismo", organismo.toString());
    if (tipoLibro) params.append("tipoLibro", tipoLibro.toString());
    if (deposito) params.append("deposito", deposito.toString());
    if (tipoMovimiento) params.append("tipoMovimiento", tipoMovimiento.toString());
   
   return this.http.get<Page<LibroSocietario>>(`${environment.apiEndpoint}${this.recurso}?${params.toString()}`);
  }
  public createLibro(libro: LibroSocietario): Observable<LibroSocietario> {
    return this.http.post<LibroSocietario>(`${environment.apiEndpoint}${this.recurso}`, libro);
  }

  public editLibro(libro: LibroSocietario): Observable<LibroSocietario> {
    return this.http.put<LibroSocietario>(`${environment.apiEndpoint}${this.recurso}/${libro.id}`, libro);
  }
  public deleteLibro(libro: LibroSocietario) {
    return this.http.delete(`${environment.apiEndpoint}${this.recurso}/${libro.id}`);
  }
}