import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { fondoDetalle, Fondos } from '../interfaces/fondos';

import { Page } from '../interfaces/page';

@Injectable({
  providedIn: 'root'
})
export class FondosService {

  recurso="/fondos"
  recursoDetalle="/fondosDetalle"


  constructor(private http: HttpClient) { 

  }
  public findAll({
 
    pageNumber = 1,
    pageSize = 1000,
    orderCriteria = "Desc",
    orderField = null,
    clienteId = null,
    grupoEconomicoId = null,
    desde= null,
    hasta= null,
    c_habilitado = null,
    cpraCliente = null, 
    estado = null

  } = {}): Observable<Page<Fondos>> {
    let params = new URLSearchParams([]);
    if (pageNumber) params.append("pageNumber", pageNumber.toString());
    if (pageSize) params.append("pageSize", pageSize.toString());
    if (orderCriteria) params.append("orderCriteria", orderCriteria);
    if (orderField) params.append("orderField", orderField);
    if (clienteId) params.append("clienteId", clienteId.toString());
    if (grupoEconomicoId) params.append("grupoEconomicoId", grupoEconomicoId.toString());
    if (desde) params.append("desde", desde.toString());
    if (hasta) params.append("hasta", hasta.toString());
    if (c_habilitado) params.append("c_habilitado", c_habilitado.toString());
    if (cpraCliente) params.append("cpraCliente", cpraCliente.toString());
    if (estado || estado === false) params.append("estado", estado.toString());

   return this.http.get<Page<Fondos>>(`${environment.apiEndpoint}${this.recurso}?${params.toString()}`);
  }
  
  public createFondo(fondo: Fondos, editar: boolean): Observable<Fondos> {
    let params = new URLSearchParams([]);
    if(editar) params.append("editar", editar.toString());
    return this.http.post<Fondos>(`${environment.apiEndpoint}${this.recurso}?${params.toString()}`, fondo);
  }

  public editFondo(fondo: Fondos, editar: boolean, email: boolean): Observable<Fondos> {
    let params = new URLSearchParams([]);
    if(editar) params.append("editar", editar.toString());
    if(email) params.append("email", email.toString());
    return this.http.put<Fondos>(`${environment.apiEndpoint}${this.recurso}/${fondo.id}?${params.toString()}`, fondo);
  }
  public aplicarFondo(fondo: Fondos): Observable<Fondos> {
    return this.http.put<Fondos>(`${environment.apiEndpoint}${this.recurso}/aplicar/${fondo.id}`, fondo);
  }
  public deleteFondo(fondo: Fondos) {
    return this.http.delete(`${environment.apiEndpoint}${this.recurso}/${fondo.id}`);
  }
  public createFondoDetalle(detalle: fondoDetalle, idFondo: number): Observable<fondoDetalle> {
    return this.http.post<fondoDetalle>(`${environment.apiEndpoint}${this.recursoDetalle}/${idFondo}`, detalle);
  }

  public editFondoDetalle(detalle: fondoDetalle): Observable<fondoDetalle> {
    return this.http.put<fondoDetalle>(`${environment.apiEndpoint}${this.recursoDetalle}/${detalle.id}`, detalle);
  }
  public deleteDetalle(detalle: fondoDetalle) {
    return this.http.delete(`${environment.apiEndpoint}${this.recursoDetalle}/${detalle.id}`);
  }

}
