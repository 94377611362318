import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from "@angular/router";
import { Observable } from "rxjs";
import { Authorities } from "../enums/authorities";
const swal = require("sweetalert");
import { AuthService } from "../services/auth.service";

@Injectable({
  providedIn: "root"
})
export class AuthGuard implements CanActivate {
  constructor(public authService: AuthService, public router: Router) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    localStorage.setItem("sige-last-url", state.url);
    if (!this.authService.isUserLoggedIn()) {
      // swal('Error de autenticación!', 'Debes iniciar sesión para acceder al sistema.', 'error');
      this.router.navigate(["login"]);
    } else {
      if (!this.authService.userHasAuthorities()) {
        swal("Esperando autorización!", "Un administrador del sistema le asignará los permisos correspondientes para poder ingresar al sistema.", "info");
        this.router.navigate(["login"]);
      } else {

        let authorities: Array<Authorities> = next.data.authorities;

        if (authorities?.length === 0) {
          return true;
        }

        if (!this.authService.isAuthorized(authorities)) {
          swal("Permiso denegado!", "No tiene los permisos requeridos para acceder a este sector del sistema.", "info");
          this.router.navigate(this.authService.getHomeByAuthorities());
        }

        return true;
      }
    }
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(next, state);
  }
}
