import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

//Servicios
import { AbmObligacionesService } from 'src/app/services/abm-obligaciones.service';

//Interfaces
import { Page } from 'src/app/interfaces/page';
import { AltaObligacion } from 'src/app/interfaces/alta-obligacion';

import { ExportarDatosComponent } from '../exportar-datos/exportar-datos.component';

import { HABILITADO } from 'src/app/enums/habilitado';

@Component({
  selector: 'app-control-obligaciones-asignadas',
  templateUrl: './control-obligaciones-asignadas.component.html',
  styleUrls: ['./control-obligaciones-asignadas.component.scss']
})
export class ControlObligacionesAsignadasComponent implements OnInit {

  @ViewChild("exportarDatos") exportarDatos: ExportarDatosComponent;
  @ViewChild("controlObligacionesAsignadas") modal: ModalDirective;

  public listadoSiNo: Array<any> = [{ id: true, text: "SI" }, { id: false, text: "NO" }];
  public obligaciones: Array<AltaObligacion> = [];
  public listadoObligaciones: any[];
  public filtroObligaciones: number;

  public obligacionesAsignadas: any[];
  public totalObligacionesAsignadas: number = 0;

  public asignada: boolean = true;
  public habilitado: HABILITADO = HABILITADO.SI;

  //Exportar
  public listadoExportar: Array<any>;
  public exportConfig: any;

  constructor(private obligacionesService: AbmObligacionesService) { }

  ngOnInit(): void {
    this.obligacionesService.findObligaciones({ habilitado: this.habilitado }).subscribe((response: Page<AltaObligacion>) => {
      this.obligaciones = response.content;
      this.listadoObligaciones = this.obligaciones.map(a => a.denominacion + " - " + a.tipoObligacion.descripcion);
    });
  }

  public seleccionDeObligaciones(value: any) {
    this.filtroObligaciones = this.obligaciones.find(obligaciones => obligaciones.denominacion + " - " + obligaciones.tipoObligacion.descripcion === value)?.id;
    this.exportConfig.title = "Control de obligaciones asignadas. Obligacion:  " + value;
    this.findObligacionesAsignadas();
  }

  public findObligacionesAsignadas() {
    this.obligacionesService.findObligacionesAsignadas({ obligacion: this.filtroObligaciones, asignados: this.asignada }).subscribe((response: Page<AltaObligacion>) => {
      this.obligacionesAsignadas = response.content;
      this.totalObligacionesAsignadas = this.obligacionesAsignadas.length;
    });
  }

  public seleccionDeAsignadas($event) {
    this.asignada = $event[0]?.value;
    if (this.filtroObligaciones)
      this.findObligacionesAsignadas();
  }

  public mapListadoImprimir() {
    this.exportarDatos.openModal();
    this.exportConfig = {
      title: "Obligaciones asignadas",
      "showColumns": ["nombre", "fechaAlta", "fechaBaja"],
      "aliasColumns": {
        nombre: "Nombre", fechaAlta: "Fecha Alta", fechaBaja: "Fecha Baja"
      }
    }
    this.listadoExportar = this.obligacionesAsignadas.map(o => {
      return {
        nombre: o.clienteFisicoBaseDto.nombre,
        fechaAlta: o.fechaAlta,
        fechaBaja: o.fechaBaja,
      }
    });
  }

  public openModal() {
    this.modal.show();
  }

}
