import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { environment } from "../../environments/environment";
import { IdDescripcionCod } from "../interfaces/id-descripcion-cod";
import { CortesAuditoria } from "../interfaces/cortes-auditoria";
import { Page } from "../interfaces/page";
import { ProgramacionCortes } from "../interfaces/programacion-cortes";
import * as moment from "moment";
import { CategoriaDocumento, CategoriaDocumentoIdEnum } from "../interfaces/categoria-documento";
import { PersonaFacturacion as IPersonaFacturacion, Valorizacion as IValorizacion } from "../interfaces/valorizacion";
import { HABILITADO } from "../enums/habilitado";

@Injectable({
  providedIn: "root"
})
export class AuditoriaService {

  recurso: string = "/auditoria";

  constructor(private http: HttpClient) { }

  public findAll({
    pageNumber = 1,
    pageSize = 1000,
    q = null,
    fechaDesdeCierre = null,
    fechaHastaCierre = null,
    estado = null,
    responsableId = null,
    asistenteId = null,
    subAsistente = null,
    clienteId = null,
    tipoCierre = null,
    grupoEconomicoId = null,
    outsourcing = null,
    digitoVerificador = null,
    valorizacion = null,
    archivos = null,
    archivosPendientes = null,
    orderCriteria = null,
    orderField = null,
    habilitado = HABILITADO.TODOS,
    rol = null,
    personalOutsourcing = null
  } = {}): Observable<Page<CortesAuditoria>> {
    let params = new URLSearchParams([]);
    if (pageNumber) params.append("pageNumber", pageNumber.toString());
    if (pageSize) params.append("pageSize", pageSize.toString());
    if (q) params.append("q", q);
    if (fechaDesdeCierre) params.append("fechaDesdeCierre", fechaDesdeCierre);
    if (fechaHastaCierre) params.append("fechaHastaCierre", fechaHastaCierre);
    if (estado) params.append("estado", estado);
    if (responsableId) params.append("responsableId", responsableId);
    if (asistenteId) params.append("asistenteId", asistenteId);
    if (subAsistente) params.append("subAsistente", subAsistente);
    if (clienteId) params.append("clienteId", clienteId);
    if (tipoCierre) params.append("tipoCierre", tipoCierre);
    if (grupoEconomicoId) params.append("grupoEconomicoId", grupoEconomicoId);
    if (outsourcing) params.append("outsourcing", outsourcing);
    if (digitoVerificador) params.append("digitoVerificador", digitoVerificador);
    if (valorizacion) params.append("valorizacion", valorizacion);
    if (archivos) params.append("archivos", archivos);
    if (archivosPendientes) params.append("archivosPendientes", archivosPendientes);
    if (orderCriteria) params.append("orderCriteria", orderCriteria);
    if (orderField) params.append("orderField", orderField);
    if (habilitado) params.append("habilitado", habilitado);
    if (rol) params.append("rol", rol);
    if (personalOutsourcing) params.append("personalOutsourcing", personalOutsourcing);
    return this.http.get<Page<CortesAuditoria>>(`${environment.apiEndpoint}${this.recurso}?${params.toString()}`);
  }

  public findAllCortes() {
    let endpoint = "/auditoria";
    return this.http.get(environment.apiEndpoint + endpoint);
  }
  public findCortes(id: number) {
    let endpoint = this.recurso + "?clienteId=" + id;
    return this.http.get(environment.apiEndpoint + endpoint);
  }
  public createCorte(corte: CortesAuditoria) {
    let endpoint = this.recurso + "/addCorte";
    return this.http.post(environment.apiEndpoint + endpoint, corte);
  }
  public editCorte(corte: CortesAuditoria) {
    let endpoint = this.recurso + "/edit";
    return this.http.put(environment.apiEndpoint + endpoint, corte);
  }
  public deleteCorte(corte: CortesAuditoria) {
    let endpoint = this.recurso + "/delete/" + corte.id;
    return this.http.delete(environment.apiEndpoint + endpoint);
  }
  public programarCortes(programacionCortes: ProgramacionCortes) {
    let endpoint = "/programacionAuditoria";
    return this.http.post(environment.apiEndpoint + endpoint, programacionCortes);
  }

  public findClientesContables({
    pageNumber = 1,
    pageSize = 10000,
    orderCriteria = "ASC",
    orderField = "clfApeyno",
    grupoEconomicoId = null,
    habilitado = true,
  } = {}) {
    let params = new URLSearchParams([]);
    if (pageNumber) params.append("pageNumber", pageNumber.toString());
    if (pageSize) params.append("pageSize", pageSize.toString());
    if (orderCriteria) params.append("orderCriteria", orderCriteria.toString());
    if (orderField) params.append("orderField", orderField.toString());
    if (grupoEconomicoId) params.append("grupoEconomicoId", grupoEconomicoId.toString());
    if (habilitado || habilitado === false) params.append("habilitado", habilitado.toString());
    return this.http.get(`${environment.apiEndpoint}/clientes/contables?${params.toString()}`);
  }

  public findResponsables() {
    let endpoint = "/responsables/02";
    return this.http.get(environment.apiEndpoint + endpoint);
  }
  public findAsistentes() {
    let endpoint = "/asistentes/02";
    return this.http.get(environment.apiEndpoint + endpoint);
  }
  public findSubAsistente() {
    let endpoint = "/subasistentes/02";
    return this.http.get(environment.apiEndpoint + endpoint);
  }
  public findGruposEconomicos(habilitado?: string) {
    if (!habilitado)
      habilitado = "TODOS";
    let endpoint = "/gruposeconomicos?habilitado=" + habilitado;
    return this.http.get(environment.apiEndpoint + endpoint);
  }
  public findTipoCierre(): Observable<Page<IdDescripcionCod>> {
    let endpoint = "/taopcion/tipocierre";
    return this.http.get<Page<IdDescripcionCod>>(environment.apiEndpoint + endpoint);
  }
  public findArchivos(): Observable<Page<IdDescripcionCod>> {
    let endpoint = "/taopcion/archivos";
    return this.http.get<Page<IdDescripcionCod>>(environment.apiEndpoint + endpoint);
  }

  public cierreResponsable(corte) {
    let endpoint = this.recurso + "/" + corte.id + "/cerrar/responsable";
    return this.http.get(environment.apiEndpoint + endpoint);
  }

  public eliminarCierreResponsable(corte: CortesAuditoria): Observable<CortesAuditoria> {
    return this.http.get<CortesAuditoria>(`${environment.apiEndpoint}${this.recurso}/${corte.id}/eliminarcierre/responsable`);
  }

  public cierreCorte(corte) {
    let endpoint = this.recurso + "/" + corte.id + "/cerrar";
    return this.http.get(environment.apiEndpoint + endpoint);
  }
  public abrirCorte(corte) {
    let endpoint = this.recurso + "/" + corte.id + "/abrir";
    return this.http.get(environment.apiEndpoint + endpoint);
  }
  public archivosFinales(corte) {
    let endpoint = this.recurso + "/" + corte.id + "/archivosfinales";
    return this.http.get(environment.apiEndpoint + endpoint);
  }
  public cierreResponsableRequiereObservacion(corte: CortesAuditoria): Observable<[boolean, string]> {
    let requiereObservacion = moment().isAfter(moment(corte.fecha_vencimiento), "day");
    let msj = requiereObservacion ? "Debés indicar un motivo por el cual no se realizó la acción en tiempo y forma." : null;
    return of([requiereObservacion, msj]);
  }

  public calcularDiferenciaEnDias(fecha1, fecha2) {
    const newFecha1 = fecha1.toLocaleString().split('-').join('')
    const newFecha2 = fecha2.toLocaleString().split('-').join('')
    const diferenciaEnDias = newFecha2 - newFecha1
    return diferenciaEnDias;
  }
  public agregarDocumentoRequiereObservacion(corte: CortesAuditoria, categoria: CategoriaDocumento): Observable<[boolean, string]> {
    let categorias = [
      CategoriaDocumentoIdEnum.EECC_PDF,
      CategoriaDocumentoIdEnum.EECC_EXCEL,
      CategoriaDocumentoIdEnum.EECC_CERTIFICADO,
      CategoriaDocumentoIdEnum.EECC_PRESENTACION_WEB_BALANCE,
      CategoriaDocumentoIdEnum.SUMAS_Y_SALDOS
    ];

    let condicionFecha
    if (categoria.id == CategoriaDocumentoIdEnum.SUMAS_Y_SALDOS) {
      const diferenciaEnDias = this.calcularDiferenciaEnDias(moment().format().split('T')[0], corte.fecha_vencimiento)
      condicionFecha = diferenciaEnDias < 15
    } else condicionFecha = moment().isAfter(moment(corte?.fecha_vencimiento), "day")
    let requiereObservacion = categorias.includes(categoria.id as CategoriaDocumentoIdEnum) && condicionFecha;
    let msj = requiereObservacion ? "Debés indicar un motivo por el cual no se realizó la acción en tiempo y forma." : null;

    return of([requiereObservacion, msj]);
  }

  public valorizar(corte): Observable<Array<IValorizacion>> {
    let endpoint = this.recurso + "/" + corte.id + "/valorizacion";
    return this.http.get<Array<IValorizacion>>(environment.apiEndpoint + endpoint);
  }

  public valorizarPresupuesto(corteId: number, valorizacion: number) {
    return this.http.post(`${environment.apiEndpoint}${this.recurso}/${corteId}/valorizacion/presupuestar`, valorizacion);
  }

  public valorizarFacturar(corteId: number, factura: IValorizacion): Observable<IValorizacion> {
    return this.http.post<IValorizacion>(`${environment.apiEndpoint}${this.recurso}/${corteId}/valorizacion/facturar`, factura);
  }

  public valorizarFacturarEdit(corte, factura) {
    let endpoint = this.recurso + "/" + corte.id + "/valorizacion/facturar";
    return this.http.put(environment.apiEndpoint + endpoint, factura);
  }

  public valorizarFacturarEliminar(corte, factura) {
    return this.http.delete(`${environment.apiEndpoint}${this.recurso}/${corte.id}/valorizacion/facturar/${factura.id}`);
  }

  public getPersonalParticipa(corteId: number): Observable<Array<IPersonaFacturacion>> {
    return this.http.get<Array<IPersonaFacturacion>>(`${environment.apiEndpoint}${this.recurso}/${corteId}/valorizacion/personalparticipa`);
  }

  public getComprobantesPorPeriodo({
    desde = null,
    hasta = null,
    clienteId = null,
    clienteHabilitado = null,
    grupoEconomicoId = null,
    personalId = null
  } = {}) {
    let endpoint = "/reportes/auditoria/comprobantes/periodos";
    let params = new URLSearchParams([]);
    if (desde) params.append("desde", desde);
    if (hasta) params.append("hasta", hasta);
    if (clienteId) params.append("clienteId", clienteId);
    if (clienteHabilitado) params.append("clienteHabilitado", clienteHabilitado);
    if (grupoEconomicoId) params.append("grupoEconomico", grupoEconomicoId);
    if (personalId) params.append("personalId", personalId);
    return this.http.get(`${environment.apiEndpoint}${endpoint}?${params.toString()}`);
  }

  public getComprobantesPorCliente({
    periodos = null,
    clienteId = null,
    clienteHabilitado = null,
    grupoEconomicoId = null,
    personalId = null,
  } = {}) {
    let endpoint = "/reportes/auditoria/comprobantes/clientes";
    let params = new URLSearchParams([]);
    if (periodos) params.append("periodos", periodos);
    if (clienteId) params.append("clienteId", clienteId);
    if (clienteHabilitado) params.append("clienteHabilitado", clienteHabilitado);
    if (grupoEconomicoId) params.append("grupoEconomico", grupoEconomicoId);
    if (personalId) params.append("personalId", personalId);
    return this.http.get(`${environment.apiEndpoint}${endpoint}?${params.toString()}`);
  }


  public getComprobantesPorAsociados({
    periodos = null,
    clienteId = null,
  } = {}) {
    let endpoint = "/reportes/auditoria/comprobantes/asociados";
    let params = new URLSearchParams([]);
    if (periodos) params.append("periodos", periodos);
    if (clienteId) params.append("clienteId", clienteId);
    return this.http.get(`${environment.apiEndpoint}${endpoint}?${params.toString()}`);
  }
}
